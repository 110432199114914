import { useContext, useMemo, useState } from 'react'
import { Box, Button, HStack } from '@chakra-ui/react'
import CompleteProfileHeader from './CompleteProfileHeader'
import { SelectableItem } from '../CustomDropdown/CustomDropdown'
import {
  CertificationRequirement,
  LicensingRequirement,
} from '../../types/userTypes'
import { TrainingCertificationContextType } from '../../types/trainingCertificationsTypes'
import { TrainingCertificationsContext } from '../../contexts/TrainingCertificationsContext'
import UpdateFirmsLicensingAndCertificationRequirementsForm from '../UpdateFirmsLicensingAndCertificationRequirementsForm/UpdateFirmsLicensingAndCertificationRequirementsForm'

const CompleteProfileUpdateFirmsLicensesAndCertifications = ({
  next,
  licensingRequirements,
  showLoader,
  certificationsRequirements,
}: {
  next: (a: CertificationRequirement[]) => void
  showLoader: boolean
  licensingRequirements?: LicensingRequirement[]
  certificationsRequirements?: CertificationRequirement[]
}) => {
  const { trainingCertifications }: TrainingCertificationContextType =
    useContext(TrainingCertificationsContext)

  //   const [licenses, setLicenses] = useState<LicensingRequirement[]>(
  //     licensingRequirements ?? []
  //   )
  const [
    additionalCertificationRequirements,
    setAdditionalCertificationRequirements,
  ] = useState<CertificationRequirement[]>(certificationsRequirements ?? [])

  const trainingCertificationsMapped: SelectableItem[] = useMemo(() => {
    return (
      trainingCertifications
        ?.map((m) => ({
          value: m._id,
          label: m.name,
          disabled: !!additionalCertificationRequirements.find(
            (l) => l.id === m._id
          ),
        }))
        ?.sort((a, b) => a.label.localeCompare(b.label)) ?? []
    )
  }, [additionalCertificationRequirements, trainingCertifications])

  //   const disableAddLicenseButton = useMemo(() => {
  //     return licenses.findIndex((l) => l.id === '') >= 0
  //   }, [licenses])

  const disableAddCertificationButton = useMemo(() => {
    return (
      additionalCertificationRequirements.findIndex(
        (l) => (l.isListed && l.id === '') || (!l.isListed && l.name === '')
      ) >= 0
    )
  }, [additionalCertificationRequirements])

  return (
    <Box width={['auto']}>
      <CompleteProfileHeader text="General or Professional Certification Requirements" />
      <UpdateFirmsLicensingAndCertificationRequirementsForm
        certifications={additionalCertificationRequirements}
        setCertifications={setAdditionalCertificationRequirements}
        disableAddCertificationButton={disableAddCertificationButton}
        trainingCertificationsMapped={trainingCertificationsMapped}
      />
      <HStack justify="end">
        <Button
          variant="adjustant"
          isLoading={showLoader}
          isDisabled={disableAddCertificationButton}
          onClick={() => next(additionalCertificationRequirements)}
        >
          Next
        </Button>
      </HStack>
    </Box>
  )
}
export default CompleteProfileUpdateFirmsLicensesAndCertifications
