import {
  Button,
  AlertDialogFooter,
  Checkbox,
  Box,
  Heading,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  HStack,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { TestimonialsConfig, AdminFirm } from '../../types/userTypes'
import { Job, Roster } from '../../types/jobTypes'
import { formatDate } from '../../utils/functions'

const UpdateTestimonialsConfigModal = ({
  jobsWhichWorkIsCompleted,
  firms,
  rosters,
  config,
  isOpen,
  onClose,
}: {
  jobsWhichWorkIsCompleted: Job[]
  firms: AdminFirm[]
  rosters: Roster[]
  config?: TestimonialsConfig
  isOpen: boolean
  onClose: (d: boolean, nc?: TestimonialsConfig) => void
}) => {
  const cancelRef = React.useRef(null)
  const [newConfig, setNewConfig] = useState(config)

  return (
    <Modal isOpen={isOpen} onClose={() => onClose(false)} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg" fontWeight="bold">
          Update testimonials config
        </ModalHeader>
        <ModalCloseButton onClick={() => onClose(false)} />

        <ModalBody>
          <Checkbox
            variant="adjustant"
            mb={4}
            isChecked={
              typeof newConfig?.show === 'undefined' || newConfig?.show
            }
            onChange={(e) => {
              setNewConfig((prev) => ({
                testimonialsToShow: prev?.testimonialsToShow ?? [],
                show: e.target.checked,
              }))
            }}
          >
            Show testimonials
          </Checkbox>

          <Heading as="h4" size="md" color="gray.500" mb={2}>
            Testimonials
          </Heading>

          {[
            ...rosters,
            // { ...rosters[0], _id: '1' },
            // { ...rosters[0], _id: '2' },
            // { ...rosters[0], _id: '3' },
            // { ...rosters[0], _id: '4' },
            // { ...rosters[0], _id: '5' },
          ].map((roster) => (
            <HStack key={roster._id} mb={2}>
              <Box>
                <Checkbox
                  variant="adjustant"
                  mb={4}
                  isDisabled={
                    newConfig?.testimonialsToShow?.length === 3 &&
                    !newConfig?.testimonialsToShow.includes(roster._id ?? '')
                  }
                  isChecked={newConfig?.testimonialsToShow.includes(
                    roster._id ?? ''
                  )}
                  onChange={(e) => {
                    setNewConfig((prev) => ({
                      show: prev?.show ?? true,
                      testimonialsToShow: e.target.checked
                        ? [
                            ...(prev?.testimonialsToShow ?? []),
                            roster?._id ?? '',
                          ]
                        : prev?.testimonialsToShow?.filter(
                            (t) => t !== roster._id
                          ) ?? [],
                    }))
                  }}
                >
                  {/* Show */}
                </Checkbox>
              </Box>
              <Box>
                <Text fontWeight={500} color="black" fontSize={16}>
                  “{roster.review?.testimonial}”
                </Text>
                <Text fontWeight={500} color="gray.500" fontSize={16}>
                  Issued {formatDate(roster.review?.date ?? '')}{' '}
                  {
                    firms.find((firm) => {
                      const firmId = jobsWhichWorkIsCompleted.find(
                        (j) => j._id === roster.jobId
                      )?.firmId
                      return firm._id === firmId
                    })?.firmName
                  }
                </Text>
              </Box>
            </HStack>
          ))}
        </ModalBody>

        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={() => onClose(false)}>
            Cancel
          </Button>
          <Button
            variant="adjustant"
            onClick={() => onClose(true, newConfig)}
            ml={3}
          >
            Save
          </Button>
        </AlertDialogFooter>
      </ModalContent>
    </Modal>
  )
}

export default UpdateTestimonialsConfigModal
