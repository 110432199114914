import React from 'react'
import { Box, Flex, Radio, RadioGroup, Stack } from '@chakra-ui/react'
import Label from '../Label/Label'

export interface CustomRadioOption {
  value: string
  label: string
}

const CustomRadio = ({
  label,
  subLabel,
  value,
  options,
  onChange,
  isDisabled,
}: {
  label: string
  subLabel?: string
  value: string
  options: CustomRadioOption[]
  onChange: (a: any) => void
  isDisabled?: boolean
}) => {
  return (
    <Box>
      <Flex mb={1}>
        <Label fontSize="sm" color="gray.700">
          {label}
        </Label>
      </Flex>
      <Flex mb={1}>
        <Label fontSize="sm" color="gray.300">
          {subLabel}
        </Label>
      </Flex>
      <RadioGroup
        variant="adjustant"
        onChange={onChange}
        value={value}
        isDisabled={isDisabled}
      >
        <Stack direction={['column', 'column', 'row']}>
          {options.map((option, index) => (
            <Radio key={index} value={option.value}>
              {option.label}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
    </Box>
  )
}

export default CustomRadio
