export enum CHAT_STATUS {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}

export interface ChatMessage {
  id: string
  message: string
  date: string
  sender: string
  read: boolean
}

export interface Chat {
  _id: string
  jobId: string
  status: CHAT_STATUS
  users: string[]
  deletedBy: string[]
  pinnedBy: string[]
  messages: ChatMessage[]
}

export type UnreadChatsContextType = {
  unreadChats?: Chat[]
  setUnreadChats: React.Dispatch<React.SetStateAction<Chat[]>>
}
