import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  AspectRatio,
  HStack,
  Box,
  Spinner,
} from '@chakra-ui/react'
import { useState, useRef, useCallback, useEffect, SetStateAction } from 'react'
import {
  moveSignNowDocumentAndCreateTemplate,
  uploadSignNowDocument,
} from '../../../services/adjustant'
import { fileToStringBuffer, storeFile } from '../../../utils/functions'
import { User } from '../../../types/userTypes'
import {
  CREATE_SIGN_NOW_FIRM_FOLDERS,
  CREATE_SIGN_NOW_TEMPLATE,
} from '../../../graphql/Mutations'
import { useMutation } from '@apollo/client'
import {
  SignNowFirmFolders,
  SignNowTemplate,
} from '../../../types/signNowTypes'
import { Dropzone } from '../../../components/Dropzone/Dropzone'

const UploadDocumentModal = ({
  isOpen,
  onClose,
  user,
  signNowFirmFolders,
  setSignNowFirmFolders,
  setSignNowTemplates,
}: {
  isOpen: boolean
  onClose: () => void
  user?: User
  signNowFirmFolders?: SignNowFirmFolders
  setSignNowFirmFolders: React.Dispatch<
    React.SetStateAction<SignNowFirmFolders | undefined>
  >
  setSignNowTemplates: React.Dispatch<SetStateAction<SignNowTemplate[]>>
}) => {
  const [createSignNowFirmFolderMutation] = useMutation(
    CREATE_SIGN_NOW_FIRM_FOLDERS
  )
  const [createSignNowTemplate] = useMutation(CREATE_SIGN_NOW_TEMPLATE)

  const [showLoader, setShowLoader] = useState<boolean>(false)

  const [url, setURL] = useState('')
  const [file, setFile] = useState<any>()
  const [fileName, setFileName] = useState<string>('')
  const [fileType, setFileType] = useState<string>('')
  const [editorUrl, setEditorUrl] = useState('')
  const [documentId, setDocumentId] = useState('')
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const [iframeLoadsCount, setIframeLoadsCount] = useState(0)

  const onFileInputChange = useCallback(
    async (e: any) => {
      const fileFromFiles = e.target.files[0]
      if (fileFromFiles) {
        setFile(fileFromFiles)
        setFileName(fileFromFiles.name)
        setFileType(fileFromFiles.type)
        const url = URL.createObjectURL(fileFromFiles)
        setURL(url)
      }
    },
    [setFile, setFileName, setURL]
  )

  const onClearFile = useCallback(() => {
    setFile(null)
    setFileName('')
    setURL('')
  }, [setFile, setFileName, setURL])

  const getSignNowFirmFoldersName = useCallback(() => {
    // return `${user?.firmName}-${user?.email}`
    return `${user?._id}-${user?.email}`
  }, [user?._id, user?.email])

  const uploadFile = useCallback(async () => {
    setShowLoader(true)
    const stringBuffer = await fileToStringBuffer(file)
    if (stringBuffer) {
      const { data, success, message } = await uploadSignNowDocument({
        document: stringBuffer,
        documentName: fileName,
        documentContentType: fileType,
        createSignNowFolders: !signNowFirmFolders,
        signNowfoldersName: getSignNowFirmFoldersName(),
      })

      if (success) {
        const { documentId, documentsFolderId, templatesFolderId, editorUrl } =
          data

        // console.log({
        //   documentId,
        //   documentsFolderId,
        //   templatesFolderId,
        //   editorUrl,
        // })

        setDocumentId(documentId)
        setEditorUrl(editorUrl)
        setShowLoader(false)

        //Insert into db the firm folders entry
        if (!signNowFirmFolders) {
          const newFolders = {
            firmId: user?._id ?? '',
            documentsFolderId,
            templatesFolderId,
          }

          const result = await createSignNowFirmFolderMutation({
            variables: {
              signNowFirmFolders: newFolders,
            },
          })

          if (result?.data?.SignNowFirmFoldersCreateOne)
            setSignNowFirmFolders(
              result?.data?.SignNowFirmFoldersCreateOne?.record
            )
        }
      } else setShowLoader(false)
    }
  }, [
    createSignNowFirmFolderMutation,
    file,
    fileName,
    fileType,
    getSignNowFirmFoldersName,
    setSignNowFirmFolders,
    signNowFirmFolders,
    user?._id,
  ])

  const moveDocumentAndCreateTemplate = useCallback(async () => {
    const body = {
      documentId,
      documentName: fileName,
      documentsFolderId: signNowFirmFolders?.documentsFolderId ?? '',
      templatesFolderId: signNowFirmFolders?.templatesFolderId ?? '',
    }

    const result = await moveSignNowDocumentAndCreateTemplate(body)
    if (result.success) {
      const { documentLink, templateId } = result.data

      if (user) {
        const documentURL = (
          await storeFile(file, fileName, user, 'documentCenter/')
        )?.data
        const signNowTemplate = {
          firmId: user?._id ?? '',
          templateId,
          templateName: fileName,
          documentURL,
        }
        const mutationResult = await createSignNowTemplate({
          variables: {
            signNowTemplate,
          },
        })

        if (mutationResult?.data?.SignNowTemplateCreateOne)
          setSignNowTemplates((prev) => [
            ...prev,
            mutationResult?.data?.SignNowTemplateCreateOne?.record,
          ])
      }
    }
    setShowLoader(false)

    onClose()
  }, [
    createSignNowTemplate,
    documentId,
    file,
    fileName,
    onClose,
    setSignNowTemplates,
    signNowFirmFolders?.documentsFolderId,
    signNowFirmFolders?.templatesFolderId,
    user,
  ])

  //WHEN THE IFRAME GETS REDIRECT
  useEffect(() => {
    if (editorUrl && iframeRef.current) {
      //   console.log(
      //     '+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++'
      //   )
      iframeRef.current?.addEventListener('load', function () {
        console.log(
          'The iframe has navigated or reloaded----------------------------------------------------------------------------',
          iframeLoadsCount
        )
        // console.log(iframeLoadsCount)

        if (iframeLoadsCount > 0) {
          //Hides editor
          // setEditorUrl('')
          setShowLoader(true)
          moveDocumentAndCreateTemplate()
        }

        setIframeLoadsCount((prev) => prev + 1)
      })
    }
  }, [moveDocumentAndCreateTemplate, editorUrl, iframeLoadsCount])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      // xs, sm, md, lg, xl, full
      size={editorUrl ? 'full' : '6xl'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload a new document</ModalHeader>
        {!editorUrl && <ModalCloseButton />}
        <ModalBody height="100%">
          {editorUrl && (
            <Box height="calc(100vh - 77px)" width="100%">
              {showLoader ? (
                <HStack
                  direction="row"
                  height="-webkit-fill-available"
                  w="100%"
                  // h="100%"
                  justifyContent="center"
                >
                  <Spinner size="xl" />
                </HStack>
              ) : (
                <iframe
                  height="100%"
                  width="100%"
                  id="update-document-iframe"
                  ref={iframeRef}
                  title="update-document-iframe"
                  src={editorUrl}
                />
              )}
            </Box>
          )}

          {!editorUrl && (
            <Box>
              {showLoader ? (
                <HStack
                  direction="row"
                  my={4}
                  height="-webkit-fill-available"
                  w="100%"
                  // h="100%"
                  justifyContent="center"
                >
                  <Spinner size="xl" />
                </HStack>
              ) : (
                <Dropzone
                  fileLoaded={!!file}
                  fileName={fileName}
                  onFileInputChange={onFileInputChange}
                  clearFile={onClearFile}
                  accept=".pdf"
                  uploadFile={uploadFile}
                  isLoading={showLoader}
                />
              )}
            </Box>
          )}
        </ModalBody>

        {!editorUrl && (
          <ModalFooter>
            <Button variant="adjustant" mr={3} onClick={onClose}>
              Close
            </Button>
            {/* <Button variant="ghost">Secondary Action</Button> */}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  )
}

export default UploadDocumentModal
