import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { UserContextType, UserType } from '../../types/userTypes'
import { UserContext } from '../../contexts/UserContext'
import {
  Box,
  Text,
  HStack,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Tag,
  IconButton,
  Tooltip,
} from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom'
import Logo from '../../assets/images/Logo'
import {
  ADJUSTER_ROUTES,
  ADMIN_ROUTES,
  FIRM_ROUTES,
  GLOBAL_ROUTES,
} from '../../App'
import {
  removeItemFromLocalStorage,
  LOCALSTORAGE_OBJECTS_NAMES,
} from '../../utils/localStorageFunctions'
import { ADJUSTANT_GREEN } from '../../themes/themes'
import { AiOutlineLogout, AiOutlineMenu } from 'react-icons/ai'
import { BsBell } from 'react-icons/bs'
import { MdWorkOutline } from 'react-icons/md'
import { IoMdClose } from 'react-icons/io'
import {
  AdjustantNotification,
  NotificationContextType,
} from '../../types/notificationTypes'
import { GET_LAST_NOTIFICATIONS } from '../../graphql/Queries'
import { NotificationsContext } from '../../contexts/NotificationsContext'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  DELETE_NOTIFICATION_BY_ID,
  UPDATE_NOTIFICATION_BY_ID,
} from '../../graphql/Mutations'
import useWindowSize from '../../hooks/useWindowSize'
import { RiQuestionMark } from 'react-icons/ri'
import { GoMail } from 'react-icons/go'
import { UnreadChatsContextType } from '../../types/chatTypes'
import { UnreadChatsContext } from '../../contexts/UnreadMessagesContext'

function Navbar() {
  const windowsSize = useWindowSize()
  const navigate = useNavigate()
  const location = useLocation()
  const { user, setUser }: UserContextType = useContext(UserContext)
  const { notifications, setNotifications }: NotificationContextType =
    useContext(NotificationsContext)
  const { unreadChats }: UnreadChatsContextType = useContext(UnreadChatsContext)

  const [getNotifications] = useLazyQuery(GET_LAST_NOTIFICATIONS, {
    onCompleted: (data: { GetLastNotifications: AdjustantNotification[] }) => {
      if (data?.GetLastNotifications)
        setNotifications([...data?.GetLastNotifications])
    },
    onError: (err) => {},
  })
  const [updateNotificationMutation] = useMutation(UPDATE_NOTIFICATION_BY_ID)
  const [deleteNotificationMutation] = useMutation(DELETE_NOTIFICATION_BY_ID)

  const userIsNotAdmin = useMemo(() => {
    return user?.userType !== UserType.ADMIN
  }, [user?.userType])

  useEffect(() => {
    getNotifications({
      variables: {
        userId: user?._id,
        limit: 3,
      },
      fetchPolicy: 'network-only',
    })
  }, [getNotifications, user?._id])

  const onNotificationClick = useCallback(
    (notification: AdjustantNotification) => {
      updateNotificationMutation({
        variables: {
          notification: { read: true },
          id: notification._id,
        },
      })
      notification.read = true
      setNotifications([...notifications])
      navigate(`${notification.link}`)
    },
    [navigate, notifications, setNotifications, updateNotificationMutation]
  )

  const deleteNotification = useCallback(
    async (notificationId: string, index: number) => {
      notifications.splice(index, 1)
      setNotifications([...notifications])
      deleteNotificationMutation({
        variables: { id: notificationId },
      })
    },
    [deleteNotificationMutation, notifications, setNotifications]
  )

  const logOut = useCallback(() => {
    const userIsAdmin = !userIsNotAdmin
    removeItemFromLocalStorage(LOCALSTORAGE_OBJECTS_NAMES.USER)
    removeItemFromLocalStorage(LOCALSTORAGE_OBJECTS_NAMES.ACCESS_TOKEN)
    removeItemFromLocalStorage(LOCALSTORAGE_OBJECTS_NAMES.STATE_LICENSES)
    removeItemFromLocalStorage(
      LOCALSTORAGE_OBJECTS_NAMES.TRAINING_CERTIFICATIONS
    )
    removeItemFromLocalStorage(LOCALSTORAGE_OBJECTS_NAMES.LOAS)
    setUser(undefined)
    navigate(userIsAdmin ? GLOBAL_ROUTES.ADMIN : GLOBAL_ROUTES.ROOT)
  }, [navigate, setUser, userIsNotAdmin])

  const adminNavigate = useCallback(
    (route: ADMIN_ROUTES | GLOBAL_ROUTES) => {
      navigate(`${GLOBAL_ROUTES.ADMIN}${route}`)
    },
    [navigate]
  )

  const logoRedirect = useCallback(() => {
    if (userIsNotAdmin) navigate(GLOBAL_ROUTES.JOBS)
    else adminNavigate(ADMIN_ROUTES.DASHBOARD)
  }, [navigate, adminNavigate, userIsNotAdmin])

  const unreadNotifications = useMemo(() => {
    return !!notifications.find((n) => !n.read)
  }, [notifications])

  const getVariant = useCallback(
    (path: string) => {
      return location?.pathname === path ? 'adjustantSelected' : 'adjustant'
    },
    [location?.pathname]
  )

  const screenWidthIsAtListMedium = useMemo(() => {
    return windowsSize.width >= 768
  }, [windowsSize.width])

  const links = useMemo(() => {
    let elements = []
    if (userIsNotAdmin)
      elements.push(
        <Link
          cursor="pointer"
          as="b"
          color={ADJUSTANT_GREEN}
          onClick={logoRedirect}
          variant={getVariant(`${GLOBAL_ROUTES.JOBS}`)}
        >
          Jobs
        </Link>
      )

    if (user?.userType === UserType.FIRM)
      elements.push(
        <Link
          cursor="pointer"
          as="b"
          color={ADJUSTANT_GREEN}
          onClick={() => navigate(FIRM_ROUTES.ADJUSTER_SEARCH)}
          variant={getVariant(`${FIRM_ROUTES.ADJUSTER_SEARCH}`)}
        >
          Adjuster Search
        </Link>,
        <Link
          cursor="pointer"
          as="b"
          color={ADJUSTANT_GREEN}
          onClick={() => navigate(FIRM_ROUTES.DOCUMENT_CENTER)}
          variant={getVariant(`${FIRM_ROUTES.DOCUMENT_CENTER}`)}
        >
          Document Center
        </Link>
      )

    if (user?.userType === UserType.ADJUSTER)
      elements.push(
        <Link
          cursor="pointer"
          as="b"
          color={ADJUSTANT_GREEN}
          onClick={() => navigate(ADJUSTER_ROUTES.INVITATIONS)}
          variant={getVariant(`${ADJUSTER_ROUTES.INVITATIONS}`)}
        >
          Invitations
        </Link>,
        <Link
          cursor="pointer"
          as="b"
          color={ADJUSTANT_GREEN}
          onClick={() => navigate(ADJUSTER_ROUTES.DOCUMENT_CENTER)}
          variant={getVariant(`${ADJUSTER_ROUTES.DOCUMENT_CENTER}`)}
        >
          Document Center
        </Link>
      )

    //   User is admin
    if (!userIsNotAdmin)
      elements.push(
        <Link
          cursor="pointer"
          as="b"
          color={ADJUSTANT_GREEN}
          onClick={() => adminNavigate(ADMIN_ROUTES.DASHBOARD)}
          variant={getVariant(
            `${GLOBAL_ROUTES.ADMIN}${ADMIN_ROUTES.DASHBOARD}`
          )}
        >
          Dashboard
        </Link>,
        <Link
          cursor="pointer"
          as="b"
          color={ADJUSTANT_GREEN}
          onClick={() => adminNavigate(ADMIN_ROUTES.FIRMS)}
          variant={getVariant(`${GLOBAL_ROUTES.ADMIN}${ADMIN_ROUTES.FIRMS}`)}
        >
          Firms
        </Link>,
        <Link
          cursor="pointer"
          as="b"
          color={ADJUSTANT_GREEN}
          onClick={() => adminNavigate(ADMIN_ROUTES.ADJUSTERS)}
          variant={getVariant(
            `${GLOBAL_ROUTES.ADMIN}${ADMIN_ROUTES.ADJUSTERS}`
          )}
        >
          Adjusters
        </Link>,
        <Link
          cursor="pointer"
          as="b"
          color={ADJUSTANT_GREEN}
          onClick={() => adminNavigate(ADMIN_ROUTES.FLAGGED_DOCUMENTS)}
          variant={getVariant(
            `${GLOBAL_ROUTES.ADMIN}${ADMIN_ROUTES.FLAGGED_DOCUMENTS}`
          )}
        >
          Documents
        </Link>,
        <Link
          cursor="pointer"
          as="b"
          color={ADJUSTANT_GREEN}
          onClick={() => adminNavigate(GLOBAL_ROUTES.JOBS)}
          variant={getVariant(`${GLOBAL_ROUTES.ADMIN}${GLOBAL_ROUTES.JOBS}`)}
        >
          Jobs
        </Link>
      )

    return elements
  }, [
    adminNavigate,
    getVariant,
    logoRedirect,
    navigate,
    user?.userType,
    userIsNotAdmin,
  ])

  return (
    //
    <HStack
      px={4}
      height="50px"
      backgroundColor="white"
      borderBottom="1px solid #e3e2e2"
      justifyContent="space-between"
    >
      <Box onClick={logoRedirect} cursor="pointer">
        <Logo />
      </Box>
      <HStack>
        {/* Profile */}
        {userIsNotAdmin && (
          <Link
            mr={2}
            cursor="pointer"
            as="b"
            color={ADJUSTANT_GREEN}
            onClick={() => navigate(GLOBAL_ROUTES.PROFILE)}
            variant={getVariant(`${GLOBAL_ROUTES.PROFILE}`)}
          >
            Profile
          </Link>
        )}

        {/* Custom links */}
        {screenWidthIsAtListMedium ? (
          links.map((element, i) => (
            <HStack mr={2} spacing={4} key={i}>
              {element}
            </HStack>
          ))
        ) : (
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<AiOutlineMenu />}
              variant="outline"
            />
            <MenuList>
              {links.map((element, i) => (
                <MenuItem key={i}>{element}</MenuItem>
              ))}
            </MenuList>
          </Menu>
        )}

        {/* Icon links */}
        {/* Contact support, Notifications, and messages */}
        {user && (
          <>
            {/* Contact support */}
            <Tooltip label="FAQ and Support" aria-label="A tooltip">
              <Box>
                <RiQuestionMark
                  color={ADJUSTANT_GREEN}
                  size="25px"
                  cursor="pointer"
                  onClick={() =>
                    window
                      .open(
                        `https://adjusttechllc.zendesk.com/hc/en-us`,
                        '_blank'
                      )
                      ?.focus()
                  }
                />
              </Box>
            </Tooltip>

            {/* Notifications */}
            <Menu closeOnSelect={false}>
              <MenuButton as={Box} cursor="pointer">
                {unreadNotifications && (
                  <Box position="absolute">
                    <Tag
                      background="red"
                      borderRadius="50%"
                      minWidth="1px"
                      minHeight="1px"
                      width="1px"
                      height="1px"
                      padding="4px"
                      position="relative"
                      left="18px"
                      top="1px"
                    />
                  </Box>
                )}
                <BsBell size={28} color={ADJUSTANT_GREEN} cursor="pointer" />
              </MenuButton>
              <MenuList width={['auto', 96, 96]} maxW={[80, 96, 96]}>
                {notifications.map((notifiation, i) => (
                  <Box key={i}>
                    <MenuItem
                      icon={
                        <Box pt={1.5} mx={2} color="black">
                          <MdWorkOutline size={20} />
                        </Box>
                      }
                      display="flex"
                      alignItems="start"
                      fontWeight={notifiation.read ? '' : '600'}
                      fontSize="md"
                      color="white"
                      _hover={{ color: 'black' }}
                      type="button"
                      onClick={() => {
                        console.log('OUTER')
                        onNotificationClick(notifiation)
                      }}
                    >
                      <HStack alignItems="start" color="inherit">
                        <Text color="black">{notifiation.message}</Text>
                        <Box
                          fontSize="xl"
                          pt={1.5}
                          pointerEvents="all"
                          onClick={(e) => {
                            console.log('INNER')
                            e.stopPropagation()
                            deleteNotification(notifiation?._id ?? '', i)
                          }}
                        >
                          <IoMdClose />
                        </Box>
                      </HStack>
                    </MenuItem>
                    <MenuDivider />
                  </Box>
                ))}

                <MenuItem
                  fontWeight="600"
                  onClick={() =>
                    navigate(
                      userIsNotAdmin
                        ? GLOBAL_ROUTES.NOTIFICATIONS
                        : `${GLOBAL_ROUTES.ADMIN}${GLOBAL_ROUTES.NOTIFICATIONS}`
                    )
                  }
                >
                  See All Notifications
                </MenuItem>
              </MenuList>
            </Menu>

            <Box>
              {(unreadChats?.length ?? 0) > 0 && (
                <Box position="absolute">
                  <Tag
                    background="red"
                    borderRadius="50%"
                    minWidth="1px"
                    minHeight="1px"
                    width="1px"
                    height="1px"
                    padding="4px"
                    position="relative"
                    left="23px"
                    top="1px"
                  />
                </Box>
              )}
              <GoMail
                size={28}
                color={ADJUSTANT_GREEN}
                onClick={() =>
                  navigate(
                    userIsNotAdmin
                      ? GLOBAL_ROUTES.CHATS
                      : `${GLOBAL_ROUTES.ADMIN}${GLOBAL_ROUTES.CHATS}`
                  )
                }
                cursor="pointer"
              />
            </Box>
          </>
        )}

        <AiOutlineLogout
          size={28}
          color={ADJUSTANT_GREEN}
          onClick={logOut}
          cursor="pointer"
        />
      </HStack>
    </HStack>
  )
}

export default Navbar
