import React, { useState } from 'react'
import type { HTMLAttributes } from 'react'
import {
  Box,
  Flex,
  InputGroup,
  InputLeftElement,
  Icon,
  InputRightElement,
  useMediaQuery,
  Divider,
  Text,
} from '@chakra-ui/react'
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from '@choc-ui/chakra-autocomplete'
import type {
  AddressAutofillSuggestion,
  AutofillSuggestion,
} from '@mapbox/search-js-core'
import { MapboxAutofill, SessionToken } from '@mapbox/search-js-core'
import disableScroll from 'disable-scroll'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { RiCloseFill } from 'react-icons/ri'
import Label from '../Label/Label'

const search = new MapboxAutofill({
  country: 'us',
  accessToken:
    process.env.NEXT_PUBLIC_MAPBOX_KEY ??
    'pk.eyJ1IjoibGlsY29yZSIsImEiOiJjbGc1bDR1bmgwNGtvM2NteTZ2bHIyZmNjIn0.u5ZVemHNtbS1LDCDnLK12g',
})
const mapBoxSessionToken = new SessionToken()

interface CustomAddressAutofillSuggestion extends AddressAutofillSuggestion {
  region: string
}

interface AdressInputProps extends HTMLAttributes<HTMLInputElement> {
  width?: string | string[]
  placeholder: string
  isRequired?: boolean
  isDisabled?: boolean
  address: string
  label?: string
  isError?: boolean
  field?: keyof CustomAddressAutofillSuggestion

  // NEW
  onAddressChange: (add: string) => void
  handleAddressClear: () => void
  onSelectOption: (o: string) => void
}

const AddressInput = ({
  width = '100%',
  placeholder = 'e.g. 718 S. Main St.',
  isRequired = false,
  isDisabled = false,
  address,
  label,
  isError,
  field = 'full_address',
  onAddressChange,
  handleAddressClear,
  onSelectOption,
}: AdressInputProps) => {
  const [isLargerThanTablet] = useMediaQuery('(min-width: 48em)')
  const [mapBoxSuggestions, setMapBoxSuggestions] = useState<
    CustomAddressAutofillSuggestion[] | undefined
  >()

  const handleAddressChange = async (add: string): Promise<void> => {
    onAddressChange(add)
    if (add) {
      const result = await search.suggest(add, {
        sessionToken: mapBoxSessionToken,
        country: 'us',
      })
      console.log(result?.suggestions)
      setMapBoxSuggestions(
        result?.suggestions as CustomAddressAutofillSuggestion[]
      )
    }
  }
  return (
    <Box w={width}>
      <Flex mb={1}>
        <Label fontSize="sm" color="gray.700">
          {label}
        </Label>
      </Flex>
      {/* This zIndex places the dropdown over every other component */}
      <InputGroup id="_address" mb={0} pl={0} zIndex={2}>
        <AutoComplete
          selectOnFocus={false}
          suggestWhenEmpty={false}
          value={address}
          disableFilter
          focusInputOnSelect={false}
          closeOnSelect
          shouldRenderSuggestions={(val: string) => {
            return val?.length > 0
          }}
          onSelectOption={({ item }) => {
            onSelectOption(item.value)
          }}
        >
          <InputLeftElement>
            <Icon color="gray.500" as={FaMapMarkerAlt} />
          </InputLeftElement>
          <AutoCompleteInput
            type="text"
            borderWidth="1"
            mb={0}
            pl={10}
            autoCorrect="off"
            textAlign="left"
            bg="white"
            color="gray.500"
            autoComplete="plz-dont-autocomplete"
            _placeholder={{
              color: 'gray.400',
            }}
            fontWeight="normal"
            fontSize={['sm', 'md']}
            errorBorderColor="red.400"
            borderColor="gray.300"
            _focus={{ borderColor: 'brand.500', borderWidth: '2px' }}
            placeholder={placeholder}
            isInvalid={isError && !mapBoxSuggestions?.length}
            isRequired={isRequired}
            isDisabled={isDisabled}
            value={address}
            onFocus={() => {
              if (isLargerThanTablet) disableScroll.on()
              return false
            }}
            onBlur={() => {
              if (isLargerThanTablet) disableScroll.off()
              return false
            }}
            onKeyDown={(e) => {
              if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                e.preventDefault()
                e.stopPropagation()
              }
              if (e.key === 'Escape') {
                e.preventDefault()
                ;(e.target as HTMLElement)?.blur()
              }
              return false
            }}
            onChange={(e) => {
              handleAddressChange(e.target.value)
            }}
          />

          <InputRightElement cursor="pointer">
            <Icon
              as={RiCloseFill}
              boxSize="1.5rem"
              color="gray.500"
              background="white"
              onClick={handleAddressClear}
            />
          </InputRightElement>
          <AutoCompleteList mt={0} p={0}>
            {mapBoxSuggestions?.map((addressOption, i) => (
              <>
                <AutoCompleteItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={`option-${addressOption.full_address}-${i}`}
                  value={addressOption[field]}
                  textTransform="capitalize"
                  alignItems="center"
                  fontSize={['xs', 'sm']}
                  marginInline={0}
                  borderRadius={0}
                  _focus={{ bg: 'gray.200', fontWeight: 'semibold' }}
                >
                  <Icon color="currentColor" as={FaMapMarkerAlt} mr={2} />
                  {addressOption[field]?.toString()}
                </AutoCompleteItem>
                {mapBoxSuggestions.length !== i + 1 && (
                  <Divider
                    key={`option-${addressOption.full_address}-${i}-divider`}
                  />
                )}
              </>
            ))}
          </AutoCompleteList>
        </AutoComplete>
      </InputGroup>
      {isError && !mapBoxSuggestions?.length && (
        <Text alignSelf="flex-start" color="red" fontSize="xs">
          Please enter a valid residential address
        </Text>
      )}
    </Box>
  )
}

export default AddressInput
