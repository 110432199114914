import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react'
import JobsList from '../JobsList/JobsList'
import { Job, Roster } from '../../types/jobTypes'
import { AdminAdjuster } from '../../types/userTypes'

const JobsListModal = ({
  header = 'Jobs Summary',
  jobs,
  rosters,
  adjusters,
  isOpen,
  onClose,
  tab,
  showRostePopover = true,
}: {
  header?: string
  jobs: Job[]
  rosters: Roster[]
  adjusters: AdminAdjuster[]
  isOpen: boolean
  onClose: () => void
  tab?: number
  showRostePopover?: boolean
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <JobsList
            jobs={jobs}
            rosters={rosters}
            adjusters={adjusters}
            tab={tab}
            showRostePopover={showRostePopover}
          />
        </ModalBody>

        <ModalFooter>
          <Button variant="adjustant" mr={3} onClick={onClose}>
            Close
          </Button>
          {/* <Button variant="ghost">Secondary Action</Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default JobsListModal
