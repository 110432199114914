import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Stack,
  HStack,
} from '@chakra-ui/react'
import { CheckrCandidate } from '../../../types/checkrTypes'
import { FIRM_ROUTES } from '../../../App'
import { User } from '../../../types/userTypes'
import { ADJUSTANT_GREEN } from '../../../themes/themes'
import { useMemo } from 'react'
import { formatDate } from '../../../utils/functions'
import { Job } from '../../../types/jobTypes'

const REACT_APP_CHECKR_PARTNERS_URL = process.env.REACT_APP_CHECKR_PARTNERS_URL
const REACT_APP_CHECKR_CLIENT_ID = process.env.REACT_APP_CHECKR_CLIENT_ID
const REACT_APP_APP_URL = process.env.REACT_APP_APP_URL

const CheckrModal = ({
  isOpen,
  onClose,
  hasCheckrAccessToken,
  selectedHiredAdjuster,
  jobs,
  selectedJobId,
  selectedHiredAdjusterCheckrCandidate,

  adjusterCheckrCandidates,
}: {
  isOpen: boolean
  onClose: ({
    inviteCandidate,
    showOverview,
  }: {
    inviteCandidate: boolean
    showOverview: boolean
  }) => void
  hasCheckrAccessToken: boolean
  selectedHiredAdjuster?: User
  jobs?: Job[]
  selectedJobId?: string
  selectedHiredAdjusterCheckrCandidate?: CheckrCandidate

  //All the instanfes of CheckrCandidate for the same adjuster
  adjusterCheckrCandidates: CheckrCandidate[]
}) => {
  const latestStatus = useMemo(
    () =>
      selectedHiredAdjusterCheckrCandidate?.checkrBackgroundCheckStatuses?.slice(
        -1
      )?.[0],
    [selectedHiredAdjusterCheckrCandidate?.checkrBackgroundCheckStatuses]
  )

  const selectedJob = useMemo(
    () => jobs?.find((job) => job._id === selectedJobId),
    [jobs, selectedJobId]
  )

  const otherAdjusterCheckrCandidates = useMemo(
    () =>
      adjusterCheckrCandidates.filter(
        (candidate) =>
          candidate?._id !== selectedHiredAdjusterCheckrCandidate?._id
      ),
    [adjusterCheckrCandidates, selectedHiredAdjusterCheckrCandidate?._id]
  )

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose({ inviteCandidate: false, showOverview: false })}
      size="xl"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Candidate Background Check Status</ModalHeader>
        <ModalCloseButton />
        <ModalBody display="grid" gap={2}>
          {!selectedHiredAdjusterCheckrCandidate ? (
            <HStack>
              <Text>No status for the job: </Text>
              <Text as="b">{selectedJob?.title}</Text>
            </HStack>
          ) : (
            <Stack>
              <HStack>
                <Text as="b">Status: </Text>
                <Text>{latestStatus?.status}</Text>
              </HStack>
              <HStack>
                <Text as="b">Date: </Text>
                <Text>{formatDate(latestStatus?.date ?? '')}</Text>
              </HStack>
            </Stack>
          )}

          {otherAdjusterCheckrCandidates.length > 0 && (
            <>
              <Text as="b" mt={4}>
                Other jobs statuses
              </Text>

              {otherAdjusterCheckrCandidates.map((candidate) => {
                const latestStatus =
                  candidate?.checkrBackgroundCheckStatuses?.slice(-1)?.[0]
                const job = jobs?.find((job) => job._id === candidate.jobId)

                return (
                  <Stack key={candidate._id} mb={4}>
                    <HStack>
                      <Text as="b">Job: </Text>
                      <Text>{job?.title}</Text>
                    </HStack>
                    <HStack>
                      <Text as="b">Status: </Text>
                      <Text>{latestStatus?.status}</Text>
                    </HStack>
                    <HStack>
                      <Text as="b">Date: </Text>
                      <Text>{formatDate(latestStatus?.date ?? '')}</Text>
                    </HStack>
                  </Stack>
                )
              })}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            variant="ghost"
            mr={3}
            size="sm"
            color={ADJUSTANT_GREEN}
            onClick={() =>
              onClose({
                inviteCandidate: false,
                showOverview: false,
              })
            }
          >
            Close
          </Button>

          {!hasCheckrAccessToken ? (
            <Button
              variant="adjustant"
              size="sm"
              onClick={() =>
                (window.location.href = `${REACT_APP_CHECKR_PARTNERS_URL}/authorize/${REACT_APP_CHECKR_CLIENT_ID}/signin?redirect_uri=${REACT_APP_APP_URL}${FIRM_ROUTES.DOCUMENT_CENTER}&state=${selectedHiredAdjuster?._id}-${selectedJobId}`)
              }
            >
              Log In to Checkr
            </Button>
          ) : (
            <>
              <Button
                variant="adjustant"
                size="sm"
                mr={3}
                isDisabled={!hasCheckrAccessToken}
                onClick={() => {
                  onClose({
                    inviteCandidate: true,
                    showOverview: false,
                  })
                }}
              >
                {!selectedHiredAdjusterCheckrCandidate
                  ? 'Request background check'
                  : 'Request a new background check'}
              </Button>

              {selectedHiredAdjusterCheckrCandidate && (
                <Button
                  variant="adjustant"
                  size="sm"
                  isDisabled={!hasCheckrAccessToken}
                  onClick={() => {
                    onClose({
                      inviteCandidate: false,
                      showOverview: true,
                    })
                  }}
                >
                  Show Reports Overview
                </Button>
              )}
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CheckrModal
