export interface SignNowFirmFolders {
  _id: string
  firmId: string
  documentsFolderId: string
  templatesFolderId: string
}

export interface SignNowTemplate {
  _id: string
  firmId: string
  templateId: string
  templateName: string
  documentURL: string
  isDeleted?: boolean
}

export interface SignNowSigningInvitation {
  _id: string
  adjusterId: string
  firmId: string
  jobId: string
  templateId: string
  documentId: string
  status: SIGN_NOW_SIGNING_STATUS
  inviteId: string
}

export enum SIGN_NOW_SIGNING_STATUS {
  PENDING = 'PENDING',
  SIGNED = 'SIGNED',
}
