import React from 'react'
import { createContext } from 'react'
import { Chat, UnreadChatsContextType } from '../types/chatTypes'

export const UnreadChatsContext = createContext<UnreadChatsContextType>({
  unreadChats: [],
  setUnreadChats: (value: React.SetStateAction<Chat[]>): void => {
    throw new Error('Function not implemented.')
  },
})
