import React, { useEffect } from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';

export enum PasswordStrengthType {
  WEAK = 'weak',
  MEDIUM = 'medium',
  OKAY = 'okay',
  STRONG = 'strong',
}
const passwordStrengthMap = {
  weak: {
    width: '25%',
    color: 'red.500',
    label: 'Weak',
  },
  medium: {
    width: '50%',
    color: 'yellow.300',
    label: 'Medium',
  },
  okay: {
    width: '75%',
    color: 'yellow.600',
    label: 'Okay',
  },
  strong: {
    width: '100%',
    color: 'green.500',
    label: 'Strong',
  },
};

const PasswordStrength = ({
  password,
  passwordStrength,
  setPasswordStrength,
}: {
  password: string;
  passwordStrength?: PasswordStrengthType;
  setPasswordStrength: React.Dispatch<
    React.SetStateAction<PasswordStrengthType | undefined>
  >;
}) => {
  useEffect(() => {
    const number = password.match(/[0-9]/);
    const spc = password.match(/[!@#$^&*();,<>/?]/);
    const upc = password.match(/[A-Z]/);
    const length = password.length >= 8;

    if (password.length) {
      if (number || spc || upc || length) {
        setPasswordStrength(PasswordStrengthType.WEAK);
      }
      if (
        (number && spc) ||
        (number && upc) ||
        (number && length) ||
        (spc && upc) ||
        (spc && length) ||
        (upc && length)
      ) {
        setPasswordStrength(PasswordStrengthType.MEDIUM);
      }
      if (
        (number && spc && upc) ||
        (number && length && spc) ||
        (length && upc && spc)
      ) {
        setPasswordStrength(PasswordStrengthType.OKAY);
      }
      if (number && spc && length && upc) {
        setPasswordStrength(PasswordStrengthType.STRONG);
      }
    } else {
      setPasswordStrength(undefined);
    }
  }, [password, setPasswordStrength]);

  return (
    <HStack
      h={8}
      display="flex"
      w="100%"
      justifyContent="space-between"
      gap={2}
      className="blueEEEEEEEEEEEEEEEEEEEEE"
      color="black"
    >
      {passwordStrength && (
        <Box w="100%">
          <Box
            backgroundColor={passwordStrengthMap[passwordStrength].color}
            w={passwordStrengthMap[passwordStrength].width}
            height={2}
          />
          <Text>
            Password strength: {passwordStrengthMap[passwordStrength].label}
          </Text>
        </Box>
      )}
    </HStack>
  );
};

export default PasswordStrength;
