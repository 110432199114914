import {
  Box,
  Button,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Tab,
  TabList,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { GLOBAL_ROUTES } from '../../App'
import { useContext, useMemo, useState } from 'react'
import { Job, JobStatus, Roster } from '../../types/jobTypes'
import { createColumnHelper } from '@tanstack/react-table'
import { formatDate, getPathRoute } from '../../utils/functions'
import CustomTable from '../CustomTable/CustomTable'
import { ADJUSTANT_GREEN } from '../../themes/themes'
import { CiEdit } from 'react-icons/ci'
import { AdminAdjuster, UserContextType } from '../../types/userTypes'
import { UserContext } from '../../contexts/UserContext'

interface TableJob {
  _id: string
  jobTitle: string
  //   req: number
  //   acc: number
  //   pend: number
  rosters: Roster[]
  finishDate: string
  postingDate: string
  reportDate: string
  status: string
}

function JobsList({
  jobs,
  rosters,
  adjusters,
  tab = 0,
  showRostePopover = true,
}: {
  jobs: Job[]
  rosters: Roster[]
  adjusters: AdminAdjuster[]
  tab?: number
  showRostePopover?: boolean
}) {
  const { user }: UserContextType = useContext(UserContext)
  const columnHelper = createColumnHelper<TableJob>()
  const [selectedTab, setSelectedTab] = useState<number>(tab)

  const jobsToShow = useMemo((): TableJob[] => {
    const status = selectedTab === 0 ? JobStatus.ONGOING : JobStatus.FINISHED
    return jobs
      .filter((job) => job.status === status)
      .map((job) => ({
        _id: job._id ?? '',
        jobTitle: job.title,
        rosters: rosters?.filter((r) => r.jobId === job._id) ?? [],
        finishDate: job.finishDate,
        postingDate: job.postingDate,
        reportDate: job.reportDate,
        status: job.status,
      }))
  }, [jobs, rosters, selectedTab])

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('_id', {
        cell: (info) => (
          <Link
            onClick={() =>
              window
                .open(
                  `${getPathRoute(user)}${
                    GLOBAL_ROUTES.JOB
                  }/${info.getValue()}`,
                  '_blank'
                )
                ?.focus()
            }
          >
            {info.getValue()}
          </Link>
        ),
        header: 'Job ID',
      }),
      columnHelper.accessor('jobTitle', {
        cell: (info) => info.getValue(),
        header: 'Job Title',
      }),
      // columnHelper.accessor('req', {
      //   cell: (info) => info.getValue(),
      //   header: '# Req.',
      // }),
      // columnHelper.accessor('acc', {
      //   cell: (info) => info.getValue(),
      //   header: '# Acc.',
      // }),
      // columnHelper.accessor('pend', {
      //   cell: (info) => info.getValue(),
      //   header: '# Pend.',
      // }),
      columnHelper.accessor('rosters', {
        cell: (info) => {
          const rosters = info.getValue()
          return (
            <Popover>
              <PopoverTrigger>
                {rosters.length > 0 ? (
                  <Link>{rosters.length}</Link>
                ) : (
                  <>Empty</>
                )}
              </PopoverTrigger>
              {showRostePopover && (
                <PopoverContent>
                  <PopoverArrow />
                  {/* <PopoverCloseButton /> */}
                  <PopoverHeader as="b">Roster</PopoverHeader>
                  <PopoverBody>
                    <TableContainer>
                      <Table variant="simple" size="sm">
                        <Thead>
                          <Tr>
                            <Th>Last Name</Th>
                            <Th>First Name</Th>
                            <Th>Adjuster ID</Th>
                            <Th>Status</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {rosters.map((roster) => (
                            <Tr key={roster._id}>
                              <Td>
                                {
                                  adjusters.find(
                                    (a) => a._id === roster.adjusterId
                                  )?.lastName
                                }
                              </Td>
                              <Td>
                                {
                                  adjusters.find(
                                    (a) => a._id === roster.adjusterId
                                  )?.firstName
                                }
                              </Td>
                              <Td>{roster.status}</Td>
                              {/* <Link
                                onClick={() => {
                                  window
                                    .open(
                                      `${GLOBAL_ROUTES.ADMIN}${GLOBAL_ROUTES.PROFILE}/${roster.adjusterId}`,
                                      '_blank'
                                    )
                                    ?.focus()
                                }}
                              >
                                {roster._id}
                              </Link> */}
                              <Td>
                                <CiEdit
                                  fontSize={20}
                                  cursor="pointer"
                                  color={ADJUSTANT_GREEN}
                                  onClick={() =>
                                    window
                                      .open(
                                        `${GLOBAL_ROUTES.ADMIN}${GLOBAL_ROUTES.PROFILE}/${roster.adjusterId}`,
                                        '_blank'
                                      )
                                      ?.focus()
                                  }
                                />
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </PopoverBody>
                </PopoverContent>
              )}
            </Popover>
          )
        },
        header: 'Roster',
      }),
      ...(selectedTab === 0
        ? [
            columnHelper.accessor('postingDate', {
              cell: (info) =>
                info.getValue() ? formatDate(info.getValue()) : '-',
              header: 'Post Date',
            }),
          ]
        : [
            columnHelper.accessor('finishDate', {
              cell: (info) =>
                info.getValue() ? formatDate(info.getValue()) : '-',
              header: 'Finish Date',
            }),
          ]),
      columnHelper.accessor('reportDate', {
        cell: (info) => (info.getValue() ? formatDate(info.getValue()) : '-'),
        header: 'Report Date',
      }),
      columnHelper.accessor('status', {
        cell: (info) => info.getValue(),
        header: 'Status',
      }),
    ]
  }, [adjusters, columnHelper, selectedTab, showRostePopover])

  return (
    <Box>
      <Tabs
        variant="adjustant"
        h="100%"
        width="100%"
        index={selectedTab}
        onChange={(index) => {
          setSelectedTab(index)
        }}
      >
        <TabList background="white">
          <Tab fontWeight={500}>Open Jobs</Tab>
          <Tab fontWeight={500}>Past Jobs</Tab>
        </TabList>

        {jobsToShow.length > 0 ? (
          <TabPanels p={2} overflowY="auto">
            <CustomTable data={jobsToShow} columns={columns} />
          </TabPanels>
        ) : (
          <Text my={8}>No jobs to show</Text>
        )}
      </Tabs>
    </Box>
  )
}

export default JobsList
