import React, { useCallback, useState } from 'react'
import { GET_USER_BY_EMAIL } from '../../graphql/Queries'
import { UserType } from '../../types/userTypes'
import { forgotPassword } from '../../services/adjustant'
import { useLazyQuery } from '@apollo/client'
import {
  Box,
  Heading,
  Input,
  Stack,
  VStack,
  Text,
  Button,
} from '@chakra-ui/react'
import Label from '../Label/Label'
import { ADJUSTANT_GREEN } from '../../themes/themes'

const SendConfirmationCode = ({
  setStep,
  email,
  setEmail,
  setUserType,
}: {
  setStep: React.Dispatch<React.SetStateAction<number>>
  email: string
  setEmail: React.Dispatch<React.SetStateAction<string>>
  setUserType: React.Dispatch<React.SetStateAction<UserType | undefined>>
}) => {
  const [showLoader, setShowLoader] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [getUser] = useLazyQuery(GET_USER_BY_EMAIL)

  const onClick = useCallback(async () => {
    if (email) {
      setShowLoader(true)
      const retrievedUser = await getUser({
        variables: { email },
      })
      const userType = retrievedUser?.data?.UserOne?.userType
      if (userType) {
        const result = await forgotPassword({ email, userType })
        console.log(result)
        setShowLoader(false)
        setErrorMessage('A code to recover your password was sent')
        setUserType(userType)
        setStep(2)
      } else {
        setErrorMessage('Email not found')
        setShowLoader(false)
      }
    }
  }, [email, getUser, setStep, setUserType])

  return (
    <Box
      minHeight="100%"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        background="white"
        border="1px solid #ECEAE9"
        borderRadius="8px"
        width="600px"
        mx={3}
      >
        <VStack py={[6, 6, 12]} px={[6, 6, 12]}>
          <Heading
            as="h3"
            fontSize="3xl"
            color="gray.500"
            mt={4}
            mb={8}
            textAlign="center"
          >
            Reset Password
          </Heading>

          <Stack mb={2} width="100%">
            <Label color="gray.500">Email</Label>
            <Input
              type="text"
              placeholder="johnny.appleseed@email.com"
              _focusVisible={{ borderColor: 'gray.300' }}
              value={email}
              onChange={(e) => setEmail(e.target.value?.toLocaleLowerCase())}
            />
          </Stack>

          <VStack mb={2} width="100%">
            <Text as="h5" color="red.500" fontSize="sm">
              {errorMessage}
            </Text>
            <Text as="h5" color="gray.500" fontSize="sm">
              We will send a link to your email to create a new password.
            </Text>
            <Button
              variant="adjustant"
              isLoading={showLoader}
              isDisabled={!email}
              _hover={{ backgroundColor: ADJUSTANT_GREEN }}
              onClick={onClick}
            >
              Reset Password
            </Button>
          </VStack>
        </VStack>
      </Box>
    </Box>
  )
}

export default SendConfirmationCode
