import { Heading, Tag, Box, Text, HStack, Tooltip } from '@chakra-ui/react'
import { HiMapPin, HiCalendar } from 'react-icons/hi2'
import { TbLock } from 'react-icons/tb'
import { ADJUSTANT_GREEN } from '../../themes/themes'
import {
  DATE_OF_LOSS_TYPE,
  Job,
  VISIBILITY,
  billingFormOptions,
  billingOptions,
  dateOfLossOptions,
  jobStatusOptions,
  jobTypeOptions,
  presenceOptions,
} from '../../types/jobTypes'
import { formatDate } from '../../utils/functions'
import { BsCurrencyDollar } from 'react-icons/bs'

const JobSummaryCard = ({
  job,
  onClick,
}: {
  job: Job
  onClick: (j: Job) => void
}) => {
  return (
    <Box
      key={job?._id}
      borderRadius={8}
      backgroundColor="white"
      cursor="pointer"
      border="1px solid var(--light-mode-base-tertiary, #ECEAE9)"
      p={4}
      h="220px"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      onClick={() => {
        onClick(job)
      }}
    >
      <Box>
        {/* Title */}
        <Heading
          as="h4"
          fontSize="md"
          mb={2}
          height="inherit"
          textOverflow="ellipsis"
          overflow="hidden"
          noOfLines={2}
        >
          {job.title}
        </Heading>

        {/* Location */}
        <Box color="gray.500" display="flex" mb={0} alignItems="start">
          <Box mr={2} mt={1}>
            <HiMapPin fontSize={16} />
          </Box>
          <Text
            fontSize="sm"
            height="inherit"
            textOverflow="ellipsis"
            overflow="hidden"
            noOfLines={2}
          >
            {job.city ? `${job.city}, ${job.state}` : job.state}
          </Text>
        </Box>

        {/* Report date */}
        <Box color="gray.500" display="flex" mb={0} alignItems="center">
          <Box mr={2}>
            <HiCalendar fontSize={16} />
          </Box>
          <Text fontSize="sm">{formatDate(job.reportDate ?? '')}</Text>
        </Box>

        {/* Date of loss */}
        <Box color="gray.500" display="flex" mb={0} alignItems="center">
          <Box mr={2}>
            <HiCalendar fontSize={16} />
          </Box>
          <Text fontSize="sm">
            DOL:{' '}
            {job.dateOfLossType === DATE_OF_LOSS_TYPE.DATE
              ? formatDate(job.dateOfLoss ?? '')
              : dateOfLossOptions.find((d) => d.value === job.dateOfLossType)
                  ?.label}
          </Text>
        </Box>

        {/* Billing type*/}
        <Box color="gray.500" display="flex" mb={2} alignItems="center">
          <Box mr={2}>
            <BsCurrencyDollar fontSize={16} />
          </Box>
          <Text fontSize="sm">
            {billingOptions.find((d) => d.value === job.billingType)?.label} -
            {billingFormOptions.find((d) => d.value === job.billingForm)?.label}
          </Text>
        </Box>

        {/* Presence, Status */}
        <HStack spacing={1} mt={2}>
          {/* Presence */}
          {job?.presence && (
            <Tag color={ADJUSTANT_GREEN} backgroundColor="gray.100" size="sm">
              {presenceOptions.find((j) => j.value === job.presence)?.label}
            </Tag>
          )}
          {/* Status */}
          <Tag color={ADJUSTANT_GREEN} backgroundColor="gray.100" size="sm">
            {jobStatusOptions.find((j) => j.value === job.status)?.label}
          </Tag>
        </HStack>
      </Box>

      {/* Job types and visibility  */}
      <HStack justifyContent="space-between">
        <HStack spacing={1}>
          {job?.types.map((t) => (
            <Tag
              color={ADJUSTANT_GREEN}
              key={t}
              backgroundColor="gray.100"
              size="sm"
            >
              {jobTypeOptions.find((j) => j.value === t)?.label}
            </Tag>
          ))}
        </HStack>

        <HStack>
          {job.visibility === VISIBILITY.PRIVATE && (
            <Tooltip
              label="This job is private, only invited users can work on it"
              //   fontSize="md"
              hasArrow
              placement="top"
            >
              <Box>
                <TbLock color="red" />
              </Box>
            </Tooltip>
          )}
        </HStack>
      </HStack>
    </Box>
  )
}

export default JobSummaryCard
