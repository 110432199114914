import React from 'react'
import { createContext } from 'react'
import { LOASContextType, LOA } from '../types/loasTypes'

export const LOASContext = createContext<LOASContextType>({
  loas: [],
  setLoas: (value: React.SetStateAction<LOA[]>): void => {
    throw new Error('Function not implemented.')
  },
})
