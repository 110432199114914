import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, ChakraProvider } from '@chakra-ui/react'
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom'
import theme from './themes/themes'

import 'react-toastify/dist/ReactToastify.css'

import { ToastContainer } from 'react-toastify'
import { useLazyQuery } from '@apollo/client'
import jwt_decode from 'jwt-decode'

import {
  LOCALSTORAGE_OBJECTS_NAMES,
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
  setItemToLocalStorage,
} from './utils/localStorageFunctions'
import {
  GET_LOAS,
  GET_STATE_LICENSES,
  GET_TRAINING_CERTIFICATIONS,
  GET_UNREAD_CHATS,
  GET_USER_BY_EMAIL,
} from './graphql/Queries'

import { UserContext } from './contexts/UserContext'
import { StateLicesesContext } from './contexts/StateLicesesContext'
import { TrainingCertificationsContext } from './contexts/TrainingCertificationsContext'
import { NotificationsContext } from './contexts/NotificationsContext'

import { User, UserType } from './types/userTypes'
import { TrainingCertification } from './types/trainingCertificationsTypes'
import { ListedStateLicense } from './types/stateLicensesTypes'
import { AdjustantNotification } from './types/notificationTypes'

import Navbar from './components/Navbar/Navbar'
import NotFound from './components/NotFound/NotFound'
import Notifications from './components/Notifications/Notifications'

import Login from './pages/LogIn/Login'
import RecoverPasswordWorkflow from './pages/RecoverPasswordWorkflow/RecoverPasswordWorkflow'
import Profile from './pages/Profile/Profile'
import CreateJob from './pages/Firm/CreateJob/CreateJob'
import UpdateProfile from './pages/UpdateProfile/UpdateProfile'
import UpdateFirmsLicensesAndCertifications from './pages/UpdateFirmsLicensesAndCertifications/UpdateFirmsLicensesAndCertifications'
import AdjusterSearch from './pages/Firm/AdjusterSearch/AdjusterSearch'
import FirmJobs from './pages/Firm/FirmJobs/FirmJobs'
import ReviewRoster from './pages/Firm/ReviewRoster/ReviewRoster'
import SetUpProfileWorkflow from './pages/SetUpProfileWorkflow/SetUpProfileWorkflow'
import SignUpWorkflow from './pages/SignUpWorkflow/SignUpWorkflow'
import UpdateAdjustersLicensesAndCertifications from './pages/UpdateAdjustersLicensesAndCertifications/UpdateAdjustersLicensesAndCertifications'
import UpdateJob from './pages/Firm/UpdateJob/UpdateJob'
import Job from './pages/Job/Job'
import AdjusterJobs from './pages/Adjuster/AdjusterJobs/AdjusterJobs'
import Invitations from './pages/Adjuster/Invitations/Invitations'

//Admin
import AdminLogin from './pages/Admin/AdminLogin/AdminLogin'
import AdminDashboard from './pages/Admin/AdminDashboard/AdminDashboard'
import AdminFirms from './pages/Admin/AdminFirms/AdminFirms'
import AdminAdjusters from './pages/Admin/AdminAdjusters/AdminAdjusters'

import AdminIniviteFirm from './pages/Admin/AdminIniviteFirm/AdminIniviteFirm'
import AdminJobs from './pages/Admin/AdminJobs/AdminJobs'
import AdminInviteAdjuster from './pages/Admin/AdminInviteAdjuster/AdminInviteAdjuster'
import DocumentCenter from './pages/Adjuster/DocumentCenter/DocumentCenter'
import FirmsDocumentCenter from './pages/Firm/DocumentCenter/DocumentCenter'
import { LOA } from './types/loasTypes'
import { LOASContext } from './contexts/LOASContext'
import AdminLoas from './pages/Admin/AdminLoas/AdminLoas'
import AdminLicenses from './pages/Admin/AdminLicenses/AdminLicenses'
import AdminCertifications from './pages/Admin/AdminCertifications/AdminCertifications'
import AdminFlaggedDocuments from './pages/Admin/AdminFlaggedDocuments/AdminFlaggedDocuments'
import Chats from './components/Chats/Chats'
import { Chat } from './types/chatTypes'
import useRecursiveTimeout from './hooks/useRecursiveTimeout'
import { UnreadChatsContext } from './contexts/UnreadMessagesContext'

export enum GLOBAL_ROUTES {
  ROOT = '/',
  SIGN_UP = '/signup',
  RECOVER_PASSWORD = '/recoverPassword',
  ADMIN = '/admin',
  PROFILE = '/profile',
  UPDATE_PROFILE = '/updateProfile',
  COMPLETE_PROFILE = '/completeProfile',
  JOBS = '/jobs',
  JOB = '/job',
  NOTIFICATIONS = '/notifications',
  CHATS = '/chats',
}

export enum ADJUSTER_ROUTES {
  UPDATE_LICENCES_AND_CERTIFICATIONS = '/updateLicencesAndCertifications',
  INVITATIONS = '/invitations',
  DOCUMENT_CENTER = '/documentCenter',
}

export enum FIRM_ROUTES {
  CREATE_JOB = '/createJob',
  UPDATE_JOB = '/updateJob',
  ROSTER = '/roster',
  ROSTER_REVIEW = '/rosterReview',
  UPDATE_LICENCES_AND_CERTIFICATIONS_REQUIREMENTS = '/updateLicencesAndCertificationsRequirements',
  ADJUSTER_SEARCH = '/adjusterSearch',
  DOCUMENT_CENTER = '/documentCenter',
}

export enum ADMIN_ROUTES {
  DASHBOARD = '/dashboard',
  FIRMS = '/firms',
  ADJUSTERS = '/adjusters',
  INVITE_FIRM = '/iniviteFirm',
  INVITE_ADJUSTER = '/iniviteAdjuster',

  CREATE_JOB = '/createJob',
  ONBOARD_ADJUSTER = '/onboardAdjuster',
  REPORTS = '/reports',
  EDIT_ADJUSTER = '/editAdjuster',
  FLAGGED_DOCUMENTS = '/flaggedDocuments',
  LOAS = '/loas',
  LICENSES = '/licenses',
  CERTIFICATIONS = '/certifications',
}

function App() {
  const [user, setUser] = useState<User | undefined>(undefined)
  const [userLoaded, setUserLoaded] = useState(false)
  const [stateLicenses, setStateLicenses] = useState<ListedStateLicense[]>([])
  const [trainingCertifications, setTrainingCertifications] = useState<
    TrainingCertification[]
  >([])
  const [loas, setLoas] = useState<LOA[]>([])
  const [notifications, setNotifications] = useState<AdjustantNotification[]>(
    []
  )
  const [unreadChats, setUnreadChats] = useState<Chat[]>([])
  const [dataRetrievedOnLoad, setDataRetrievedOnLoad] = useState(false)

  //   Queries
  const [getUser] = useLazyQuery(GET_USER_BY_EMAIL, {
    onCompleted: (data: { UserOne: User }) => {
      if (data?.UserOne) {
        setItemToLocalStorage(LOCALSTORAGE_OBJECTS_NAMES.USER, data?.UserOne)
        setUser(data?.UserOne)
      }
    },
    // onError: (err) => {
    //   console.log('')
    // }
  })

  const [getStateLicensesQuery] = useLazyQuery(GET_STATE_LICENSES, {
    onCompleted: (data: { StateLicenseMany: ListedStateLicense[] }) => {
      if (data?.StateLicenseMany) {
        // console.log(data.StateLicenseMany)
        setItemToLocalStorage(
          LOCALSTORAGE_OBJECTS_NAMES.STATE_LICENSES,
          data?.StateLicenseMany
        )
        setStateLicenses(data?.StateLicenseMany)
      }
    },
  })

  const [getTrainingCertificationsQuery] = useLazyQuery(
    GET_TRAINING_CERTIFICATIONS,
    {
      onCompleted: (data: {
        TrainingCertificationMany: TrainingCertification[]
      }) => {
        if (data?.TrainingCertificationMany) {
          // console.log(data.TrainingCertificationMany)
          setItemToLocalStorage(
            LOCALSTORAGE_OBJECTS_NAMES.TRAINING_CERTIFICATIONS,
            data?.TrainingCertificationMany
          )
          setTrainingCertifications(data?.TrainingCertificationMany)
        }
      },
      // onError: (err) => {}
    }
  )

  const [getLoasQuery] = useLazyQuery(GET_LOAS, {
    onCompleted: (data: { LOAMany: LOA[] }) => {
      if (data?.LOAMany) {
        // console.log(data.LOAMany)
        setItemToLocalStorage(LOCALSTORAGE_OBJECTS_NAMES.LOAS, data?.LOAMany)
        setLoas(data?.LOAMany)
      }
    },
    // onError: (err) => {}
  })

  const [getUnreadChats] = useLazyQuery(GET_UNREAD_CHATS)

  const logOut = useCallback(() => {
    removeItemFromLocalStorage(LOCALSTORAGE_OBJECTS_NAMES.USER)
    removeItemFromLocalStorage(LOCALSTORAGE_OBJECTS_NAMES.ACCESS_TOKEN)
    removeItemFromLocalStorage(LOCALSTORAGE_OBJECTS_NAMES.STATE_LICENSES)
    removeItemFromLocalStorage(LOCALSTORAGE_OBJECTS_NAMES.LOAS)
    removeItemFromLocalStorage(
      LOCALSTORAGE_OBJECTS_NAMES.TRAINING_CERTIFICATIONS
    )
    setUser(undefined)
  }, [])

  useEffect(() => {
    const u = getItemFromLocalStorage(LOCALSTORAGE_OBJECTS_NAMES.USER)
    if (u) {
      console.log('USER FROM APP COMPONENT', u)
      setUser(u as User)
      // Updates the user
      getUser({
        variables: {
          email: u.email,
        },
        fetchPolicy: 'network-only',
      })
    }
    setUserLoaded(true)
  }, [getUser])

  useEffect(() => {
    // console.log('RETRIEVING TOKEN')
    const token = getItemFromLocalStorage(
      LOCALSTORAGE_OBJECTS_NAMES.ACCESS_TOKEN
    )
    if (token && user) {
      //   console.log(token)
      const obj: any = jwt_decode(token)
      //   console.log(obj)
      const tokenIsExpired = obj?.exp * 1000 <= Date.now()
      console.log('TOKEN IS EXPIRED: ', tokenIsExpired)
      // if (tokenIsExpired) refreshAccessToken()
      if (tokenIsExpired) logOut()
    }
  }, [logOut, user])

  useEffect(() => {
    if (user) {
      const sl = getItemFromLocalStorage(
        LOCALSTORAGE_OBJECTS_NAMES.STATE_LICENSES
      ) as ListedStateLicense[]
      const tc = getItemFromLocalStorage(
        LOCALSTORAGE_OBJECTS_NAMES.TRAINING_CERTIFICATIONS
      ) as TrainingCertification[]
      const lo = getItemFromLocalStorage(
        LOCALSTORAGE_OBJECTS_NAMES.LOAS
      ) as LOA[]

      if (!sl?.length) getStateLicensesQuery({ fetchPolicy: 'network-only' })
      else setStateLicenses(sl)
      if (!tc?.length)
        getTrainingCertificationsQuery({ fetchPolicy: 'network-only' })
      else setTrainingCertifications(tc)

      if (!lo?.length) getLoasQuery({ fetchPolicy: 'network-only' })
      else setLoas(lo)
    }
  }, [
    getLoasQuery,
    getStateLicensesQuery,
    getTrainingCertificationsQuery,
    user,
  ])

  const getChatsRecursiveCallback = useCallback(async () => {
    // console.log('CALLING RECURSIVE NUMBER')
    const result = await getUnreadChats({
      variables: {
        userId: user?._id,
      },
      fetchPolicy: 'network-only',
    })
    // console.log(result?.data?.UserUnreadChats)
    if (result?.data?.UserUnreadChats)
      setUnreadChats(result?.data?.UserUnreadChats)
  }, [getUnreadChats, user?._id])

  //It calls getChatsRecursiveCallback ON LOAD
  useEffect(() => {
    if (!dataRetrievedOnLoad && user?._id) {
      getChatsRecursiveCallback()
      setDataRetrievedOnLoad(true)
    }
  }, [dataRetrievedOnLoad, getChatsRecursiveCallback, user?._id])

  //It starts to call getChatsRecursiveCallback AFTER 60000
  useRecursiveTimeout(getChatsRecursiveCallback, 60000)

  const userIsNotAdmin = useMemo(() => {
    return user?.userType !== UserType.ADMIN
  }, [user?.userType])

  return (
    <ChakraProvider theme={theme}>
      <NotificationsContext.Provider
        value={{ notifications, setNotifications }}
      >
        <UnreadChatsContext.Provider value={{ unreadChats, setUnreadChats }}>
          <StateLicesesContext.Provider
            value={{ stateLicenses, setStateLicenses }}
          >
            <TrainingCertificationsContext.Provider
              value={{
                trainingCertifications,
                setTrainingCertifications,
              }}
            >
              <LOASContext.Provider
                value={{
                  loas,
                  setLoas,
                }}
              >
                <UserContext.Provider value={{ user, setUser }}>
                  <ToastContainer />
                  <Router>
                    {user && <Navbar />}
                    <Box
                      backgroundColor="#f3f3f3"
                      // paddingX={['10px', '50px']}
                      // paddingY="10px"
                      overflowY="auto"
                      height={user ? 'calc(100vh - 50px)' : '100vh'}
                    >
                      <Routes>
                        {!user && (
                          <>
                            <Route
                              path={GLOBAL_ROUTES.ROOT}
                              element={<Login />}
                            />
                            <Route
                              path={GLOBAL_ROUTES.ADMIN}
                              element={<AdminLogin />}
                            />
                            <Route
                              path={`${GLOBAL_ROUTES.SIGN_UP}`}
                              element={<SignUpWorkflow />}
                            />
                            <Route
                              path={GLOBAL_ROUTES.RECOVER_PASSWORD}
                              element={<RecoverPasswordWorkflow />}
                            />
                          </>
                        )}
                        {user && userIsNotAdmin && (
                          <>
                            {/* {!user.profileSet && ( */}
                            <Route
                              path={GLOBAL_ROUTES.COMPLETE_PROFILE}
                              element={<SetUpProfileWorkflow />}
                            />
                            {/*  )}*/}
                            <Route
                              path={`${GLOBAL_ROUTES.PROFILE}/:userId`}
                              element={<Profile />}
                            />
                            <Route
                              path={`${GLOBAL_ROUTES.PROFILE}`}
                              element={<Profile />}
                            />
                            <Route
                              path={GLOBAL_ROUTES.UPDATE_PROFILE}
                              element={<UpdateProfile />}
                            />
                            <Route
                              path={`${GLOBAL_ROUTES.JOB}/:jobId`}
                              element={<Job />}
                            />
                            <Route
                              path={`${GLOBAL_ROUTES.NOTIFICATIONS}`}
                              element={<Notifications />}
                            />
                            <Route
                              path={`${GLOBAL_ROUTES.CHATS}`}
                              element={<Chats />}
                            />
                          </>
                        )}
                        {user?.userType === UserType.ADJUSTER && (
                          <>
                            <Route
                              path={GLOBAL_ROUTES.ROOT}
                              element={
                                <Navigate to={GLOBAL_ROUTES.JOBS} replace />
                              }
                            />
                            <Route
                              path={
                                ADJUSTER_ROUTES.UPDATE_LICENCES_AND_CERTIFICATIONS
                              }
                              element={
                                <UpdateAdjustersLicensesAndCertifications />
                              }
                            />
                            <Route
                              path={GLOBAL_ROUTES.JOBS}
                              element={<AdjusterJobs />}
                            />
                            <Route
                              path={`${GLOBAL_ROUTES.JOBS}/:jobId`}
                              element={<AdjusterJobs />}
                            />
                            <Route
                              path={`${GLOBAL_ROUTES.JOBS}/:jobId/:tab`}
                              element={<AdjusterJobs />}
                            />
                            <Route
                              path={ADJUSTER_ROUTES.INVITATIONS}
                              element={<Invitations />}
                            />
                            <Route
                              path={ADJUSTER_ROUTES.DOCUMENT_CENTER}
                              element={<DocumentCenter />}
                            />
                            <Route
                              path={`${ADJUSTER_ROUTES.DOCUMENT_CENTER}/:stripeSessionID/:niprNpn`}
                              element={<DocumentCenter />}
                            />
                          </>
                        )}
                        {user?.userType === UserType.FIRM && (
                          <>
                            <Route
                              path={GLOBAL_ROUTES.ROOT}
                              element={
                                <Navigate to={GLOBAL_ROUTES.JOBS} replace />
                              }
                            />
                            <Route
                              path={
                                FIRM_ROUTES.UPDATE_LICENCES_AND_CERTIFICATIONS_REQUIREMENTS
                              }
                              element={<UpdateFirmsLicensesAndCertifications />}
                            />
                            <Route
                              path={FIRM_ROUTES.CREATE_JOB}
                              element={<CreateJob />}
                            />
                            <Route
                              path={`${FIRM_ROUTES.UPDATE_JOB}/:jobId`}
                              element={<UpdateJob />}
                            />
                            <Route
                              path={GLOBAL_ROUTES.JOBS}
                              element={<FirmJobs />}
                            />
                            <Route
                              path={`${GLOBAL_ROUTES.JOBS}/:jobId`}
                              element={<FirmJobs />}
                            />
                            <Route
                              path={`${GLOBAL_ROUTES.JOBS}/:jobId/:tab`}
                              element={<FirmJobs />}
                            />
                            <Route
                              path={`${FIRM_ROUTES.ROSTER_REVIEW}/:jobId/:adjustersIds`}
                              element={<ReviewRoster />}
                            />
                            <Route
                              path={FIRM_ROUTES.ADJUSTER_SEARCH}
                              element={<AdjusterSearch />}
                            />
                            <Route
                              path={FIRM_ROUTES.DOCUMENT_CENTER}
                              element={<FirmsDocumentCenter />}
                            />
                          </>
                        )}
                        {user?.userType === UserType.ADMIN && (
                          <Route path={GLOBAL_ROUTES.ADMIN}>
                            <Route
                              path={`${GLOBAL_ROUTES.ADMIN}/${GLOBAL_ROUTES.ROOT}`}
                              element={
                                <Navigate
                                  to={`${GLOBAL_ROUTES.ADMIN}/${ADMIN_ROUTES.DASHBOARD}`}
                                  replace
                                />
                              }
                            />
                            {/* <Route
                            path={`${GLOBAL_ROUTES.ADMIN}/${GLOBAL_ROUTES.UPDATE_PROFILE}`}
                            element={<UpdateProfile />}
                          /> */}
                            <Route
                              path={`${GLOBAL_ROUTES.ADMIN}/${ADMIN_ROUTES.DASHBOARD}`}
                              element={<AdminDashboard />}
                            />
                            <Route
                              path={`${GLOBAL_ROUTES.ADMIN}/${ADMIN_ROUTES.FIRMS}`}
                              element={<AdminFirms />}
                            />
                            <Route
                              path={`${GLOBAL_ROUTES.ADMIN}/${ADMIN_ROUTES.ADJUSTERS}`}
                              element={<AdminAdjusters />}
                            />
                            <Route
                              path={`${GLOBAL_ROUTES.ADMIN}/${GLOBAL_ROUTES.JOBS}`}
                              element={<AdminJobs />}
                            />
                            <Route
                              path={`${GLOBAL_ROUTES.ADMIN}/${GLOBAL_ROUTES.NOTIFICATIONS}`}
                              element={<Notifications />}
                            />
                            <Route
                              path={`${GLOBAL_ROUTES.ADMIN}/${GLOBAL_ROUTES.CHATS}`}
                              element={<Chats />}
                            />
                            <Route
                              path={`${GLOBAL_ROUTES.ADMIN}/${ADMIN_ROUTES.INVITE_FIRM}`}
                              element={<AdminIniviteFirm />}
                            />
                            <Route
                              path={`${GLOBAL_ROUTES.ADMIN}/${ADMIN_ROUTES.INVITE_ADJUSTER}`}
                              element={<AdminInviteAdjuster />}
                            />
                            <Route
                              path={`${GLOBAL_ROUTES.ADMIN}/${GLOBAL_ROUTES.PROFILE}/:userId`}
                              element={<Profile />}
                            />
                            <Route
                              path={`${GLOBAL_ROUTES.ADMIN}/${GLOBAL_ROUTES.UPDATE_PROFILE}/:userId`}
                              element={<UpdateProfile />}
                            />
                            <Route
                              path={`${GLOBAL_ROUTES.ADMIN}/${GLOBAL_ROUTES.JOB}/:jobId`}
                              element={<Job />}
                            />
                            <Route
                              path={`${GLOBAL_ROUTES.ADMIN}/${FIRM_ROUTES.UPDATE_JOB}/:jobId`}
                              element={<UpdateJob />}
                            />
                            <Route
                              path={`${GLOBAL_ROUTES.ADMIN}/${FIRM_ROUTES.UPDATE_LICENCES_AND_CERTIFICATIONS_REQUIREMENTS}/:userId`}
                              element={<UpdateFirmsLicensesAndCertifications />}
                            />
                            <Route
                              path={`${GLOBAL_ROUTES.ADMIN}/${ADJUSTER_ROUTES.UPDATE_LICENCES_AND_CERTIFICATIONS}/:userId`}
                              element={
                                <UpdateAdjustersLicensesAndCertifications />
                              }
                            />
                            <Route
                              path={`${GLOBAL_ROUTES.ADMIN}/${FIRM_ROUTES.ROSTER_REVIEW}/:jobId/:adjustersIds`}
                              element={<ReviewRoster />}
                            />
                            <Route
                              path={`${GLOBAL_ROUTES.ADMIN}/${ADMIN_ROUTES.FLAGGED_DOCUMENTS}`}
                              element={<AdminFlaggedDocuments />}
                            />
                            <Route
                              path={`${GLOBAL_ROUTES.ADMIN}/${ADMIN_ROUTES.LOAS}`}
                              element={<AdminLoas />}
                            />
                            <Route
                              path={`${GLOBAL_ROUTES.ADMIN}/${ADMIN_ROUTES.LICENSES}`}
                              element={<AdminLicenses />}
                            />
                            <Route
                              path={`${GLOBAL_ROUTES.ADMIN}/${ADMIN_ROUTES.CERTIFICATIONS}`}
                              element={<AdminCertifications />}
                            />

                            {/* ////////////////// */}
                            {/* <Route
                          path={`${GLOBAL_ROUTES.ADMIN}/${ADMIN_ROUTES.REPORTS}`}
                          element={<Reports />}
                        /> */}
                          </Route>
                        )}

                        {userLoaded && (
                          <Route
                            path="*"
                            element={
                              user ? (
                                <NotFound />
                              ) : (
                                <Navigate to={GLOBAL_ROUTES.ROOT} replace />
                              )
                            }
                          />
                        )}
                      </Routes>
                    </Box>
                  </Router>
                </UserContext.Provider>
              </LOASContext.Provider>
            </TrainingCertificationsContext.Provider>
          </StateLicesesContext.Provider>
        </UnreadChatsContext.Provider>
      </NotificationsContext.Provider>
    </ChakraProvider>
  )
}

export default App
