import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Link,
  Heading,
} from '@chakra-ui/react'
import { User } from '../../../types/userTypes'
import { encodeS3URI, getFileNameFromURL } from '../../../utils/functions'

const HRDocumentComponent = ({
  label,
  url,
}: {
  label: string
  url: string
}) => {
  return (
    <>
      <Heading as="h3" fontSize="xl">
        {label}
      </Heading>

      {url ? (
        <Link color="teal.500" href={encodeS3URI(url)} isExternal>
          {getFileNameFromURL(url)}
        </Link>
      ) : (
        <Text>-</Text>
      )}
    </>
  )
}

const AdjusterHRDocumentsListModal = ({
  isOpen,
  onClose,
  adjuster,
}: {
  isOpen: boolean
  onClose: (adjustersToInvite?: User[]) => void
  adjuster: User
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      size="xl"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>HR Documents</ModalHeader>
        <ModalCloseButton />
        <ModalBody display="grid" gap={2}>
          <HRDocumentComponent
            label="Driver's License"
            url={adjuster?.driverLicense?.url ?? ''}
          />

          <HRDocumentComponent
            label="W9 Tax Form"
            url={adjuster?.w9TaxForm?.url ?? ''}
          />

          <HRDocumentComponent
            label="W4 Tax Form"
            url={adjuster?.w4TaxForm?.url ?? ''}
          />

          <HRDocumentComponent
            label="I9 Tax Form"
            url={adjuster?.i9TaxForm?.url ?? ''}
          />

          <HRDocumentComponent
            label="Secondary Form of ID"
            url={adjuster?.secondaryFormOfID?.url ?? ''}
          />

          <HRDocumentComponent
            label="Miscellaneous Document"
            url={adjuster?.miscelaneosDocument?.url ?? ''}
          />

          {adjuster.signedDocuments?.length && (
            <Heading as="h3" fontSize="xl">
              Signed Documents
            </Heading>
          )}

          {adjuster.signedDocuments?.map((doc, index) => (
            <>
              <Link color="teal.500" href={encodeS3URI(doc.url)} isExternal>
                {getFileNameFromURL(doc.url)}
              </Link>
            </>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button
            //   variant="adjustant"
            mr={3}
            onClick={() => {
              onClose()
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default AdjusterHRDocumentsListModal
