import React, { useMemo, useState } from 'react'
import { UserType } from '../../types/userTypes'
import { useNavigate } from 'react-router-dom'
import { GLOBAL_ROUTES } from '../../App'
import CreateNewPassword from '../../components/RecoverPassword/CreateNewPassword'
import InsertConfirmationCode from '../../components/RecoverPassword/InsertConfirmationCode'
import SendConfirmationCode from '../../components/RecoverPassword/SendConfirmationCode'
import { Box } from '@chakra-ui/react'

const RecoverPasswordWorkflow = () => {
  const navigate = useNavigate()
  const [step, setStep] = useState(1)
  const [email, setEmail] = useState('')
  const [userType, setUserType] = useState<UserType | undefined>()
  const [confirmationCode, setConfirmationCode] = useState<string>('')

  const onSendConfirmationCode = (code: string) => {
    setConfirmationCode(code)
    setStep(3)
  }

  const element = useMemo(() => {
    switch (step) {
      case 1:
        return (
          <SendConfirmationCode
            setStep={setStep}
            email={email}
            setEmail={setEmail}
            setUserType={setUserType}
          />
        )
      case 2:
        return (
          <InsertConfirmationCode
            email={email}
            userType={userType}
            onSendConfirmationCode={onSendConfirmationCode}
          />
        )
      case 3:
        return (
          <CreateNewPassword
            email={email}
            userType={userType}
            confirmationCode={confirmationCode}
            onSendNewPassword={() => navigate(GLOBAL_ROUTES.PROFILE)}
          />
        )

      default:
        break
    }
  }, [step, email, userType, confirmationCode, navigate])

  return (
    <Box
      minHeight="100%"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {element}
    </Box>
  )
}

export default RecoverPasswordWorkflow
