import { gql } from '@apollo/client'

// STATS
export const GET_ADMIN_STATS = gql`
  query getAdminStats {
    getAdminStats {
      YTD
      PAST_12_MONTHS
      PAST_6_MONTHS
      PAST_90_DAYS
      PAST_30_DAYS
    }
  }
`

export const GET_ADJUSTERS_SIGNUP_HISTORICAL = gql`
  query getAdjustersSignUpHistorical {
    getAdjustersSignUpHistorical {
      YTD
      PAST_12_MONTHS
      PAST_6_MONTHS
      PAST_90_DAYS
      PAST_30_DAYS
    }
  }
`

export const GET_JOBS_HISTORICAL = gql`
  query getJobsHistorical {
    getJobsHistorical {
      YTD
      PAST_12_MONTHS
      PAST_6_MONTHS
      PAST_90_DAYS
      PAST_30_DAYS
    }
  }
`

export const GET_STATE_LICENSES = gql`
  query {
    StateLicenseMany {
      _id
      name
      code
      states
    }
  }
`

export const GET_TRAINING_CERTIFICATIONS = gql`
  query {
    TrainingCertificationMany {
      _id
      name
    }
  }
`

export const GET_LOAS = gql`
  query {
    LOAMany {
      _id
      name
      code
    }
  }
`

// USERS
export const GET_USER_BY_EMAIL = gql`
  query getUser($email: String) {
    UserOne(filter: { email: $email }) {
      _id
      firstName
      lastName
      email
      phoneNumber
      password
      userType
      profileSet
      profilePicture
      bannerPicture
      location

      jobTypes
      jobLocations
      bio
      testimonialsConfig
      yearsOfLicensedExperience
      status
      availabilityDate
      availabilityInfo
      driverLicense
      femaCertification
      w9TaxForm
      w4TaxForm
      i9TaxForm
      miscelaneosDocument
      secondaryFormOfID
      signedDocuments
      licenses
      certifications
      npnNumber
      niprMetadata
      languagePreference
      emergencyContact
      xactimateId
      companyAlias
      experience

      firmName
      areasOfOperation
      additionalCertificationRequirements

      hasCheckrAccessToken
    }
  }
`

export const GET_USER_BY_ID = gql`
  query getUser($id: MongoID) {
    UserOne(filter: { _id: $id }) {
      _id
      firstName
      lastName
      email
      phoneNumber
      password
      userType
      profileSet
      profilePicture
      bannerPicture
      location

      jobTypes
      jobLocations
      bio
      testimonialsConfig
      status
      yearsOfLicensedExperience
      availabilityDate
      availabilityInfo
      driverLicense
      femaCertification
      w9TaxForm
      w4TaxForm
      i9TaxForm
      miscelaneosDocument
      secondaryFormOfID
      signedDocuments
      licenses
      certifications
      npnNumber
      niprMetadata
      languagePreference
      emergencyContact
      xactimateId
      companyAlias
      experience

      firmName
      areasOfOperation
      additionalCertificationRequirements
    }
  }
`

export const GET_USERS_BY_FILTERS = gql`
  query getUsersByFilter(
    $userType: String
    $searcherValue: String
    $location: String
    $locationAbbreviation: String
    $jobTypes: [String]
    $status: [String]
    $licensesIds: [String]
    $licensesStates: [String]
    $certificationsIds: [String]
    $ids: [String]
    $yearsOfLicensedExperience: Int
  ) {
    UsersByFilter(
      filter: {
        userType: $userType
        searcherValue: $searcherValue
        location: $location
        locationAbbreviation: $locationAbbreviation
        jobTypes: $jobTypes
        status: $status
        licensesIds: $licensesIds
        licensesStates: $licensesStates
        certificationsIds: $certificationsIds
        ids: $ids
        yearsOfLicensedExperience: $yearsOfLicensedExperience
      }
    ) {
      _id
      firstName
      lastName
      userType
      email
      phoneNumber
      password
      firmName
      location
      jobTypes
      jobLocations
      profileSet
      bio
      testimonialsConfig
      profilePicture
      bannerPicture
      status
      availabilityDate
      availabilityInfo
      yearsOfLicensedExperience
      licenses
      certifications
      driverLicense
      w9TaxForm
      w4TaxForm
      i9TaxForm
      secondaryFormOfID
      signedDocuments
      miscelaneosDocument
      femaCertification
      npnNumber
      niprMetadata
      languagePreference
      emergencyContact
      xactimateId
      companyAlias
      experience
    }
  }
`

export const GET_FIRMS_BY_FILTERS = gql`
  query getFirmsByFilter(
    $searcherValue: String
    $jobId: String
    $ids: [String]
  ) {
    FirmsByFilter(
      filter: { searcherValue: $searcherValue, jobId: $jobId, ids: $ids }
    ) {
      _id
      firmName
      areasOfOperation
      # activeDeployments
      # openJobs
      # jobs
      lastActive
    }
  }
`

export const GET_ADJUSTERS_BY_FILTERS = gql`
  query getAdjustersByFilter(
    $searcherValue: String
    $location: String
    $locationAbbreviation: String
    $jobTypes: [String]
    $status: [String]
    $documentsStatus: String
    $licenses: [String]
    $certifications: [String]
    $ids: [String]
    $notListedCertificationName: String
    $jobId: String
    $yearsOfLicensedExperience: Int
  ) {
    AdjustersByFilter(
      filter: {
        searcherValue: $searcherValue
        location: $location
        locationAbbreviation: $locationAbbreviation
        jobTypes: $jobTypes
        status: $status
        documentsStatus: $documentsStatus
        licenses: $licenses
        certifications: $certifications
        ids: $ids
        notListedCertificationName: $notListedCertificationName
        jobId: $jobId
        yearsOfLicensedExperience: $yearsOfLicensedExperience
      }
    ) {
      _id
      firstName
      lastName
      email
      status
      documentsStatus
      lastActive
    }
  }
`

export const GET_HIRED_ADJUSTERS = gql`
  query getHiredAdjusters($firmId: String) {
    HiredAdjusters(filter: { firmId: $firmId }) {
      _id
      firstName
      lastName
      email
      phoneNumber
      location

      driverLicense
      w9TaxForm
      w4TaxForm
      i9TaxForm
      secondaryFormOfID
      signedDocuments
      miscelaneosDocument
    }
  }
`

export const GET_ADMINS = gql`
  query getAdmins {
    UserMany(filter: { userType: "Admin" }) {
      _id
      email
    }
  }
`

export const GET_FLAGGED_DOCUMENTS = gql`
  query {
    AdjustersFlaggedDocuments {
      adjusterFirstName
      adjusterLastName
      adjusterID
      adjusterEmail
      documentType
      key
      flaggedReason
    }
  }
`

// JOBS -- THE RESOLVERS MUST AVOID RETRIEVING DELETED JOBS
// DRAFT JOBS TOO, EXCEPT WHEN A FIRM MAKES A QUERY
export const GET_ALL_JOBS = gql`
  query getJobs($firmId: MongoID, $statuses: [String]) {
    GetJobMany(filter: { firmId: $firmId, statuses: $statuses }) {
      _id
      title
      description
      types
      typesDescription
      billingType
      billingTypeDescription
      billingTypeFileURLs
      billingForm
      billingStatementsURLs
      isFemaCertificationRequired
      femaCertificationCategories
      licensingRequirements
      certificationRequirements
      reportDate
      dateOfLossType
      dateOfLoss
      location
      state
      city
      allAdjustersCanApply
      visibility
      adjustersNeeded
      presence

      postingDate
      finishDate
      createdAt
      updatedAt
      firmId
      status
    }
  }
`

export const GET_ALL_FIRM_JOBS = gql`
  query getJobs($firmId: MongoID, $statuses: [String]) {
    GetJobMany(filter: { firmId: $firmId, statuses: $statuses }) {
      _id
      title
      description
      types
      typesDescription
      billingType
      billingTypeDescription
      billingTypeFileURLs
      billingForm
      billingStatementsURLs
      isFemaCertificationRequired
      femaCertificationCategories
      licensingRequirements
      certificationRequirements
      reportDate
      dateOfLossType
      dateOfLoss
      location
      state
      city
      allAdjustersCanApply
      visibility
      adjustersNeeded
      presence

      postingDate
      finishDate
      createdAt
      updatedAt
      firmId
      status
    }
  }
`

// This returns only ONGOING JOBS
export const GET_ALL_ADJUSTER_JOBS = gql`
  query getJobs(
    $adjusterID: String
    $jobsIds: [String]
    $onlyApplicableJobs: Boolean
  ) {
    AdjusterJobs(
      filter: {
        adjusterID: $adjusterID
        jobsIds: $jobsIds
        onlyApplicableJobs: $onlyApplicableJobs
      }
    ) {
      _id
      title
      description
      types
      typesDescription
      billingType
      billingTypeDescription
      billingTypeFileURLs
      billingForm
      billingStatementsURLs
      isFemaCertificationRequired
      femaCertificationCategories
      licensingRequirements
      certificationRequirements
      reportDate
      dateOfLossType
      dateOfLoss
      location
      state
      city
      allAdjustersCanApply
      visibility
      adjustersNeeded
      presence

      postingDate
      finishDate
      createdAt
      updatedAt
      firmId
      status
    }
  }
`

export const GET_JOB_BY_ID = gql`
  query getJob($id: MongoID!, $statuses: [String]) {
    GetJobById(filter: { id: $id, statuses: $statuses }) {
      _id
      title
      description
      types
      billingType
      typesDescription
      billingTypeDescription
      billingTypeFileURLs
      billingForm
      billingStatementsURLs
      isFemaCertificationRequired
      femaCertificationCategories
      licensingRequirements
      certificationRequirements
      reportDate
      dateOfLossType
      dateOfLoss
      location
      state
      city
      allAdjustersCanApply
      visibility
      adjustersNeeded
      presence

      postingDate
      finishDate
      createdAt
      updatedAt
      firmId
      status
    }
  }
`

export const GET_JOBS_BY_IDS = gql`
  query getJobs($jobsIds: [MongoID], $statuses: [String]) {
    JobsByIds(filter: { jobsIds: $jobsIds, statuses: $statuses }) {
      _id
      title
      description
      types
      typesDescription
      billingType
      billingTypeDescription
      billingTypeFileURLs
      billingForm
      billingStatementsURLs
      isFemaCertificationRequired
      femaCertificationCategories
      licensingRequirements
      certificationRequirements
      reportDate
      dateOfLossType
      dateOfLoss
      location
      state
      city
      allAdjustersCanApply
      visibility
      adjustersNeeded
      presence

      postingDate
      finishDate
      createdAt
      updatedAt
      firmId
      status
    }
  }
`

export const GET_JOB_SPECS_BY_ID = gql`
  query getJobSpecs($jobId: MongoID) {
    GetJobSpecsById(filter: { jobId: $jobId }) {
      hired
      invites
      workforce
      applications
    }
  }
`

export const GET_ADMIN_JOBS = gql`
  query getAdminJobs(
    $searcherValue: String
    $statuses: [String]
    $id: String
    $firmName: String
    $req: Int
    $postingDateRangeStart: String
    $postingDateRangeEnd: String
    $reportDateRangeStart: String
    $reportDateRangeEnd: String
  ) {
    AdminJobs(
      filter: {
        searcherValue: $searcherValue
        statuses: $statuses
        id: $id
        firmName: $firmName
        req: $req
        postingDateRangeStart: $postingDateRangeStart
        postingDateRangeEnd: $postingDateRangeEnd
        reportDateRangeStart: $reportDateRangeStart
        reportDateRangeEnd: $reportDateRangeEnd
      }
    ) {
      _id
      jobTitle
      firmName
      req
      acc
      pend
      postingDate
      finishDate
      reportDate
      status
    }
  }
`

export const GET_ALL_JOBS_WITHOUT_FILTERS = gql`
  query {
    JobMany {
      _id
      title
      firmId
      postingDate
      finishDate
      reportDate
      dateOfLossType
      dateOfLoss
      status
    }
  }
`

// APPLICATIONS
export const GET_APPLICATIONS = gql`
  query getApplications(
    $adjusterId: MongoID
    $jobId: MongoID
    $status: String
  ) {
    ApplicationMany(
      filter: { adjusterId: $adjusterId, jobId: $jobId, status: $status }
    ) {
      _id
      adjusterId
      jobId
      status
      message
    }
  }
`

export const GET_APPLICATIONS_AND_TITLE = gql`
  query getApplicationAndTitle($adjusterId: MongoID) {
    ApplicationsAndJobTitle(filter: { adjusterId: $adjusterId }) {
      _id
      adjusterId
      jobId
      status
      message
      jobTitle
    }
  }
`

// INVITATIONS
export const GET_INVITATIONS = gql`
  query getInvitations($adjusterId: MongoID, $jobId: MongoID, $status: String) {
    InvitationMany(
      filter: { adjusterId: $adjusterId, jobId: $jobId, status: $status }
    ) {
      _id
      adjusterId
      jobId
      status
      message
    }
  }
`

export const GET_INVITATIONS_AND_TITLE = gql`
  query getInvitations($adjusterId: MongoID) {
    InvitationsAndJobTitle(filter: { adjusterId: $adjusterId }) {
      _id
      adjusterId
      jobId
      status
      message
      jobTitle
    }
  }
`

export const GET_INVITATIONS_BY_FILTER = gql`
  query getInvitations($adjusterId: MongoID, $jobsIds: [MongoID]) {
    InvitationsByFilter(
      filter: { adjusterId: $adjusterId, jobsIds: $jobsIds }
    ) {
      _id
      adjusterId
      jobId
    }
  }
`

// ROSTERS
export const GET_ROSTERS = gql`
  query getRosters($adjusterId: MongoID, $jobId: MongoID) {
    RosterMany(filter: { adjusterId: $adjusterId, jobId: $jobId }) {
      _id
      adjusterId
      jobId
      status
      review

      createdAt
    }
  }
`

export const GET_ROSTERS_BY_FILTER = gql`
  query getRosters(
    $adjusterId: MongoID
    $jobId: MongoID
    $jobsIds: [MongoID]
    $adjustersIds: [MongoID]
  ) {
    RostersByFilter(
      filter: {
        adjusterId: $adjusterId
        jobId: $jobId
        jobsIds: $jobsIds
        adjustersIds: $adjustersIds
      }
    ) {
      _id
      adjusterId
      jobId
      status
      review
    }
  }
`

export const GET_ROSTER_BY_ID = gql`
  query getRoster($id: MongoID, $adjusterId: MongoID, $jobId: MongoID) {
    RosterOne(filter: { _id: $id, adjusterId: $adjusterId, jobId: $jobId }) {
      _id
      adjusterId
      jobId
      status
      review
    }
  }
`

// NOTIFICATIONS
export const GET_NOTIFICATIONS = gql`
  query getNotifications($userId: MongoID) {
    NotificationMany(filter: { userId: $userId }) {
      _id
      userId
      message
      link
      read
      createdAt
    }
  }
`

export const GET_LAST_NOTIFICATIONS = gql`
  query getNotifications($userId: MongoID, $limit: Int) {
    GetLastNotifications(filter: { userId: $userId, limit: $limit }) {
      _id
      userId
      message
      link
      read
      createdAt
    }
  }
`

//CHATS
export const GET_USER_CHATS = gql`
  query UserChats($userId: MongoID) {
    UserChats(filter: { userId: $userId }) {
      _id
      jobId
      status
      users
      deletedBy
      messages
      pinnedBy
    }
  }
`

export const GET_FIRM_CHATS = gql`
  query FirmChats($jobId: MongoID, $usersIds: [String]) {
    FirmChats(filter: { jobId: $jobId, usersIds: $usersIds }) {
      _id
      jobId
      status
      users
      deletedBy
      messages
      pinnedBy
    }
  }
`

export const GET_ADMIN_CHATS = gql`
  query AdminChats {
    AdminChats {
      _id
      jobId
      status
      users
      deletedBy
      messages
      pinnedBy
    }
  }
`

export const GET_ADMIN_USER_CHAT = gql`
  query AdminUserChat($userId: MongoID) {
    AdminUserChat(filter: { userId: $userId }) {
      _id
      jobId
      status
      users
      deletedBy
      messages
      pinnedBy
    }
  }
`

export const GET_UNREAD_CHATS = gql`
  query UserUnreadChats($userId: MongoID) {
    UserUnreadChats(filter: { userId: $userId }) {
      # quantity
      _id
      jobId
      status
      users
      deletedBy
      messages
      pinnedBy
    }
  }
`

//SIGN NOW
export const GET_SIGN_NOW_FIRM_FOLDERS = gql`
  query signNowFirmFolders($firmId: MongoID) {
    SignNowFirmFoldersOne(filter: { firmId: $firmId }) {
      _id
      firmId
      documentsFolderId
      templatesFolderId
    }
  }
`

export const GET_SIGN_NOW_TEMPLATES = gql`
  query signNowTemplates($firmId: MongoID) {
    SignNowTemplateMany(filter: { firmId: $firmId }) {
      _id
      firmId
      templateId
      isDeleted
      templateName
      documentURL
    }
  }
`

// query getJobs($firmId: MongoID, $statuses: [String]) {
//   GetJobMany(filter: { firmId: $firmId, statuses: $statuses }) {

export const GET_SIGN_NOW_SIGNING_INVITATIONS = gql`
  query getSignNowSigningInvitations($firmId: MongoID, $adjusterId: MongoID) {
    SignNowSigningInvitationMany(
      filter: { firmId: $firmId, adjusterId: $adjusterId }
    ) {
      _id
      adjusterId
      firmId
      jobId
      templateId
      documentId
      status
      inviteId
    }
  }
`

// CHECKR
export const GET_FIRM_CHECKR_CANDIDATES = gql`
  query getCheckrCandidates($firmId: MongoID) {
    CheckrCandidateMany(filter: { firmId: $firmId }) {
      _id
      candidateId
      adjusterId
      firmId
      jobId
      plan
      checkrBackgroundCheckStatuses
    }
  }
`
