import { GET_ALL_FIRM_JOBS, GET_USER_BY_ID } from '../../../graphql/Queries'
import { useParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { FIRM_ROUTES, GLOBAL_ROUTES } from '../../../App'
import { User, UserContextType, UserType } from '../../../types/userTypes'
import { UserContext } from '../../../contexts/UserContext'
import {
  Box,
  Button,
  HStack,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Job, JobStatus } from '../../../types/jobTypes'
import useWindowSize from '../../../hooks/useWindowSize'
import JobSummaryCard from '../../../components/JobSummaryCard/JobSummaryCard'
import FirmJobTabs from '../../../components/FirmJobTabs/FirmJobTabs'
import { BiArrowBack } from 'react-icons/bi'

function FirmJobs() {
  const navigate = useNavigate()
  const windowsSize = useWindowSize()
  const { user }: UserContextType = useContext(UserContext)
  const [firm, setFirm] = useState<User | undefined>()
  const { jobId, tab } = useParams()

  const [selectedTab, setSelectedTab] = useState<number>(0)
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [jobsRetrieved, setJobsRetrieved] = useState<boolean>(false)
  const [jobs, setJobs] = useState<Job[]>([])
  const [selectedJob, setSelectedJob] = useState<Job | undefined>()

  const [getUser] = useLazyQuery(GET_USER_BY_ID)

  const retrieveFirm = useCallback(async () => {
    let firmUser = user

    if (user?.userType === UserType.ADMIN) {
      const firm = await getUser({
        variables: { id: selectedJob?.firmId },
        fetchPolicy: 'network-only',
      })
      firmUser = firm?.data?.UserOne
    }

    setFirm(firmUser)
  }, [getUser, selectedJob?.firmId, user])

  const jobsToShow = useMemo(() => {
    return selectedTab === 0
      ? jobs.filter((j) =>
          [JobStatus.ONGOING, JobStatus.DRAFT].includes(j.status)
        )
      : jobs.filter((j) => j.status === JobStatus.FINISHED)
  }, [selectedTab, jobs])

  const [getJobs] = useLazyQuery(GET_ALL_FIRM_JOBS, {
    onCompleted: (data: { GetJobMany: Job[] }) => {
      setShowLoader(false)
      if (data?.GetJobMany) {
        const firmJobs = data?.GetJobMany
        setJobs([...firmJobs])

        if (jobId) {
          const job = firmJobs.find((j) => j._id === jobId)
          setSelectedJob(job)
        }
      }
    },
    onError: () => {
      setShowLoader(false)
    },
  })

  // Runs the first time the page loads
  useEffect(() => {
    if (!jobsRetrieved && user) {
      setShowLoader(true)
      getJobs({
        variables: {
          firmId: user?._id,
          statuses: [JobStatus.DRAFT, JobStatus.ONGOING, JobStatus.FINISHED],
        },
        fetchPolicy: 'network-only',
      })
      setJobsRetrieved(true)
    }
  }, [setShowLoader, getJobs, jobsRetrieved, user])

  useEffect(() => {
    if (user) {
      retrieveFirm()
    }
  }, [retrieveFirm, user])

  const screenWidthIsAtListMedium = useMemo(() => {
    return windowsSize.width >= 768
  }, [windowsSize.width])

  const jobsListDisplay = useMemo(() => {
    return !screenWidthIsAtListMedium && selectedJob ? 'none' : 'block'
  }, [screenWidthIsAtListMedium, selectedJob])

  const jobsListWidth = useMemo(() => {
    return screenWidthIsAtListMedium ? '25%' : !selectedJob ? '100%' : '0'
  }, [screenWidthIsAtListMedium, selectedJob])

  const selectedJobSectionWidth = useMemo(() => {
    return screenWidthIsAtListMedium ? '75%' : selectedJob ? '100%' : '0'
  }, [screenWidthIsAtListMedium, selectedJob])

  const firmJob = useMemo(() => {
    return selectedJob ? (
      <FirmJobTabs
        jobId={jobId}
        tab={tab}
        firm={firm}
        job={selectedJob}
        setJob={setSelectedJob}
        onDelete={(jobId) => {
          const index = jobs.findIndex((f) => f._id === jobId)
          jobs.splice(index, 1)
          setJobs([...jobs])
        }}
        onUpdate={(updatedJob?: Job) => {
          if (updatedJob) {
            let jobIndex = jobs.findIndex((j) => j._id === updatedJob._id)
            if (jobIndex >= 0) {
              jobs[jobIndex] = { ...updatedJob }
              setJobs([...jobs])
            }
          }
        }}
        onReadQueryParams={() => {
          //   navigate(GLOBAL_ROUTES.JOBS)
        }}
        setShowParentLoader={setShowLoader}
        backButton={
          <>
            {!screenWidthIsAtListMedium && (
              <HStack justifyContent="space-between" mb={4}>
                <Box
                  fontSize={30}
                  cursor="pointer"
                  onClick={() => setSelectedJob(undefined)}
                >
                  <BiArrowBack />
                </Box>
              </HStack>
            )}
          </>
        }
      />
    ) : null
  }, [firm, jobId, jobs, screenWidthIsAtListMedium, selectedJob, tab])

  return (
    <Box h="100%" width="100%" display="flex">
      {/* Jobs lists (sidebar) */}
      <Box
        h="100%"
        display={jobsListDisplay}
        width={jobsListWidth}
        borderRight="1px solid #e3e2e2"
      >
        <Tabs
          variant="adjustant"
          h="100%"
          width="100%"
          index={selectedTab}
          onChange={(index) => {
            setSelectedTab(index)
          }}
        >
          <TabList background="white" width="100%">
            <Tab fontWeight={500} width="50%" py={2} mx={0}>
              Open Jobs
            </Tab>
            <Tab fontWeight={500} width="50%" py={2} mx={0}>
              Past Jobs
            </Tab>
          </TabList>

          {!showLoader && jobsToShow.length > 0 && (
            <TabPanels p={2} maxH="calc(100% - 92px)" overflowY="auto">
              {jobsToShow?.map((job) => (
                <Box key={job._id} mb={1}>
                  <JobSummaryCard
                    job={job}
                    onClick={() => {
                      setSelectedJob(job)
                    }}
                  />
                </Box>
              ))}
            </TabPanels>
          )}

          <VStack px={2} gap={0}>
            {showLoader && (
              <Stack direction="row" my={4}>
                <Spinner size="xl" />
              </Stack>
            )}

            {!showLoader && jobsToShow.length === 0 && (
              <Text my={8}>No jobs to show</Text>
            )}

            <Button
              w="100%"
              variant="adjustant"
              onClick={() => navigate(FIRM_ROUTES.CREATE_JOB)}
            >
              Create Job
            </Button>
          </VStack>
        </Tabs>
      </Box>

      {/* Job details, roster, invitations and applications */}
      <Box
        h="100%"
        overflowY="auto"
        width={selectedJobSectionWidth}
        backgroundColor="white"
      >
        {firmJob}
      </Box>
    </Box>
  )
}

export default FirmJobs
