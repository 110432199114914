import React from 'react'
import { createContext } from 'react'
import {
  NotificationContextType,
  AdjustantNotification,
} from '../types/notificationTypes'

export const NotificationsContext = createContext<NotificationContextType>({
  notifications: [],
  setNotifications: (
    value: React.SetStateAction<AdjustantNotification[]>
  ): void => {
    throw new Error('Function not implemented.')
  },
})
