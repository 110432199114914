import React, { useCallback, useContext, useMemo, useState } from 'react'
import CompleteProfileIntro from '../../components/CompleteProfile/CompleteProfileIntro'
import CompleteProfileUpdateDriverLicense from '../../components/CompleteProfile/CompleteProfileUpdateDriverLicense'
import CompleteProfileSetJobPreferences from '../../components/CompleteProfile/CompleteProfileSetJobPreferences'
import CompleteProfileSetProfileDetails from '../../components/CompleteProfile/CompleteProfileSetProfileDetails'
import CompleteProfileSetAreasOfOperation from '../../components/CompleteProfile/CompleteProfileSetAreasOfOperation'
import CompleteProfileUpdateFirmsLicensesAndCertifications from '../../components/CompleteProfile/CompleteProfileUpdateFirmsLicensesAndCertifications'
import { Box, Link } from '@chakra-ui/react'
import { GLOBAL_ROUTES } from '../../App'
import { UserContext } from '../../contexts/UserContext'
import {
  LicensingRequirement,
  User,
  UserContextType,
  UserType,
} from '../../types/userTypes'
import { storeFile } from '../../utils/functions'
import { useMutation } from '@apollo/client'
import { UPDATE_USER_BY_EMAIL } from '../../graphql/Mutations'
import {
  setItemToLocalStorage,
  LOCALSTORAGE_OBJECTS_NAMES,
} from '../../utils/localStorageFunctions'
import { useNavigate } from 'react-router-dom'
import { DamageType } from '../../types/jobTypes'
import CompleteProfileUpdateUpdateW9TaxForm from '../../components/CompleteProfile/CompleteProfileUpdateUpdateW9TaxForm'
import CompleteProfileUpdateUpdateI9TaxForm from '../../components/CompleteProfile/CompleteProfileUpdateUpdateI9TaxForm'
import { US_STATES_ABBREVIATIONS } from '../../types/stateLicensesTypes'
import CompleteProfileUpdateUpdateW4TaxForm from '../../components/CompleteProfile/CompleteProfileUpdateUpdateW4TaxForm'

const Wrapper = ({ children }: { children: React.JSX.Element }) => {
  return (
    <Box
      background="white"
      border="1px solid #ECEAE9"
      borderRadius="8px"
      p={4}
      m={3}
    >
      <Box mb={2} textAlign="end">
        <Link href={GLOBAL_ROUTES.JOBS}>Skip</Link>
      </Box>
      <Box p={[0, 6, 10]}>{children}</Box>
    </Box>
  )
}

const SetUpProfileWorkflow = () => {
  const { user, setUser }: UserContextType = useContext(UserContext)
  const [updateUser] = useMutation(UPDATE_USER_BY_EMAIL(user?.email))
  const [step, setStep] = useState(1)
  const navigate = useNavigate()
  const [showLoader, setShowLoader] = useState(false)

  const updateProfile = useCallback(
    async (partialUser?: Partial<User>) => {
      if (user) {
        console.log('TO UPDATE: ', partialUser)
        const result = await updateUser({
          variables: { user: partialUser },
        })
        // console.log(result)
        if (result?.data) {
          setUser({ ...user, ...partialUser })
          setItemToLocalStorage(LOCALSTORAGE_OBJECTS_NAMES.USER, {
            ...user,
            ...partialUser,
          })
        }
      }
    },
    [user, updateUser, setUser]
  )

  // Adjuster Functions
  const onDriverLicenseNext = useCallback(
    async (newDL: any, { file, fileName }: { file: any; fileName: string }) => {
      //   console.log(newDL, { file, fileName })
      setShowLoader(true)
      if (user) {
        const result = await storeFile(file, fileName, user)
        await updateProfile({
          driverLicense: {
            ...newDL,
            url:
              file && fileName
                ? result?.data
                : fileName
                ? user?.driverLicense?.url
                : '',
          },
        })
      }
      setShowLoader(false)
      setStep(3)
    },
    [setShowLoader, setStep, updateProfile, user]
  )

  // Adjuster Functions
  const onW9TaxFormNext = useCallback(
    async (newDL: any, { file, fileName }: { file: any; fileName: string }) => {
      //   console.log(newDL, { file, fileName })
      setShowLoader(true)
      if (user) {
        const result = await storeFile(file, fileName, user)
        await updateProfile({
          w9TaxForm: {
            ...newDL,
            url:
              file && fileName
                ? result?.data
                : fileName
                ? user?.w9TaxForm?.url
                : '',
          },
        })
      }
      setShowLoader(false)
      setStep(4)
    },
    [setShowLoader, setStep, updateProfile, user]
  )

  const onW4TaxFormNext = useCallback(
    async (newDL: any, { file, fileName }: { file: any; fileName: string }) => {
      //   console.log(newDL, { file, fileName })
      setShowLoader(true)
      if (user) {
        const result = await storeFile(file, fileName, user)
        await updateProfile({
          w4TaxForm: {
            ...newDL,
            url:
              file && fileName
                ? result?.data
                : fileName
                ? user?.w4TaxForm?.url
                : '',
          },
        })
      }
      setShowLoader(false)
      setStep(5)
    },
    [setShowLoader, setStep, updateProfile, user]
  )

  const onI9TaxFormNext = useCallback(
    async (newDL: any, { file, fileName }: { file: any; fileName: string }) => {
      //   console.log(newDL, { file, fileName })
      setShowLoader(true)
      if (user) {
        const result = await storeFile(file, fileName, user)
        await updateProfile({
          i9TaxForm: {
            ...newDL,
            url:
              file && fileName
                ? result?.data
                : fileName
                ? user?.i9TaxForm?.url
                : '',
          },
        })
      }
      setShowLoader(false)
      setStep(6)
    },
    [setShowLoader, setStep, updateProfile, user]
  )

  const onJobPreferencesNext = useCallback(
    async (jobLocations: US_STATES_ABBREVIATIONS[], jobTypes: DamageType[]) => {
      setShowLoader(true)
      await updateProfile({
        jobLocations,
        jobTypes,
      })
      setShowLoader(false)
      setStep(7)
    },
    [updateProfile]
  )

  const onProfileDetailsNext = useCallback(
    async (
      {
        yearsOfLicensedExperience,
        bio,
        firmName,
        location,
        languagePreference,
        emergencyContact,
        xactimateId,
        companyAlias,
        phoneNumber,
      }: {
        yearsOfLicensedExperience: number
        bio: string
        firmName: string
        location: string
        languagePreference: string
        emergencyContact: string
        xactimateId: string
        companyAlias: string
        phoneNumber: string
      },
      { fileName, file }: { fileName: string; file: any }
    ) => {
      setShowLoader(true)
      if (user) {
        const result = await storeFile(file, fileName, user)
        await updateProfile({
          yearsOfLicensedExperience,
          bio,
          firmName,
          location,
          languagePreference,
          emergencyContact,
          xactimateId,
          companyAlias,
          phoneNumber,
          profileSet: true,
          profilePicture:
            file && fileName
              ? result?.data
              : fileName
              ? user?.profilePicture
              : '',
        })
      }
      setShowLoader(false)
      navigate(GLOBAL_ROUTES.PROFILE)
    },
    [user, navigate, updateProfile]
  )

  // Firm Functions
  const onAreasOfOperationNext = useCallback(
    async (states: string[]) => {
      setShowLoader(true)
      await updateProfile({
        areasOfOperation: states,
      })
      setShowLoader(false)
      setStep(3)
    },
    [updateProfile]
  )

  const onFirmsLicensesAndCertificationsNext = useCallback(
    async (additionalCertificationRequirements: LicensingRequirement[]) => {
      setShowLoader(true)
      await updateProfile({
        additionalCertificationRequirements,
      })
      setShowLoader(false)
      setStep(4)
    },
    [updateProfile]
  )

  const adjusterStep = useMemo(() => {
    switch (step) {
      case 1:
        return (
          <CompleteProfileIntro
            next={() => {
              //   setStep(2)
              setStep(6)
            }}
          />
        )
      case 2:
        return (
          <CompleteProfileUpdateDriverLicense
            next={onDriverLicenseNext}
            driverLicense={user?.driverLicense}
            showLoader={showLoader}
          />
        )
      case 3:
        return (
          <CompleteProfileUpdateUpdateW9TaxForm
            next={onW9TaxFormNext}
            w9TaxForm={user?.w9TaxForm}
            showLoader={showLoader}
          />
        )
      case 4:
        return (
          <CompleteProfileUpdateUpdateW4TaxForm
            next={onW4TaxFormNext}
            w4TaxForm={user?.w4TaxForm}
            showLoader={showLoader}
          />
        )
      case 5:
        return (
          <CompleteProfileUpdateUpdateI9TaxForm
            next={onI9TaxFormNext}
            i9TaxForm={user?.i9TaxForm}
            showLoader={showLoader}
          />
        )
      case 6:
        return (
          <CompleteProfileSetJobPreferences
            jobLocations={user?.jobLocations}
            jobTypes={user?.jobTypes}
            showLoader={showLoader}
            next={onJobPreferencesNext}
          />
        )
      case 7:
        return (
          <CompleteProfileSetProfileDetails
            userType={user?.userType}
            bio={user?.bio}
            yearsOfLicensedExperience={user?.yearsOfLicensedExperience}
            profilePicture={user?.profilePicture}
            location={user?.location}
            languagePreference={user?.languagePreference}
            emergencyContact={user?.emergencyContact}
            xactimateId={user?.xactimateId}
            companyAlias={user?.companyAlias}
            phoneNumber={user?.phoneNumber}
            next={onProfileDetailsNext}
            showLoader={showLoader}
          />
        )
      default:
        return <></>
    }
  }, [
    step,
    onDriverLicenseNext,
    user?.driverLicense,
    user?.w9TaxForm,
    user?.w4TaxForm,
    user?.i9TaxForm,
    user?.jobLocations,
    user?.jobTypes,
    user?.userType,
    user?.bio,
    user?.yearsOfLicensedExperience,
    user?.profilePicture,
    user?.location,
    user?.languagePreference,
    user?.emergencyContact,
    user?.xactimateId,
    user?.companyAlias,
    user?.phoneNumber,
    showLoader,
    onW9TaxFormNext,
    onW4TaxFormNext,
    onI9TaxFormNext,
    onJobPreferencesNext,
    onProfileDetailsNext,
  ])

  const firmStep = useMemo(() => {
    switch (step) {
      case 1:
        return (
          <CompleteProfileIntro
            next={() => {
              setStep(2)
            }}
          />
        )
      case 2:
        return (
          <CompleteProfileSetAreasOfOperation
            showLoader={showLoader}
            areasOfOperation={user?.areasOfOperation}
            next={onAreasOfOperationNext}
          />
        )
      case 3:
        return (
          <CompleteProfileUpdateFirmsLicensesAndCertifications
            showLoader={showLoader}
            certificationsRequirements={
              user?.additionalCertificationRequirements
            }
            next={onFirmsLicensesAndCertificationsNext}
          />
        )
      case 4:
        return (
          <CompleteProfileSetProfileDetails
            userType={user?.userType}
            bio={user?.bio}
            firmName={user?.firmName}
            location={user?.location}
            profilePicture={user?.profilePicture}
            next={onProfileDetailsNext}
            showLoader={showLoader}
          />
        )
      default:
        return <></>
    }
  }, [
    user,
    step,
    setStep,
    showLoader,
    onAreasOfOperationNext,
    onFirmsLicensesAndCertificationsNext,
    onProfileDetailsNext,
  ])

  return (
    <Box
      minHeight="100%"
      width="100%"
      background="gray.100"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Wrapper>
        {user?.userType === UserType.ADJUSTER ? adjusterStep : firmStep}
      </Wrapper>
    </Box>
  )
}

export default SetUpProfileWorkflow
