import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react'
import FirmsList from '../FirmsList/FirmsList'
import { AdminFirm } from '../../types/userTypes'
import { Job } from '../../types/jobTypes'

const FirmsListModal = ({
  firms,
  jobs,
  isOpen,
  onClose,
}: {
  firms: AdminFirm[]
  jobs: Job[]
  //   rosters: Roster[]
  isOpen: boolean
  onClose: () => void
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Firms Summary</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FirmsList firms={firms} jobs={jobs} />
        </ModalBody>

        <ModalFooter>
          <Button variant="adjustant" mr={3} onClick={onClose}>
            Close
          </Button>
          {/* <Button variant="ghost">Secondary Action</Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default FirmsListModal
