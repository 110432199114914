import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react'
import { Job, Roster } from '../../types/jobTypes'
import AdjustersList from '../AdjustersList/AdjustersList'
import { AdminAdjuster } from '../../types/userTypes'

const AdjustersListModal = ({
  header = 'Adjusters Summary',
  adjusters,
  jobs,
  rosters,
  isOpen,
  onClose,
  tab,
}: {
  header?: string
  adjusters: AdminAdjuster[]
  jobs: Job[]
  rosters: Roster[]
  isOpen: boolean
  onClose: () => void
  tab?: number
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <AdjustersList
            adjusters={adjusters}
            jobs={jobs}
            rosters={rosters}
            tab={tab}
          />
        </ModalBody>

        <ModalFooter>
          <Button variant="adjustant" mr={3} onClick={onClose}>
            Close
          </Button>
          {/* <Button variant="ghost">Secondary Action</Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default AdjustersListModal
