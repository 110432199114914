import {
  Center,
  VStack,
  HStack,
  Button,
  Text,
  Box,
  Icon,
  Square,
  Spinner,
} from '@chakra-ui/react'
import { AiOutlineClose, AiOutlineCloudUpload } from 'react-icons/ai'
import FileInput from '../FileInput/FileInput'

export const Dropzone = ({
  onFileInputChange,
  clearFile,
  accept,
  fileName,
  fileLoaded,
  uploadFile,
  isLoading,
}: {
  onFileInputChange: any
  clearFile?: any
  accept: string
  fileName: string
  fileLoaded: boolean
  uploadFile: () => Promise<void>
  isLoading: boolean
}) => (
  <Center borderWidth="1px" borderRadius="lg" px="6" py="12">
    <HStack spacing="1" whiteSpace="nowrap">
      {!fileLoaded ? (
        isLoading ? (
          <HStack
            direction="row"
            my={4}
            height="-webkit-fill-available"
            w="100%"
            // h="100%"
            justifyContent="center"
          >
            <Spinner size="xl" />
          </HStack>
        ) : (
          <FileInput
            element={
              <Square size="10" bg="bg.subtle" borderRadius="lg" gap={2}>
                <Icon as={AiOutlineCloudUpload} boxSize="5" color="fg.muted" />
                <Text>Upload</Text>
              </Square>
            }
            accept={accept}
            onFileInputChange={onFileInputChange}
            clearFile={clearFile}
          />
        )
      ) : (
        <VStack>
          <HStack>
            <Text>{fileName}</Text>
            <Box cursor="pointer" mt="3px">
              <AiOutlineClose onClick={clearFile} />
            </Box>
          </HStack>

          <Button variant="adjustant" onClick={uploadFile}>
            Upload
          </Button>
        </VStack>
      )}
    </HStack>
  </Center>
)
