import React, { useCallback, useMemo, useState } from 'react'
import PasswordStrength, {
  PasswordStrengthType,
} from '../PasswordStrength/PasswordStrength'
import { registerUser } from '../../services/adjustant'
import { UserType } from '../../types/userTypes'
import {
  VStack,
  Heading,
  Box,
  Input,
  Text,
  Stack,
  Button,
} from '@chakra-ui/react'
import Label from '../Label/Label'
import { ADJUSTANT_GREEN } from '../../themes/themes'

const CreatePassword = ({
  email,
  password,
  userType,
  setPassword,
  setStep,
}: {
  email: string
  password: string
  userType: UserType
  setPassword: React.Dispatch<React.SetStateAction<string>>
  setStep: React.Dispatch<React.SetStateAction<number>>
}) => {
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [passwordStrength, setPasswordStrength] = useState<
    PasswordStrengthType | undefined
  >()

  const isPasswordInvalid = useMemo(() => {
    return passwordStrength !== 'strong' || password !== confirmPassword
  }, [passwordStrength, password, confirmPassword])

  const onContinue = useCallback(async () => {
    setErrorMessage('')
    if (!isPasswordInvalid) {
      setShowLoader(true)
      const responseData = await registerUser({
        email,
        userType,
        pwd: password,
      })

      if (responseData?.success) {
        setErrorMessage('A confirmation code was sent to your email')
        setTimeout(() => {
          setStep(3)
        }, 2000)
      } else {
        setErrorMessage(responseData?.message)
      }
      setShowLoader(false)
    } else {
      setErrorMessage('Password must meet requrements !')
    }
  }, [isPasswordInvalid, email, userType, password, setStep, setShowLoader])

  return (
    <Box
      minHeight="100%"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        background="white"
        border="1px solid #ECEAE9"
        borderRadius="8px"
        width="600px"
        mx={3}
      >
        <VStack py={[6, 6, 12]} px={[6, 6, 12]}>
          <Heading
            as="h3"
            fontSize="3xl"
            color="gray.500"
            mt={4}
            mb={8}
            textAlign="center"
          >
            Create a Password
          </Heading>

          <Stack mb={2} width="100%">
            <Label color="gray.500">Password</Label>
            <Input
              type="password"
              placeholder="Password"
              _focusVisible={{ borderColor: 'gray.300' }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <PasswordStrength
              password={password}
              passwordStrength={passwordStrength}
              setPasswordStrength={setPasswordStrength}
            />
          </Stack>

          <Stack mb={2} width="100%">
            <Label color="gray.500">Confirm Password</Label>
            <Input
              type="password"
              placeholder="Confirm Password"
              _focusVisible={{ borderColor: 'gray.300' }}
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value)
                if (e.target.value !== password) {
                  setErrorMessage('Passwords must be the same !')
                } else {
                  setErrorMessage('')
                }
              }}
            />
          </Stack>

          <Text as="h5" color="gray.500" fontSize="sm">
            Password must be at least 8 characters long and include an uppercase
            letter, a number, and a special character.
          </Text>
          <Text as="h5" color="red.500" fontSize="sm">
            {errorMessage}
          </Text>

          <Button
            mt={6}
            variant="adjustant"
            isLoading={showLoader}
            isDisabled={isPasswordInvalid}
            _hover={{ backgroundColor: ADJUSTANT_GREEN }}
            onClick={onContinue}
          >
            Continue
          </Button>
        </VStack>
      </Box>
    </Box>
  )
}

export default CreatePassword
