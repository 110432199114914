import {
  Box,
  HStack,
  Divider,
  Text,
  Stack,
  Heading,
  Spinner,
} from '@chakra-ui/react'
import { useCallback, useContext, useEffect, useState } from 'react'
import { NotificationsContext } from '../../contexts/NotificationsContext'
import {
  AdjustantNotification,
  NotificationContextType,
} from '../../types/notificationTypes'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  UPDATE_NOTIFICATION_BY_ID,
  DELETE_NOTIFICATION_BY_ID,
} from '../../graphql/Mutations'
import { useNavigate } from 'react-router-dom'
import { GET_LAST_NOTIFICATIONS } from '../../graphql/Queries'
import { UserContext } from '../../contexts/UserContext'
import { UserContextType } from '../../types/userTypes'
import { IoMdClose } from 'react-icons/io'
import { MdWorkOutline } from 'react-icons/md'
import { formatDate } from '../../utils/functions'

const Notifications = () => {
  const navigate = useNavigate()
  const [showLoader, setShowLoader] = useState<boolean>(true)
  const { user }: UserContextType = useContext(UserContext)
  const { notifications, setNotifications }: NotificationContextType =
    useContext(NotificationsContext)
  const [oldNotifications, setOldNotifications] = useState<
    AdjustantNotification[]
  >([])

  const [getNotifications] = useLazyQuery(GET_LAST_NOTIFICATIONS, {
    onCompleted: (data: { GetLastNotifications: AdjustantNotification[] }) => {
      setShowLoader(false)
      if (data?.GetLastNotifications)
        setOldNotifications([...data?.GetLastNotifications])
    },
    onError: (err) => {
      setShowLoader(false)
    },
  })
  const [updateNotificationMutation] = useMutation(UPDATE_NOTIFICATION_BY_ID)
  const [deleteNotificationMutation] = useMutation(DELETE_NOTIFICATION_BY_ID)

  useEffect(() => {
    setShowLoader(true)
    getNotifications({
      variables: {
        userId: user?._id,
        limit: 50,
      },
      fetchPolicy: 'network-only',
    })
  }, [getNotifications, user?._id])

  const updateLocalLastNotification = useCallback(
    (id: string) => {
      const noti = notifications.find((n) => n._id === id)
      if (noti) {
        noti.read = true
        setNotifications([...notifications])
      }
    },
    [notifications, setNotifications]
  )

  const onNotificationClick = useCallback(
    (notification: AdjustantNotification) => {
      updateNotificationMutation({
        variables: {
          notification: { read: true },
          id: notification._id,
        },
      })
      notification.read = true
      setOldNotifications([...oldNotifications])
      updateLocalLastNotification(notification._id ?? '')
      navigate(`${notification.link}`)
    },
    [
      navigate,
      oldNotifications,
      updateLocalLastNotification,
      updateNotificationMutation,
    ]
  )

  const deleteLocalLastNotification = useCallback(
    (id: string) => {
      const index = notifications.findIndex((n) => n._id === id)
      if (index >= 0) {
        notifications.splice(index, 1)
        setNotifications([...notifications])
      }
    },
    [notifications, setNotifications]
  )

  const deleteNotification = useCallback(
    async (notificationId: string, index: number) => {
      oldNotifications.splice(index, 1)
      setOldNotifications([...oldNotifications])
      deleteLocalLastNotification(notificationId)
      deleteNotificationMutation({
        variables: { id: notificationId },
      })
    },
    [deleteLocalLastNotification, deleteNotificationMutation, oldNotifications]
  )

  return (
    <Box h="100%" width="100%" backgroundColor="white" p={[4, 8]}>
      <Heading as="h4" size="md" color="gray.500" m={[4, 8]}>
        Notifications
      </Heading>

      {oldNotifications.length > 0 && !showLoader && (
        <>
          <Divider />
          {oldNotifications.map((notifiation, i) => (
            <Box key={i}>
              <Box
                display="flex"
                alignItems="start"
                fontWeight={notifiation.read ? '' : '600'}
                fontSize="md"
                color="black"
                cursor="pointer"
                onClick={() => {
                  console.log('OUTER')
                  onNotificationClick(notifiation)
                }}
              >
                <HStack
                  color="inherit"
                  p={4}
                  w="100%"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <HStack alignItems="center">
                    <MdWorkOutline size={30} />
                    <Stack>
                      <Text color="black" fontSize="xl">
                        {notifiation.message}
                      </Text>
                      <Text color="gray.500">
                        {formatDate(notifiation.createdAt ?? '')}
                      </Text>
                    </Stack>
                  </HStack>
                  <IoMdClose
                    size={25}
                    cursor="pointer"
                    onClick={(e) => {
                      console.log('INNER')
                      e.stopPropagation()
                      deleteNotification(notifiation?._id ?? '', i)
                    }}
                  />
                </HStack>
              </Box>
              <Divider />
            </Box>
          ))}
        </>
      )}

      {showLoader && (
        <HStack justifyContent="center" h="calc(100% - 90px)">
          <Spinner size="xl" />
        </HStack>
      )}

      {oldNotifications.length === 0 && !showLoader && (
        <HStack direction="row" h="calc(100% - 90px)" justifyContent="center">
          <Heading>No Notifications Received</Heading>
        </HStack>
      )}
    </Box>
  )
}

export default Notifications
