import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Box, Button, HStack } from '@chakra-ui/react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { UserContext } from '../../../contexts/UserContext'
import {
  ADD_JOB,
  CREATE_NOTIFICATION,
  UPDATE_JOB_BY_ID,
} from '../../../graphql/Mutations'
import {
  DamageType,
  BillingType,
  JobLicensingRequirement,
  JobCertificationRequirement,
  JobStatus,
  VISIBILITY,
  Job,
  PRESENCE,
  DATE_OF_LOSS_TYPE,
  BILLING_FORM,
  BillingFile,
} from '../../../types/jobTypes'
import { FEMA_CATEGORY, User, UserContextType } from '../../../types/userTypes'
import { GLOBAL_ROUTES } from '../../../App'
import { useNavigate } from 'react-router-dom'
import { storeFile } from '../../../utils/functions'
import { GET_ADMINS } from '../../../graphql/Queries'
import CreateJobForm from '../../../components/CreateJobForm/CreateJobForm'

function CreateJob() {
  const { user }: UserContextType = useContext(UserContext)
  const navigate = useNavigate()
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [admins, setAdmins] = useState<User[]>([])
  const [adminsRetrieved, setAdminsRetrieved] = useState(false)
  const [createJobMutation] = useMutation(ADD_JOB)
  const [updateJobMutation] = useMutation(UPDATE_JOB_BY_ID)
  const [createNotificationMutation] = useMutation(CREATE_NOTIFICATION)

  const [title, setTitle] = useState<string>('')
  const [adjustersNeeded, setAdjustersNeeded] = useState<number>(1)
  const [presence, setPresence] = useState<PRESENCE>(PRESENCE.FIELD)
  const [damageTypes, setDamageTypes] = useState<DamageType[]>([])
  const [damageTypesDescription, setDamageTypeDescription] = useState('')
  const [reportDate, setReportDate] = useState<string>('')
  const [dateOfLossType, setDateOfLossType] = useState<DATE_OF_LOSS_TYPE>(
    DATE_OF_LOSS_TYPE.DATE
  )
  const [dateOfLoss, setDateOfLoss] = useState<string>('')
  const [location, setLocation] = useState<string>('')
  const [state, setState] = useState<string>('')
  const [city, setCity] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [allAdjustersCanApply, setAllAdjustersCanApply] = useState(false)
  const [visibility, setVisibility] = useState<VISIBILITY>(VISIBILITY.PUBLIC)
  const [billingType, setBillingType] = useState<BillingType>(
    BillingType.HOURLY
  )
  const [billingTypeDescription, setBillingTypeDescription] =
    useState<string>('')
  const [billingForm, setBillingForm] = useState<BILLING_FORM>(BILLING_FORM.W2)
  const [billingFiles, setBillingFiles] = useState<BillingFile[]>([])

  const [isFemaCertificationRequired, setIsFemaCertificationRequired] =
    useState<boolean>(false)
  const [femaCertificationCategories, setFemaCertificationCategories] =
    useState<FEMA_CATEGORY[]>([])
  const [licensingRequirements, setLicensingRequirements] = useState<
    JobLicensingRequirement[]
  >([])
  const [certificationRequirements, setCertificationRequirements] = useState<
    JobCertificationRequirement[]
  >([])

  const invalidForm = useMemo(() => {
    const a = licensingRequirements.find((l) => !l.id)
    const b = certificationRequirements.find((c) => !c.id && !c.name)

    return (
      !title ||
      !adjustersNeeded ||
      damageTypes.length === 0 ||
      !damageTypesDescription ||
      !reportDate ||
      !state ||
      //   !description ||
      !billingType ||
      !visibility ||
      (isFemaCertificationRequired &&
        femaCertificationCategories.length === 0) ||
      (dateOfLossType === DATE_OF_LOSS_TYPE.DATE && !dateOfLoss) ||
      !!a ||
      !!b
    )
  }, [
    licensingRequirements,
    certificationRequirements,
    title,
    adjustersNeeded,
    damageTypes.length,
    damageTypesDescription,
    reportDate,
    state,
    billingType,
    visibility,
    isFemaCertificationRequired,
    femaCertificationCategories,
    dateOfLossType,
    dateOfLoss,
  ])

  const notifyAdmins = useCallback(
    async (message: string, jobId: string) => {
      const a = await admins.map(async (admin) => {
        await createNotificationMutation({
          variables: {
            notification: {
              userId: admin?._id,
              message,
              link: `${GLOBAL_ROUTES.ADMIN}${GLOBAL_ROUTES.JOB}/${jobId}`,
              read: false,
            },
          },
        })

        return null
      })

      await Promise.all(a)
    },
    [admins, createNotificationMutation]
  )

  const createJob = useCallback(
    async (saveAsDraft: boolean) => {
      //   console.log('CREATING A JOB')
      if (invalidForm) {
        console.log('INVALID CREATE JOB FORM')
        return
      }
      console.log('CREATING A JOB')
      if (billingType && damageTypes && user) {
        try {
          setShowLoader(true)

          const jobData = {
            title,
            adjustersNeeded,
            presence,
            types: damageTypes,
            typesDescription: damageTypesDescription,
            reportDate,
            dateOfLossType,
            dateOfLoss:
              dateOfLossType === DATE_OF_LOSS_TYPE.DATE
                ? dateOfLoss
                : undefined,
            location,
            state,
            city,
            description,
            isFemaCertificationRequired,
            femaCertificationCategories,
            licensingRequirements,
            certificationRequirements,
            billingType,
            billingTypeDescription,
            billingTypeFileURLs: [],
            billingForm,
            allAdjustersCanApply,
            visibility,
            firmId: user?._id ?? '',
            status: saveAsDraft ? JobStatus.DRAFT : JobStatus.ONGOING,
            postingDate: new Date(),
          }

          const result = await createJobMutation({
            variables: { job: jobData },
          })
          const createdJob = result?.data?.JobCreateOne?.record as Job
          if (createdJob) {
            await notifyAdmins(
              `The job "${createdJob?.title}" has been created`,
              createdJob?._id ?? ''
            )

            //Update urls
            const urlsPromise = billingFiles.map(async (billingFile) => {
              const billingTypeFileResponse = await storeFile(
                billingFile.file,
                billingFile.fileName,
                user,
                `jobs/${createdJob?._id}/billingCompensationSchedules/`
              )
              return billingTypeFileResponse?.data ?? ''
            })

            const urls = await Promise.all(urlsPromise)
            await updateJobMutation({
              variables: {
                job: { billingTypeFileURLs: urls },
                id: createdJob?._id,
              },
            })

            navigate(GLOBAL_ROUTES.JOBS)
          }
          setShowLoader(false)
        } catch (error) {
          setShowLoader(false)
        }
      }
    },
    [
      invalidForm,
      billingType,
      damageTypes,
      user,
      title,
      adjustersNeeded,
      presence,
      damageTypesDescription,
      reportDate,
      dateOfLossType,
      dateOfLoss,
      location,
      state,
      city,
      description,
      isFemaCertificationRequired,
      femaCertificationCategories,
      licensingRequirements,
      certificationRequirements,
      billingTypeDescription,
      billingForm,
      allAdjustersCanApply,
      visibility,
      createJobMutation,
      notifyAdmins,
      billingFiles,
      updateJobMutation,
      navigate,
    ]
  )

  const [getAdmins] = useLazyQuery(GET_ADMINS, {
    onCompleted: (data: { UserMany: User[] }) => {
      if (data?.UserMany) {
        const newUsers = [...data?.UserMany]
        setAdmins(newUsers)
      }
      setAdminsRetrieved(true)
    },
    onError: (err) => {
      console.log(err)
    },
  })

  useEffect(() => {
    if (!adminsRetrieved) getAdmins()
  }, [adminsRetrieved, getAdmins])

  return (
    <Box paddingX={['10px', '50px']} paddingY="10px">
      <HStack w="100%" justifyContent="center">
        <CreateJobForm
          user={user}
          title={title}
          setTitle={setTitle}
          adjustersNeeded={adjustersNeeded}
          setAdjustersNeeded={setAdjustersNeeded}
          presence={presence}
          setPresence={setPresence}
          damageTypes={damageTypes}
          setDamageTypes={setDamageTypes}
          damageTypesDescription={damageTypesDescription}
          setDamageTypeDescription={setDamageTypeDescription}
          reportDate={reportDate}
          setReportDate={setReportDate}
          dateOfLossType={dateOfLossType}
          setDateOfLossType={setDateOfLossType}
          dateOfLoss={dateOfLoss}
          setDateOfLoss={setDateOfLoss}
          location={location}
          setLocation={setLocation}
          state={state}
          setState={setState}
          city={city}
          setCity={setCity}
          description={description}
          setDescription={setDescription}
          allAdjustersCanApply={allAdjustersCanApply}
          setAllAdjustersCanApply={setAllAdjustersCanApply}
          visibility={visibility}
          setVisibility={setVisibility}
          isFemaCertificationRequired={isFemaCertificationRequired}
          setIsFemaCertificationRequired={setIsFemaCertificationRequired}
          femaCertificationCategories={femaCertificationCategories}
          setFemaCertificationCategories={setFemaCertificationCategories}
          licensingRequirements={licensingRequirements}
          setLicensingRequirements={setLicensingRequirements}
          certificationRequirements={certificationRequirements}
          setCertificationRequirements={setCertificationRequirements}
          billingType={billingType}
          setBillingType={setBillingType}
          billingTypeDescription={billingTypeDescription}
          setBillingTypeDescription={setBillingTypeDescription}
          billingFiles={billingFiles}
          setBillingFiles={setBillingFiles}
          billingForm={billingForm}
          setBillingForm={setBillingForm}
          buttons={
            <HStack justifyContent="center">
              <Button
                type="submit"
                variant="adjustant"
                // isDisabled={invalidForm}
                onClick={() => createJob(true)}
                isLoading={showLoader}
              >
                Save as Draft
              </Button>
              <Button
                type="submit"
                variant="adjustant"
                // isDisabled={invalidForm}
                onClick={() => createJob(false)}
                isLoading={showLoader}
              >
                Post Job
              </Button>
            </HStack>
          }
        />
      </HStack>
    </Box>
  )
}

export default CreateJob
