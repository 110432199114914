import { Box, Button, HStack, Link, Stack, VStack } from '@chakra-ui/react'
import { AiOutlineClose, AiOutlineCloudUpload } from 'react-icons/ai'
import FileInput from '../FileInput/FileInput'
import Label from '../Label/Label'
import { useCallback } from 'react'
import { encodeS3URI } from '../../utils/functions'

const UpdateTaxForm = ({
  editable,
  url,
  setURL,
  setFile,
  fileName,
  setFileName,
  hideFile,
  align = 'start',
  officialDocumentURL,
  documentName,
}: {
  editable: boolean
  url: string
  setURL?: React.Dispatch<React.SetStateAction<string>>
  setFile?: React.Dispatch<React.SetStateAction<any>>
  fileName: string
  setFileName?: React.Dispatch<React.SetStateAction<string>>
  hideFile: boolean
  align?: 'start' | 'center'
  officialDocumentURL?: string
  documentName: string
}) => {
  const onFileInputChange = useCallback(
    async (e: any) => {
      const fileFromFiles = e.target.files[0]
      if (fileFromFiles) {
        if (setFile) setFile(fileFromFiles)
        if (setFileName) setFileName(fileFromFiles.name)
        const url = URL.createObjectURL(fileFromFiles)
        if (setURL) setURL(url)
      }
    },
    [setFile, setFileName, setURL]
  )
  const deleteFile = useCallback(() => {
    if (setFile) setFile(null)
    if (setFileName) setFileName('')
    if (setURL) setURL('')
  }, [setFile, setFileName, setURL])

  return (
    <Box>
      <Stack textAlign={align}>
        {(editable || hideFile) && (
          <>
            <HStack textAlign={align} justifyContent={align} width="100%">
              <Label fontSize="sm" color="gray.700">
                {editable
                  ? `Update your ${documentName}`
                  : 'Document Received and Approved - ON FILE'}
              </Label>
            </HStack>
          </>
        )}

        {!hideFile && (
          <>
            {url || !editable ? (
              <HStack textAlign={align} justifyContent={align}>
                <Link color="teal.500" href={encodeS3URI(url)} isExternal>
                  {fileName || '-'}
                </Link>
                {editable && (
                  <AiOutlineClose cursor="pointer" onClick={deleteFile} />
                )}
              </HStack>
            ) : (
              <FileInput
                element={
                  <Button
                    variant="adjustant"
                    rightIcon={<AiOutlineCloudUpload />}
                  >
                    Upload
                  </Button>
                }
                accept=".pdf,.png,.jpeg,.jpg"
                onFileInputChange={onFileInputChange}
                clearFile={() => {
                  if (setFile) setFile(undefined)
                }}
              />
            )}
          </>
        )}

        {officialDocumentURL && (
          <Link
            color="teal.500"
            href={encodeS3URI(officialDocumentURL)}
            isExternal
            textOverflow="ellipsis"
            overflow="hidden"
            //   noOfLines={1}
          >
            Download {documentName}
          </Link>
        )}
      </Stack>
    </Box>
  )
}

export default UpdateTaxForm
