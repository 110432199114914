import _ from 'lodash'
import { DocumentData } from '../components/UploadAdjusterLicensesOrCertifications/UploadAdjusterLicensesAndCertifications'
import { sendDataToS3 } from '../services/adjustant'
import {
  DATA_SOURCE,
  FEMA_CATEGORY,
  User,
  UserType,
  femaCategoriesOptions,
} from '../types/userTypes'
import { Buffer } from 'buffer'
import {
  BILLING_FORM,
  Job,
  JobCertificationRequirement,
  JobLicensingRequirement,
  JobSpecs,
} from '../types/jobTypes'
import { ListedStateLicense } from '../types/stateLicensesTypes'
import { GLOBAL_ROUTES } from '../App'
import moment from 'moment'
import { truncate } from 'fs/promises'

export const encodeS3URI = (url?: string) => {
  const encodings = {
    '+': '%2B',
    '!': '%21',
    '"': '%22',
    '#': '%23',
    $: '%24',
    '&': '%26',
    "'": '%27',
    '(': '%28',
    ')': '%29',
    '*': '%2A',
    ',': '%2C',
    // ':': '%3A',
    ';': '%3B',
    '=': '%3D',
    '?': '%3F',
    '@': '%40',
  }
  return encodeURI(url ?? '') // Do the standard url encoding
    .replace(/(\+|!|"|#|\$|&|'|\(|\)|\*|\+|,|;|=|\?|@)/gim, function (match) {
      return (encodings as any)[match]
    })
}

export const fileToStringBuffer = async (e: File): Promise<string> => {
  const arrayBuffer = await e.arrayBuffer()
  return Buffer.from(arrayBuffer).toString('base64')
}

// export const blobToStringBuffer = async (e: Blob): Promise<string> => {
//   const arrayBuffer = await e.arrayBuffer()
//   return Buffer.from(arrayBuffer).toString('base64')
// }

export const validatePhoneNumber = (phoneNumber: string): boolean => {
  const phoneNumberRegex = /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/
  if (phoneNumberRegex.test(phoneNumber)) {
    return true
  }
  return false
}

export const validateEmail = (email: string): boolean => {
  const regex = new RegExp(
    "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
  )
  if (regex.test(email)) return true
  return false
}

export const getFileName = (e: any): string => {
  return e.target.files[0].name
}

export const isNumeric = (number: number | string | undefined): boolean => {
  const newNumber = typeof number === 'string' ? parseInt(number, 10) : number
  return typeof newNumber !== 'undefined' && !isNaN(newNumber)
}

export const getFileNameFromURL = (url?: string): string => {
  const arrayString = url?.replace('//', '')?.split('/')
  return arrayString?.[arrayString.length - 1] ?? ''
}

export const truncateNumber = (n: number) => {
  const truncated = n.toString().match(/^-?\d+(?:\.\d{0,1})?/)?.[0]
  if (truncated?.length === 1) return `${truncated}.0`
  return truncated
}

export const formatDate = (
  unformattedDate: string | number,
  options: Intl.DateTimeFormatOptions = {
    weekday: undefined,
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },
  ac?: string
) => {
  if (!unformattedDate) return '-'

  if (typeof unformattedDate === 'number')
    return !options.year && !options.month && !options.day
      ? new Date(unformattedDate).toLocaleTimeString('en-US', options)
      : new Date(unformattedDate).toLocaleDateString('en-US', options)
  else {
    const containsOnlyNumbers = /^\d+$/.test(unformattedDate)
    let a: any
    if (containsOnlyNumbers) a = +unformattedDate
    else a = unformattedDate.replace('Z', '')

    // return new Date(a).toLocaleDateString('en-US', options)

    return !options.year && !options.month && !options.day
      ? new Date(a).toLocaleTimeString('en-US', options)
      : new Date(a).toLocaleDateString('en-US', options)
  }
}

export const dateIsExpired = (unformattedDate: string | number): boolean => {
  const d = new Date(formatDate(unformattedDate)).setHours(0, 0, 0, 0)
  const t = new Date(Date.now()).setHours(0, 0, 0, 0)
  return t > d
}

export const getPathRoute = (user?: User): string => {
  return user?.userType === UserType.ADMIN ? `${GLOBAL_ROUTES.ADMIN}` : ''
}

//returns true if it finds an incomplete document
export const checkIfDocumentsAreComplete = (
  documents: DocumentData[],
  certifications = false
) => {
  return (
    documents.find((d) => {
      if (d.source === DATA_SOURCE.NIPR) return false
      const licensesCheck = !certifications ? !d.expiryDate || !d.state : false
      const common = !d.url || !d.issuedDate || licensesCheck
      const r = !d.name || common
      const l = !d.id || common
      return d.isListed ? l : r
    }) && documents.length !== 0
  )
}

export const storeFile = async (
  file: any,
  fileName: string,
  user: User,
  extraPath?: string
) => {
  if (file) {
    const stringBuffer = await fileToStringBuffer(file)
    if (stringBuffer && user) {
      const payload = {
        file: stringBuffer,
        key: `${user.firstName}${user.lastName}-${user.email}/${
          extraPath ?? ''
        }${fileName}`,
      }
      return await sendDataToS3(payload)
    }
  }
}

export const storeFileFromUrl = async (
  url: string,
  fileName: string,
  user: User,
  extraPath?: string
) => {
  if (url) {
    if (url && user) {
      const payload = {
        url,
        key: `${user.firstName}${user.lastName}-${user.email}/${
          extraPath ?? ''
        }${fileName}`,
      }
      return await sendDataToS3(payload)
    }
  }
}

export const capitalizeFirst = (text: string) => {
  const mtext = text.toLowerCase()
  return mtext.charAt(0).toUpperCase() + mtext.slice(1)
}

export const mergeStringArrays = (old: string[], newa: string[]) => {
  return _.union(old, newa)
}

/////////////////USER FUNCTIONS/////////////////////

const joinFemaCertifications = (
  femaCertificationCategories: FEMA_CATEGORY[]
) => {
  const femaCertificationCategoriesLabels = femaCertificationCategories.map(
    (femaCategory) =>
      femaCategoriesOptions.find((f) => f.value === femaCategory)?.label
  )
  const str = femaCertificationCategoriesLabels.join(', ')

  const lastIndex = str.lastIndexOf(', ')

  const replacement = ', or'

  return (
    str.substring(0, lastIndex) + replacement + str.substring(lastIndex + 1)
  )
}

export const checkIfAdjusterCanRequireWork = (
  job: Job,
  specs?: JobSpecs,
  user?: User
): {
  canRequireWork: boolean
  message: string
} => {
  let canRequireWork = true
  let message = ''

  if (job.allAdjustersCanApply) {
    return {
      canRequireWork: true,
      message: '',
    }
  }

  const primaryLicense = user?.licenses?.find((license) => license.isPrimary)
  const femaCertificationDoesntMatch =
    user?.femaCertification?.categories?.some((category) => {
      return job?.femaCertificationCategories.includes(category)
    })

  if (specs && specs?.hired >= specs?.workforce) {
    message = 'The job is full'
    canRequireWork = false
  } else if (
    !primaryLicense ||
    (primaryLicense.source !== DATA_SOURCE.NIPR && !primaryLicense.approved)
  ) {
    message =
      'An approved primary state license is required to apply to this job'
    canRequireWork = false
  } else if (!user?.driverLicense?.approved) {
    message = "An approved Driver's license is required to apply to this job"
    canRequireWork = false
  } else if (
    job.isFemaCertificationRequired &&
    (!user?.femaCertification?.has ||
      !user?.femaCertification?.approved ||
      !femaCertificationDoesntMatch)
  ) {
    message = `An approved ${joinFemaCertifications(
      job?.femaCertificationCategories
    )} FEMA certification is required to apply to this job`
    canRequireWork = false
  } else if (
    job.billingForm === BILLING_FORM.TEN99 &&
    !user?.w9TaxForm?.approved
  ) {
    message = 'An approved W9 Form is required to apply to this job'
    canRequireWork = false
  } else if (
    job.billingForm === BILLING_FORM.W2 &&
    !user?.w4TaxForm?.approved
  ) {
    message = 'An approved W4 Form is required to apply to this job'
    canRequireWork = false
  } else if (
    job.billingForm === BILLING_FORM.W2 &&
    !user?.i9TaxForm?.approved
  ) {
    message = 'An approved I9 Form is required to apply to this job'
    canRequireWork = false
  }

  return {
    canRequireWork,
    message,
  }
}

export const isReciprocal = (
  selectedJobLicenseStates: string[],
  allUserLicensesStates: string[]
) => {
  let itAppliesByState = false
  allUserLicensesStates.forEach((state) => {
    if (selectedJobLicenseStates?.includes(state)) itAppliesByState = true
  })
  return itAppliesByState
}

export const checkIfAdjusterMeetsTheRequirements = ({
  adjuster,
  jobLicenses,
  jobCertifications,
  stateLicenses,
  allUserLicensesStates,
}: {
  adjuster: User
  jobLicenses: JobLicensingRequirement[]
  jobCertifications: JobCertificationRequirement[]
  stateLicenses: ListedStateLicense[]
  allUserLicensesStates: string[]
}): {
  meetRequirements: boolean
  requirementsMessage: string
} => {
  let meetRequirements = true
  let requirementsMessage = 'Meets requirements'

  // Meets requirements
  if (jobLicenses?.length === 0 && jobCertifications?.length === 0) {
  } else {
    // Certifications check
    if (jobCertifications.length > 0) {
      jobCertifications.forEach((certification) => {
        const found = !!adjuster?.certifications?.find(
          (l) => l.id === certification.id && l.approved
        )
        if (!found) {
          meetRequirements = false
          requirementsMessage = 'Missing certifications'
          return
        }
      })
    }

    if (meetRequirements && jobLicenses.length > 0) {
      jobLicenses.forEach((jobLicense) => {
        //Looks if the user has the license
        const licenseWithinAdditionalLicenses = !!adjuster?.licenses?.find(
          (l) => l.id === jobLicense.id && l.approved
        )
        const foundLicense = licenseWithinAdditionalLicenses

        // Verifies if one the user licences states matches with one of the required license states
        const licenseValidStates = stateLicenses?.find(
          (l) => jobLicense.id === l._id
        )?.states

        const reciprocal = isReciprocal(
          Object.keys(licenseValidStates ?? {}) ?? [],
          allUserLicensesStates
        )

        // Verifies is the user license has the job license loas
        let hasLoas = true
        const userLicenseLoas =
          adjuster?.licenses?.find(
            (l) =>
              l.id === jobLicense.id &&
              l.state === jobLicense.state &&
              l.approved
          )?.loas ?? []
        const requiredLoas = jobLicense.loas
        requiredLoas.forEach((loa) => {
          if (!userLicenseLoas.includes(loa)) hasLoas = false
        })

        if ((foundLicense || reciprocal) && hasLoas) {
        } else {
          meetRequirements = false
          requirementsMessage = 'Insufficient Licensure'
          return
        }
      })
    }
  }

  return {
    meetRequirements,
    requirementsMessage,
  }
}

// export const notifyAdmins =
//   async (admins: User[] ,message: string, userId: string) => {
//     admins.map((admin) => {
//       createNotificationMutation({
//         variables: {
//           notification: {
//             userId: admin?._id,
//             message,
//             link: `${GLOBAL_ROUTES.ADMIN}${GLOBAL_ROUTES.PROFILE}/${userId}`,
//             read: false,
//           },
//         },
//       })

//       return null
//     })
//   }
