import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  Text,
  Button,
  Spinner,
  HStack,
  useDisclosure,
  TableCaption,
} from '@chakra-ui/react'
import { useParams } from 'react-router'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { UserContext } from '../../../contexts/UserContext'
import {
  USER_STATUS,
  User,
  UserContextType,
  UserType,
} from '../../../types/userTypes'
import { FaUserAlt } from 'react-icons/fa'
import { ADJUSTANT_GREEN } from '../../../themes/themes'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  GET_FIRM_CHATS,
  GET_JOB_BY_ID,
  GET_ROSTERS_BY_FILTER,
  GET_USERS_BY_FILTERS,
} from '../../../graphql/Queries'
import {
  ADJUSTER_WORK_STATUS,
  Job,
  Review,
  ReviewSurvey,
  Roster,
} from '../../../types/jobTypes'
import { useNavigate } from 'react-router-dom'
import { GLOBAL_ROUTES } from '../../../App'
import {
  CREATE_NOTIFICATION,
  UPDATE_CHAT_BY_ID,
  UPDATE_ROSTER_BY_ID,
  UPDATE_USER_BY_ID,
} from '../../../graphql/Mutations'
import { getPathRoute } from '../../../utils/functions'
import { CHAT_STATUS, Chat } from '../../../types/chatTypes'
import ReviewRosterWorkflowModal from '../../../components/ReviewRosterWorkflowModal/ReviewRosterWorkflowModal'
import { AiOutlineCheck } from 'react-icons/ai'

export const ROSTER_DEPLOYMENT_SURVEY_QUESTIONS: {
  question: string
  name: keyof ReviewSurvey
  hasNA: boolean
}[] = [
  {
    question:
      'How would you rate the employee’s ability to handle instructions and directed tasks?',
    name: 'q1',
    hasNA: false,
  },
  {
    question:
      'How would you rate the employee’s loss inspection or claim file review abilities?',
    name: 'q2',
    hasNA: true,
  },
  {
    question: 'How would you rate the employee’s timeliness?',
    name: 'q3',
    hasNA: false,
  },
  {
    question:
      'How would you rate the employee’s communication with insureds and claims management?',
    name: 'q4',
    hasNA: true,
  },
  {
    question:
      'How would you rate the employee’s strength in estimating losses and reconciling estimates?',
    name: 'q5',
    hasNA: true,
  },
  {
    question: 'How would you rate the employee’s overall job performance?',
    name: 'q6',
    hasNA: false,
  },
]

function ReviewRoster() {
  const { jobId, adjustersIds } = useParams()
  const { user }: UserContextType = useContext(UserContext)
  const navigate = useNavigate()

  const { onOpen, isOpen, onClose } = useDisclosure()

  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [showButtonLoader, setShowButtonLoader] = useState<boolean>(false)
  const [dataRetrieved, setDataRetrieved] = useState<boolean>(false)

  const [job, setJob] = useState<Job | undefined>()
  const [jobRosters, setJobRosters] = useState<Roster[]>([])
  const [usersOngoingRosters, setUsersOngoingRosters] = useState<Roster[]>([])
  const [adjusters, setAdjusters] = useState<User[]>([])
  const [chats, setChats] = useState<Chat[]>([])
  const [jobRosterToReviewIndex, setJobRosterToReviewIndex] = useState<
    number | undefined
  >()

  const [getJob] = useLazyQuery(GET_JOB_BY_ID)
  const [getRosters] = useLazyQuery(GET_ROSTERS_BY_FILTER)
  const [getUsersByFilter] = useLazyQuery(GET_USERS_BY_FILTERS)
  const [updateRosterMutation] = useMutation(UPDATE_ROSTER_BY_ID)
  const [createNotificationMutation] = useMutation(CREATE_NOTIFICATION)
  const [updateAdjuster] = useMutation(UPDATE_USER_BY_ID)
  const [getChats] = useLazyQuery(GET_FIRM_CHATS)
  const [updateChatMutation] = useMutation(UPDATE_CHAT_BY_ID)

  const retrieveData = useCallback(async () => {
    setShowLoader(true)
    let result
    result = await getJob({
      variables: { id: jobId },
    })

    const job: Job = result?.data?.GetJobById
    if (!job || (job.firmId !== user?._id && user?.userType !== UserType.ADMIN))
      navigate(GLOBAL_ROUTES.JOBS)

    //   console.log(result?.data?.GetJobById)
    setJob(result?.data?.GetJobById)

    //GET ADJUSTERS ROSTERS
    result = await getRosters({
      variables: {
        // jobId,
        adjustersIds: adjustersIds?.split(','),
      },
    })
    const rosters = result?.data?.RostersByFilter
    if (rosters) {
      // console.log(rosters)
      const aRosters: Roster[] = rosters.filter(
        (roster: Roster) =>
          roster.status === ADJUSTER_WORK_STATUS.ONGOING &&
          roster.jobId !== jobId
      )
      setUsersOngoingRosters([...aRosters.map((r) => ({ ...r }))])

      const jRosters: Roster[] = rosters.filter(
        (roster: Roster) => roster.jobId === jobId
      )
      //   console.log([
      //     ...jRosters.map((r) => ({
      //       ...r,
      //       review: {
      //         ...(r.review as Review),
      //         survey: { ...(r.review?.survey as ReviewSurvey) },
      //       },
      //     })),
      //   ])
      setJobRosters([
        ...jRosters.map((r) => ({
          ...r,
          review: {
            ...(r.review as Review),
            survey: { ...(r.review?.survey as ReviewSurvey) },
          },
        })),
      ])

      const adjustersIds = jRosters.map((r) => r.adjusterId)
      result = await getUsersByFilter({
        variables: { ids: adjustersIds, userType: UserType.ADJUSTER },
      })

      if (result?.data?.UsersByFilter) {
        //   console.log(result?.data?.UsersByFilter)
        const users = result?.data?.UsersByFilter
        setAdjusters([...users])

        result = await getChats({
          variables: { jobId, usersIds: adjustersIds },
          fetchPolicy: 'network-only',
        })
        if (result?.data?.FirmChats) {
          setChats(result?.data?.FirmChats)
        }
      } else {
        navigate(GLOBAL_ROUTES.ROOT)
      }
    } else {
      navigate(GLOBAL_ROUTES.ROOT)
    }

    setShowLoader(false)
  }, [
    adjustersIds,
    getChats,
    getJob,
    getRosters,
    getUsersByFilter,
    jobId,
    navigate,
    user?._id,
    user?.userType,
  ])

  useEffect(() => {
    if (!dataRetrieved && user) {
      setDataRetrieved(true)
      retrieveData()
    }
  }, [
    setShowLoader,
    dataRetrieved,
    user,
    getRosters,
    jobId,
    retrieveData,
    adjustersIds,
  ])

  const getRosterAdjusterName = useCallback(
    (adjusterId: string) => {
      const adjuster = adjusters.find((a) => a._id === adjusterId)
      return adjuster ? `${adjuster.firstName} ${adjuster.lastName}` : ''
    },
    [adjusters]
  )

  const sendReview = useCallback(async () => {
    const mapped = jobRosters
      .filter((r) => !!r.review)
      .map(async (roster: any) => {
        setShowButtonLoader(true)
        const { _id, __typename, ...r } = roster
        await updateRosterMutation({
          variables: {
            roster: r,
            id: _id,
          },
        })

        const id = chats.find((chat) => chat.users.includes(r.adjusterId))?._id
        if (id) {
          await updateChatMutation({
            variables: {
              chat: { status: CHAT_STATUS.DISABLED },
              id,
            },
          })
        }

        //SET ADJUSTER AS AVAILABLE
        const adjusterIsDeployedOnAnotherJob = !!usersOngoingRosters.find(
          (r) => r.adjusterId === (roster as Roster).adjusterId
        )
        if (!adjusterIsDeployedOnAnotherJob)
          await updateAdjuster({
            variables: {
              user: {
                status: USER_STATUS.AVAILABLE,
                availabilityDate: undefined,
                availabilityInfo: '',
              },
              id: (roster as Roster).adjusterId,
            },
          })

        await createNotificationMutation({
          variables: {
            notification: {
              userId: roster.adjusterId,
              message: `Your work on the job "${job?.title}" has been reviewed.`,
              link: `${GLOBAL_ROUTES.JOBS}/${roster?.jobId}/1`,
              read: false,
            },
          },
        })
      })

    await Promise.all(mapped)
    setShowButtonLoader(false)
    navigate(`${getPathRoute(user)}${GLOBAL_ROUTES.JOBS}/${jobId}/1`)
  }, [
    chats,
    createNotificationMutation,
    job?.title,
    jobId,
    jobRosters,
    navigate,
    updateAdjuster,
    updateChatMutation,
    updateRosterMutation,
    user,
    usersOngoingRosters,
  ])

  const isDisabled = useMemo(() => {
    return !!jobRosters.find((r) => !r.review?.survey?.q6)
  }, [jobRosters])

  return (
    <Box
      display="flex"
      justifyContent="center"
      background="white"
      height="100%"
      w="100%"
    >
      {jobRosterToReviewIndex !== undefined && (
        <ReviewRosterWorkflowModal
          isOpen={isOpen}
          jobRosters={jobRosters}
          jobRosterToReviewIndex={jobRosterToReviewIndex}
          onClose={(newJobRoster?: Roster) => {
            if (newJobRoster) {
              if (newJobRoster.review?.survey) {
                const invalidRoster = Object.keys(
                  newJobRoster.review.survey
                ).find((key) => {
                  console.log(
                    newJobRoster.review?.survey[key as keyof ReviewSurvey]
                  )
                  return !newJobRoster.review?.survey[key as keyof ReviewSurvey]
                })
                if (!invalidRoster) {
                  jobRosters[jobRosterToReviewIndex] = newJobRoster
                  setJobRosters([...jobRosters])
                }
              }
            }

            setJobRosterToReviewIndex(undefined)
            onClose()
          }}
        />
      )}

      {!showLoader && (
        // The container has the overflow-x scroll
        <TableContainer
          background="white"
          overflowY="auto"
          maxH="100%"
          h="fit-content"
          w="100%"
          display="flex"
          justifyContent={['initial', 'initial', 'initial', 'center', 'center']}
        >
          <Table variant="striped" maxW="min-content">
            <Thead position="sticky" top={0} zIndex={1} background="white">
              <Tr>
                <Th>
                  <Box>
                    <Text fontSize={12} mb={2}>
                      Performance Review
                    </Text>
                    {/* <Text fontSize={10}>Select for All</Text> */}
                  </Box>
                </Th>
                <Th>
                  <Box>
                    <Text fontSize={12} mb={2}>
                      Status
                    </Text>
                  </Box>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {jobRosters.map((roster, index) => (
                <Tr key={index}>
                  <Td display="flex" color={ADJUSTANT_GREEN}>
                    <Box mr={2}>
                      <FaUserAlt />
                    </Box>
                    {getRosterAdjusterName(roster.adjusterId)}
                  </Td>
                  <Td color={ADJUSTANT_GREEN} py={0}>
                    {!!roster.review?.survey.q1 ? (
                      <Button
                        variant="adjustant"
                        size="sm"
                        onClick={() => {
                          setJobRosterToReviewIndex(index)
                          onOpen()
                        }}
                      >
                        DEPLOYMENT CLOSED
                      </Button>
                    ) : (
                      <Button
                        variant="adjustant"
                        size="sm"
                        onClick={() => {
                          setJobRosterToReviewIndex(index)
                          onOpen()
                        }}
                      >
                        REVIEW AND CLOSE OUT DEPLOYMENT
                      </Button>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
            <TableCaption>
              <Button
                variant="adjustant"
                rightIcon={<AiOutlineCheck />}
                isDisabled={isDisabled}
                isLoading={showButtonLoader}
                onClick={sendReview}
              >
                Complete Deployment
              </Button>
            </TableCaption>
          </Table>
        </TableContainer>
      )}
      {showLoader && (
        <HStack direction="row" h="100%" justifyContent="center">
          <Spinner size="xl" />
        </HStack>
      )}
    </Box>
  )
}
export default ReviewRoster
