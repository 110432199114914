import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { LOASContext } from '../../../contexts/LOASContext'
import { LOA, LOASContextType } from '../../../types/loasTypes'
import { CiEdit } from 'react-icons/ci'
import { ADJUSTANT_GREEN } from '../../../themes/themes'
import { createColumnHelper } from '@tanstack/react-table'
import CustomTable from '../../../components/CustomTable/CustomTable'
import { BsFillTrashFill } from 'react-icons/bs'
import Label from '../../../components/Label/Label'
import { useMutation } from '@apollo/client'
import {
  CREATE_CERTIFICATION,
  CREATE_LOA,
  DELETE_CERTIFICATION_BY_ID,
  DELETE_LOA_BY_ID,
  UPDATE_CERTIFICATION_BY_ID,
  UPDATE_LOA_BY_ID,
} from '../../../graphql/Mutations'
import { showSuccessToast } from '../../../components/Toast/Toast'
import DeleteModal from '../../../components/Modals/DeleteModal'
import {
  TrainingCertification,
  TrainingCertificationContextType,
} from '../../../types/trainingCertificationsTypes'
import { TrainingCertificationsContext } from '../../../contexts/TrainingCertificationsContext'

const UpdateModal = ({
  certification,
  isOpen,
  onClose,
}: {
  certification?: TrainingCertification
  isOpen: boolean
  onClose: (newCertification: TrainingCertification, updated: boolean) => void
}) => {
  const [certificationName, setCertificationName] = useState(
    certification?.name ?? ''
  )
  useEffect(() => {
    setCertificationName(certification?.name ?? '')
  }, [certification])

  const canUpdate = useMemo(() => {
    return certification?.name !== certificationName
  }, [certification?.name, certificationName])

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        if (certification) onClose(certification, false)
      }}
      isCentered
      //   size="6xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update Certification</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex justify="space-between" mb={2}>
            <Label fontSize="sm" color="gray.700">
              Name
            </Label>
          </Flex>
          <Input
            value={certificationName}
            //   placeholder=" (optional)"
            borderRadius={3}
            onChange={(e) => {
              setCertificationName(e.target.value)
            }}
          />
        </ModalBody>

        <ModalFooter>
          <Button
            variant="adjustant"
            mr={3}
            onClick={() => {
              if (certification) onClose(certification, false)
            }}
          >
            Close
          </Button>
          <Button
            variant="adjustant"
            isDisabled={!canUpdate}
            onClick={() => {
              if (certification)
                onClose(
                  {
                    _id: certification._id,
                    name: certificationName,
                  },
                  true
                )
            }}
          >
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const CreateModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: (certificationName: string) => void
}) => {
  const [certificationName, setCertificationName] = useState('')

  const canCreate = useMemo(() => {
    return !!certificationName
  }, [certificationName])

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose('')
      }}
      isCentered
      //   size="6xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Certification</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex justify="space-between" mb={2}>
            <Label fontSize="sm" color="gray.700">
              Name
            </Label>
          </Flex>
          <Input
            value={certificationName}
            //   placeholder=" (optional)"
            borderRadius={3}
            onChange={(e) => {
              setCertificationName(e.target.value)
            }}
          />
        </ModalBody>

        <ModalFooter>
          <Button
            variant="adjustant"
            mr={3}
            onClick={() => {
              onClose('')
            }}
          >
            Close
          </Button>
          <Button
            variant="adjustant"
            isDisabled={!canCreate}
            onClick={() => {
              onClose(certificationName)
              setCertificationName('')
            }}
          >
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const AdminCertifications = () => {
  const [showLoader, setShowLoader] = useState(false)
  const {
    trainingCertifications,
    setTrainingCertifications,
  }: TrainingCertificationContextType = useContext(
    TrainingCertificationsContext
  )
  const [certificationToUpdate, setCertificationToUpdate] = useState<
    TrainingCertification | undefined
  >()
  const [certificationToUDelete, setCertificationToDelete] = useState<
    TrainingCertification | undefined
  >()

  const [updateCertificationMutation] = useMutation(UPDATE_CERTIFICATION_BY_ID)
  const [createCertificationMutation] = useMutation(CREATE_CERTIFICATION)
  const [deleteCertificationMutation] = useMutation(DELETE_CERTIFICATION_BY_ID)

  const columnHelper = createColumnHelper<TrainingCertification>()
  const columns = [
    // columnHelper.accessor('_id', {
    //   cell: (info) => info.getValue(),
    //   header: 'ID',
    // }),
    columnHelper.accessor('name', {
      cell: (info) => info.getValue(),
      header: 'Name',
    }),
    columnHelper.accessor('_id', {
      cell: (info) => {
        return (
          <HStack spacing={4} justifyContent="center">
            {showLoader ? (
              <Spinner />
            ) : (
              <>
                <CiEdit
                  fontSize={20}
                  cursor="pointer"
                  color={ADJUSTANT_GREEN}
                  onClick={() => {
                    const l = trainingCertifications?.find(
                      (o) => o._id === info.getValue()
                    )
                    if (l) {
                      setCertificationToUpdate(l)
                      onOpenUpdateCertificationModal()
                    }
                  }}
                />
                <BsFillTrashFill
                  fontSize={20}
                  cursor="pointer"
                  color="red"
                  onClick={() => {
                    const l = trainingCertifications?.find(
                      (o) => o._id === info.getValue()
                    )
                    if (l) {
                      setCertificationToDelete(l)
                      onOpenDeleteCertificationModal()
                    }
                  }}
                />
              </>
            )}
          </HStack>
        )
      },
      header: '',
    }),
  ]

  const {
    isOpen: updateCertificationModalIsOpen,
    onOpen: onOpenUpdateCertificationModal,
    onClose: onCloseUpdateCertificationModal,
  } = useDisclosure()

  const {
    isOpen: createCertificationModalIsOpen,
    onOpen: onOpenCreateCertificationModal,
    onClose: onCloseCreateCertificationModal,
  } = useDisclosure()

  const {
    isOpen: deleteCertificationModalIsOpen,
    onOpen: onOpenDeleteCertificationModal,
    onClose: onCloseDeleteCertificationModal,
  } = useDisclosure()

  const updateCertification = useCallback(
    async (newCertification: TrainingCertification) => {
      setShowLoader(true)
      const { _id, ...c } = newCertification
      const result = await updateCertificationMutation({
        variables: {
          certification: c,
          id: _id,
        },
      })
      if (result?.data?.TrainingCertificationUpdateOne?.record) {
        const index =
          trainingCertifications?.findIndex((c) => c._id === _id) ?? -1
        if (index >= 0 && trainingCertifications) {
          trainingCertifications[index] = newCertification
          setTrainingCertifications([...trainingCertifications])
          showSuccessToast('LOA Updated Succesfully!')
        }
      }
      setShowLoader(false)
    },
    [
      updateCertificationMutation,
      trainingCertifications,
      setTrainingCertifications,
    ]
  )

  const createCertification = useCallback(
    async (certificationName: string) => {
      setShowLoader(true)
      const newCertification = {
        name: certificationName,
      }
      const result = await createCertificationMutation({
        variables: {
          certification: newCertification,
        },
      })
      if (result?.data?.TrainingCertificationCreateOne?.record) {
        setTrainingCertifications((prev) => [
          ...prev,
          result?.data?.TrainingCertificationCreateOne?.record,
        ])
        showSuccessToast('Certification Created Succesfully!')
      }
      setShowLoader(false)
    },
    [createCertificationMutation, setTrainingCertifications]
  )

  const deleteCertification = useCallback(
    async (id: string) => {
      setShowLoader(true)
      const result = await deleteCertificationMutation({
        variables: {
          id,
        },
      })
      console.log(result)
      if (
        result?.data?.TrainingCertificationRemoveById?.record &&
        trainingCertifications
      ) {
        const certificationIndex =
          trainingCertifications?.findIndex((l) => l._id === id) ?? -1
        if (certificationIndex >= 0) {
          trainingCertifications.splice(certificationIndex, 1)
          setTrainingCertifications([...trainingCertifications])
          showSuccessToast('LOA Deleted Succesfully!')
        }
      }
      setShowLoader(false)
    },
    [
      deleteCertificationMutation,
      trainingCertifications,
      setTrainingCertifications,
    ]
  )

  return (
    <Box background="white" px={[2, 4, 12]} py={6}>
      <UpdateModal
        certification={certificationToUpdate}
        isOpen={updateCertificationModalIsOpen}
        onClose={(certification, updated) => {
          onCloseUpdateCertificationModal()
          if (updated) updateCertification(certification)
        }}
      />
      <CreateModal
        isOpen={createCertificationModalIsOpen}
        onClose={(certificationName) => {
          onCloseCreateCertificationModal()
          if (certificationName) createCertification(certificationName)
        }}
      />
      <DeleteModal
        header="Delete Certification"
        isOpen={deleteCertificationModalIsOpen}
        onClose={(d) => {
          onCloseDeleteCertificationModal()
          if (d && certificationToUDelete)
            deleteCertification(certificationToUDelete._id)
        }}
      />
      <Heading
        mb={4}
        fontSize="2xl"
        display="flex"
        justifyContent="space-between"
      >
        Certifications ({trainingCertifications?.length ?? 0})
        <Button
          isLoading={showLoader}
          variant="adjustant"
          mr={3}
          onClick={() => {
            onOpenCreateCertificationModal()
          }}
        >
          Create
        </Button>
      </Heading>
      <CustomTable data={trainingCertifications ?? []} columns={columns} />
    </Box>
  )
}

export default AdminCertifications
