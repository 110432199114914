import React, { useCallback, useContext, useMemo, useState } from 'react'
import SelectJobLicensesAndCertifications from '../SelectJobLicensesAndCertifications/SelectJobLicensesAndCertifications'
import {
  FEMA_CATEGORY,
  User,
  femaCategoriesOptions,
} from '../../types/userTypes'
import { useForm } from 'react-hook-form'

import {
  billingOptions,
  BillingType,
  DamageType,
  JobLicensingRequirement,
  JobCertificationRequirement,
  VISIBILITY,
  jobVisibilityOptions,
  FEMAOptions,
  jobTypeOptions,
  presenceOptions,
  PRESENCE,
  DATE_OF_LOSS_TYPE,
  dateOfLossOptions,
  BILLING_FORM,
  billingFormOptions,
  BillingFile,
} from '../../types/jobTypes'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Link,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { Calendar } from 'react-multi-date-picker'
import CustomRadio from '../CustomRadio/CustomRadio'
import { StateLicesesContext } from '../../contexts/StateLicesesContext'
import { TrainingCertificationsContext } from '../../contexts/TrainingCertificationsContext'
import {
  StateLicenseContextType,
  US_STATES,
} from '../../types/stateLicensesTypes'
import { TrainingCertificationContextType } from '../../types/trainingCertificationsTypes'
import { AiOutlineClose, AiOutlineFile } from 'react-icons/ai'
import { ADJUSTANT_GREEN } from '../../themes/themes'
import FileInput from '../FileInput/FileInput'
import CustomDropdown, {
  SelectableItem,
} from '../CustomDropdown/CustomDropdown'
import CustomDropdownMultiple from '../CustomDropdownMultiple/CustomDropdownMultiple'
import { encodeS3URI } from '../../utils/functions'

const JOBTITLE_MAX_LENGTH = 60
const TEXTAREA_MAX_LENGTH = 1500

function CreateJobForm({
  user,
  title,
  setTitle,
  adjustersNeeded,
  setAdjustersNeeded,
  presence,
  setPresence,
  damageTypes,
  setDamageTypes,
  damageTypesDescription,
  setDamageTypeDescription,
  reportDate,
  setReportDate,
  dateOfLossType,
  setDateOfLossType,
  dateOfLoss,
  setDateOfLoss,
  location,
  setLocation,
  state,
  setState,
  city,
  setCity,
  description,
  setDescription,
  billingType,
  setBillingType,
  billingTypeDescription,
  setBillingTypeDescription,
  billingForm,
  setBillingForm,
  billingFiles,
  setBillingFiles,

  allAdjustersCanApply,
  setAllAdjustersCanApply,

  visibility,
  setVisibility,

  isFemaCertificationRequired,
  setIsFemaCertificationRequired,
  femaCertificationCategories,
  setFemaCertificationCategories,

  licensingRequirements,
  setLicensingRequirements,
  certificationRequirements,
  setCertificationRequirements,
  buttons,
}: {
  user?: User
  title: string
  setTitle: React.Dispatch<React.SetStateAction<string>>
  adjustersNeeded: number
  setAdjustersNeeded: React.Dispatch<React.SetStateAction<number>>
  presence: PRESENCE
  setPresence: React.Dispatch<React.SetStateAction<PRESENCE>>
  damageTypes: DamageType[]
  setDamageTypes: React.Dispatch<React.SetStateAction<DamageType[]>>
  damageTypesDescription: string
  setDamageTypeDescription: React.Dispatch<React.SetStateAction<string>>
  reportDate: string
  setReportDate: React.Dispatch<React.SetStateAction<string>>

  dateOfLossType: DATE_OF_LOSS_TYPE
  setDateOfLossType: React.Dispatch<React.SetStateAction<DATE_OF_LOSS_TYPE>>
  dateOfLoss: string
  setDateOfLoss: React.Dispatch<React.SetStateAction<string>>

  location: string
  setLocation: React.Dispatch<React.SetStateAction<string>>
  state: string
  setState: React.Dispatch<React.SetStateAction<string>>
  city: string
  setCity: React.Dispatch<React.SetStateAction<string>>
  description: string
  setDescription: React.Dispatch<React.SetStateAction<string>>

  billingType: BillingType
  setBillingType: React.Dispatch<React.SetStateAction<BillingType>>
  billingTypeDescription: string
  setBillingTypeDescription: React.Dispatch<React.SetStateAction<string>>
  billingForm: BILLING_FORM
  setBillingForm: React.Dispatch<React.SetStateAction<BILLING_FORM>>

  billingFiles: BillingFile[]
  setBillingFiles: React.Dispatch<React.SetStateAction<BillingFile[]>>

  allAdjustersCanApply: boolean
  setAllAdjustersCanApply: React.Dispatch<React.SetStateAction<boolean>>

  visibility: VISIBILITY
  setVisibility: React.Dispatch<React.SetStateAction<VISIBILITY>>

  isFemaCertificationRequired: boolean
  setIsFemaCertificationRequired: React.Dispatch<React.SetStateAction<boolean>>
  femaCertificationCategories: FEMA_CATEGORY[]
  setFemaCertificationCategories: React.Dispatch<
    React.SetStateAction<FEMA_CATEGORY[]>
  >

  licensingRequirements: JobLicensingRequirement[]
  setLicensingRequirements: React.Dispatch<
    React.SetStateAction<JobLicensingRequirement[]>
  >
  certificationRequirements: JobCertificationRequirement[]
  setCertificationRequirements: React.Dispatch<
    React.SetStateAction<JobCertificationRequirement[]>
  >
  buttons?: any
}) {
  const { stateLicenses }: StateLicenseContextType =
    useContext(StateLicesesContext)
  const { trainingCertifications }: TrainingCertificationContextType =
    useContext(TrainingCertificationsContext)

  const statesOptions: SelectableItem[] = US_STATES.map((s) => {
    return { value: s.name, label: s.name }
  })

  const [errorMessage, setErrorMessage] = useState('')

  const licensesOptions = useMemo(() => {
    const op =
      stateLicenses?.map((s) => ({
        id: s._id,
        name: s.name,
        isListed: true,
      })) ?? []

    return [
      {
        id: '',
        name: 'Not Applicable - State License not required',
        isListed: false,
      },
      ...op,
    ]
  }, [stateLicenses])

  const certificationOptions = useMemo(() => {
    const a = trainingCertifications?.map((s) => ({
      id: s._id,
      name: s.name,
      isListed: true,
    }))
    const additional =
      user?.additionalCertificationRequirements?.map((a) => ({
        ...a,
        isListed: true, //Not listed in trainingCertifications but listed within the firm's profile
      })) ?? []
    const listed = a ?? []
    return [...listed, ...additional]
  }, [trainingCertifications, user?.additionalCertificationRequirements])

  const onBillingTypeFileInputChange = useCallback(
    async (e: any) => {
      const fileFromFiles = e.target.files[0]
      if (fileFromFiles) {
        const newFile = {
          file: fileFromFiles,
          fileName: fileFromFiles.name,
          url: URL.createObjectURL(fileFromFiles),
        }

        setBillingFiles((prev) => [...prev, newFile])
      }
    },
    [setBillingFiles]
  )

  const deleteBillingTypeFile = useCallback(
    (index: number) => {
      setBillingFiles((prev) => {
        prev.splice(index, 1)
        return [...prev]
      })
    },
    [setBillingFiles]
  )

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm()

  const onSubmit = useCallback(
    (values: any) => {
      setErrorMessage('')
      return new Promise<void>((resolve) => {
        // console.log('FIRST PASSEDD', values)
        if (damageTypes.length === 0)
          setErrorMessage('At list one claim type must be selected')
        if (!state) setErrorMessage('The state must be selected')
        if (!reportDate) setErrorMessage('The report date must be selected')
        if (
          isFemaCertificationRequired &&
          femaCertificationCategories.length === 0
        )
          setErrorMessage('The FEMA category must be selected')
        if (dateOfLossType === DATE_OF_LOSS_TYPE.DATE && !dateOfLoss)
          setErrorMessage('The Date of loss must be selected')

        const a = licensingRequirements.find((l) => !l.id)
        if (!!a) setErrorMessage('The licenses must be complete')

        const b = certificationRequirements.find((c) => !c.id && !c.name)
        if (!!b) setErrorMessage('The certifications must be complete')
      })
    },
    [
      certificationRequirements,
      damageTypes.length,
      dateOfLoss,
      dateOfLossType,
      femaCertificationCategories,
      isFemaCertificationRequired,
      licensingRequirements,
      reportDate,
      state,
    ]
  )

  return (
    <Box maxW="4xl">
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Title */}
        <Box
          py={10}
          px={8}
          background="white"
          borderRadius={8}
          border="1px solid #ECEAE9"
          mb={2}
        >
          <FormControl isRequired>
            <FormLabel fontSize="xl" color="gray.500" mb={2}>
              Title
            </FormLabel>
            <Text fontSize="sm" color="gray.600" fontWeight="bold" mb={4}>
              This will display on the job card in the side along with type,
              report date, and location.
            </Text>
            <Input
              value={title}
              placeholder="Title"
              borderRadius={3}
              onChange={(e) => {
                setTitle(e.target.value)
              }}
              maxLength={JOBTITLE_MAX_LENGTH}
            />
            <Text width="100%" textAlign="end" color="gray.500">
              {title.length}/{JOBTITLE_MAX_LENGTH}
            </Text>
          </FormControl>
        </Box>

        {/* Workforce */}
        <Box
          py={10}
          px={8}
          background="white"
          borderRadius={8}
          border="1px solid #ECEAE9"
          mb={2}
        >
          <FormControl isRequired>
            <FormLabel fontSize="xl" color="gray.500" mb={2}>
              Workforce
            </FormLabel>
            <Text fontSize="sm" color="gray.600" fontWeight="bold" mb={4}>
              How many adjusters Needed for this job?
            </Text>
            <NumberInput
              mb={4}
              value={adjustersNeeded}
              onChange={(e) => {
                setAdjustersNeeded(+e)
              }}
              min={0}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>

            <CustomRadio
              label="Presence"
              subLabel=""
              value={presence ?? ''}
              options={presenceOptions}
              onChange={(e) => {
                if (setPresence) setPresence(e)
              }}
            />
          </FormControl>
        </Box>

        {/* Claim type */}
        <Box
          py={10}
          px={8}
          background="white"
          borderRadius={8}
          border="1px solid #ECEAE9"
          mb={2}
        >
          <FormControl isRequired mb={4}>
            <FormLabel fontSize="xl" color="gray.500" mb={2}>
              Claim Type
            </FormLabel>
            <Text fontSize="sm" color="gray.600" fontWeight="bold" mb={1}>
              Which type of damage does this job fall into?
            </Text>
            <CustomDropdownMultiple
              label=""
              value={
                damageTypes?.map((damageType) => ({
                  value: damageType,
                  label:
                    jobTypeOptions?.find((o) => o.value === damageType)
                      ?.label ?? '',
                })) ?? []
              }
              options={jobTypeOptions}
              isClearable
              onChange={(newTypes) => {
                setDamageTypes(newTypes as DamageType[])
              }}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel fontSize="xl" color="gray.500" mb={2}>
              Work Description
            </FormLabel>
            <Textarea
              placeholder=""
              onChange={(e) => {
                setDamageTypeDescription(e.target.value)
              }}
              value={damageTypesDescription}
              //   maxLength={TEXTAREA_MAX_LENGTH}
              style={{ resize: 'none' }}
            />
          </FormControl>
        </Box>

        {/* Report Date */}
        <Box
          py={10}
          px={8}
          background="white"
          borderRadius={8}
          border="1px solid #ECEAE9"
          mb={2}
        >
          <FormControl isRequired>
            <FormLabel fontSize="xl" color="gray.500" mb={2}>
              Report Date
            </FormLabel>
            <Text fontSize="sm" color="gray.600" fontWeight="bold" mb={4}>
              Select the start date for this job.
            </Text>

            <Calendar
              highlightToday={false}
              minDate={new Date()}
              value={reportDate}
              onChange={(selectedDates) =>
                setReportDate(selectedDates?.toString() ?? '')
              }
              shadow={false}
              sort
              className="customCalendar"
            />
          </FormControl>
        </Box>

        {/*  Date of loss */}
        <Box
          py={10}
          px={8}
          background="white"
          borderRadius={8}
          border="1px solid #ECEAE9"
          mb={2}
        >
          <FormControl isRequired>
            <FormLabel fontSize="xl" color="gray.500" mb={2}>
              Date of Loss
            </FormLabel>

            <Box mb={2}>
              <CustomRadio
                label=""
                value={dateOfLossType}
                options={dateOfLossOptions}
                onChange={(a) => setDateOfLossType(a)}
              />
            </Box>

            {dateOfLossType === DATE_OF_LOSS_TYPE.DATE && (
              <>
                <Text fontSize="sm" color="gray.600" fontWeight="bold" mb={4}>
                  Select the dale of loss
                </Text>
                <Calendar
                  highlightToday={false}
                  //   minDate={new Date()}
                  value={dateOfLoss}
                  onChange={(selectedDates) =>
                    setDateOfLoss(selectedDates?.toString() ?? '')
                  }
                  shadow={false}
                  sort
                  className="customCalendar"
                />
              </>
            )}
          </FormControl>
        </Box>

        {/* Location */}
        <Box
          py={10}
          px={8}
          background="white"
          borderRadius={8}
          border="1px solid #ECEAE9"
          mb={2}
        >
          <FormControl isRequired>
            <FormLabel fontSize="xl" color="gray.500" mb={2}>
              Location
            </FormLabel>
            <Text fontSize="sm" color="gray.600" fontWeight="bold" mb={4}>
              Select the state and city where the job is taking place.
            </Text>

            <FormLabel fontSize="sm" color="gray.700" fontWeight="bold" mb={2}>
              State
            </FormLabel>
            {/* <Input
              value={state}
              onChange={() => {}}
              border={0}
              position="absolute"
              zIndex={0}
            /> */}
            <CustomDropdown
              label=""
              placeholder="Select a state"
              options={statesOptions}
              value={state}
              textAlign="start"
              handleChange={(state) => {
                setState(state)
              }}
            />
          </FormControl>

          <Text fontSize="sm" color="gray.700" fontWeight="bold" mb={2}>
            City
          </Text>
          <Input
            value={city}
            placeholder="City (optional)"
            borderRadius={3}
            onChange={(e) => {
              setCity(e.target.value)
            }}
            maxLength={JOBTITLE_MAX_LENGTH}
          />
        </Box>

        {/* Description */}
        <Box
          py={10}
          px={8}
          background="white"
          borderRadius={8}
          border="1px solid #ECEAE9"
          mb={2}
        >
          <FormControl isRequired={false}>
            <FormLabel fontSize="xl" color="gray.500" mb={2}>
              Description
            </FormLabel>
            <Text fontSize="sm" color="gray.600" fontWeight="bold" mb={4}>
              Additional information outside of date, location,
              licensing/certifications, and billing details.
            </Text>

            <Textarea
              placeholder="Give a brief description of the job with any additional details the adjuster should know before working this job."
              onChange={(e) => {
                setDescription(e.target.value)
              }}
              value={description}
              maxLength={TEXTAREA_MAX_LENGTH}
              style={{ resize: 'none' }}
            />
            <Text width="100%" textAlign="end" color="gray.500">
              {description.length}/{TEXTAREA_MAX_LENGTH}
            </Text>
          </FormControl>
        </Box>

        {/* Licensing/Certification requirements */}
        <Box
          py={10}
          px={8}
          background="white"
          borderRadius={8}
          border="1px solid #ECEAE9"
          mb={2}
        >
          <FormControl>
            <FormLabel fontSize="xl" color="gray.500" mb={2}>
              Licensing & Certification Requirements
            </FormLabel>

            <Box mb={4} borderRadius="8px" backgroundColor="gray.50" p={2}>
              <FormLabel fontSize="xl" color="gray.500" mb={2}>
                FEMA certification (required for Flood)
              </FormLabel>

              <Box mb={2}>
                <CustomRadio
                  label=""
                  subLabel="Federal flood certified through FEMA?"
                  value={isFemaCertificationRequired.toString()}
                  options={FEMAOptions}
                  //   isDisabled={damageTypes === DamageType.FLOOD ? true : false}
                  onChange={(a) => setIsFemaCertificationRequired(a === 'true')}
                />
              </Box>

              {isFemaCertificationRequired && (
                // <CustomDropdown
                //   isRequired
                //   label="Category"
                //   placeholder="Select a category"
                //   options={femaCategoriesOptions}
                //   value={femaCertificationCategories}
                //   handleChange={(category) => {
                //     setFemaCertificationCategories(category)
                //   }}
                // />
                <CustomDropdownMultiple
                  label=""
                  value={
                    femaCertificationCategories?.map((femaCategory) => ({
                      value: femaCategory,
                      label:
                        femaCategoriesOptions?.find(
                          (o) => o.value === femaCategory
                        )?.label ?? '',
                    })) ?? []
                  }
                  options={femaCategoriesOptions}
                  isClearable
                  onChange={(newCategories) => {
                    setFemaCertificationCategories(
                      newCategories as FEMA_CATEGORY[]
                    )
                  }}
                />
              )}
            </Box>

            <Box mb={4} borderRadius="8px" backgroundColor="gray.50" p={2}>
              <FormLabel fontSize="xl" color="gray.500" mb={2}>
                Licenses And Certifications
              </FormLabel>
              <SelectJobLicensesAndCertifications
                unshiftCustomOptions
                licensesOptions={licensesOptions}
                licenses={licensingRequirements}
                certificationOptions={certificationOptions}
                certifications={certificationRequirements}
                setCertifications={setCertificationRequirements}
                setLicenses={setLicensingRequirements}
              />
            </Box>
          </FormControl>
        </Box>

        {/* Billing Type */}
        <Box
          py={10}
          px={8}
          background="white"
          borderRadius={8}
          border="1px solid #ECEAE9"
          mb={2}
        >
          <FormControl isRequired mb={4}>
            <FormLabel fontSize="xl" color="gray.500" mb={2}>
              Billing
            </FormLabel>
            <Text fontSize="sm" color="gray.600" fontWeight="bold" mb={0}>
              What type of billing for this job?
            </Text>
            <CustomRadio
              label=""
              subLabel=""
              value={billingType ?? ''}
              options={billingOptions}
              onChange={(e) => {
                if (setBillingType) setBillingType(e)
              }}
            />

            <Text
              fontSize="sm"
              color="gray.600"
              fontWeight="bold"
              mb={0}
              mt={4}
            >
              Form
            </Text>
            <CustomRadio
              label=""
              subLabel=""
              value={billingForm}
              options={billingFormOptions}
              onChange={(e) => {
                setBillingForm(e)
              }}
            />
          </FormControl>

          {/* Billing type description */}
          <Stack gap={0}>
            <FormLabel fontSize="xl" color="gray.500">
              Description
            </FormLabel>
            <Text fontSize="sm" color="gray.600" fontWeight="bold" mb={1}>
              % amount, Hourly Rate, Required hours, Days of week - if
              applicable
            </Text>
            <Textarea
              placeholder=""
              onChange={(e) => {
                setBillingTypeDescription(e.target.value)
              }}
              value={billingTypeDescription}
              maxLength={TEXTAREA_MAX_LENGTH}
              style={{ resize: 'none' }}
            />
            <Text width="100%" textAlign="end" color="gray.500">
              {billingTypeDescription.length}/{TEXTAREA_MAX_LENGTH}
            </Text>
          </Stack>

          {billingFiles.map((billingFile, index) => (
            <HStack key={index} alignItems="center" gap={2} mb={2}>
              <Link
                color="teal.500"
                href={encodeS3URI(billingFile.url)}
                isExternal
              >
                {billingFile.fileName ?? '-'}
                {/* {encodeS3URI(billingFile.url)} */}
              </Link>
              <Box cursor="pointer">
                <AiOutlineClose onClick={() => deleteBillingTypeFile(index)} />
              </Box>
            </HStack>
          ))}

          <FileInput
            element={
              <Button
                textColor={ADJUSTANT_GREEN}
                variant="link"
                leftIcon={<AiOutlineFile />}
                mt={2}
              >
                Upload Adjuster Compensation Schedule
              </Button>
            }
            accept=".pdf,.png,.jpeg,.jpg"
            onFileInputChange={onBillingTypeFileInputChange}
          />
        </Box>

        {/* Applicability */}
        <Box
          py={10}
          px={8}
          background="white"
          borderRadius={8}
          border="1px solid #ECEAE9"
          mb={2}
        >
          <FormControl>
            <FormLabel fontSize="xl" color="gray.500" mb={2}>
              Applicability
            </FormLabel>
            <Text fontSize="sm" color="gray.600" fontWeight="bold" mb={4}>
              Only Adjusters that meet minimum requirements can apply
            </Text>
            <Checkbox
              variant="adjustant"
              mb={4}
              isChecked={allAdjustersCanApply}
              onChange={(e) => {
                setAllAdjustersCanApply(e.target.checked)
              }}
            >
              All Adjusters can apply
            </Checkbox>
          </FormControl>
        </Box>

        {/* Visibility */}
        <Box
          py={10}
          px={8}
          background="white"
          borderRadius={8}
          border="1px solid #ECEAE9"
          mb={2}
        >
          <FormControl isRequired>
            <FormLabel fontSize="xl" color="gray.500" mb={2}>
              Visibility
            </FormLabel>
            <Text fontSize="sm" color="gray.600" fontWeight="bold" mb={4}>
              Public jobs will be seen by all Adjusters.
            </Text>
            <Text fontSize="sm" color="gray.600" fontWeight="bold" mb={4}>
              Private jobs will be seen only by Adjuster Invitation.
            </Text>
            <CustomRadio
              label=""
              subLabel=""
              value={visibility ?? ''}
              options={jobVisibilityOptions}
              onChange={(e) => {
                if (setVisibility) setVisibility(e)
              }}
            />
          </FormControl>
        </Box>

        {errorMessage && (
          <Text color="red" mb={4}>
            {errorMessage}
          </Text>
        )}

        {buttons}
      </form>
    </Box>
  )
}

export default CreateJobForm
