import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Avatar,
  HStack,
  Stack,
  Text,
  Box,
  Textarea,
  Flex,
} from '@chakra-ui/react'
import { ADJUSTANT_GREEN } from '../../themes/themes'
import { User } from '../../types/userTypes'
import { useState } from 'react'
import Label from '../Label/Label'
import CustomDropdown, {
  SelectableItem,
} from '../CustomDropdown/CustomDropdown'

const DEFAULT_MESSAGE = `Hello!

I'd like to invite you to take a look at the job I've posted.`

const InviteAdjusterModal = ({
  isOpen,
  onClose,
  adjuster,
  jobsMapped,
}: {
  isOpen: boolean
  onClose: (jobID: string, message: string, invite: boolean) => void
  adjuster?: User
  jobsMapped?: SelectableItem[]
}) => {
  const [message, setMessage] = useState(DEFAULT_MESSAGE)
  const [jobID, setJobID] = useState('')

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose('', '', false)
      }}
      size="xl"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Invite to job</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack mb={4}>
            <Stack width="40px" height="40px">
              <Avatar
                size="full"
                src={
                  adjuster?.profilePicture || '/images/no_profile_picture.png'
                }
              />
            </Stack>
            <Stack gap={0} ml={2}>
              <Text fontSize="sm" fontWeight="bold" color={ADJUSTANT_GREEN}>
                {adjuster?.firstName ?? ''} {adjuster?.lastName ?? ''}
                {/* {adjuster?._id} */}
              </Text>
              <Text color="gray.500" fontSize="sm">
                {adjuster?.location ?? ''}
              </Text>
            </Stack>
          </HStack>

          {jobsMapped && (
            <Box>
              <CustomDropdown
                isSearchable
                label="Job"
                placeholder="Select a Job"
                options={jobsMapped}
                value={jobID}
                handleChange={(jid) => {
                  setJobID(jid)
                }}
              />
            </Box>
          )}

          <Box>
            <Flex mb={2}>
              <Label fontSize="sm" color="gray.600">
                Message
              </Label>
            </Flex>
            <Textarea
              placeholder=""
              resize="vertical"
              size="xl"
              rows={6}
              p={2}
              value={message}
              borderRadius={3}
              onChange={(e) => {
                setMessage(e.target.value)
              }}
            />
          </Box>
        </ModalBody>
        <ModalFooter>
          {/* <Button variant="ghost">Close</Button> */}
          <Button
            variant="adjustant"
            mr={3}
            onClick={() => {
              onClose(jobID, message, true)
              setMessage(DEFAULT_MESSAGE)
              setJobID('')
            }}
            isDisabled={jobsMapped ? !jobID : false}
          >
            Send Invitation
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default InviteAdjusterModal
