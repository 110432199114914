import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react'
import CreateJobForm from '../../../components/CreateJobForm/CreateJobForm'
import { Box, Button, HStack, Spinner } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import { UserContext } from '../../../contexts/UserContext'
import {
  CREATE_NOTIFICATION,
  UPDATE_JOB_BY_ID,
} from '../../../graphql/Mutations'
import {
  DamageType,
  BillingType,
  JobLicensingRequirement,
  JobCertificationRequirement,
  JobStatus,
  Job,
  VISIBILITY,
  Roster,
  PRESENCE,
  DATE_OF_LOSS_TYPE,
  BILLING_FORM,
  BillingFile,
} from '../../../types/jobTypes'
import {
  FEMA_CATEGORY,
  User,
  UserContextType,
  UserType,
} from '../../../types/userTypes'
import {
  GET_ADMINS,
  GET_JOB_BY_ID,
  GET_ROSTERS,
} from '../../../graphql/Queries'
import { GLOBAL_ROUTES } from '../../../App'
import { getFileNameFromURL, storeFile } from '../../../utils/functions'

function UpdateJob() {
  const { jobId } = useParams()
  const navigate = useNavigate()
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const { user }: UserContextType = useContext(UserContext)
  const [admins, setAdmins] = useState<User[]>([])
  const [adminsRetrieved, setAdminsRetrieved] = useState(false)
  const [createNotificationMutation] = useMutation(CREATE_NOTIFICATION)

  const [jobRetrieved, setJobRetrieved] = useState<boolean>(false)

  const [status, setStatus] = useState<JobStatus>(JobStatus.ONGOING)
  const [title, setTitle] = useState<string>('')
  const [adjustersNeeded, setAdjustersNeeded] = useState<number>(1)
  const [presence, setPresence] = useState<PRESENCE>(PRESENCE.FIELD)
  const [damageTypes, setDamageTypes] = useState<DamageType[]>([])
  const [damageTypesDescription, setDamageTypeDescription] = useState('')
  const [reportDate, setReportDate] = useState<string>('')
  const [dateOfLossType, setDateOfLossType] = useState<DATE_OF_LOSS_TYPE>(
    DATE_OF_LOSS_TYPE.DATE
  )
  const [dateOfLoss, setDateOfLoss] = useState<string>('')
  const [location, setLocation] = useState<string>('')
  const [state, setState] = useState<string>('')
  const [city, setCity] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [postingDate, setPostingDate] = useState<string>('')
  const [allAdjustersCanApply, setAllAdjustersCanApply] = useState(false)
  const [visibility, setVisibility] = useState<VISIBILITY>(VISIBILITY.PUBLIC)
  const [billingType, setBillingType] = useState<BillingType>(
    BillingType.HOURLY
  )
  const [billingTypeDescription, setBillingTypeDescription] =
    useState<string>('')
  const [billingForm, setBillingForm] = useState<BILLING_FORM>(BILLING_FORM.W2)
  const [billingFiles, setBillingFiles] = useState<BillingFile[]>([])

  const [jobFirmId, setJobFirmId] = useState<string>('')
  const [isFemaCertificationRequired, setIsFemaCertificationRequired] =
    useState<boolean>(false)
  const [femaCertificationCategories, setFemaCertificationCategories] =
    useState<FEMA_CATEGORY[]>([])
  const [licensingRequirements, setLicensingRequirements] = useState<
    JobLicensingRequirement[]
  >([])
  const [certificationRequirements, setCertificationRequirements] = useState<
    JobCertificationRequirement[]
  >([])
  const [rosters, setRosters] = useState<Roster[]>([])

  const [updateJobMutation] = useMutation(UPDATE_JOB_BY_ID)
  const [getRosters] = useLazyQuery(GET_ROSTERS)

  const [getJob] = useLazyQuery(GET_JOB_BY_ID, {
    onCompleted: async (data: { GetJobById: Job }) => {
      const job = data?.GetJobById
      if (
        !job ||
        (job.firmId !== user?._id && user?.userType !== UserType.ADMIN)
      )
        navigate(GLOBAL_ROUTES.JOBS)

      setTitle(job.title)
      setAdjustersNeeded(job.adjustersNeeded ?? 0)
      setPresence(job.presence ?? PRESENCE.FIELD)
      setDamageTypes(job.types ?? [])
      setDamageTypeDescription(job.typesDescription ?? '')
      setReportDate(job.reportDate)
      setDateOfLossType(job.dateOfLossType ?? DATE_OF_LOSS_TYPE.DATE)
      setDateOfLoss(job.dateOfLoss ?? '')
      setLocation(job.location ?? '')
      setState(job.state ?? '')
      setCity(job.city ?? '')
      setDescription(job.description)
      setBillingType(job.billingType)
      const bf: BillingFile[] =
        job?.billingTypeFileURLs
          ?.filter((a) => a)
          ?.map((url) => ({
            url,
            fileName: getFileNameFromURL(url),
            file: null,
          })) ?? []
      setBillingFiles(bf)
      setBillingForm(job.billingForm ?? BILLING_FORM.W2)
      setLicensingRequirements([...job.licensingRequirements])
      setCertificationRequirements([...job.certificationRequirements])
      setStatus(job.status)
      setVisibility(job.visibility)
      setAllAdjustersCanApply(!!job.allAdjustersCanApply)
      setPostingDate(job.postingDate)
      setJobFirmId(job.firmId)
      setIsFemaCertificationRequired(job.isFemaCertificationRequired)
      setFemaCertificationCategories(job.femaCertificationCategories)

      const result = await getRosters({
        variables: { jobId: job._id },
      })
      if (result?.data?.RosterMany?.length > 0) {
        const ros: Roster[] = result?.data?.RosterMany
        setRosters([...ros])
      }

      setShowLoader(false)
      setJobRetrieved(false)
    },
    onError: (err) => {
      setShowLoader(false)
      setJobRetrieved(false)
    },
  })

  const invalidForm = useMemo(() => {
    const a = licensingRequirements.find((l) => !l.id)
    const b = certificationRequirements.find((c) => !c.id && !c.name)
    return (
      !title ||
      !adjustersNeeded ||
      damageTypes.length === 0 ||
      !damageTypesDescription ||
      !reportDate ||
      !state ||
      //   !description ||
      !billingType ||
      !visibility ||
      (isFemaCertificationRequired &&
        femaCertificationCategories.length === 0) ||
      (dateOfLossType === DATE_OF_LOSS_TYPE.DATE && !dateOfLoss) ||
      !!a ||
      !!b
    )
  }, [
    licensingRequirements,
    certificationRequirements,
    title,
    adjustersNeeded,
    damageTypes.length,
    damageTypesDescription,
    reportDate,
    state,
    // description,
    billingType,
    visibility,
    isFemaCertificationRequired,
    femaCertificationCategories,
    dateOfLossType,
    dateOfLoss,
  ])

  useEffect(() => {
    if (jobId && !jobRetrieved) {
      setShowLoader(true)
      getJob({
        variables: {
          id: jobId,
          statuses: [JobStatus.DRAFT, JobStatus.ONGOING, JobStatus.FINISHED],
        },
      })
    }
  }, [getJob, jobId, jobRetrieved])

  const notifyAdmins = useCallback(
    async (message: string, jobId: string) => {
      const a = await admins.map(async (admin) => {
        await createNotificationMutation({
          variables: {
            notification: {
              userId: admin?._id,
              message,
              link: `${GLOBAL_ROUTES.ADMIN}${GLOBAL_ROUTES.JOB}/${jobId}`,
              read: false,
            },
          },
        })

        return null
      })

      await Promise.all(a)
    },
    [admins, createNotificationMutation]
  )

  const updateJob = useCallback(
    async (saveAsDraft: boolean) => {
      // console.log('UPDATING A JOB')
      if (invalidForm) {
        console.log('INVALID UPDATE JOB FORM')
        return
      }

      if (
        billingType &&
        damageTypes &&
        (jobFirmId === user?._id || user?.userType === UserType.ADMIN)
      ) {
        setShowLoader(true)

        const urlsPromise = billingFiles.map(async (billingFile) => {
          if (billingFile.file) {
            const billingTypeFileResponse = await storeFile(
              billingFile.file,
              billingFile.fileName,
              user,
              `jobs/${jobId}/billingCompensationSchedules/`
            )
            return billingTypeFileResponse?.data ?? ''
          } else {
            return billingFile.url
          }
        })

        const urls = await Promise.all(urlsPromise)

        const jobData = {
          title,
          adjustersNeeded,
          presence,
          types: damageTypes,
          typesDescription: damageTypesDescription,
          reportDate,
          dateOfLossType,
          dateOfLoss:
            dateOfLossType === DATE_OF_LOSS_TYPE.DATE ? dateOfLoss : undefined,
          location,
          state,
          city,
          description,
          isFemaCertificationRequired,
          femaCertificationCategories,
          licensingRequirements,
          certificationRequirements,
          billingType,
          billingTypeDescription,
          billingTypeFileURLs: urls,
          billingForm,
          allAdjustersCanApply,
          visibility,
          firmId: user?._id ?? '',
          postingDate: saveAsDraft ? new Date(postingDate) : new Date(),
          status: saveAsDraft
            ? JobStatus.DRAFT
            : status === JobStatus.DRAFT
            ? JobStatus.ONGOING
            : status,
        }

        const result = await updateJobMutation({
          variables: { job: jobData, id: jobId },
        })
        const updatedJob = result?.data?.JobUpdateById?.record as Job
        if (updatedJob && user?.userType !== UserType.ADMIN) {
          await notifyAdmins(
            `The job "${updatedJob?.title}" has been updated`,
            updatedJob?._id ?? ''
          )
          navigate(GLOBAL_ROUTES.JOBS)
        }
        setShowLoader(false)
      }
    },
    [
      invalidForm,
      billingType,
      damageTypes,
      jobFirmId,
      user,
      billingFiles,
      title,
      adjustersNeeded,
      presence,
      damageTypesDescription,
      reportDate,
      dateOfLossType,
      dateOfLoss,
      location,
      state,
      city,
      description,
      isFemaCertificationRequired,
      femaCertificationCategories,
      licensingRequirements,
      certificationRequirements,
      billingTypeDescription,
      billingForm,
      allAdjustersCanApply,
      visibility,
      postingDate,
      status,
      updateJobMutation,
      jobId,
      notifyAdmins,
      navigate,
    ]
  )

  const [getAdmins] = useLazyQuery(GET_ADMINS, {
    onCompleted: (data: { UserMany: User[] }) => {
      if (data?.UserMany) {
        const newUsers = [...data?.UserMany]
        setAdmins(newUsers)
      }
      setAdminsRetrieved(true)
    },
    onError: (err) => {
      console.log(err)
    },
  })

  useEffect(() => {
    if (!adminsRetrieved) getAdmins()
  }, [adminsRetrieved, getAdmins])

  return (
    <>
      {showLoader ? (
        <HStack h="100%" justifyContent="center">
          <Spinner size="xl" />
        </HStack>
      ) : (
        <Box paddingX={['10px', '50px']} paddingY="10px">
          <HStack w="100%" justifyContent="center">
            <CreateJobForm
              user={user}
              title={title}
              setTitle={setTitle}
              adjustersNeeded={adjustersNeeded}
              setAdjustersNeeded={setAdjustersNeeded}
              presence={presence}
              setPresence={setPresence}
              damageTypes={damageTypes}
              setDamageTypes={setDamageTypes}
              damageTypesDescription={damageTypesDescription}
              setDamageTypeDescription={setDamageTypeDescription}
              reportDate={reportDate}
              setReportDate={setReportDate}
              dateOfLossType={dateOfLossType}
              setDateOfLossType={setDateOfLossType}
              dateOfLoss={dateOfLoss}
              setDateOfLoss={setDateOfLoss}
              location={location}
              setLocation={setLocation}
              state={state}
              setState={setState}
              city={city}
              setCity={setCity}
              description={description}
              setDescription={setDescription}
              allAdjustersCanApply={allAdjustersCanApply}
              setAllAdjustersCanApply={setAllAdjustersCanApply}
              visibility={visibility}
              setVisibility={setVisibility}
              isFemaCertificationRequired={isFemaCertificationRequired}
              setIsFemaCertificationRequired={setIsFemaCertificationRequired}
              femaCertificationCategories={femaCertificationCategories}
              setFemaCertificationCategories={setFemaCertificationCategories}
              licensingRequirements={licensingRequirements}
              setLicensingRequirements={setLicensingRequirements}
              certificationRequirements={certificationRequirements}
              setCertificationRequirements={setCertificationRequirements}
              billingType={billingType}
              setBillingType={setBillingType}
              billingTypeDescription={billingTypeDescription}
              setBillingTypeDescription={setBillingTypeDescription}
              billingFiles={billingFiles}
              setBillingFiles={setBillingFiles}
              billingForm={billingForm}
              setBillingForm={setBillingForm}
              buttons={
                <HStack justifyContent="center">
                  {rosters.length === 0 && (
                    <Button
                      type="submit"
                      variant="adjustant"
                      //   isDisabled={invalidForm}
                      onClick={() => updateJob(true)}
                      isLoading={showLoader}
                    >
                      Save as Draft
                    </Button>
                  )}
                  <Button
                    type="submit"
                    variant="adjustant"
                    // isDisabled={invalidForm}
                    onClick={() => updateJob(false)}
                    isLoading={showLoader}
                  >
                    {status === JobStatus.DRAFT ? 'Post Job' : 'Update Job'}
                  </Button>
                </HStack>
              }
            />
          </HStack>
        </Box>
      )}
    </>
  )
}

export default UpdateJob
