import React from 'react'
import { createContext } from 'react'
import {
  TrainingCertificationContextType,
  TrainingCertification
} from '../types/trainingCertificationsTypes'

export const TrainingCertificationsContext =
  createContext<TrainingCertificationContextType>({
    trainingCertifications: [],
    setTrainingCertifications: (
      value: React.SetStateAction<TrainingCertification[]>
    ): void => {
      throw new Error('Function not implemented.')
    }
  })
