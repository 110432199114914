import './index.css'
import App from './App'
import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import {
  HttpLink,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from '@apollo/client'
import { graphqlCall } from './services/adjustant'

// THIS IS FOR GRAPHQL CALLS ONLY
// ALL GRAPHQL CALLS MADE TO `${process.env.REACT_APP_API_URL}/graphql` PASS THROUGH HERE FIRST
const customHttpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
  fetch: async (uri: string, options: RequestInit): Promise<Response> => {
    const response = await graphqlCall(uri, options)
    const customResponse = new Response(JSON.stringify(response))
    Object.assign(customResponse, response)
    return customResponse
  },
})
const client = new ApolloClient({
  link: customHttpLink,
  cache: new InMemoryCache(),
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ApolloProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
