import React, { useState } from 'react'
import { IoIosStar } from 'react-icons/io'
import { Radio, HStack, Box } from '@chakra-ui/react'
import { ADJUSTANT_GREEN } from '../../themes/themes'

const StarRating = ({
  rating,
  setRating,
  count = 5,
  isDisabled = false,
}: {
  rating: number
  setRating?: React.Dispatch<React.SetStateAction<number>>
  count?: number
  isDisabled?: boolean
}) => {
  const [hover, setHover] = useState<number | undefined>()
  return (
    <HStack spacing={'4px'} flexFlow="wrap" justifyContent="center">
      {[...Array(count)].map((star, index) => {
        const starNumber = index + 1
        const diff = rating - starNumber
        const offsetPercentage = 100 - diff * -100
        const greenPercentage =
          diff > 0 ? 100 : diff > -1 ? offsetPercentage : 0
        // const greenPercentage = 1
        // const grayPercentage = 100 - greenPercentage

        return (
          <Box
            as="label"
            key={index}
            // color={
            //   ratingValue <= (hover || rating) ? ADJUSTANT_GREEN : '#e4e5e9'
            // }
            onMouseEnter={() => {
              if (!isDisabled) setHover(starNumber)
            }}
            onMouseLeave={() => {
              if (!isDisabled) setHover(undefined)
            }}
          >
            <Radio
              name="rating"
              onChange={() => {
                if (setRating) {
                  setRating(starNumber)
                }
              }}
              value={starNumber.toString()}
              display="none"
              isDisabled
            ></Radio>
            {/* <IoIosStar
              cursor={isDisabled ? 'initial' : 'pointer'}
              size={50}
            /> */}

            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="50"
              width="50"
              viewBox="0 0 32 32"
              cursor={isDisabled ? 'initial' : 'pointer'}
            >
              <defs>
                <linearGradient
                  id={`grad-${starNumber}`}
                  x1="0%"
                  x2={greenPercentage + '%'}
                >
                  {/* <linearGradient id={`grad-${starNumber}`}> */}
                  <stop
                    // offset={greenPercentage + '%'}
                    offset="100%"
                    stopColor={ADJUSTANT_GREEN}
                  />

                  {/* <stop offset={grayPercentage + '%'} stopColor="#e4e5e9" /> */}
                  <stop offset="0%" stopColor="#e4e5e9" />
                </linearGradient>
                <style></style>
              </defs>
              <path
                fill={`url(#grad-${starNumber})`}
                d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,12.118l11.547-1.2L16.026,0.6L20.388,10.918z"
              />
            </svg>
          </Box>
        )
      })}
    </HStack>
  )
}

export default StarRating
