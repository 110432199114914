import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  HStack,
  Box,
  Spinner,
  ModalCloseButton,
} from '@chakra-ui/react'
import { useState, useRef, useEffect, useCallback } from 'react'
import {
  SIGN_NOW_SIGNING_STATUS,
  SignNowSigningInvitation,
} from '../../../types/signNowTypes'
import { createSigningInviteLink } from '../../../services/adjustant'

const SignDocumentModal = ({
  isOpen,
  onClose,
  signNowInvitation,
}: {
  isOpen: boolean
  onClose: (success?: boolean) => void
  signNowInvitation?: SignNowSigningInvitation
}) => {
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [link, setLink] = useState<string>('')

  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const [iframeLoadsCount, setIframeLoadsCount] = useState(0)

  //WHEN THE IFRAME GETS REDIRECT
  useEffect(() => {
    if (link && iframeRef.current) {
      iframeRef.current?.addEventListener('load', function () {
        console.log(
          'The iframe has navigated or reloaded----------------------------------------------------------------------------'
        )
        console.log(iframeLoadsCount)

        if (iframeLoadsCount > 0) {
          //Hides editor
          // setEditorUrl('')
          setShowLoader(true)
          onClose(
            signNowInvitation?.status === SIGN_NOW_SIGNING_STATUS.PENDING
              ? true
              : false
          )
        }

        setIframeLoadsCount((prev) => prev + 1)
      })
    }
  }, [iframeLoadsCount, link, onClose, signNowInvitation?.status])

  const createSigningLink = useCallback(async () => {
    setShowLoader(true)
    const result = await createSigningInviteLink({
      inviteId: signNowInvitation?.inviteId ?? '',
      documentId: signNowInvitation?.documentId ?? '',
    })

    if (result.success) {
      setLink(result.data)
    }

    setShowLoader(false)
  }, [signNowInvitation?.documentId, signNowInvitation?.inviteId])

  useEffect(() => {
    if (!link && signNowInvitation) createSigningLink()
  }, [createSigningLink, link, signNowInvitation])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      // xs, sm, md, lg, xl, full
      size="full"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Sign the document</ModalHeader>
        <ModalBody height="100%">
          <Box height="calc(100vh - 78px)" width="100%">
            {showLoader ? (
              <HStack
                direction="row"
                height="-webkit-fill-available"
                w="100%"
                // h="100%"
                justifyContent="center"
              >
                <Spinner size="xl" />
              </HStack>
            ) : (
              <iframe
                height="100%"
                width="100%"
                id="update-document-iframe"
                ref={iframeRef}
                title="update-document-iframe"
                src={link}
              />
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default SignDocumentModal
