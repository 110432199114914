import { useCallback, useContext, useEffect, useState } from 'react'
import { ADD_LOGIN_LOG } from '../../graphql/Mutations'
import { GET_USER_BY_EMAIL } from '../../graphql/Queries'
import { User, UserContextType, UserType } from '../../types/userTypes'
import { UserContext } from '../../contexts/UserContext'
import { completeLoginUser, loginUser } from '../../services/adjustant'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import {
  setItemToLocalStorage,
  LOCALSTORAGE_OBJECTS_NAMES,
} from '../../utils/localStorageFunctions'
import { GLOBAL_ROUTES } from '../../App'
import {
  Box,
  Heading,
  Input,
  Stack,
  Tab,
  TabList,
  Button,
  Tabs,
  VStack,
  Link,
  Flex,
  Text,
  HStack,
  PinInput,
  PinInputField,
} from '@chakra-ui/react'
import Label from '../../components/Label/Label'
import { ADJUSTANT_GREEN } from '../../themes/themes'
import Logo from '../../assets/images/Logo'

const CODE_LENGHT = 6

const Login = () => {
  const navigate = useNavigate()
  const { setUser }: UserContextType = useContext(UserContext)
  const [showLoader, setShowLoader] = useState(false)
  const [index, setIndex] = useState(1)
  const [userType, setUserType] = useState<UserType>(UserType.FIRM)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const [step, setStep] = useState<number>(1)
  const [code, setCode] = useState<string>('')
  const [encryptedCode, setEncryptedCode] = useState<string>('')
  const [accessToken, setAccessToken] = useState<string>('')

  const [getUser] = useLazyQuery(GET_USER_BY_EMAIL, {
    onCompleted: async (data: { UserOne: User & { __typename: any } }) => {
      const { __typename, ...loggedInUser } = data.UserOne

      setItemToLocalStorage(LOCALSTORAGE_OBJECTS_NAMES.USER, loggedInUser)
      setItemToLocalStorage(
        LOCALSTORAGE_OBJECTS_NAMES.ACCESS_TOKEN,
        accessToken
      )
      await logLogIn({
        variables: {
          stats: {
            userId: loggedInUser._id,
          },
        },
      })
      setUser(loggedInUser) // this is what triggers th usefect that sets the user inside app.tsx

      if (!loggedInUser?.profileSet) navigate(GLOBAL_ROUTES.COMPLETE_PROFILE)
      else navigate(GLOBAL_ROUTES.PROFILE)
    },
    onError: (err) => {
      setShowLoader(false)
    },
  })

  useEffect(() => {
    setUserType(index === 1 ? UserType.FIRM : UserType.ADJUSTER)
  }, [index])

  const [logLogIn] = useMutation(ADD_LOGIN_LOG)

  const logIn = useCallback(async () => {
    setShowLoader(true)
    setErrorMessage('')
    const responseData = await loginUser({
      email,
      password,
      userType,
    })

    if (!responseData.success) {
      setErrorMessage(responseData.message)
      setShowLoader(false)
    } else {
      if (responseData?.data?.EncryptedCode) {
        setErrorMessage(responseData.message)
        setShowLoader(false)
        setAccessToken(responseData?.data?.AccessToken)
        setEncryptedCode(responseData?.data?.EncryptedCode)
        setTimeout(() => {
          setErrorMessage('')
          setStep(2)
        }, 2000)
      } else {
        getUser({ variables: { email } })
      }
    }
  }, [email, getUser, password, userType])

  const completeLogIn = useCallback(async () => {
    setShowLoader(true)
    setErrorMessage('')
    const responseData = await completeLoginUser({
      encryptedCode,
      enteredCode: code,
    })
    if (!responseData.success) {
      setErrorMessage(responseData.message)
      setShowLoader(false)
    } else {
      getUser({ variables: { email } })
    }
  }, [encryptedCode, code, getUser, email])

  return (
    <Box
      minHeight="100%"
      width="100%"
      display="flex"
      alignItems="center"
      backgroundImage="/images/loginBackground.png"
      backgroundSize="cover"
      backgroundRepeat={['no-repeat', 'no-repeat', 'no-repeat', 'round']}
      flexDirection={[
        'column-reverse',
        'column-reverse',
        'column-reverse',
        'row',
      ]}
      justifyContent={['center', 'center', 'center', 'space-evenly']}
      gap={[4, 4, 4, 0]}
    >
      <Box position="absolute" top={6} left={6}>
        <Logo height="32" width="32" />
      </Box>

      <Box
        width={['90%', '90%', '90%', '600px']}
        fontSize={[16, 16, 16, 28]}
        color="white"
        fontWeight={600}
      >
        <Text mb={4} as="b">
          Adjusters and Employers enter Jobs Board Here.
        </Text>
        <Text mb={4}>
          If you are an Adjuster, sign in or create an account for free. Set up
          your adjuster profile and Adjustant will start matching you with the
          best job prospects for your qualifications and preferences.
        </Text>
        <Text>
          If you are an Employer,{' '}
          <Link
            href="https://www.adjustant.com/adjusters-1"
            color={ADJUSTANT_GREEN}
          >
            contact us
          </Link>{' '}
          to get in touch with our sales team to join Adjustant. Already joined?
          Login here to manage your jobs.
        </Text>
      </Box>

      <Box
        background="white"
        border="1px solid #ECEAE9"
        borderRadius="8px"
        // width="600px"
        mx={3}
        width={['90%', '90%', '90%', '600px']}
      >
        {step === 1 ? (
          <Tabs index={index} onChange={(i) => setIndex(i)}>
            <TabList>
              <Tab
                fontWeight={600}
                color={index === 0 ? ADJUSTANT_GREEN : 'gray.500'}
                width="50%"
                borderColor={
                  index === 0
                    ? `${ADJUSTANT_GREEN}!important`
                    : 'transparent!important'
                }
                borderBottomWidth={4}
                p={4}
                _pressed={{ backgroundColor: 'white' }}
              >
                Adjuster
              </Tab>
              <Tab
                fontWeight={600}
                color={index === 1 ? ADJUSTANT_GREEN : 'gray.500'}
                width="50%"
                borderColor={
                  index === 1
                    ? `${ADJUSTANT_GREEN}!important`
                    : 'transparent!important'
                }
                borderBottomWidth={4}
                p={4}
              >
                Firm
              </Tab>
            </TabList>

            <VStack py={[6, 6, 12]} px={[6, 6, 24]}>
              <Heading
                as="h3"
                fontSize="3xl"
                color="gray.500"
                mt={4}
                mb={8}
                textAlign="center"
              >
                Welcome
              </Heading>
              <Stack mb={2} width="100%">
                <Label color="gray.500">Email</Label>
                <Input
                  type="email"
                  placeholder="johnny.appleseed@email.com"
                  _focusVisible={{ borderColor: 'gray.300' }}
                  value={email}
                  onChange={(e) =>
                    setEmail(e.target.value?.toLocaleLowerCase())
                  }
                />
              </Stack>
              <Stack width="100%" mb={4}>
                <Flex justifyContent="space-between">
                  <Label color="gray.500">Password</Label>
                  <Label color={ADJUSTANT_GREEN}>
                    <Link
                      href={GLOBAL_ROUTES.RECOVER_PASSWORD}
                      textDecoration="underline"
                    >
                      Forgot Password?
                    </Link>
                  </Label>
                </Flex>
                <Input
                  type="password"
                  placeholder="Password"
                  _focusVisible={{ borderColor: 'gray.300' }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Stack>

              <Text as="h5" color="red.500" fontSize="sm">
                {errorMessage}
              </Text>

              <Button
                variant="adjustant"
                isLoading={showLoader}
                isDisabled={!email || !password}
                _hover={{ backgroundColor: ADJUSTANT_GREEN }}
                onClick={logIn}
              >
                Log In
              </Button>

              {userType === UserType.ADJUSTER && (
                <Stack>
                  <Label color="gray.500">
                    Don’t have an account?{' '}
                    <Link color="teal.500" href={`${GLOBAL_ROUTES.SIGN_UP}`}>
                      Get Started
                    </Link>
                  </Label>
                </Stack>
              )}
            </VStack>
          </Tabs>
        ) : step === 2 ? (
          <VStack my={4}>
            <Heading
              as="h3"
              fontSize="3xl"
              color="gray.500"
              mt={4}
              mb={8}
              textAlign="center"
            >
              Welcome Back
            </Heading>

            <HStack>
              <PinInput onChange={setCode}>
                {[...Array(CODE_LENGHT)].map((_key, index) => (
                  <PinInputField key={index} />
                ))}
              </PinInput>
            </HStack>

            <Text as="h5" color="red.500" fontSize="sm">
              {errorMessage}
            </Text>
            <Button
              variant="adjustant"
              isLoading={showLoader}
              isDisabled={code.length !== CODE_LENGHT}
              _hover={{ backgroundColor: ADJUSTANT_GREEN }}
              onClick={completeLogIn}
            >
              Log In
            </Button>
          </VStack>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  )
}

export default Login
