import { SelectableItem } from '../components/CustomDropdown/CustomDropdown'
import { DamageType } from './jobTypes'
import { US_STATES_ABBREVIATIONS } from './stateLicensesTypes'

export enum UserType {
  FIRM = 'Firm',
  ADJUSTER = 'Adjuster',
  ADMIN = 'Admin',
}

export enum USER_STATUS {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
  DEPLOYED = 'DEPLOYED',
}

export enum DOCUMENTS_STATUS {
  PENDING_REVIEW = 'PENDING_REVIEW',
  APPROVED = 'APPROVED',
  // NO_ACTION_TAKEN = 'NO_ACTION_TAKEN',
}

export enum FLAG_REASON {
  OTHER = 'OTHER',
  INCORRECT = 'INCORRECT',
  INCOMPLETE = 'INCOMPLETE',
  DOES_NOT_MATCH = 'DOES_NOT_MATCH',
}

export enum DATA_SOURCE {
  NIPR = 'NIPR',
}

export enum FEMA_CATEGORY {
  RESIDENTIAL = 'RESIDENTIAL',
  MOBILE_HOME = 'MOBILE_HOME',
  SMALL_COMMERCIAL = 'SMALL_COMMERCIAL',
  LARGE_COMMERCIAL = 'LARGE_COMMERCIAL',
  RESIDENTIAL_CONDO = 'RESIDENTIAL_CONDO',
}
export interface DriverLicense {
  id: string
  address: string
  city: string
  state: string
  zipcode: string
  birthDate: string
  url: string
  flag?: FLAG_REASON | string
  approved?: boolean
}

interface DocumentDates {
  expiryDate: string
  issuedDate: string
}

export interface FemaCertification extends DocumentDates {
  has: boolean
  url?: string
  flag?: FLAG_REASON | string
  approved?: boolean
  fcnNumber?: string
  categories?: FEMA_CATEGORY[]
}
export interface LicenseCertification extends DocumentDates {
  id: string
  name: string
  url?: string
  state: US_STATES_ABBREVIATIONS | string
  loas?: string[]
  isListed: boolean
  isPrimary: boolean
  flag?: FLAG_REASON | string
  approved?: boolean
  source?: DATA_SOURCE
  metadata?: any
}

export interface CertificationRequirement {
  id: string
  name: string
  isListed: boolean
}

export const EXPERIENCE_VALUES = [
  '0',
  '1 to 11',
  '12 to 23',
  '24 to 35',
  '36 to 59',
  '60+',
]

export const EXPERIENCE_TYPES: { [K in keyof Experience]: string } = {
  //
  propertyResidential: 'Residential Property Claims', //BOLD
  propertyResidentialAppraisalClaimHandling:
    'Property - Residential - Appraisal Claim Handling',
  propertyResidentialDailyZone: 'Property - Residential - Daily/Zone',
  propertyResidentialeEarthQuake: 'Property - Residential - EarthQuake',
  propertyResidentialfFarmRanch: 'Property - Residential - Farm Ranch',
  propertyResidentialFlood: 'Property - Residential - Flood',
  propertyResidentialFreezeIce: 'Property - Residential - Freeze/Ice',
  propertyResidentialHail: 'Property - Residential - Hail',
  propertyResidentialHurricaneFlood: 'Property - Residential - Hurricane Flood',
  propertyResidentialHurricaneWind: 'Property - Residential - Hurricane Wind',
  propertyResidentialInOfficeClaimHandling:
    'Property - Residential - InOffice Claim Handling',
  propertyResidentialLargeLoss: 'Property - Residential - Large',
  propertyResidentialRopeAndHarness:
    'Property - Residential - Rope and Harness',
  propertyResidentialSewerBackupBUSD:
    'Property - Residential - Sewer Backup BUSD',
  propertyResidentialStaff: 'Property - Residential - Staff',
  // propertyResidentialTaskAdjusting: 'Property - Residential - Task Adjusting',
  propertyResidentialTotalLoss: 'Property - Residential - Total Loss',
  propertyResidentialUmpireClaimHandling:
    'Property - Residential - Umpire Claim Handling',
  propertyResidentialVirtualAdjusting:
    'Property - Residential - Virtual Adjusting',
  propertyResidentialWildfire: 'Property - Residential - Wildfire',

  //
  auto: 'Auto Claims',
  autoBodilyInjury: 'Auto - Bodily Injury',
  autoCat: 'Auto - Cat',
  autoCollision: 'Auto - Collision',
  autoEstimations: 'Auto - Estimations',
  autoFlood: 'Auto - Flood',
  autoInOfficeClaimHandling: 'Auto - In Office Claim Handling',
  autoLiability: 'Auto - Liability',
  autoPhysicalDamage: 'Auto - Physical Damage',
  autoStaff: 'Auto - Staff',
  // autoTaskAdjusting: 'Auto - Task Adjusting',
  autoTotalLoss: 'Auto - Total Loss',
  autoVirtualAdjusting: 'Auto - Virtual Adjusting',

  //
  commercial: 'Commercial Claims',
  commercialAppraisalClaimHandling: 'Commercial - Appraisal Claim Handling',
  commercialBusinessInterruption: 'Commercial - Business Interruption',
  commercialPersonalProperty: 'Commercial - Personal Property',
  commercialBusinessPropertyDamage: 'Commercial - Business Property Damage',
  commercialLiability: 'Commercial - Liability',
  commercialUmpireClaimHandling: 'Commercial - Umpire Claim Handling',
  commercialVirtualAdjusting: 'Commercial - Virtual Adjusting',

  //
  specialtyServices: 'Specialty Services Claims',
  specialtyServicesAgriculture: 'Specialty Services - Agriculture',
  specialtyServicesCasualty: 'Specialty Services - Casualty',
  specialtyServicesEnvironmental: 'Specialty Services - Environmental',
  specialtyServicesFileReview: 'Specialty Services - File Review',
  specialtyServicesInlandMarine: 'Specialty Services - Inland Marine',
  specialtyServicesInLanguageSpanishAdjusting:
    'Specialty Services - In Language Spanish Adjusting',
  specialtyServicesLitigationAdjuster:
    'Specialty Services - Litigation Adjuster',
  specialtyServicesStaffAdjuster: 'Specialty Services - Staff Adjuster',
}

export interface Experience {
  //
  propertyResidential?: number
  propertyResidentialAppraisalClaimHandling?: number
  propertyResidentialDailyZone?: number
  propertyResidentialeEarthQuake?: number
  propertyResidentialfFarmRanch?: number
  propertyResidentialFlood?: number
  propertyResidentialFreezeIce?: number
  propertyResidentialHail?: number
  propertyResidentialHurricaneFlood?: number
  propertyResidentialHurricaneWind?: number
  propertyResidentialInOfficeClaimHandling?: number
  propertyResidentialLargeLoss?: number
  propertyResidentialRopeAndHarness?: number
  propertyResidentialSewerBackupBUSD?: number
  propertyResidentialStaff?: number
  propertyResidentialTaskAdjusting?: number
  propertyResidentialTotalLoss?: number
  propertyResidentialUmpireClaimHandling?: number
  propertyResidentialVirtualAdjusting?: number
  propertyResidentialWildfire?: number

  //
  auto?: number
  autoBodilyInjury?: number
  autoCat?: number
  autoCollision?: number
  autoEstimations?: number
  autoFlood?: number
  autoInOfficeClaimHandling?: number
  autoLiability?: number
  autoPhysicalDamage?: number
  autoStaff?: number
  autoTaskAdjusting?: number
  autoTotalLoss?: number
  autoVirtualAdjusting?: number

  //
  commercial?: number
  commercialAppraisalClaimHandling?: number
  commercialBusinessInterruption?: number
  commercialPersonalProperty?: number
  commercialBusinessPropertyDamage?: number
  commercialLiability?: number
  commercialUmpireClaimHandling?: number
  commercialVirtualAdjusting?: number

  //
  specialtyServices?: number
  specialtyServicesAgriculture?: number
  specialtyServicesCasualty?: number
  specialtyServicesEnvironmental?: number
  specialtyServicesFileReview?: number
  specialtyServicesInlandMarine?: number
  specialtyServicesInLanguageSpanishAdjusting?: number
  specialtyServicesLitigationAdjuster?: number
  specialtyServicesStaffAdjuster?: number
}

export interface LicensingRequirement {
  id: string
  name: string
  isListed: boolean
}

export interface TaxForm {
  url: string
  flag?: FLAG_REASON | string
  approved?: boolean
}

export interface MiscelaneosDocument {
  url: string
  description: string
  flag?: FLAG_REASON | string
  approved?: boolean
}

export interface TestimonialsConfig {
  show: boolean
  testimonialsToShow: string[]
}

export type User = {
  _id?: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  password: string
  userType: UserType
  profileSet?: boolean
  profilePicture?: string
  bannerPicture?: string
  location?: string

  // Adjusters data
  jobTypes?: DamageType[]
  bio?: string
  testimonialsConfig?: TestimonialsConfig
  yearsOfLicensedExperience?: number
  status: USER_STATUS
  availabilityDate?: string
  availabilityInfo?: string
  jobLocations?: US_STATES_ABBREVIATIONS[]
  driverLicense?: DriverLicense
  secondaryFormOfID?: TaxForm
  miscelaneosDocument?: MiscelaneosDocument
  femaCertification?: FemaCertification
  w9TaxForm?: TaxForm
  w4TaxForm?: TaxForm
  i9TaxForm?: TaxForm
  signedDocuments?: { url: string; documentId: string }[]
  //
  licenses?: LicenseCertification[]
  certifications?: LicenseCertification[]
  //
  npnNumber?: number
  niprMetadata?: any
  languagePreference?: string
  emergencyContact?: string
  xactimateId?: string
  companyAlias?: string
  experience?: Experience

  //Firms data
  firmName?: string
  areasOfOperation?: string[]
  additionalCertificationRequirements?: CertificationRequirement[]
  hasCheckrAccessToken?: boolean
}

export interface AdminAdjuster {
  _id: string
  firstName: string
  lastName: string
  email: string
  status: USER_STATUS
  documentsStatus?: DOCUMENTS_STATUS
  lastActive: string
}

export interface AdminFirm {
  _id: string
  firmName: string
  areasOfOperation: string[]
  pastJobs: number
  openJobs: number
  lastActive: string
}

export type UserContextType = {
  user?: User
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>
}

export const flagReasonsOptions: SelectableItem[] = [
  {
    value: FLAG_REASON.INCORRECT,
    label: 'Incorrect Document.',
  },
  {
    value: FLAG_REASON.INCOMPLETE,
    label: 'Incomplete Document.',
  },
  {
    value: FLAG_REASON.DOES_NOT_MATCH,
    label: 'Document does not match the Adjuster identity.',
  },
  { value: FLAG_REASON.OTHER, label: 'Other' },
]

export const femaCategoriesOptions: SelectableItem[] = [
  {
    value: FEMA_CATEGORY.RESIDENTIAL,
    label: 'Residential',
  },
  {
    value: FEMA_CATEGORY.MOBILE_HOME,
    label: 'Mobile Home',
  },
  {
    value: FEMA_CATEGORY.SMALL_COMMERCIAL,
    label: 'Small Commercial',
  },
  {
    value: FEMA_CATEGORY.LARGE_COMMERCIAL,
    label: 'Large Commercial',
  },
  {
    value: FEMA_CATEGORY.RESIDENTIAL_CONDO,
    label: 'RCBAP (Residential Condo)',
  },
]

export const userStatusOptions: SelectableItem[] = [
  {
    value: USER_STATUS.AVAILABLE,
    label: 'Available',
  },
  {
    value: USER_STATUS.UNAVAILABLE,
    label: 'Unavailable',
  },
  {
    value: USER_STATUS.DEPLOYED,
    label: 'Deployed',
  },
]

export const documentStatusOptions: SelectableItem[] = [
  {
    value: DOCUMENTS_STATUS.APPROVED,
    label: 'Approved',
  },
  {
    value: DOCUMENTS_STATUS.PENDING_REVIEW,
    label: 'Pending Review',
  },
]

export const getFlagReason = (
  flagReason: FLAG_REASON | string | undefined
): SelectableItem | undefined => {
  return flagReasonsOptions.find((bo) => bo.value === flagReason)
}
