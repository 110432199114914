import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Box,
  Textarea,
} from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import {
  ADJUSTER_WORK_STATUS,
  JOB_REVIEW_FEEDBACK,
  ReviewSurvey,
  Roster,
} from '../../types/jobTypes'
import { ROSTER_DEPLOYMENT_SURVEY_QUESTIONS } from '../../pages/Firm/ReviewRoster/ReviewRoster'

const ReviewRosterWorkflowModal = ({
  isOpen,
  onClose,
  jobRosters,
  jobRosterToReviewIndex,
}: {
  isOpen: boolean
  onClose: (newJobRoster?: Roster) => void
  jobRosters: Roster[]
  jobRosterToReviewIndex?: number
}) => {
  const [step, setStep] = useState(1)
  const [jobRosterToReview, setJobRosterToReview] = useState<
    Roster | undefined
  >()

  const [radioChecked, setRadioChecked] = useState<
    JOB_REVIEW_FEEDBACK | undefined
  >()

  const question = useMemo(() => {
    return (
      <Box>
        <Stack gap={4}>
          <Text as="b" textAlign="center">
            Question {step} of {ROSTER_DEPLOYMENT_SURVEY_QUESTIONS.length}
          </Text>
          <Text as="b">
            {ROSTER_DEPLOYMENT_SURVEY_QUESTIONS[step - 1]?.question ?? ''}
          </Text>
        </Stack>

        {step <= ROSTER_DEPLOYMENT_SURVEY_QUESTIONS.length ? (
          <RadioGroup
            key={step}
            variant="adjustant"
            justifyContent="center"
            mt={8}
            value={
              jobRosterToReview?.review?.survey?.[
                ('q' + step) as keyof ReviewSurvey
              ]
            }
            onChange={(e: JOB_REVIEW_FEEDBACK) => {
              if (jobRosterToReview) {
                if (jobRosterToReview?.review) {
                  //   console.log(jobRosterToReview)
                  jobRosterToReview.review.survey[
                    ROSTER_DEPLOYMENT_SURVEY_QUESTIONS[step - 1]
                      .name as keyof ReviewSurvey
                  ] = e
                } else {
                  jobRosterToReview.review = {
                    version: 'V1',
                    testimonial: '',
                    date: new Date().toString(),
                    survey: {
                      q1: e,
                    },
                  }
                }
                setRadioChecked(e)
                jobRosterToReview.status = ADJUSTER_WORK_STATUS.FINISHED
                setJobRosterToReview({ ...jobRosterToReview })
              }
            }}
            display="flex"
          >
            <Stack alignItems="center" as="b">
              <Text fontSize={16}>ABOVE AVERAGE</Text>
              <Radio value={JOB_REVIEW_FEEDBACK.ABOVE_AVERAGE} />
            </Stack>
            <Text fontSize={16} mx={2}>
              /
            </Text>
            <Stack alignItems="center" as="b">
              <Text fontSize={16}>SATISFACTORY</Text>
              <Radio value={JOB_REVIEW_FEEDBACK.SATISFACTORY} />
            </Stack>
            <Text fontSize={16} mx={2}>
              /
            </Text>
            <Stack alignItems="center" as="b">
              <Text fontSize={16}>UNSATISFACTORY</Text>
              <Radio value={JOB_REVIEW_FEEDBACK.UNSATISFACTORY} />
            </Stack>
            {ROSTER_DEPLOYMENT_SURVEY_QUESTIONS[step - 1].hasNA && (
              <>
                <Text fontSize={16} mx={2}>
                  /
                </Text>
                <Stack alignItems="center" as="b">
                  <Text fontSize={16}>N/A</Text>
                  <Radio value={JOB_REVIEW_FEEDBACK.NA} />
                </Stack>
              </>
            )}
          </RadioGroup>
        ) : (
          <Textarea
            // rows={1}
            // height="auto!important"
            //   minHeight="5px"
            placeholder="Write a testimonial"
            // fontSize="sm"
            value={jobRosterToReview?.review?.testimonial ?? ''}
            onChange={(e) => {
              if (jobRosterToReview?.review) {
                jobRosterToReview.review = {
                  ...jobRosterToReview.review,
                  testimonial: e.target.value,
                }
                jobRosterToReview.status = ADJUSTER_WORK_STATUS.FINISHED
                setJobRosterToReview({ ...jobRosterToReview })
              }
            }}
          />
        )}
      </Box>
    )
  }, [jobRosterToReview, step])

  useEffect(() => {
    if (jobRosterToReviewIndex !== undefined && !jobRosterToReview) {
      setJobRosterToReview({ ...jobRosters[jobRosterToReviewIndex] })
    }
  }, [jobRosterToReview, jobRosterToReviewIndex, jobRosters])

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>PERFORMANCE REVIEW</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{question}</ModalBody>

        <ModalFooter justifyContent="space-between">
          <Button
            variant="ghost"
            mr={3}
            isDisabled={step === 1}
            onClick={() => {
              setRadioChecked(undefined)
              if (step > 1) setStep(step - 1)
            }}
          >
            Back
          </Button>
          <Button
            variant="adjustant"
            isDisabled={
              step <= ROSTER_DEPLOYMENT_SURVEY_QUESTIONS.length &&
              !radioChecked &&
              !jobRosterToReview?.review?.survey?.[
                ('q' + step) as keyof ReviewSurvey
              ]
            }
            onClick={() => {
              setRadioChecked(undefined)
              if (step <= ROSTER_DEPLOYMENT_SURVEY_QUESTIONS.length)
                setStep(step + 1)
              else onClose(jobRosterToReview)
            }}
          >
            {step <= ROSTER_DEPLOYMENT_SURVEY_QUESTIONS.length
              ? 'Next'
              : 'Finish'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ReviewRosterWorkflowModal
