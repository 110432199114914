import {
  Avatar,
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { IoMdSend } from 'react-icons/io'
import { ADJUSTANT_GREEN } from '../../themes/themes'
import { CHAT_STATUS, Chat } from '../../types/chatTypes'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { User, UserContextType, UserType } from '../../types/userTypes'
import { UserContext } from '../../contexts/UserContext'
import moment from 'moment'
import { formatDate } from '../../utils/functions'
import { Job } from '../../types/jobTypes'

const ChatBox = ({
  chat,
  receiver,
  job,
  onSendMessage,
  onClick,
}: {
  chat?: Chat
  job?: Job
  receiver?: User
  onSendMessage: (message: string, chat?: Chat) => void
  onClick: () => void
}) => {
  const { user: sender }: UserContextType = useContext(UserContext)
  const [message, setMessage] = useState('')

  const messagesBoxRef = useRef(null)
  const textareaRef = useRef(null)

  const dateFormat = useCallback((date: string): Intl.DateTimeFormatOptions => {
    const lastMessageDateString = date
    const lastMessageDate = new Date(lastMessageDateString ?? '')

    //today
    if (moment(lastMessageDate).isSame(new Date(), 'day')) {
      //   console.log('TODAY')
      return {
        hour: '2-digit',
        minute: '2-digit',
      }
    }

    //last week
    const daysDiff = moment(lastMessageDate).diff(new Date(), 'days')

    if (Math.abs(daysDiff) < 7) {
      //   console.log('LAST WEEK')
      return {
        weekday: 'long',
        hour: '2-digit',
        minute: '2-digit',
      }
    }

    return {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }
  }, [])

  const senderIsAdmin = useMemo(() => {
    return sender?.userType === UserType.ADMIN
  }, [sender?.userType])

  const receiverIsAdmin = useMemo(() => {
    return chat?.users.includes(UserType.ADMIN) && !senderIsAdmin
  }, [chat?.users, senderIsAdmin])

  const userFullName = useMemo(() => {
    return receiver?.userType === UserType.ADJUSTER
      ? `${receiver.firstName} ${receiver.lastName}`
      : receiverIsAdmin
      ? 'Adjustant Administrator'
      : receiver?.firmName
  }, [
    receiver?.firmName,
    receiver?.firstName,
    receiver?.lastName,
    receiver?.userType,
    receiverIsAdmin,
  ])

  return (
    <Flex direction="column" flex="1" onClick={onClick}>
      {/* Description */}
      <Flex borderBottomWidth="1px" px="4" py="4">
        <HStack spacing="4" flex="1">
          <Avatar
            name={userFullName}
            src={receiver?.profilePicture}
            key={receiver?._id}
          ></Avatar>
          <Stack>
            <Text fontWeight="medium" fontSize="xl">
              {userFullName}
            </Text>
            <Text fontSize="md">{job?.title}</Text>
          </Stack>
        </HStack>
      </Flex>

      {/* Messages */}
      <Box flex="1" overflow="auto" px="1" py="4" className="custom-scrollbar">
        <Box
          //   borderWidth="2px"
          //   borderStyle="dashed"
          px="3"
          height="full"
          overflow="auto"
          display="flex"
          flexDirection="column-reverse"
          ref={messagesBoxRef}
        >
          <Stack spacing={1}>
            {chat?.messages.map((message, index) => (
              <HStack
                key={index}
                background={
                  sender?._id === message.sender ||
                  (senderIsAdmin && message.sender === UserType.ADMIN)
                    ? ADJUSTANT_GREEN
                    : 'gray.500'
                }
                color="white"
                borderRadius="5px"
                padding="5px"
                // width="max-content"
                width="fit-content"
                maxW="90%"
                minHeight="45px"
                spacing="2px"
                alignSelf={
                  sender?._id === message.sender ||
                  (senderIsAdmin && message.sender === UserType.ADMIN)
                    ? 'end'
                    : ''
                }
              >
                <Box alignSelf="baseline" maxW="90%">
                  <Text whiteSpace="pre-line">{message.message}</Text>
                </Box>
                <Box alignSelf="end">
                  <Text fontSize="xs">
                    {formatDate(message.date, dateFormat(message.date))}
                  </Text>
                </Box>
              </HStack>
            ))}
          </Stack>
        </Box>
      </Box>

      {/* Input box */}
      {
        //   !receiverIsAdmin &&
        chat?.status !== CHAT_STATUS.DISABLED && (
          <Box bg="bg.subtle" py="4" px="5" borderTopWidth="1px">
            <InputGroup className="grow-wrap">
              {/* <Input
              placeholder="Send a message..."
              fontSize="sm"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            /> */}
              <Textarea
                ref={textareaRef}
                rows={1}
                height="auto!important"
                //   minHeight="5px"
                placeholder="Write a message"
                fontSize="sm"
                onInput={() => {
                  if (textareaRef.current) {
                    ;(
                      textareaRef.current as any
                    ).parentNode.dataset.replicatedValue = (
                      textareaRef.current as any
                    ).value
                  }
                }}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <InputRightElement>
                <IconButton
                  size="sm"
                  variant="ghost"
                  aria-label="Send message"
                  icon={
                    <Icon
                      as={IoMdSend}
                      fontSize="md!"
                      color={ADJUSTANT_GREEN}
                    />
                  }
                  onClick={() => {
                    onSendMessage(message, chat)
                    ;(
                      textareaRef.current as any
                    ).parentNode.dataset.replicatedValue = ''
                    setMessage('')
                  }}
                />
              </InputRightElement>
            </InputGroup>
          </Box>
        )
      }
    </Flex>
  )
}

export default ChatBox
