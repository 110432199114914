import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Box, Button, Heading, Spinner, Stack, VStack } from '@chakra-ui/react'
import UpdateFirmsLicensingAndCertificationRequirementsForm from '../../components/UpdateFirmsLicensingAndCertificationRequirementsForm/UpdateFirmsLicensingAndCertificationRequirementsForm'
import { SelectableItem } from '../../components/CustomDropdown/CustomDropdown'
import { TrainingCertificationsContext } from '../../contexts/TrainingCertificationsContext'
import { TrainingCertificationContextType } from '../../types/trainingCertificationsTypes'
import { useLazyQuery, useMutation } from '@apollo/client'
import { UserContext } from '../../contexts/UserContext'
import { UPDATE_USER_BY_EMAIL } from '../../graphql/Mutations'
import {
  CertificationRequirement,
  User,
  UserContextType,
  UserType,
} from '../../types/userTypes'
import {
  setItemToLocalStorage,
  LOCALSTORAGE_OBJECTS_NAMES,
} from '../../utils/localStorageFunctions'
import { showSuccessToast } from '../../components/Toast/Toast'
import { useParams, useNavigate } from 'react-router-dom'
import { GLOBAL_ROUTES, ADMIN_ROUTES } from '../../App'
import { GET_USER_BY_ID } from '../../graphql/Queries'
import { BiArrowBack } from 'react-icons/bi'

const UpdateFirmsLicensesAndCertificationsContent = ({
  user,
  editorIsAdmin,
}: {
  user?: User
  editorIsAdmin: boolean
}) => {
  const navigate = useNavigate()
  const { setUser }: UserContextType = useContext(UserContext)
  const [updateUserMutation] = useMutation(UPDATE_USER_BY_EMAIL(user?.email))

  const [showLoader, setShowLoader] = useState(false)
  const { trainingCertifications }: TrainingCertificationContextType =
    useContext(TrainingCertificationsContext)

  const [
    additionalCertificationRequirements,
    setAdditionalCertificationRequirements,
  ] = useState<CertificationRequirement[]>(
    user?.additionalCertificationRequirements ?? []
  )

  const trainingCertificationsMapped: SelectableItem[] = useMemo(() => {
    return (
      trainingCertifications
        ?.map((m) => ({
          value: m._id,
          label: m.name,
          disabled: !!additionalCertificationRequirements.find(
            (l) => l.id === m._id
          ),
        }))
        ?.sort((a, b) => a.label.localeCompare(b.label)) ?? []
    )
  }, [additionalCertificationRequirements, trainingCertifications])

  const disableAddCertificationButton = useMemo(() => {
    return (
      additionalCertificationRequirements.findIndex(
        (l) => (l.isListed && l.id === '') || (!l.isListed && l.name === '')
      ) >= 0
    )
  }, [additionalCertificationRequirements])

  const onUpdate = useCallback(async () => {
    if (user) {
      setShowLoader(true)

      const partialUser: Partial<User> = {
        additionalCertificationRequirements,
      }
      const result = await updateUserMutation({
        variables: { user: partialUser },
      })

      if (result?.data) {
        if (!editorIsAdmin) {
          setUser({ ...user, ...partialUser })
          setItemToLocalStorage(LOCALSTORAGE_OBJECTS_NAMES.USER, {
            ...user,
            ...partialUser,
          })
        }
        showSuccessToast('Licences & Certifications Updated!')
      }
      setShowLoader(false)
    }
  }, [
    user,
    additionalCertificationRequirements,
    updateUserMutation,
    editorIsAdmin,
    setUser,
  ])

  return (
    <Box
      paddingX={['10px', '50px']}
      paddingY="10px"
      display="flex"
      justifyContent="center"
    >
      <Box
        maxW={600}
        py={10}
        px={8}
        background="white"
        borderRadius={8}
        border="1px solid #ECEAE9"
        mb={2}
      >
        <Heading as="h4" size="md" color="gray.500" mb={2}>
          <Box mb={2}>
            <BiArrowBack
              cursor="pointer"
              onClick={() =>
                navigate(
                  `${editorIsAdmin ? GLOBAL_ROUTES.ADMIN : ''}${
                    GLOBAL_ROUTES.PROFILE
                  }${editorIsAdmin ? '/' + user?._id : ''}`
                )
              }
            />
          </Box>
          Update Certification Requirements
        </Heading>
        <UpdateFirmsLicensingAndCertificationRequirementsForm
          certifications={additionalCertificationRequirements}
          setCertifications={setAdditionalCertificationRequirements}
          disableAddCertificationButton={disableAddCertificationButton}
          trainingCertificationsMapped={trainingCertificationsMapped}
        />
        <VStack mt={6}>
          <Button
            variant="adjustant"
            isLoading={showLoader}
            isDisabled={disableAddCertificationButton}
            onClick={onUpdate}
          >
            Update
          </Button>
        </VStack>
      </Box>
    </Box>
  )
}

function UpdateFirmsLicensesAndCertifications() {
  const { userId } = useParams()
  const navigate = useNavigate()
  const { user }: UserContextType = useContext(UserContext)
  const [userToShow, setUserToShow] = useState<User | undefined>()
  const [loadingProfile, setLoadingProfile] = useState<boolean>(true)

  const [getUser] = useLazyQuery(GET_USER_BY_ID, {
    onCompleted: (data: { UserOne: User }) => {
      setLoadingProfile(false)
      if (data?.UserOne) setUserToShow(data?.UserOne)
    },
    // onError: (err) => {}
  })

  useEffect(() => {
    if (!userToShow && user) {
      console.log('userId: ', userId)
      if (!userId || userId === user?._id) {
        setUserToShow(user)
        setLoadingProfile(false)
      } else if (user.userType === UserType.ADMIN) {
        getUser({ variables: { id: userId } })
      } else {
        navigate(`${GLOBAL_ROUTES.ADMIN}${ADMIN_ROUTES.DASHBOARD}`)
      }
    }
  }, [userId, user, userToShow, setUserToShow, getUser, navigate])

  if (loadingProfile)
    return (
      <Box
        minHeight="100%"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Stack direction="row" spacing={4}>
          <Spinner size="xl" />
        </Stack>
      </Box>
    )
  return (
    <UpdateFirmsLicensesAndCertificationsContent
      user={userToShow}
      editorIsAdmin={user?.userType === UserType.ADMIN}
    />
  )
}

export default UpdateFirmsLicensesAndCertifications
