import React, { useCallback, useState } from 'react'
import { MdOutlineNavigateNext } from 'react-icons/md'
import { HStack, Button, Box } from '@chakra-ui/react'
import CompleteProfileHeader from './CompleteProfileHeader'
import { DamageType, jobTypeOptions } from '../../types/jobTypes'
import CustomDropdownMultiple from '../CustomDropdownMultiple/CustomDropdownMultiple'
import {
  US_STATES,
  US_STATES_ABBREVIATIONS,
} from '../../types/stateLicensesTypes'
import { SelectableItem } from '../CustomDropdown/CustomDropdown'

function CompleteProfileSetJobPreferences({
  showLoader,
  next,
  jobLocations: oldJobLocations,
  jobTypes: oldJobTypes,
}: {
  showLoader: boolean
  next: (jobLocation: US_STATES_ABBREVIATIONS[], jobTypes: DamageType[]) => void
  jobLocations?: US_STATES_ABBREVIATIONS[]
  jobTypes?: DamageType[]
}) {
  const statesOptions: SelectableItem[] = US_STATES.map((s) => {
    return { value: s.abbreviation, label: s.name }
  })

  const [jobLocations, setJobLocations] = useState<US_STATES_ABBREVIATIONS[]>(
    oldJobLocations ?? []
  )
  const [jobTypes, setJobTypes] = useState<DamageType[]>(oldJobTypes ?? [])

  const nextStep = useCallback(() => {
    next(jobLocations, jobTypes)
  }, [next, jobLocations, jobTypes])

  return (
    <Box width="400px">
      <CompleteProfileHeader text="Job Preferences" />
      <Box mb={4}>
        {/* <AddressInput
          isRequired
          label="Job Location (Optional)"
          placeholder="e.g. 718 S. Main St."
          address={jobLocations}
          onAddressChange={setJobLocations}
          onSelectOption={setJobLocations}
          handleAddressClear={() => {
            setJobLocations('')
          }}
        /> */}
      </Box>

      <CustomDropdownMultiple ///////////////////////////////// COLOCAR SELECT ALL
        label="Job Locations (Optional)"
        value={
          jobLocations?.map((jl) => {
            const label =
              statesOptions.find((sp) => sp.value === jl)?.label ?? ''
            return {
              label,
              value: jl,
            }
          }) ?? []
        }
        options={statesOptions}
        isClearable
        onChange={(a) => setJobLocations(a as US_STATES_ABBREVIATIONS[])}
      />

      <Box>
        <CustomDropdownMultiple
          label="Job Types (Optional)"
          value={
            jobTypes?.map((jt) => {
              const label =
                jobTypeOptions.find((jtp) => jtp.value === jt)?.label ?? ''
              return {
                label,
                value: jt,
              }
            }) ?? []
          }
          options={jobTypeOptions}
          isClearable
          onChange={(a) => setJobTypes(a as DamageType[])}
        />
      </Box>
      <HStack justify="end" mt={8}>
        <Button
          variant="adjustant"
          rightIcon={<MdOutlineNavigateNext size="20px" />}
          isLoading={showLoader}
          onClick={nextStep}
        >
          Next
        </Button>
      </HStack>
    </Box>
  )
}

export default CompleteProfileSetJobPreferences
