import {
  Box,
  Button,
  Flex,
  HStack,
  Link,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { AiOutlineClose, AiOutlineCloudUpload } from 'react-icons/ai'
import FileInput from '../FileInput/FileInput'
import Label from '../Label/Label'
import { useCallback } from 'react'
import { encodeS3URI } from '../../utils/functions'

const UpdateMiscellaneosDocument = ({
  editable,
  url,
  setURL,
  setFile,
  fileName,
  setFileName,
  description,
  setDescription,
  align = 'center',
}: {
  editable: boolean
  url: string
  setURL?: React.Dispatch<React.SetStateAction<string>>
  setFile?: React.Dispatch<React.SetStateAction<any>>
  fileName: string
  setFileName?: React.Dispatch<React.SetStateAction<string>>
  description: string
  setDescription?: React.Dispatch<React.SetStateAction<string>>
  align?: 'start' | 'center'
}) => {
  const onFileInputChange = useCallback(
    async (e: any) => {
      const fileFromFiles = e.target.files[0]
      if (fileFromFiles) {
        if (setFile) setFile(fileFromFiles)
        if (setFileName) setFileName(fileFromFiles.name)
        const url = URL.createObjectURL(fileFromFiles)
        if (setURL) setURL(url)
      }
    },
    [setFile, setFileName, setURL]
  )
  const deleteFile = useCallback(() => {
    if (setFile) setFile(null)
    if (setFileName) setFileName('')
    if (setURL) setURL('')
  }, [setFile, setFileName, setURL])

  return (
    <Box>
      <Stack textAlign={align}>
        <HStack textAlign={align} justifyContent={align} width="100%">
          <Label fontSize="sm" color="gray.700">
            {editable ? 'Upload your Miscellaneous Document' : 'Document'}
          </Label>
        </HStack>
        {url || !editable ? (
          <HStack textAlign={align} justifyContent={align}>
            <Link color="teal.500" href={encodeS3URI(url)} isExternal>
              {fileName || '-'}
            </Link>
            {editable && (
              <AiOutlineClose cursor="pointer" onClick={deleteFile} />
            )}
          </HStack>
        ) : (
          <FileInput
            element={
              <Button variant="adjustant" rightIcon={<AiOutlineCloudUpload />}>
                Upload
              </Button>
            }
            accept=".pdf,.png,.jpeg,.jpg"
            onFileInputChange={onFileInputChange}
            clearFile={() => {
              if (setFile) setFile(undefined)
            }}
          />
        )}

        <Box
        //   py={10}
        //   px={8}
        //   background="white"
        //   borderRadius={8}
        //   border="1px solid #ECEAE9"
        //   mb={2}
        >
          <Flex mb={2}>
            <Label fontSize="sm" color="gray.700">
              Description
            </Label>
          </Flex>
          {editable ? (
            <Textarea
              placeholder=""
              onChange={(e) => {
                if (setDescription) setDescription(e.target.value)
              }}
              value={description}
              //   maxLength={TEXTAREA_MAX_LENGTH}
              style={{ resize: 'none' }}
            />
          ) : (
            <Text>{description}</Text>
          )}
        </Box>
      </Stack>
    </Box>
  )
}

export default UpdateMiscellaneosDocument
