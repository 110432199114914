import {
  Box,
  Spinner,
  Stack,
  TabPanels,
  VStack,
  Text,
  Tab,
  TabList,
  Tabs,
  HStack,
} from '@chakra-ui/react'
import { Application, Job } from '../../../types/jobTypes'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import useWindowSize from '../../../hooks/useWindowSize'
import JobSummaryCard from '../../../components/JobSummaryCard/JobSummaryCard'
import { useLazyQuery } from '@apollo/client'
import {
  GET_APPLICATIONS,
  GET_ALL_ADJUSTER_JOBS,
  GET_ADMINS,
} from '../../../graphql/Queries'
import { User, UserContextType } from '../../../types/userTypes'
import { UserContext } from '../../../contexts/UserContext'
import { useParams } from 'react-router-dom'
import AdjusterJobTabs from '../../../components/AdjusterJobTabs/AdjusterJobTabs'
import { BiArrowBack } from 'react-icons/bi'

const AdjusterJobs = () => {
  const windowsSize = useWindowSize()
  const { jobId } = useParams()
  const { user }: UserContextType = useContext(UserContext)

  const [selectedTab, setSelectedTab] = useState<number>(0)
  const [showJobListLoader, setShowJobListLoader] = useState<boolean>(false)

  const [admins, setAdmins] = useState<User[]>([])
  const [applications, setApplications] = useState<Application[]>([])
  const [allOngoingJobs, setAllOngoingJobs] = useState<Job[]>([])
  const [adjusterJobs, setAdjusterJobs] = useState<Job[]>([])
  const [jobsRetrieved, setJobsRetrieved] = useState<boolean>(false)
  const [selectedJob, setSelectedJob] = useState<Job | undefined>()

  const [getAdjusterJobsQuery] = useLazyQuery(GET_ALL_ADJUSTER_JOBS)
  const [getOngoingJobsQuery] = useLazyQuery(GET_ALL_ADJUSTER_JOBS)

  const jobsToShow = useMemo(() => {
    return selectedTab === 0 ? allOngoingJobs : adjusterJobs
  }, [selectedTab, allOngoingJobs, adjusterJobs])

  const onJobSummaryCardClick = useCallback((job: Job) => {
    setSelectedJob(job)
  }, [])

  const autoSelectJob = useCallback(
    (job: Job) => {
      console.log(jobId)
      if (job) {
        onJobSummaryCardClick(job)
        setSelectedTab(1)
      }
    },
    [jobId, onJobSummaryCardClick]
  )

  const getJobs = useCallback(async () => {
    setShowJobListLoader(true)

    // GET ALL ADJUSTER JOBS
    const adjusterJobs = await getAdjusterJobsQuery({
      variables: { adjusterID: user?._id },
      fetchPolicy: 'network-only',
    })
    const aj: Job[] = adjusterJobs?.data?.AdjusterJobs
    if (aj) setAdjusterJobs(aj)

    // GET ALL ONGOING JOBS
    const jobsIds = aj ? aj?.map((j) => j._id) : []
    // It returns all the jobs that are not within jobsIds
    const allJobs = await getOngoingJobsQuery({
      variables: { jobsIds, onlyApplicableJobs: true },
      fetchPolicy: 'network-only',
    })
    if (allJobs?.data?.AdjusterJobs)
      setAllOngoingJobs(allJobs?.data?.AdjusterJobs)

    if (jobId) {
      const j = aj.find((j) => j._id === jobId)
      if (j) autoSelectJob(j)
    }

    setShowJobListLoader(false)
  }, [
    autoSelectJob,
    getAdjusterJobsQuery,
    getOngoingJobsQuery,
    jobId,
    user?._id,
  ])

  const [getApplications] = useLazyQuery(GET_APPLICATIONS, {
    onCompleted: (data: { ApplicationMany: Application[] }) => {
      if (data?.ApplicationMany) setApplications(data?.ApplicationMany)
    },
    onError: (err) => {},
  })

  const [getAdmins] = useLazyQuery(GET_ADMINS, {
    onCompleted: (data: { UserMany: User[] }) => {
      if (data?.UserMany) {
        const newUsers = [...data?.UserMany]
        setAdmins(newUsers)
      }
    },
    onError: (err) => {
      console.log(err)
    },
  })

  const userIsHired = useMemo(() => {
    return !!adjusterJobs.find((aj) => aj._id === selectedJob?._id)
  }, [adjusterJobs, selectedJob?._id])

  useEffect(() => {
    if (!jobsRetrieved) {
      setJobsRetrieved(true)
      getJobs()
      getApplications({
        fetchPolicy: 'network-only',
      })
      getAdmins()
    }
  }, [getAdmins, getApplications, getJobs, jobsRetrieved])

  const screenWidthIsAtListMedium = useMemo(() => {
    return windowsSize.width >= 768
  }, [windowsSize.width])

  const jobsListDisplay = useMemo(() => {
    return !screenWidthIsAtListMedium && selectedJob ? 'none' : 'block'
  }, [screenWidthIsAtListMedium, selectedJob])

  const jobsListWidth = useMemo(() => {
    return screenWidthIsAtListMedium ? '25%' : !selectedJob ? '100%' : '0'
  }, [screenWidthIsAtListMedium, selectedJob])

  const selectedJobSectionWidth = useMemo(() => {
    return screenWidthIsAtListMedium ? '75%' : selectedJob ? '100%' : '0'
  }, [screenWidthIsAtListMedium, selectedJob])

  return (
    <Box h="100%" width="100%" display="flex">
      {/* Jobs lists (sidebar) */}
      <Box
        h="100%"
        display={jobsListDisplay}
        width={jobsListWidth}
        borderRight="1px solid #e3e2e2"
      >
        <Tabs
          variant="adjustant"
          h="100%"
          width="100%"
          index={selectedTab}
          onChange={(index) => setSelectedTab(index)}
        >
          <TabList background="white" width="100%">
            <Tab fontWeight={500} width="50%" py={2} mx={0}>
              All Jobs
            </Tab>
            <Tab fontWeight={500} width="50%" py={2} mx={0}>
              My Jobs
            </Tab>
          </TabList>

          {!showJobListLoader && jobsToShow.length > 0 && (
            <TabPanels p={2} maxH="calc(100% - 52px)" overflowY="auto">
              {jobsToShow?.map((job) => (
                <Box key={job._id} mb={1}>
                  <JobSummaryCard
                    job={job}
                    onClick={() => onJobSummaryCardClick(job)}
                  />
                </Box>
              ))}
            </TabPanels>
          )}

          <VStack px={2} gap={0}>
            {showJobListLoader && (
              <Stack direction="row" my={4}>
                <Spinner size="xl" />
              </Stack>
            )}

            {!showJobListLoader && jobsToShow.length === 0 && (
              <Text my={8}>No jobs to show</Text>
            )}
          </VStack>
        </Tabs>
      </Box>

      {/* Job details and roster */}
      <Box
        h="100%"
        overflowY="auto"
        width={selectedJobSectionWidth}
        backgroundColor="white"
      >
        {/* Job Details */}
        {selectedJob && user && (
          <AdjusterJobTabs
            userIsHired={userIsHired}
            applications={applications}
            setApplications={setApplications}
            admins={admins}
            backButton={
              <>
                {!screenWidthIsAtListMedium && (
                  <HStack justifyContent="space-between" mb={4}>
                    <Box
                      fontSize={30}
                      cursor="pointer"
                      onClick={() => setSelectedJob(undefined)}
                    >
                      <BiArrowBack />
                    </Box>
                  </HStack>
                )}
              </>
            }
            job={selectedJob}
            user={user}
          />
        )}
      </Box>
    </Box>
  )
}

export default AdjusterJobs
