import {
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useDisclosure,
  Link,
  Button,
  HStack,
  Spinner,
  Input,
  Text,
  Code,
} from '@chakra-ui/react'
import CustomTable from '../../../components/CustomTable/CustomTable'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { UserContext } from '../../../contexts/UserContext'
import {
  GET_ALL_FIRM_JOBS,
  GET_FIRM_CHECKR_CANDIDATES,
  GET_HIRED_ADJUSTERS,
  GET_ROSTERS_BY_FILTER,
  GET_SIGN_NOW_FIRM_FOLDERS,
  GET_SIGN_NOW_SIGNING_INVITATIONS,
  GET_SIGN_NOW_TEMPLATES,
} from '../../../graphql/Queries'
import { User, UserContextType } from '../../../types/userTypes'
import {
  SIGN_NOW_SIGNING_STATUS,
  SignNowFirmFolders,
  SignNowSigningInvitation,
  SignNowTemplate,
} from '../../../types/signNowTypes'
import UploadDocumentModal from './UploadDocumentModal'
import { createColumnHelper } from '@tanstack/react-table'
import { ADJUSTANT_GREEN } from '../../../themes/themes'
import { FaFileDownload, FaFileSignature } from 'react-icons/fa'
import SelectDocumentModal from './SelectDocumentModal'
import {
  createSigningInvite,
  getCheckrAccountInfo,
  sendUserEmailNotification,
} from '../../../services/adjustant'
import {
  CREATE_CHECKR_CANDIDATE,
  CREATE_NOTIFICATION,
  CREATE_SIGN_NOW_SIGNING_INVITATION,
  UPDATE_SIGN_NOW_SIGNING_INVITATION,
  UPDATE_SIGN_NOW_TEMPLATE,
  UPDATE_USER_BY_ID,
} from '../../../graphql/Mutations'
import { ADJUSTER_ROUTES, FIRM_ROUTES } from '../../../App'
import {
  showErrorToast,
  showSuccessToast,
} from '../../../components/Toast/Toast'
import SelectAdjustersModal from './SelectAdjustersModal'
import { useNavigate, useSearchParams } from 'react-router-dom'
// @ts-ignore
import { Embeds } from '@checkr/web-sdk'
import AdjusterHRDocumentsListModal from './AdjusterHRDocumentsListModal'
import CheckrLogo from '../../../assets/images/CheckrLogo'
import { Job, JobStatus, Roster } from '../../../types/jobTypes'
import CustomDropdown from '../../../components/CustomDropdown/CustomDropdown'
import { CheckrCandidate, CheckrUserStatus } from '../../../types/checkrTypes'
import { requestSignatureEmailTemplate } from '../../../assets/emailTemplates/requestSignatureEmailTemplate'
import { BsFillTrashFill } from 'react-icons/bs'
import CheckrModal from './CheckrModal'
import {
  setItemToLocalStorage,
  LOCALSTORAGE_OBJECTS_NAMES,
} from '../../../utils/localStorageFunctions'

export const filterAndSortAdjusters = (adjusters: User[], search: string) =>
  adjusters
    .filter((adjuster) => {
      const searchValue = search.toLowerCase()
      return (
        adjuster.firstName.toLowerCase().includes(searchValue) ||
        adjuster.lastName.toLowerCase().includes(searchValue) ||
        adjuster.email.toLowerCase().includes(searchValue)
      )
    })
    .sort((a, b) => a.firstName.localeCompare(b.firstName))

const DocumentCenter = () => {
  const templatesColumnHelper = createColumnHelper<SignNowTemplate>()
  const hiredAdjustersColumnHelper = createColumnHelper<User>()
  const { user, setUser }: UserContextType = useContext(UserContext)
  const navigate = useNavigate()
  const params = useSearchParams()
  const [queryParams, setQueryParams] = useState<
    { param: string; value: string }[]
  >([])

  const {
    isOpen: isUploadDocumentModalOpen,
    onOpen: onUploadDocumentModalOpen,
    onClose: onUploadDocumentModalClose,
  } = useDisclosure()
  const {
    isOpen: isSelectDocumentModalOpen,
    onOpen: onSelectDocumentModalOpen,
    onClose: onSelectDocumentModalClose,
  } = useDisclosure()
  const {
    isOpen: isSelectAdjustersModalOpen,
    onOpen: onSelectAdjustersModalOpen,
    onClose: onSelectAdjustersModalClose,
  } = useDisclosure()
  const {
    isOpen: isAdjusterHRDocumentsListModalOpen,
    onOpen: onAdjusterHRDocumentsListModalOpen,
    onClose: onAdjusterHRDocumentsListModalClose,
  } = useDisclosure()
  const {
    isOpen: isCheckrModalOpen,
    onOpen: onCheckrModalOpen,
    onClose: onCheckrModalClose,
  } = useDisclosure()

  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [selectedTab, setSelectedTab] = useState<number>(0)

  const [dataRetrieved, setDataRetrieved] = useState<boolean>(false)
  const [signNowFirmFolders, setSignNowFirmFolders] = useState<
    SignNowFirmFolders | undefined
  >()
  const [signNowTemplates, setSignNowTemplates] = useState<SignNowTemplate[]>(
    []
  )

  const filteredSignowTemplates = useMemo(
    () => signNowTemplates.filter((t) => !t.isDeleted),
    [signNowTemplates]
  )

  const [jobs, setJobs] = useState<Job[]>([])
  const [selectedJobId, setSelectedJobId] = useState<string | undefined>()
  const [rosters, setRosters] = useState<Roster[]>([])
  const [hiredAdjusters, setHiredAdjusters] = useState<User[]>([])
  const [hiredAdjustersByJob, setHiredAdjustersByJob] = useState<User[]>([])
  const [filteredHiredAdjusters, setFilteredHiredAdjusters] = useState<User[]>(
    []
  )
  const [adjustersFilter, setAdjustersFilter] = useState('')
  const [signNowSigningInvitations, setSignNowSigningInvitations] = useState<
    SignNowSigningInvitation[]
  >([])

  const [
    selectedHiredAdjusterForHRDocumentsList,
    setSelectedHiredAdjusterForHRDocumentsList,
  ] = useState<User | undefined>()
  const [checkrCandidates, setCheckrCandidates] = useState<CheckrCandidate[]>(
    []
  )

  //
  const [selectedSignNowTemplate, setSelectedSignNowTemplate] = useState<
    SignNowTemplate | undefined
  >()
  const [selectedHiredAdjuster, setSelectedHiredAdjuster] = useState<
    User | undefined
  >()

  const [checkrUserStatus, setCheckrUserStatus] = useState<CheckrUserStatus>({
    authorized: false,
    approvedForHierarchy: false,
    hasAccessToken: false,
  })

  const [
    selectedHiredAdjusterCheckrCandidate,
    setSelectedHiredAdjusterCheckrCandidate,
  ] = useState<CheckrCandidate | undefined>()

  const [getSignNowFirmFolders] = useLazyQuery(GET_SIGN_NOW_FIRM_FOLDERS)
  const [getSignNowTemplates] = useLazyQuery(GET_SIGN_NOW_TEMPLATES)
  const [getJobs] = useLazyQuery(GET_ALL_FIRM_JOBS)
  const [getRosters] = useLazyQuery(GET_ROSTERS_BY_FILTER)
  const [getHiredAdjusters] = useLazyQuery(GET_HIRED_ADJUSTERS)
  const [getSignNowSigningInvitations] = useLazyQuery(
    GET_SIGN_NOW_SIGNING_INVITATIONS
  )
  const [createSignNowSigningInvitation] = useMutation(
    CREATE_SIGN_NOW_SIGNING_INVITATION
  )
  const [createCheckrCandidate] = useMutation(CREATE_CHECKR_CANDIDATE)

  const [getCheckrCandidates] = useLazyQuery(GET_FIRM_CHECKR_CANDIDATES)
  const [createNotificationMutation] = useMutation(CREATE_NOTIFICATION)
  const [updateUser] = useMutation(UPDATE_USER_BY_ID)
  const [updateSignNowTemplateMutation] = useMutation(UPDATE_SIGN_NOW_TEMPLATE)

  const retrieveData = useCallback(async () => {
    setShowLoader(true)
    let result = await getSignNowFirmFolders({
      variables: { firmId: user?._id },
    })

    let r = result?.data?.SignNowFirmFoldersOne
    if (r) setSignNowFirmFolders(r)

    result = await getSignNowTemplates({
      variables: { firmId: user?._id },
    })

    r = result?.data?.SignNowTemplateMany
    if (r) setSignNowTemplates(r)

    result = await getJobs({
      variables: {
        firmId: user?._id,
        statuses: [JobStatus.ONGOING],
      },
      fetchPolicy: 'network-only',
    })

    //Get jobs and rosters
    if (result?.data?.GetJobMany) {
      const firmJobs = [...(result?.data?.GetJobMany as Job[])].sort((a, b) =>
        a.title.localeCompare(b.title)
      )
      setJobs([...firmJobs])

      if (firmJobs.length > 0) {
        result = await getRosters({
          variables: {
            jobsIds: firmJobs.map((u) => u._id),
          },
        })
        if (result?.data?.RostersByFilter) {
          setRosters(result?.data?.RostersByFilter)
        }
      }
    }

    result = await getHiredAdjusters({
      variables: {
        firmId: user?._id,
      },
      fetchPolicy: 'network-only',
    })

    if (result?.data?.HiredAdjusters) {
      const a = [...result?.data?.HiredAdjusters].sort((a, b) =>
        a.firstName.localeCompare(b.firstName)
      )

      setHiredAdjusters(a)
      //   setFilteredHiredAdjusters(a)
    }

    result = await getSignNowSigningInvitations({
      variables: {
        firmId: user?._id,
      },
      fetchPolicy: 'network-only',
    })

    if (result?.data?.SignNowSigningInvitationMany)
      setSignNowSigningInvitations([
        ...result?.data?.SignNowSigningInvitationMany,
      ])

    result = await getCheckrCandidates({
      variables: {
        firmId: user?._id,
      },
      fetchPolicy: 'network-only',
    })

    if (result?.data?.CheckrCandidateMany)
      setCheckrCandidates([...result?.data?.CheckrCandidateMany])

    const at = await getCheckrAccountInfo({
      code: '',
      firmId: user?._id ?? '',
      isNew: false,
    })
    const data = at.data

    // console.log({ data })
    setCheckrUserStatus({
      authorized: !!data?.accountData?.authorized,
      approvedForHierarchy: !!data?.hierarchyData?.approvedForHierarchy,
      hasAccessToken: !!data?.hasAccessToken,
    })

    setShowLoader(false)
  }, [
    getCheckrCandidates,
    getHiredAdjusters,
    getJobs,
    getRosters,
    getSignNowFirmFolders,
    getSignNowSigningInvitations,
    getSignNowTemplates,
    user?._id,
  ])

  const requestAdjustersToSign = useCallback(
    async (selectedHiredAdjusters: User[], document: SignNowTemplate) => {
      setShowLoader(true)
      const result = await createSigningInvite({
        templateId: document.templateId,
        documentName: document.templateName,
        documentsFolderId: signNowFirmFolders?.documentsFolderId ?? '',
        adjusters: selectedHiredAdjusters,
      })

      if (result.success) {
        const data = result.data as {
          inviteId: string
          adjusterId: string
          documentId: string
        }[]

        const newSignnowSigningInvitations = data.map((s) => ({
          firmId: user?._id,
          jobId: selectedJobId ?? '',
          status: SIGN_NOW_SIGNING_STATUS.PENDING,
          templateId: document.templateId,
          ...s,
        }))

        const destinationEmails: string[] = []
        const notificationSubject = `${user?.firmName} has requested a signature for "${document.templateName}". Go to the Sign Now section within the document center to sign the document.`

        const pr = await newSignnowSigningInvitations.map(
          async (signNowSigningInvitation) => {
            const mutationResult = await createSignNowSigningInvitation({
              variables: {
                signNowSigningInvitation,
              },
            })

            if (mutationResult?.data?.SignNowSigningInvitationCreateOne)
              setSignNowSigningInvitations((prev) => [
                ...prev,
                mutationResult?.data?.SignNowSigningInvitationCreateOne?.record,
              ])

            await createNotificationMutation({
              variables: {
                notification: {
                  userId: signNowSigningInvitation.adjusterId,
                  message: notificationSubject,
                  link: `${ADJUSTER_ROUTES.DOCUMENT_CENTER}`,
                  read: false,
                },
              },
            })

            const adjuster = hiredAdjusters.find(
              (ha) => ha._id === signNowSigningInvitation.adjusterId
            )
            if (adjuster) destinationEmails.push(adjuster.email)
          }
        )

        await Promise.all(pr)

        console.log({ newSignnowSigningInvitations })
        console.log({ hiredAdjusters })
        console.log({ destinationEmails })

        const notificationHtml = requestSignatureEmailTemplate
          .replace('SUBJECT', notificationSubject)
          .replace(
            'SRCREPLY',
            `${process.env.REACT_APP_APP_URL}${ADJUSTER_ROUTES.DOCUMENT_CENTER}`
          )

        sendUserEmailNotification({
          notificationHtml,
          notificationSubject,
          destinationEmails,
          //   destinationEmails: ['lilcore60+32@gmail.com'],
        })
      }

      showSuccessToast('Request Sent Succesfully!')
      setShowLoader(false)
    },
    [
      createNotificationMutation,
      createSignNowSigningInvitation,
      hiredAdjusters,
      selectedJobId,
      signNowFirmFolders?.documentsFolderId,
      user?._id,
      user?.firmName,
    ]
  )

  const checkAuthenticatedUser = useCallback(async () => {
    setSelectedTab(1)
    const state = queryParams.find((qp) => qp.param === 'state')?.value
    const stateParts = state?.split('-') ?? []
    // const adjusterId = stateParts[0]
    const jobId = stateParts[1]
    if (jobId) {
      setSelectedJobId(jobId)
      const r = rosters.filter((r) => r.jobId === jobId)
      const adjustersIds = r.map((r) => r.adjusterId)
      const adjusters = hiredAdjusters.filter((ha) =>
        adjustersIds.includes(ha._id ?? '')
      )

      setHiredAdjustersByJob(adjusters)
      setFilteredHiredAdjusters(adjusters)
    }

    const code = queryParams.find((qp) => qp.param === 'code')?.value ?? ''
    setQueryParams([])

    if (code) {
      await updateUser({
        variables: {
          user: { hasCheckrAccessToken: true },
          id: user?._id,
        },
      })
      if (user) {
        setUser({ ...user, hasCheckrAccessToken: true })
        setItemToLocalStorage(LOCALSTORAGE_OBJECTS_NAMES.USER, {
          ...user,
          hasCheckrAccessToken: true,
        })
      }

      //In this call we get for the first time the access token and store in in the db
      const at = await getCheckrAccountInfo({
        code,
        firmId: user?._id ?? '',
        isNew: true,
      })
      const data = at.data
      console.log(at)

      setCheckrUserStatus({
        authorized: !!data?.accountData?.authorized,
        approvedForHierarchy: !!data?.hierarchyData?.approvedForHierarchy,
        hasAccessToken: !!data?.hasAccessToken,
      })
      if (
        at.success &&
        data?.accountData?.authorized &&
        data?.hierarchyData?.approvedForHierarchy
      ) {
        showSuccessToast(
          `Connected and approved to run background checks.`,
          4000
        )
      } else {
        showSuccessToast(
          data?.accountData?.authorized &&
            !data?.hierarchyData?.approvedForHierarchy
            ? `You are connected but not approved for hierarchy, contact Checkr's Customer Support.`
            : 'Your account will be approved by Checkr within the next few days.',
          4000
        )
      }
    }
  }, [hiredAdjusters, queryParams, rosters, setUser, updateUser, user])

  const checkrInviteCandidate = useCallback(async () => {
    if (!!selectedHiredAdjuster) {
      const adjusterId = selectedHiredAdjuster._id ?? ''

      const embed = new Embeds.NewInvitation({
        sessionTokenPath: `${process.env.REACT_APP_API_URL}/get-checkr-session-token`,
        env: process.env.REACT_APP_APP_URL?.includes('jobs') ////////////////IMPROVE THIS
          ? 'prod'
          : 'staging', /////////////////////////////////////////////FOR TESTING ONLY///////////////////////////////////////////////////
        //   fakeMode: true,
        sessionTokenRequestHeaders: () => ({
          code: '',
          firmId: user?._id ?? '',
          'Content-Type': 'application/json',
          'X-Api-Key': process.env.REACT_APP_X_API_KEY ?? '',
        }),

        externalCandidateId: selectedHiredAdjuster._id,
        // defaultEmail: selectedHiredAdjuster?.email,
        defaultPhone: selectedHiredAdjuster?.phoneNumber ?? '',

        presetEmail: selectedHiredAdjuster?.email,
        // presetPhone: selectedHiredAdjuster?.phoneNumber,
        // presetNodeCustomId: '1000002',
        // presetPackageSlug: 'criminal_drug',
        presetWorkLocation: { country: 'US', state: '', city: '' },

        onInvitationSuccess: async (response: any) => {
          console.log(response)
          showSuccessToast(response.custom_external_status.message)
          const candidateId = response.candidate_id as string
          const plan = response.package.slug as string

          const checkrCandidate: CheckrCandidate = {
            firmId: user?._id ?? '',
            jobId: selectedJobId ?? '',
            adjusterId,
            candidateId,
            plan,
            checkrBackgroundCheckStatuses: [
              {
                status: 'invitation.created',
                date: new Date().toISOString(),
              },
            ],
          }
          const mutationResult = await createCheckrCandidate({
            variables: {
              checkrCandidate,
            },
          })

          if (mutationResult?.data?.CheckrCandidateCreateOne) {
            setCheckrCandidates([
              ...checkrCandidates,
              mutationResult?.data?.CheckrCandidateCreateOne?.record,
            ])
          }

          // setHiredAdjusters([...hiredAdjusters])
          //     setHiredAdjustersByJob([...hiredAdjustersByJob])
          //     setFilteredHiredAdjusters([...hiredAdjustersByJob])

          const result = await createNotificationMutation({
            variables: {
              notification: {
                userId: adjusterId,
                message: `${user?.firmName} has requested a Checkr background check.`,
                link: `${ADJUSTER_ROUTES.DOCUMENT_CENTER}`,
                read: false,
              },
            },
          })

          if (result?.data?.NotificationCreateOne) {
            showSuccessToast('Checkr background check request sent.')
          }
        },
        onInvitationError: (response: any) => {
          console.log(response)
          showErrorToast(response.custom_external_status.message)
        },
      })
      embed.modal()

      ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //   const hiredAdjusterCheckrCandidate = checkrCandidates.find(
      //     (cc) => cc.adjusterId === hiredAdjuster?._id && cc.jobId === jobId
      //   )
      //   //   console.log(checkrCandidates)
      //   //   console.log(hiredAdjusters)
      //   //   console.log(jobId)
      //   //   console.log(hiredAdjusterCheckrCandidate)
      //   //   console.log({
      //   //     candidateId: hiredAdjusterCheckrCandidate?.candidateId,
      //   //   })
      //   const embed = new Embeds.ReportsOverview({
      //     sessionTokenPath: `${process.env.REACT_APP_API_URL}/get-checkr-session-token`,
      //     env: process.env.REACT_APP_APP_URL?.includes('jobs') ////////////////IMPROVE THIS
      //       ? 'prod'
      //       : 'staging', /////////////////////////////////////////////FOR TESTING ONLY///////////////////////////////////////////////////
      //     //   fakeMode: true,
      //     sessionTokenRequestHeaders: () => ({
      //       code, // It can be used only once and expires 5 minutes after creation.
      //       'Content-Type': 'application/json',
      //       'X-Api-Key': process.env.REACT_APP_X_API_KEY ?? '',
      //     }),
      //     candidateId: hiredAdjusterCheckrCandidate?.candidateId,
      //     // externalCandidateId: ,
      //   })
      //   embed.modal()
      ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    }
  }, [
    checkrCandidates,
    createCheckrCandidate,
    createNotificationMutation,
    selectedHiredAdjuster,
    selectedJobId,
    user?._id,
    user?.firmName,
  ])

  const checkrShowReportsOverview = useCallback(async () => {
    if (!!selectedHiredAdjuster) {
      const hiredAdjusterCheckrCandidate = checkrCandidates.find(
        (cc) =>
          cc.adjusterId === selectedHiredAdjuster?._id &&
          cc.jobId === selectedJobId
      )

      console.log(hiredAdjusterCheckrCandidate)
      const embed = new Embeds.ReportsOverview({
        sessionTokenPath: `${process.env.REACT_APP_API_URL}/get-checkr-session-token`,
        env: process.env.REACT_APP_APP_URL?.includes('jobs') ////////////////IMPROVE THIS
          ? 'prod'
          : 'staging', /////////////////////////////////////////////FOR TESTING ONLY///////////////////////////////////////////////////
        //   fakeMode: true,
        sessionTokenRequestHeaders: () => ({
          code: '', // It can be used only once and expires 5 minutes after creation.
          firmId: user?._id ?? '',
          'Content-Type': 'application/json',
          'X-Api-Key': process.env.REACT_APP_X_API_KEY ?? '',
        }),
        candidateId: hiredAdjusterCheckrCandidate?.candidateId,
        // externalCandidateId: ,
      })
      embed.modal()
    }
  }, [checkrCandidates, selectedHiredAdjuster, selectedJobId, user?._id])

  const hideTemplate = useCallback(
    async (templateId: string) => {
      const mutationResult = await updateSignNowTemplateMutation({
        variables: {
          template: { isDeleted: true },
          id: templateId,
        },
      })
      if (mutationResult?.data) {
        const index = signNowTemplates.findIndex((s) => s._id === templateId)
        if (index >= 0) {
          const updatedTemplate = {
            ...signNowTemplates[index],
            isDeleted: true,
          }
          const updatedTemplates = [...signNowTemplates]
          updatedTemplates[index] = updatedTemplate
          setSignNowTemplates(updatedTemplates)
        }

        showSuccessToast('Document deleted successfully!')
      } else {
        //   showErrorToast('There was an error delete the document')
      }
    },
    [signNowTemplates, updateSignNowTemplateMutation]
  )

  useEffect(() => {
    if (!dataRetrieved) {
      setDataRetrieved(true)
      retrieveData()
    }
  }, [dataRetrieved, retrieveData])

  useEffect(() => {
    if (params[0].size !== 0) {
      let qp = (Array.from(new Set(params[0].entries())) as string[][]).map(
        (a) => ({
          param: a[0],
          value: a[1],
        })
      )
      if (qp.length > 0) {
        setQueryParams(qp)
        console.log(window.location.href)
        navigate(FIRM_ROUTES.DOCUMENT_CENTER, { replace: true })
      }
    }
  }, [navigate, params])

  useEffect(() => {
    if (
      queryParams.length > 0 &&
      hiredAdjusters.length > 0 &&
      rosters.length > 0 &&
      !showLoader //this awaits until all the requests are done
    ) {
      checkAuthenticatedUser()
    }
  }, [
    checkAuthenticatedUser,
    hiredAdjusters.length,
    queryParams,
    rosters.length,
    showLoader,
  ])

  const templateColumns = [
    templatesColumnHelper.accessor('templateName', {
      cell: (info) => info.getValue()?.split('.')?.[0] ?? '',
      header: 'Name',
    }),
    templatesColumnHelper.accessor('documentURL', {
      cell: (info) => {
        return (
          <Link
            onClick={() => {
              console.log(info.getValue())
              window.open(info.getValue(), '_blank')?.focus()
            }}
          >
            Document
          </Link>
        )
      },
      header: 'URL',
    }),
    templatesColumnHelper.accessor('_id', {
      cell: (info) => {
        const documentId = info.getValue()
        const template = signNowTemplates.find((te) => te._id === documentId)
        return (
          <HStack>
            <FaFileSignature
              title="Request signature"
              fontSize={20}
              cursor={hiredAdjustersByJob.length ? 'pointer' : 'not-allowed'}
              color={hiredAdjustersByJob.length ? ADJUSTANT_GREEN : '#919191'}
              onClick={
                hiredAdjustersByJob.length
                  ? () => {
                      const templateId = info.getValue()
                      const template = signNowTemplates.find(
                        (te) => te._id === templateId
                      )
                      if (template) setSelectedSignNowTemplate(template)
                      onSelectAdjustersModalOpen()
                    }
                  : () => {}
              }
            />
            <FaFileDownload
              title="Download Document"
              fontSize={20}
              cursor="pointer"
              color={ADJUSTANT_GREEN}
              onClick={() => {
                window.open(template?.documentURL, '_blank')?.focus()
              }}
            />
            <BsFillTrashFill
              title="Delete Document"
              fontSize={20}
              cursor="pointer"
              color="red"
              onClick={() => {
                hideTemplate(template?._id ?? '')
              }}
            />
          </HStack>
        )
      },
      header: '',
    }),
  ]

  const hiredAdjustersColumns = useMemo(
    () => [
      hiredAdjustersColumnHelper.accessor('firstName', {
        cell: (info) => info.getValue(),
        header: 'First Name',
      }),
      hiredAdjustersColumnHelper.accessor('lastName', {
        cell: (info) => info.getValue(),
        header: 'Last Name',
      }),
      hiredAdjustersColumnHelper.accessor('email', {
        cell: (info) => info.getValue(),
        header: 'Email',
      }),
      hiredAdjustersColumnHelper.accessor('_id', {
        cell: (info) => {
          const id = info.getValue()
          const hiredAdjuster = hiredAdjustersByJob.find((ha) => ha._id === id)
          const hiredAdjusterCheckrCandidate = checkrCandidates.find(
            (cc) =>
              cc.adjusterId === hiredAdjuster?._id && cc.jobId === selectedJobId
          )

          const checkrLatestBackgroundCheckStatus =
            hiredAdjusterCheckrCandidate?.checkrBackgroundCheckStatuses?.slice(
              -1
            )?.[0]?.status ?? ''

          const status =
            checkrLatestBackgroundCheckStatus?.split('.')?.[1] ?? ''

          return (
            <HStack>
              <FaFileSignature
                title="Request signature"
                fontSize={20}
                cursor={signNowTemplates.length ? 'pointer' : 'none'}
                color={signNowTemplates.length ? ADJUSTANT_GREEN : '#919191'}
                onClick={
                  signNowTemplates.length
                    ? () => {
                        setSelectedHiredAdjuster(hiredAdjuster)
                        onSelectDocumentModalOpen()
                      }
                    : () => {}
                }
              />
              <FaFileDownload
                title="Download HR Documents"
                fontSize={20}
                cursor="pointer"
                color={ADJUSTANT_GREEN}
                onClick={() => {
                  setSelectedHiredAdjusterForHRDocumentsList(hiredAdjuster)
                  onAdjusterHRDocumentsListModalOpen()
                }}
              />

              <Box
                cursor="pointer"
                onClick={() => {
                  setSelectedHiredAdjuster(hiredAdjuster)
                  setSelectedHiredAdjusterCheckrCandidate(
                    hiredAdjusterCheckrCandidate
                  )

                  onCheckrModalOpen()
                }}
              >
                <CheckrLogo
                  width="60"
                  id={hiredAdjuster?._id ?? ''}
                  color={
                    !hiredAdjusterCheckrCandidate
                      ? ADJUSTANT_GREEN
                      : ['suspended', 'dispute', 'canceled'].includes(status)
                      ? '#e74141'
                      : ['pending, created'].includes(status)
                      ? '#a5a534'
                      : '#a9a1a1'
                  }
                  title={
                    checkrLatestBackgroundCheckStatus
                      ? `Checkr Background Check Status: "${checkrLatestBackgroundCheckStatus}"`
                      : 'Request Background Check'
                  }
                />
              </Box>
            </HStack>
          )
        },
        header: '',
      }),
    ],
    [
      hiredAdjustersColumnHelper,
      hiredAdjustersByJob,
      checkrCandidates,
      signNowTemplates.length,
      selectedJobId,
      onSelectDocumentModalOpen,
      onAdjusterHRDocumentsListModalOpen,
      onCheckrModalOpen,
    ]
  )

  return (
    <Box paddingY="10px" background="white" h="100%" width="100%">
      <UploadDocumentModal
        isOpen={isUploadDocumentModalOpen}
        onClose={onUploadDocumentModalClose}
        user={user}
        signNowFirmFolders={signNowFirmFolders}
        setSignNowFirmFolders={setSignNowFirmFolders}
        setSignNowTemplates={setSignNowTemplates}
      />
      <SelectDocumentModal
        isOpen={isSelectDocumentModalOpen}
        onClose={(doc) => {
          if (doc && selectedHiredAdjuster) {
            requestAdjustersToSign([selectedHiredAdjuster], doc)
          }
          onSelectDocumentModalClose()
        }}
        documents={filteredSignowTemplates}
        signNowSigningInvitationsByJob={signNowSigningInvitations.filter(
          (invitation) =>
            invitation.jobId === selectedJobId &&
            invitation.adjusterId === selectedHiredAdjuster?._id
        )}
        selectedHiredAdjuster={selectedHiredAdjuster}
      />
      <SelectAdjustersModal
        isOpen={isSelectAdjustersModalOpen}
        onClose={(adjustersToInvite) => {
          if (adjustersToInvite?.length && selectedSignNowTemplate) {
            requestAdjustersToSign(adjustersToInvite, selectedSignNowTemplate)
          }
          onSelectAdjustersModalClose()
        }}
        adjusters={hiredAdjustersByJob}
        signNowSigningInvitations={signNowSigningInvitations.filter(
          (s) =>
            s.jobId === selectedJobId &&
            s.templateId === selectedSignNowTemplate?.templateId
        )}
      />
      <CheckrModal
        isOpen={isCheckrModalOpen}
        onClose={({ inviteCandidate, showOverview }) => {
          onCheckrModalClose()

          if (inviteCandidate) {
            checkrInviteCandidate()
          }

          if (showOverview) {
            checkrShowReportsOverview()
          }
        }}
        hasCheckrAccessToken={!!user?.hasCheckrAccessToken}
        selectedJobId={selectedJobId}
        jobs={jobs}
        selectedHiredAdjuster={selectedHiredAdjuster}
        selectedHiredAdjusterCheckrCandidate={
          selectedHiredAdjusterCheckrCandidate
        }
        adjusterCheckrCandidates={checkrCandidates.filter(
          (cc) =>
            cc.adjusterId === selectedHiredAdjuster?._id &&
            cc.firmId === user?._id
        )}
      />
      {selectedHiredAdjusterForHRDocumentsList && (
        <AdjusterHRDocumentsListModal
          isOpen={isAdjusterHRDocumentsListModalOpen}
          onClose={onAdjusterHRDocumentsListModalClose}
          adjuster={selectedHiredAdjusterForHRDocumentsList}
        />
      )}
      <Box width={{ base: '100%', md: '25%' }}>
        <CustomDropdown
          label=""
          placeholder="Select a ongoing job"
          options={jobs.map((o) => ({
            value: o._id,
            label: o.title,
          }))}
          value={selectedJobId}
          textAlign="start"
          isDisabled={showLoader}
          handleChange={(jobId) => {
            setSelectedJobId(jobId)
            const r = rosters.filter((r) => r.jobId === jobId)
            const adjustersIds = r.map((r) => r.adjusterId)
            const adjusters = hiredAdjusters.filter((ha) =>
              adjustersIds.includes(ha._id ?? '')
            )

            setHiredAdjustersByJob(adjusters)
            setFilteredHiredAdjusters(adjusters)
          }}
        />
      </Box>

      <Tabs
        variant="adjustant"
        h="calc(100% - 40px)"
        width="100%"
        index={selectedTab}
        onChange={(index) => {
          setSelectedTab(index)
        }}
      >
        <TabList
          background="white"
          width="100%"
          borderBottom="1px solid #e3e2e2"
        >
          <Tab
            fontWeight={500}
            width="50%"
            py={2}
            mx={0}
            fontSize={['sm', 'md']}
          >
            Documents
          </Tab>
          <Tab
            fontWeight={500}
            width="50%"
            py={2}
            mx={0}
            fontSize={['sm', 'md']}
          >
            Adjusters
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            {showLoader ? (
              <HStack
                direction="row"
                my={4}
                height="-webkit-fill-available"
                w="100%"
                // h="100%"
                justifyContent="center"
              >
                <Spinner size="xl" />
              </HStack>
            ) : (
              <>
                <HStack width="100%" justifyContent="end">
                  <Button
                    variant="adjustant"
                    onClick={onUploadDocumentModalOpen}
                  >
                    Upload
                  </Button>
                </HStack>
                <CustomTable
                  data={filteredSignowTemplates}
                  columns={templateColumns}
                />
              </>
            )}
          </TabPanel>

          <TabPanel>
            {showLoader ? (
              <HStack
                direction="row"
                my={4}
                height="-webkit-fill-available"
                w="100%"
                // h="100%"
                justifyContent="center"
              >
                <Spinner size="xl" />
              </HStack>
            ) : (
              <>
                <Code
                  m={2}
                  mb={4}
                  children={`Checkr Customer Status: ${
                    checkrUserStatus.authorized &&
                    checkrUserStatus.approvedForHierarchy
                      ? 'Connected and approved to run background checks.'
                      : checkrUserStatus.hasAccessToken &&
                        !checkrUserStatus.authorized
                      ? `Your account will be approved by Checkr within the next few days.`
                      : checkrUserStatus.hasAccessToken &&
                        !checkrUserStatus.approvedForHierarchy
                      ? `You are connected but not approved for hierarchy, contact Checkr's Customer Support.`
                      : !checkrUserStatus.hasAccessToken
                      ? 'Not Connected'
                      : ''

                    // checkrUserStatus.authorized &&
                    // checkrUserStatus.approvedForHierarchy
                    //   ? 'Connected and approved to run background checks'
                    //   : checkrUserStatus.hasAccessToken &&
                    //     (!checkrUserStatus.authorized ||
                    //       !checkrUserStatus?.approvedForHierarchy)
                    //   ? `Connected but not authorized to run background checks`
                    //   : 'Not Connected'
                  }`}
                />
                {/* <Text>
					Checkr Customer Status:{' '}
					{checkrUserStatus.authorized && checkrUserStatus.approvedForHierarchy
					? 'Connected and approved to run background checks'
					: checkrUserStatus.hasAccessToken &&
						(!checkrUserStatus.authorized ||
						!checkrUserStatus?.approvedForHierarchy)
					? `Connected but not authorized to run background checks`
					: 'Not Connected'}
				</Text> */}
                <Input
                  type="text"
                  placeholder="Search an adjuster"
                  value={adjustersFilter}
                  onChange={(e) => {
                    setAdjustersFilter(e.target.value)
                    const filteredAndSorted = filterAndSortAdjusters(
                      hiredAdjustersByJob,
                      e.target.value
                    )
                    setFilteredHiredAdjusters(filteredAndSorted)
                  }}
                />
                <CustomTable
                  data={filteredHiredAdjusters}
                  columns={hiredAdjustersColumns}
                />
              </>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default DocumentCenter
