import { Box, Button, useDisclosure } from '@chakra-ui/react'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
  Invitation,
  Job,
  JobApplicationStatus,
  JobStatus,
  Roster,
} from '../../../types/jobTypes'
import { UserContext } from '../../../contexts/UserContext'
import { User, UserContextType, UserType } from '../../../types/userTypes'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  GET_ALL_FIRM_JOBS,
  GET_INVITATIONS_BY_FILTER,
  GET_ROSTERS_BY_FILTER,
  GET_USERS_BY_FILTERS,
} from '../../../graphql/Queries'
import moment from 'moment'
import { SelectableItem } from '../../../components/CustomDropdown/CustomDropdown'
import AdjusterSearcher, {
  OnSearchFunctionParams,
} from '../../../components/AdjusterSearcher/AdjusterSearcher'
import InviteAdjusterModal from '../../../components/Modals/InviteAdjusterModal'
import { FcInvite } from 'react-icons/fc'
import { ADJUSTANT_GREEN } from '../../../themes/themes'
import { showSuccessToast } from '../../../components/Toast/Toast'
import {
  CREATE_INVITATION,
  CREATE_NOTIFICATION,
  DELETE_APPLICATION,
} from '../../../graphql/Mutations'
import { ADJUSTER_ROUTES } from '../../../App'
import { US_STATES } from '../../../types/stateLicensesTypes'

const AdjusterSearch = () => {
  const { user }: UserContextType = useContext(UserContext)
  const [dataRequested, setDataRequested] = useState<boolean>(false)
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [jobsRetrieved, setJobsRetrieved] = useState<boolean>(false)
  const { onOpen, isOpen, onClose } = useDisclosure()
  const [adjusterToInvite, setAdjusterToInvite] = useState<User | undefined>()
  const [adjusterToInviteRosters, setAdjusterToInviteRosters] = useState<
    Roster[]
  >([])
  const [adjusterToInviteInvitations, setAjusterToInviteInvitations] = useState<
    Invitation[]
  >([])
  const [jobsMapped, setJobsMapped] = useState<SelectableItem[]>([])
  const [adjusters, setAdjusters] = useState<User[]>([])

  const [getRosters] = useLazyQuery(GET_ROSTERS_BY_FILTER)
  const [getInvitations] = useLazyQuery(GET_INVITATIONS_BY_FILTER)
  const [createNotificationMutation] = useMutation(CREATE_NOTIFICATION)
  const [createInvitationMutation] = useMutation(CREATE_INVITATION)
  const [deleteApplicationMutation] = useMutation(DELETE_APPLICATION)

  const [getUsersByFilter] = useLazyQuery(GET_USERS_BY_FILTERS, {
    onCompleted: (data: { UsersByFilter: User[] }) => {
      setShowLoader(false)
      if (data?.UsersByFilter) {
        const newUsers = [...data?.UsersByFilter]
        setAdjusters(newUsers)
      }
    },
    onError: (err) => {
      setShowLoader(false)
      console.log(err)
    },
  })
  const [getJobs] = useLazyQuery(GET_ALL_FIRM_JOBS, {
    onCompleted: (data: { GetJobMany: Job[] }) => {
      if (data?.GetJobMany) {
        const firmJobs = data?.GetJobMany
        const firmJobsMapped = firmJobs.map((j) => ({
          value: j._id,
          label: j.title,
        }))
        setJobsMapped(firmJobsMapped)
      }
    },
    onError: (err) => {},
  })

  useEffect(() => {
    if (!jobsRetrieved && user) {
      getJobs({
        variables: { firmId: user?._id, statuses: [JobStatus.ONGOING] },
      })
      setJobsRetrieved(true)
    }
  }, [getJobs, jobsRetrieved, user])

  const onSearch = useCallback(
    ({
      searcherValue,
      location,
      jobTypes,
      yearsOfLicensedExperience,
      statuses,
      licenses,
      certifications,
    }: OnSearchFunctionParams) => {
      setDataRequested(true)
      setShowLoader(true)
      const locationAbbreviation = US_STATES.find(
        (a) => a.name.toLowerCase() === location.toLowerCase() ?? ''
      )?.abbreviation
      const licensesIds = licenses.filter((l) => l.id)?.map((l) => l.id)
      const licensesStates = licenses.filter((l) => !l.id)?.map((l) => l.state)
      getUsersByFilter({
        variables: {
          userType: UserType.ADJUSTER,
          searcherValue,
          locationAbbreviation,
          location,
          jobTypes,
          status: statuses,
          yearsOfLicensedExperience,
          licensesIds,
          licensesStates,
          certificationsIds: certifications.map((l) => l.id),
        },
        fetchPolicy: 'network-only',
      })
    },
    [getUsersByFilter]
  )

  const getAdjusterCardCustomContent = useCallback(
    (adjuster: User) => {
      return (
        <Button
          //   color={ADJUSTANT_GREEN}
          //   cursor="pointer"
          //   fontSize="large"
          size="sm"
          variant="adjustant"
          onClick={async () => {
            onOpen()
            setAdjusterToInvite(adjuster)
            const result = await getRosters({
              variables: {
                adjusterId: adjuster?._id,
                jobsIds: jobsMapped.map((u) => u.value),
              },
            })
            if (result?.data?.RostersByFilter) {
              setAdjusterToInviteRosters(result?.data?.RostersByFilter)
            }

            const resultI = await getInvitations({
              variables: {
                adjusterId: adjuster?._id,
                jobsIds: jobsMapped.map((u) => u.value),
              },
              fetchPolicy: 'network-only',
            })
            if (resultI?.data?.InvitationsByFilter) {
              setAjusterToInviteInvitations(resultI?.data?.InvitationsByFilter)
            }
          }}
        >
          Invite Adjuster
        </Button>
      )
    },
    [getInvitations, getRosters, jobsMapped, onOpen]
  )

  const inviteAdjuter = useCallback(
    async (jobID: string, message: string) => {
      setShowLoader(true)
      const invitation = {
        message,
        adjusterId: adjusterToInvite?._id,
        jobId: jobID,
        status: JobApplicationStatus.WAITING,
      }
      const result = await createInvitationMutation({
        variables: { invitation },
      })
      if (result?.data?.InvitationCreateOne?.record) {
        setAjusterToInviteInvitations([
          ...adjusterToInviteInvitations,
          { ...result?.data?.InvitationCreateOne?.record },
        ])
        createNotificationMutation({
          variables: {
            notification: {
              userId: adjusterToInvite?._id,
              message: `You have received an invitation for the job "${
                jobsMapped.find((j) => j.value === jobID)?.label
              }".`,
              //   link: `job/${selectedJob?._id}`,
              link: `${ADJUSTER_ROUTES.INVITATIONS}`,
              read: false,
            },
          },
        })
        deleteApplicationMutation({
          variables: {
            adjusterId: adjusterToInvite?._id,
            jobId: jobID,
          },
        })
        showSuccessToast('Adjuster Invited Succesfully!')
      }
      setShowLoader(false)
    },
    [
      adjusterToInvite?._id,
      adjusterToInviteInvitations,
      createInvitationMutation,
      createNotificationMutation,
      deleteApplicationMutation,
      jobsMapped,
    ]
  )

  const jobsFiltered = useMemo((): SelectableItem[] => {
    return jobsMapped.map((j) => {
      const alreadyInRoster = !!adjusterToInviteRosters.find(
        (r) => r.jobId === j.value && r.adjusterId === adjusterToInvite?._id
      )
      const alreadyInvited = !!adjusterToInviteInvitations.find(
        (i) => i.jobId === j.value && i.adjusterId === adjusterToInvite?._id
      )
      const disabled = alreadyInRoster || alreadyInvited
      return { ...j, disabled }
    })
  }, [
    adjusterToInvite?._id,
    adjusterToInviteInvitations,
    adjusterToInviteRosters,
    jobsMapped,
  ])

  return (
    <Box
      h="100%"
      width="100%"
      display="flex"
      backgroundColor="white"
      pt={8}
      px={[4, 8]}
      overflowY="auto"
    >
      <InviteAdjusterModal
        isOpen={isOpen}
        onClose={(jobID, message, invite) => {
          onClose()
          if (invite) {
            inviteAdjuter(jobID, message)
          }
        }}
        adjuster={adjusterToInvite}
        jobsMapped={jobsFiltered}
      />
      <AdjusterSearcher
        adjusters={adjusters}
        showLoader={showLoader}
        dataRequested={dataRequested}
        onSearch={onSearch}
        getAdjusterCardCustomContent={getAdjusterCardCustomContent}
        replaceAdjusterName={user?.userType === UserType.FIRM}
      />
    </Box>
  )
}

export default AdjusterSearch
