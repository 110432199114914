import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Box, Button, Heading, Spinner, Stack, VStack } from '@chakra-ui/react'
import UpdateAdjusterLicensesAndCertificationsForm from '../../components/UpdateAdjusterLicensesAndCertificationsForm/UpdateAdjusterLicensesAndCertificationsForm'
import {
  checkIfDocumentsAreComplete,
  getFileNameFromURL,
  storeFile,
} from '../../utils/functions'
import { DocumentData } from '../../components/UploadAdjusterLicensesOrCertifications/UploadAdjusterLicensesAndCertifications'
import {
  FemaCertification,
  LicenseCertification,
  User,
  UserContextType,
  UserType,
} from '../../types/userTypes'
import { UserContext } from '../../contexts/UserContext'
import {
  CREATE_NOTIFICATION,
  UPDATE_USER_BY_EMAIL,
} from '../../graphql/Mutations'
import { useLazyQuery, useMutation } from '@apollo/client'
import { showSuccessToast } from '../../components/Toast/Toast'
import {
  setItemToLocalStorage,
  LOCALSTORAGE_OBJECTS_NAMES,
} from '../../utils/localStorageFunctions'
import { useParams, useNavigate } from 'react-router-dom'
import { GLOBAL_ROUTES, ADMIN_ROUTES } from '../../App'
import { GET_ADMINS, GET_USER_BY_ID } from '../../graphql/Queries'
import { BiArrowBack } from 'react-icons/bi'

const UpdateAdjustersLicensesAndCertificationsContent = ({
  user,
  admins,
  editorIsAdmin,
}: {
  user?: User
  admins: User[]
  editorIsAdmin: boolean
}) => {
  const navigate = useNavigate()
  const [showLoader, setShowLoader] = useState(false)
  const { setUser }: UserContextType = useContext(UserContext)
  const [updateUserMutation] = useMutation(UPDATE_USER_BY_EMAIL(user?.email))
  const [createNotificationMutation] = useMutation(CREATE_NOTIFICATION)

  //FEMA certification
  const [femaCertificationClone, setFemaCertificationClone] =
    useState<FemaCertification>({
      has: user?.femaCertification?.has ?? false,
      url: user?.femaCertification?.url ?? '',
      categories: user?.femaCertification?.categories ?? [],
      fcnNumber: user?.femaCertification?.fcnNumber,
      expiryDate: user?.femaCertification?.expiryDate ?? '',
      issuedDate: user?.femaCertification?.issuedDate ?? '',
      approved: user?.femaCertification?.approved,
      flag: user?.femaCertification?.flag,
    })
  const [femaCertificationFile, setFemaCertificationFile] = useState<any>()
  const [femaCertificationFileName, setFemaCertificationFileName] =
    useState<string>(getFileNameFromURL(user?.femaCertification?.url))

  // Licenses
  const [licenses, setLicenses] = useState<DocumentData[]>(
    user?.licenses?.map((li) => ({
      id: li.id,
      isListed: li.isListed,
      isPrimary: li.isPrimary,
      issuedDate: li.issuedDate,
      expiryDate: li.expiryDate,
      state: li.state,
      loas: li.loas,
      url: li.url ?? '',
      name: li.name,
      file: undefined,
      fileName: getFileNameFromURL(li?.url),
      oldUrl: li.url ?? '',
      approved: li.approved,
      flag: li?.flag,
      source: li.source,
      metadata: li.metadata,
    })) ?? []
  )

  // certifications
  const [certifications, setCertifications] = useState<DocumentData[]>(
    user?.certifications?.map((ce) => ({
      id: ce.id,
      isListed: ce.isListed,
      isPrimary: ce.isPrimary,
      issuedDate: ce.issuedDate,
      expiryDate: ce.expiryDate,
      state: ce.state,
      url: ce.url ?? '',
      name: ce.name,
      file: undefined,
      fileName: getFileNameFromURL(ce?.url),
      oldUrl: ce.url ?? '',
      approved: ce.approved,
      flag: ce?.flag,
    })) ?? []
  )

  const disableUpdateButton = useMemo(() => {
    const femaLicenseIsMissing =
      femaCertificationClone.has &&
      (!femaCertificationClone.url ||
        !femaCertificationClone?.expiryDate ||
        !femaCertificationClone?.issuedDate ||
        !femaCertificationClone?.fcnNumber ||
        femaCertificationClone?.categories?.length === 0)

    const thereIsAnIncompleteAdditionalLicense =
      checkIfDocumentsAreComplete(licenses)
    const thereIsAnIncompleteAdditionalCertification =
      checkIfDocumentsAreComplete(certifications, true)

    return (
      femaLicenseIsMissing ||
      thereIsAnIncompleteAdditionalLicense ||
      thereIsAnIncompleteAdditionalCertification
    )
  }, [femaCertificationClone, licenses, certifications])

  const notifyAdmins = useCallback(
    async (message: string, userId: string, queryParams: string) => {
      admins.map((admin) => {
        createNotificationMutation({
          variables: {
            notification: {
              userId: admin?._id,
              message,
              link: `${GLOBAL_ROUTES.ADMIN}${GLOBAL_ROUTES.PROFILE}/${userId}${queryParams}`,
              read: false,
            },
          },
        })

        return null
      })
    },
    [admins, createNotificationMutation]
  )

  const onUpdate = useCallback(async () => {
    if (user) {
      setShowLoader(true)
      const femaCertificationResponse = await storeFile(
        femaCertificationFile,
        femaCertificationFileName,
        user
      )

      const newLicenses: LicenseCertification[] = await Promise.all(
        licenses.map(async (al) => {
          const result = await storeFile(al?.file, al.fileName, user)
          const {
            id,
            state,
            isListed,
            isPrimary,
            expiryDate,
            issuedDate,
            name,
            approved,
            loas,
            flag,
            source,
            metadata,
          } = al
          return {
            id,
            state,
            loas,
            isListed,
            isPrimary,
            expiryDate,
            issuedDate,
            name,
            url:
              al.file && al.fileName
                ? result?.data
                : al.fileName
                ? al?.oldUrl
                : '',
            approved,
            flag,
            source,
            metadata,
          }
        })
      )

      const newCertifications: LicenseCertification[] = await Promise.all(
        certifications.map(async (ad) => {
          const result = await storeFile(ad?.file, ad.fileName, user)
          const {
            id,
            state,
            isListed,
            expiryDate,
            issuedDate,
            name,
            approved,
            loas,
            flag,
            source,
            metadata,
          } = ad
          return {
            id,
            state,
            isListed,
            isPrimary: false,
            expiryDate,
            issuedDate,
            name,
            url:
              ad.file && ad.fileName
                ? result?.data
                : ad.fileName
                ? ad?.oldUrl
                : '',
            loas,
            approved,
            flag,
            source,
            metadata,
          }
        })
      )

      const partialUser: Partial<User> = {
        femaCertification: {
          ...femaCertificationClone,
          url:
            femaCertificationFile && femaCertificationFileName
              ? femaCertificationResponse?.data
              : femaCertificationFileName
              ? user?.femaCertification?.url
              : '',
        },
        licenses: newLicenses,
        certifications: newCertifications,
      }
      console.log('USER TO UPDATE: ', partialUser)
      const result = await updateUserMutation({
        variables: { user: partialUser },
      })

      if (result?.data) {
        if (!editorIsAdmin) {
          setUser({ ...user, ...partialUser })
          setItemToLocalStorage(LOCALSTORAGE_OBJECTS_NAMES.USER, {
            ...user,
            ...partialUser,
          })
          if (user._id) {
            const param: keyof User = 'licenses'
            notifyAdmins(
              `The Adjuster ${user.firstName} ${user.lastName} has updated his additional licenses and certifications`,
              user._id,
              `?section=${param}`
            )
          }
        }
        showSuccessToast('Licences & Certifications Updated!')
      }
      setShowLoader(false)
    }
  }, [
    user,
    femaCertificationFile,
    femaCertificationFileName,
    licenses,
    certifications,
    femaCertificationClone,
    updateUserMutation,
    editorIsAdmin,
    notifyAdmins,
    setUser,
  ])

  return (
    <Box
      paddingX={['10px', '50px']}
      paddingY="10px"
      display="flex"
      justifyContent="center"
    >
      <Box
        width={['100%', '100%', 600]}
        py={10}
        px={[4, 4, 8]}
        background="white"
        borderRadius={8}
        border="1px solid #ECEAE9"
        mb={2}
      >
        <Heading as="h4" size="md" color="gray.500" mb={2}>
          <Box mb={2}>
            <BiArrowBack
              cursor="pointer"
              onClick={() =>
                navigate(
                  `${editorIsAdmin ? GLOBAL_ROUTES.ADMIN : ''}${
                    GLOBAL_ROUTES.PROFILE
                  }${editorIsAdmin ? '/' + user?._id : ''}`
                )
              }
            />
          </Box>
          Update Licences & Certifications
        </Heading>

        <UpdateAdjusterLicensesAndCertificationsForm
          // Fema Certification
          femaCertification={femaCertificationClone}
          setFemaCertification={setFemaCertificationClone}
          setFemaCertificationFile={setFemaCertificationFile}
          femaCertificationFileName={femaCertificationFileName}
          setFemaCertificationFileName={setFemaCertificationFileName}
          //Licenses
          licenses={licenses}
          setLicenses={setLicenses}
          // Certifications
          certifications={certifications}
          setCertifications={setCertifications}
        />

        <VStack mt={6}>
          <Button
            variant="adjustant"
            isLoading={showLoader}
            isDisabled={disableUpdateButton}
            onClick={onUpdate}
          >
            Update
          </Button>
        </VStack>
      </Box>
    </Box>
  )
}

const UpdateAdjustersLicensesAndCertifications = () => {
  const { userId } = useParams()
  const navigate = useNavigate()
  const { user }: UserContextType = useContext(UserContext)
  const [userToShow, setUserToShow] = useState<User | undefined>()
  const [loadingProfile, setLoadingProfile] = useState<boolean>(true)

  const [admins, setAdmins] = useState<User[]>([])

  const [getUser] = useLazyQuery(GET_USER_BY_ID, {
    onCompleted: (data: { UserOne: User }) => {
      setLoadingProfile(false)
      if (data?.UserOne) setUserToShow(data?.UserOne)
    },
    // onError: (err) => {}
  })

  const [getAdmins] = useLazyQuery(GET_ADMINS, {
    onCompleted: (data: { UserMany: User[] }) => {
      if (data?.UserMany) {
        const newUsers = [...data?.UserMany]
        setAdmins(newUsers)
      }
    },
    onError: (err) => {
      console.log(err)
    },
  })

  useEffect(() => {
    if (!userToShow && user) {
      console.log('userId: ', userId)
      getAdmins()
      if (!userId || userId === user?._id) {
        setUserToShow(user)
        setLoadingProfile(false)
      } else if (user.userType === UserType.ADMIN) {
        getUser({ variables: { id: userId }, fetchPolicy: 'network-only' })
      } else {
        navigate(`${GLOBAL_ROUTES.ADMIN}${ADMIN_ROUTES.DASHBOARD}`)
      }
    }
  }, [userId, user, userToShow, setUserToShow, getUser, navigate, getAdmins])

  if (loadingProfile)
    return (
      <Box
        minHeight="100%"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Stack direction="row" spacing={4}>
          <Spinner size="xl" />
        </Stack>
      </Box>
    )
  return (
    <UpdateAdjustersLicensesAndCertificationsContent
      user={userToShow}
      admins={admins}
      editorIsAdmin={user?.userType === UserType.ADMIN}
    />
  )
}

export default UpdateAdjustersLicensesAndCertifications
