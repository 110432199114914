import React from 'react'
import { Heading, Stack, Text } from '@chakra-ui/react'

const CompleteProfileHeader = ({
  text,
  subText,
  align = 'center',
}: {
  text: string
  subText?: string
  align?: 'center' | 'start'
}) => {
  return (
    <Stack mb={8}>
      <Heading
        as="h3"
        fontSize={['3xl', '4xl']}
        color="#6D6E6F"
        textAlign={align ?? 'center'}
      >
        {text}
      </Heading>
      <Text as="i" fontSize="md">
        {subText}
      </Text>
    </Stack>
  )
}

export default CompleteProfileHeader
