import { Box, Divider, HStack, Heading, Spinner } from '@chakra-ui/react'
import { useContext, useMemo, useState } from 'react'
import {
  DamageType,
  JobLicensingRequirement,
  JobCertificationRequirement,
} from '../../types/jobTypes'
import { UserContext } from '../../contexts/UserContext'
import {
  USER_STATUS,
  User,
  UserContextType,
  UserType,
} from '../../types/userTypes'
import AdjusterCard from '../AdjusterCard/AdjusterCard'
import UserSearcherBox from '../UserSearcherBox/UserSearcherBox'
import { StateLicesesContext } from '../../contexts/StateLicesesContext'
import { TrainingCertificationsContext } from '../../contexts/TrainingCertificationsContext'
import { StateLicenseContextType } from '../../types/stateLicensesTypes'
import { TrainingCertificationContextType } from '../../types/trainingCertificationsTypes'

export interface OnSearchFunctionParams {
  searcherValue: string
  location: string
  yearsOfLicensedExperience: number
  jobTypes: DamageType[]
  statuses?: USER_STATUS[]
  licenses: JobLicensingRequirement[]
  certifications: JobCertificationRequirement[]
}

const AdjusterSearcher = ({
  showLoader,
  dataRequested,
  onSearch,
  getAdjusterCardCustomContent,
  adjusters,
  replaceAdjusterName,
}: {
  adjusters: User[]
  showLoader: boolean
  onSearch: (p: OnSearchFunctionParams) => void
  getAdjusterCardCustomContent: (a: User) => void
  dataRequested: boolean
  replaceAdjusterName?: boolean
}) => {
  const { user }: UserContextType = useContext(UserContext)
  const { stateLicenses }: StateLicenseContextType =
    useContext(StateLicesesContext)
  const { trainingCertifications }: TrainingCertificationContextType =
    useContext(TrainingCertificationsContext)

  const [searcherValue, setSearcherValue] = useState<string>('')
  const [location, setLocation] = useState<string>('')
  const [jobTypes, setJobTypes] = useState<DamageType[]>([])
  const [statuses, setStatuses] = useState<USER_STATUS[]>([])
  const [yearsOfLicensedExperience, setYearsOfLicensedExperience] =
    useState<number>(0)
  const [licenses, setLicenses] = useState<JobLicensingRequirement[]>([])
  const [certifications, setCertifications] = useState<
    JobCertificationRequirement[]
  >([])

  const licensesOptions = useMemo(() => {
    return (
      stateLicenses?.map((s) => ({
        id: s._id,
        name: s.name,
        isListed: true,
      })) ?? []
    )
  }, [stateLicenses])

  const certificationOptions = useMemo(() => {
    const a = trainingCertifications?.map((s) => ({
      id: s._id,
      name: s.name,
      isListed: true,
    }))
    const additional = user?.additionalCertificationRequirements ?? []
    const listed = a ?? []
    return [...listed, ...additional]
  }, [trainingCertifications, user?.additionalCertificationRequirements])

  return (
    <Box w="100%">
      {/* Filters */}
      <UserSearcherBox
        value={searcherValue}
        setValue={setSearcherValue}
        location={location}
        setLocation={setLocation}
        jobTypes={jobTypes}
        setJobTypes={setJobTypes}
        statuses={statuses}
        setStatuses={setStatuses}
        yearsOfLicensedExperience={yearsOfLicensedExperience}
        setYearsOfLicensedExperience={setYearsOfLicensedExperience}
        certifications={certifications}
        licenses={licenses}
        setCertifications={setCertifications}
        setLicenses={setLicenses}
        licensesOptions={licensesOptions}
        certificationOptions={certificationOptions}
        onSearch={() => {
          onSearch({
            searcherValue,
            location,
            jobTypes,
            statuses,
            licenses,
            certifications,
            yearsOfLicensedExperience,
          })
        }}
      />

      {/* Results */}
      <Box overflowY="auto" width="100%" height="calc(100% - 42px)" p={8}>
        {dataRequested && (
          <>
            {showLoader ? (
              <HStack
                direction="row"
                my={4}
                height="-webkit-fill-available"
                w="100%"
                // h="100%"
                justifyContent="center"
              >
                <Spinner size="xl" />
              </HStack>
            ) : (
              <Box>
                <Heading as="h3" fontSize="2xl" mb={8}>
                  Results: {adjusters.length}
                </Heading>

                <Divider />
                {adjusters.map((adjuster, index) => (
                  <Box key={index}>
                    <AdjusterCard
                      adjuster={adjuster}
                      customFooterContent={getAdjusterCardCustomContent(
                        adjuster
                      )}
                      replaceAdjusterName={replaceAdjusterName}
                    />
                    <Divider />
                  </Box>
                ))}
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

export default AdjusterSearcher
