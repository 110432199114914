import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Radio,
  RadioGroup,
} from '@chakra-ui/react'
import { useState } from 'react'
import {
  SIGN_NOW_SIGNING_STATUS,
  SignNowSigningInvitation,
  SignNowTemplate,
} from '../../../types/signNowTypes'
import { User } from '../../../types/userTypes'

const SelectDocumentModal = ({
  isOpen,
  onClose,
  documents,
  signNowSigningInvitationsByJob,
  selectedHiredAdjuster,
}: {
  isOpen: boolean
  onClose: (document?: SignNowTemplate) => void
  documents: SignNowTemplate[]
  signNowSigningInvitationsByJob: SignNowSigningInvitation[]
  selectedHiredAdjuster?: User
}) => {
  const [selectedDocumentId, setSelectedDocumentId] = useState('')

  //   console.log(signNowSigningInvitationsByJob)
  //   console.log(documents)

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setSelectedDocumentId('')
        onClose()
      }}
      size="xl"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select Document For Signature</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <RadioGroup
            gap={2}
            display="grid"
            onChange={(e) => setSelectedDocumentId(e)}
            value={selectedDocumentId}
            variant="adjustant"
          >
            {documents.map((document) => (
              <Radio
                key={document._id}
                value={document._id}
                isDisabled={signNowSigningInvitationsByJob.some(
                  (s) => s.templateId === document.templateId
                )}
              >
                {document.templateName}
              </Radio>
            ))}
          </RadioGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="adjustant"
            mr={3}
            onClick={() => {
              const document = documents.find(
                (d) => d._id === selectedDocumentId
              )
              setSelectedDocumentId('')
              onClose(document)
            }}
            isDisabled={!selectedDocumentId}
          >
            Send Signature Request
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SelectDocumentModal
