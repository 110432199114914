import React, { useState } from 'react'
import { Box, Button, HStack } from '@chakra-ui/react'
import { TaxForm } from '../../types/userTypes'
import CompleteProfileHeader from './CompleteProfileHeader'
import { MdOutlineNavigateNext } from 'react-icons/md'
import { getFileNameFromURL } from '../../utils/functions'
import { W9_FORM_URL } from '../../constants/constants'
import UpdateTaxForm from '../UpdateTaxForm/UpdateTaxForm'

function CompleteProfileUpdateUpdateW9TaxForm({
  next,
  w9TaxForm,
  showLoader,
}: {
  next: (a: TaxForm, b: { fileName: string; file: any }) => void
  w9TaxForm?: TaxForm
  showLoader: boolean
}) {
  const [url, setURL] = useState(w9TaxForm?.url ?? '')

  const [file, setFile] = useState<any>()
  const [fileName, setFileName] = useState<string>(
    getFileNameFromURL(w9TaxForm?.url)
  )

  return (
    <Box maxW="400px">
      <CompleteProfileHeader
        text="W9 Tax Form"
        subText="You’ll need to upload basic employment documents to apply for jobs, you
        can choose to set this up later in your profile"
      />
      <UpdateTaxForm
        editable={!w9TaxForm?.approved}
        url={url}
        setURL={setURL}
        setFile={setFile}
        fileName={fileName}
        setFileName={setFileName}
        hideFile={!!w9TaxForm?.approved}
        officialDocumentURL={W9_FORM_URL}
        documentName="W-9 Form"
        align="center"
      />
      <HStack justify="end" mt={8}>
        <Button
          variant="adjustant"
          rightIcon={<MdOutlineNavigateNext size="20px" />}
          isLoading={showLoader}
          onClick={() => {
            next(
              {
                url: '',
              },
              { file, fileName }
            )
          }}
        >
          Next
        </Button>
      </HStack>
    </Box>
  )
}
export default CompleteProfileUpdateUpdateW9TaxForm
