import React, { useCallback, useState } from 'react'
import { UserType } from '../../types/userTypes'
import { forgotPassword } from '../../services/adjustant'
import {
  Box,
  Heading,
  VStack,
  Text,
  Button,
  Stack,
  HStack,
  PinInput,
  PinInputField,
} from '@chakra-ui/react'
import { ADJUSTANT_GREEN } from '../../themes/themes'

const CODE_LENGHT = 6

const InsertConfirmationCode = ({
  email,
  userType,
  onSendConfirmationCode,
}: {
  email: string
  userType?: UserType
  onSendConfirmationCode: (code: string) => void
}) => {
  const [code, setCode] = useState<string | undefined>()
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [showErrorMessage] = useState<boolean>(false)

  const handleOnSendConfirmationCode = useCallback(async () => {
    if (code?.length === CODE_LENGHT) {
      setShowLoader(true)
      await onSendConfirmationCode(code)
    }
  }, [code, onSendConfirmationCode])

  const resendCode = useCallback(async () => {
    if (userType) {
      console.log('RESENDING CODE')
      console.log({ email, userType })
      const result = await forgotPassword({ email, userType })
      console.log(result)
    }
  }, [userType, email])

  return (
    <Box
      minHeight="100%"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        background="white"
        border="1px solid #ECEAE9"
        borderRadius="8px"
        width="600px"
        mx={3}
      >
        <VStack py={[6, 6, 12]} px={[6, 6, 12]}>
          <Heading
            as="h3"
            fontSize="3xl"
            color="gray.500"
            mt={4}
            mb={8}
            textAlign="center"
          >
            Verify Your Email
          </Heading>

          <HStack>
            <PinInput onChange={setCode}>
              {[...Array(CODE_LENGHT)].map((_key, index) => (
                <PinInputField key={index} />
              ))}
            </PinInput>
          </HStack>

          <VStack textAlign="center" mt={6}>
            {showErrorMessage && (
              <Text as="h5" color="red.500" fontSize="sm">
                Wrong verification code please try again
              </Text>
            )}
            <Text as="h5" color="gray.500" fontSize="sm">
              Please check your email for the verification code we just sent. If
              you don’t see it, please wait a few minutes or check your spam
              folder.
            </Text>

            <Button
              variant="adjustant"
              isLoading={showLoader}
              isDisabled={code?.toString()?.length !== CODE_LENGHT}
              _hover={{ backgroundColor: ADJUSTANT_GREEN }}
              onClick={handleOnSendConfirmationCode}
            >
              Continue
            </Button>

            <Stack>
              <Text fontSize="sm">Didn&apos;t receive a code?</Text>
              <Button
                variant="link"
                color={ADJUSTANT_GREEN}
                textDecoration="underline"
                onClick={resendCode}
              >
                Resend
              </Button>
            </Stack>
          </VStack>
        </VStack>
      </Box>
    </Box>
  )
}

export default InsertConfirmationCode
