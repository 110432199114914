import React from 'react'
import { createContext } from 'react'
import {
  ListedStateLicense,
  StateLicenseContextType
} from '../types/stateLicensesTypes'

export const StateLicesesContext = createContext<StateLicenseContextType>({
  stateLicenses: [],
  setStateLicenses: (
    value: React.SetStateAction<ListedStateLicense[]>
  ): void => {
    throw new Error('Function not implemented.')
  }
})
