import { useCallback, useContext, useState } from 'react'
import { User, UserContextType } from '../../types/userTypes'
import {
  ADJUSTER_WORK_STATUS,
  Roster as RosterType,
} from '../../types/jobTypes'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Divider,
  Tag,
  Link,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { ADJUSTANT_GREEN } from '../../themes/themes'
import AdjusterCard from '../AdjusterCard/AdjusterCard'
import { useNavigate } from 'react-router-dom'
import { FIRM_ROUTES } from '../../App'
import { getFileNameFromURL, getPathRoute } from '../../utils/functions'
import { UserContext } from '../../contexts/UserContext'
import { AiOutlineLink } from 'react-icons/ai'
import SendMessageModal from '../Modals/SendMessageModal'
import { showSuccessToast } from '../Toast/Toast'
import { CHAT_STATUS, Chat } from '../../types/chatTypes'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  CREATE_CHAT,
  PULL_DELETED_BY_CHAT,
  PUSH_MESSAGE,
} from '../../graphql/Mutations'

export default function Roster({
  selectedJobId,
  adjusters,
  rosters,
  chats,
  setChats,
}: {
  selectedJobId?: string
  adjusters: (User | undefined)[]
  rosters: RosterType[]
  chats: Chat[]
  setChats: React.Dispatch<React.SetStateAction<Chat[]>>
}) {
  const navigate = useNavigate()
  const { user }: UserContextType = useContext(UserContext)
  const [enableStartReview, setEnableStartReview] = useState(false)
  const [enableSendMessage, setEnableSendMessage] = useState(false)
  const [selectedAdjustersIds, setSelectedAdjustersIds] = useState<string[]>([])

  const [createChatMutation] = useMutation(CREATE_CHAT)
  const [pushMessagetMutation] = useLazyQuery(PUSH_MESSAGE)
  const [pullDeletedByChatMutation] = useLazyQuery(PULL_DELETED_BY_CHAT)

  const {
    isOpen: messageModalIsOpen,
    onOpen: onOpenMessageModal,
    onClose: onCloseMessageModal,
  } = useDisclosure()

  const getCustomContent = useCallback(
    (adjuster?: User) => {
      const roster = rosters.find((r) => r.adjusterId === adjuster?._id)
      if (roster?.status === ADJUSTER_WORK_STATUS.FINISHED && !roster?.review)
        return (
          <>
            <Tag color={ADJUSTANT_GREEN} backgroundColor="gray.100" size="sm">
              {adjuster?.status}
            </Tag>
            <Tag color="red" backgroundColor="gray.100" size="sm">
              NEEDS REVIEW
            </Tag>
          </>
        )

      if (roster?.review)
        return (
          <>
            <Tag color={ADJUSTANT_GREEN} backgroundColor="gray.100" size="sm">
              {adjuster?.status}
            </Tag>
            <Tag color={ADJUSTANT_GREEN} backgroundColor="gray.100" size="sm">
              JOB COMPLETED
            </Tag>
          </>
        )

      return (
        <Tag color={ADJUSTANT_GREEN} backgroundColor="gray.100" size="sm">
          {adjuster?.status}
        </Tag>
      )
    },
    [rosters]
  )

  const onReviewClick = useCallback(() => {
    if (!enableStartReview) setEnableStartReview(true)
    else {
      navigate(
        `${getPathRoute(user)}${
          FIRM_ROUTES.ROSTER_REVIEW
        }/${selectedJobId}/${selectedAdjustersIds.join(',')}`
      )
    }
  }, [selectedAdjustersIds, selectedJobId, enableStartReview, user, navigate])

  const onSendMessageClick = useCallback(() => {
    if (!enableSendMessage) setEnableSendMessage(true)
    else {
      onOpenMessageModal()
    }
  }, [onOpenMessageModal, enableSendMessage])

  const checkAdjuster = useCallback(
    (checked: boolean, adjusterId?: string) => {
      if (adjusterId) {
        if (checked) setSelectedAdjustersIds((prev) => [...prev, adjusterId])
        else {
          const index = selectedAdjustersIds.findIndex((a) => a === adjusterId)
          setSelectedAdjustersIds((prev) => {
            prev.splice(index, 1)
            return [...prev]
          })
        }
      }
    },
    [selectedAdjustersIds]
  )

  const sendMessage = useCallback(
    async (message: string) => {
      //   console.log(message)
      const createMessagePromise = await selectedAdjustersIds.map(
        async (id) => {
          const chat = chats.find((chat) => chat.users.includes(id))
          const newMessage = {
            id: `${Date.now()}`,
            message,
            date: new Date().toString(),
            sender: user?._id ?? '',
            read: false,
          }

          if (chat) {
            await pushMessagetMutation({
              variables: {
                message: newMessage,
                id: chat._id,
              },
            })
            chat.messages = [...chat.messages, newMessage]

            //Deletes the receiver from deletedBy so he can read the last message sent to him
            const receiverId =
              chat.users[0] === user?._id ? chat.users[1] : chat.users[0]
            if (chat.deletedBy.includes(receiverId)) {
              pullDeletedByChatMutation({
                variables: {
                  userId: receiverId,
                  id: chat._id,
                },
              })

              chat.deletedBy = []
            }

            setChats([...chats])
          } else {
            //create the chat with the message
            const newChat = {
              jobId: selectedJobId,
              status: CHAT_STATUS.ACTIVE,
              users: [user?._id, id],
              deletedBy: [],
              messages: [newMessage],
              pinnedBy: [],
            }

            const result = await createChatMutation({
              variables: {
                chat: newChat,
              },
            })

            if (result?.data?.ChatCreateOne)
              setChats((prev) => [...prev, result?.data?.ChatCreateOne?.record])
          }

          return false
        }
      )
      await Promise.all(createMessagePromise)

      setEnableSendMessage(false)
      setSelectedAdjustersIds([])
      showSuccessToast('Message Sent!')
    },
    [
      chats,
      createChatMutation,
      pullDeletedByChatMutation,
      pushMessagetMutation,
      selectedAdjustersIds,
      selectedJobId,
      setChats,
      user?._id,
    ]
  )

  return (
    <Box>
      <SendMessageModal
        isOpen={messageModalIsOpen}
        onClose={(message) => {
          onCloseMessageModal()
          if (message) sendMessage(message)
        }}
      />

      {adjusters?.length > 0 && (
        <>
          {!enableStartReview && (
            <Button
              variant="adjustant"
              //   w="100%"
              mb={4}
              mr={4}
              isDisabled={
                enableSendMessage ? selectedAdjustersIds?.length < 1 : false
              }
              onClick={onSendMessageClick}
            >
              {enableSendMessage ? 'Write a Message' : 'Send a Message'}
            </Button>
          )}

          {!enableSendMessage && (
            <Button
              variant="adjustant"
              //   w="100%"
              mb={4}
              mr={4}
              isDisabled={
                enableStartReview ? selectedAdjustersIds?.length < 1 : false
              }
              onClick={onReviewClick}
            >
              {enableStartReview ? 'Review Roster' : 'Complete Deployment'}
            </Button>
          )}

          {(enableStartReview || enableSendMessage) && (
            <Button
              color="white"
              bg="gray.500"
              mb={4}
              onClick={() => {
                if (enableStartReview) setEnableStartReview(false)
                else setEnableSendMessage(false)

                setSelectedAdjustersIds([])
              }}
            >
              Cancel
            </Button>
          )}
        </>
      )}
      <Divider />
      {adjusters.map((adjuster, index) => (
        <Accordion key={index} allowMultiple>
          <AccordionItem border="unset">
            <AccordionButton
              _hover={{ backgroundColor: 'white' }}
              textAlign="start"
            >
              <Box w="100%" pr={4} display="flex" alignItems="start">
                {(enableStartReview || enableSendMessage) && (
                  <Checkbox
                    variant="adjustant"
                    mr={4}
                    pt={4}
                    isChecked={
                      !!selectedAdjustersIds.find((a) => a === adjuster?._id)
                    }
                    onChange={(e) => {
                      checkAdjuster(e.target.checked, adjuster?._id)
                    }}
                  />
                )}

                <AdjusterCard
                  adjuster={adjuster}
                  customFooterContent={getCustomContent(adjuster)}
                  color={
                    rosters.find((r) => r.adjusterId === adjuster?._id)?.review
                      ? 'red.500'
                      : ADJUSTANT_GREEN
                  }
                />
              </Box>
              <AccordionIcon color={ADJUSTANT_GREEN} />
            </AccordionButton>
            <AccordionPanel pb={4} color={ADJUSTANT_GREEN}>
              {/* <Link
                href={adjuster?.licenses?.find((l) => l.isPrimary)?.url}
                display="flex"
                alignItems="center"
                gap={2}
              >
                <AiOutlineLink />
                {adjuster?.licenses?.find((l) => l.isPrimary)?.url ??
                  'No primary license set'}
              </Link> */}
              {adjuster?.driverLicense?.url && (
                <Link
                  href={adjuster?.driverLicense?.url}
                  display="flex"
                  alignItems="center"
                  gap={2}
                >
                  <AiOutlineLink />
                  Driver's License{' - '}
                  {getFileNameFromURL(adjuster?.driverLicense?.url)}
                </Link>
              )}
              {adjuster?.w9TaxForm?.url && (
                <Link
                  href={adjuster?.w9TaxForm?.url}
                  display="flex"
                  alignItems="center"
                  gap={2}
                >
                  <AiOutlineLink />
                  W-9 Form{' - '}
                  {getFileNameFromURL(adjuster?.w9TaxForm?.url)}
                </Link>
              )}
              {adjuster?.w4TaxForm?.url && (
                <Link
                  href={adjuster?.w4TaxForm?.url}
                  display="flex"
                  alignItems="center"
                  gap={2}
                >
                  <AiOutlineLink />
                  W-4 Form{' - '}
                  {getFileNameFromURL(adjuster?.w4TaxForm?.url)}
                </Link>
              )}
              {adjuster?.i9TaxForm?.url && (
                <Link
                  href={adjuster?.i9TaxForm?.url}
                  display="flex"
                  alignItems="center"
                  gap={2}
                >
                  <AiOutlineLink />
                  I-9 Employment Eligibility Form{' - '}
                  {getFileNameFromURL(adjuster?.i9TaxForm?.url)}
                </Link>
              )}
              {adjuster?.secondaryFormOfID?.url && (
                <Link
                  href={adjuster?.secondaryFormOfID?.url}
                  display="flex"
                  alignItems="center"
                  gap={2}
                >
                  <AiOutlineLink />
                  Secondary Form of ID{' - '}
                  {getFileNameFromURL(adjuster?.i9TaxForm?.url)}
                </Link>
              )}
              {adjuster?.miscelaneosDocument?.url && (
                <Link
                  href={adjuster?.miscelaneosDocument?.url}
                  display="flex"
                  alignItems="center"
                  gap={2}
                >
                  <AiOutlineLink />
                  Miscellaneos Document{' - '}
                  {getFileNameFromURL(adjuster?.miscelaneosDocument?.url)}
                </Link>
              )}
              {adjuster?.femaCertification?.url && (
                <Link
                  href={adjuster?.femaCertification?.url}
                  display="flex"
                  alignItems="center"
                  gap={2}
                >
                  <AiOutlineLink />
                  FEMA Certification{' - '}
                  {getFileNameFromURL(adjuster?.femaCertification?.url)}
                </Link>
              )}

              {adjuster?.languagePreference && (
                <Box my={2}>
                  <Text as="b">Language Preference</Text>
                  <Text>{adjuster.languagePreference}</Text>
                </Box>
              )}
              {adjuster?.emergencyContact && (
                <Box my={2}>
                  <Text as="b">Emergency Contact</Text>
                  <Text>{adjuster.emergencyContact}</Text>
                </Box>
              )}
              {adjuster?.xactimateId && (
                <Box my={2}>
                  <Text as="b">Xactimate ID</Text>
                  <Text>{adjuster.xactimateId}</Text>
                </Box>
              )}
              {adjuster?.companyAlias && (
                <Box my={2}>
                  <Text as="b">Company Alias</Text>
                  <Text>{adjuster.companyAlias}</Text>
                </Box>
              )}
              {adjuster?.phoneNumber && (
                <Box my={2}>
                  <Text as="b">Phone Number</Text>
                  <Text>{adjuster.phoneNumber}</Text>
                </Box>
              )}
            </AccordionPanel>
          </AccordionItem>
          <Divider />
        </Accordion>
      ))}
    </Box>
  )
}
