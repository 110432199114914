import React, { useContext, useMemo } from 'react'
import {
  Page,
  Text,
  Document,
  StyleSheet,
  Font,
  PDFViewer,
  View,
} from '@react-pdf/renderer'
import { LicenseCertification, User } from '../../types/userTypes'
import { formatDate } from '../../utils/functions'
import { LOASContext } from '../../contexts/LOASContext'
import { LOA, LOASContextType } from '../../types/loasTypes'
import { style } from 'd3'

// Create styles
const styles = StyleSheet.create({
  iframe: {
    height: '100vh',
    width: 'calc(100% - 25px)',
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    marginLeft: 40,
  },
  text: {
    fontSize: 14,
  },
  textBold: {
    fontSize: 14,
    // fontWeight: 600,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
  },
  //   pageNumber: {
  //     position: 'absolute',
  //     fontSize: 12,
  //     bottom: 30,
  //     left: 0,
  //     right: 0,
  //     textAlign: 'center',
  //     color: 'grey',
  //   },

  table: {
    width: '100%',
    marginTop: 30,
  },
  table2: {
    width: '100%',
    marginTop: 15,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 8,
  },
  row1: {
    width: '27%',
  },
  row2: {
    width: '15%',
  },
  row3: {
    width: '11%',
  },
  row4: {
    width: '20%',
  },
  row5: {
    width: '27%',
  },
})

// Create Document Component
const NIPRPDF = ({
  license,
  user,
  licenses,
  loas,
}: {
  license?: LicenseCertification
  user?: User
  licenses?: LOA[]
  loas?: LOA[]
}) => {
  const loasData = useMemo(() => {
    const metadataLoasDetail = license?.metadata?.DETAILS?.DETAIL
    const metadataLoas = Array.isArray(metadataLoasDetail)
      ? metadataLoasDetail
      : [metadataLoasDetail]

    if (license?.metadata) {
      return (
        <>
          {/* <View style={[styles.row, styles.textBold]}>
            <Text style={styles.row1}>Line Of Authority</Text>
            <Text style={styles.row2}>Authority Issue Date</Text>
            <Text style={styles.row3}>Status</Text>
            <Text style={styles.row4}>Status Reason</Text>
            <Text style={styles.row5}>Status Date</Text>
          </View>
          {metadataLoas.map((metadataLoa, i) => {
            return (
              <View key={i} style={[styles.row, styles.text]} wrap={false}>
                <Text style={styles.row1}>{metadataLoa?.LOA}</Text>
                <Text style={[styles.row2]}>
                  {metadataLoa?.AUTHORITY_ISSUE_DATE}
                </Text>
                <Text style={[styles.row3]}>{metadataLoa.STATUS}</Text>
                <Text style={[styles.row4]}>
                  {metadataLoa?.STATUS_REASON.toString()}
                </Text>
                <Text style={[styles.row5]}>
                  {metadataLoa.STATUS_REASON_DATE}
                </Text>
              </View>
            )
          })} */}

          {metadataLoas.map((metadataLoa, i) => {
            return (
              <View style={styles.table2} key={i}>
                <Text style={styles.textBold}>
                  Line Of Authority:{' '}
                  <Text style={styles.text}>{metadataLoa?.LOA}</Text>
                </Text>
                <Text style={styles.textBold}>
                  Authority Issue Datey:{' '}
                  <Text style={styles.text}>
                    {typeof metadataLoa?.AUTHORITY_ISSUE_DATE === 'object'
                      ? ''
                      : metadataLoa?.AUTHORITY_ISSUE_DATE}
                  </Text>
                </Text>
                <Text style={styles.textBold}>
                  Status:{' '}
                  <Text style={styles.text}>
                    {typeof metadataLoa?.STATUS === 'object'
                      ? ''
                      : metadataLoa?.STATUS}
                  </Text>
                </Text>
                <Text style={styles.textBold}>
                  Status Reason:{' '}
                  <Text style={styles.text}>
                    {typeof metadataLoa?.STATUS_REASON === 'object'
                      ? ''
                      : metadataLoa?.STATUS_REASON}
                  </Text>
                </Text>
                <Text style={styles.textBold}>
                  Status Date:{' '}
                  <Text style={styles.text}>
                    {typeof metadataLoa?.STATUS_REASON_DATE === 'object'
                      ? ''
                      : metadataLoa?.STATUS_REASON_DATE}
                  </Text>
                </Text>
                <Text style={styles.textBold}>
                  CE Compliance:{' '}
                  <Text style={styles.text}>
                    {typeof metadataLoa?.CE_COMPLIANCE === 'object'
                      ? ''
                      : metadataLoa?.CE_COMPLIANCE}
                  </Text>
                </Text>
                <Text style={styles.textBold}>
                  CE Credits Needed:{' '}
                  <Text style={styles.text}>
                    {typeof metadataLoa?.CE_CREDITS_NEEDED === 'object'
                      ? ''
                      : metadataLoa?.CE_CREDITS_NEEDED}
                  </Text>
                </Text>
                <Text style={styles.textBold}>
                  CE Renewal Date:{' '}
                  <Text style={styles.text}>
                    {typeof metadataLoa?.CE_RENEWAL_DATE === 'object'
                      ? ''
                      : metadataLoa?.CE_RENEWAL_DATE}
                  </Text>
                </Text>
              </View>
            )
          })}
        </>
      )
    } else {
      return (
        <Text style={[styles.table, styles.text]}>
          Lines Of Authority:{' '}
          {license?.loas?.map((loaId, index) => {
            const LOA = loas?.find((loa) => loa._id === loaId)
            return (
              <Text style={styles.textBold} key={loaId}>
                {index !== 0 ? ', ' : ''}{' '}
                <Text style={styles.text}>{LOA?.name}</Text>
              </Text>
            )
          })}
        </Text>
      )
    }
  }, [license?.loas, license?.metadata, loas])

  return (
    <PDFViewer style={styles.iframe}>
      <Document>
        <Page style={styles.body}>
          <Text style={styles.textBold}>
            {user?.firstName} {user?.lastName}
          </Text>
          <Text style={styles.textBold}>
            NPM: <Text style={styles.text}>{`${user?.npnNumber}`}</Text>
          </Text>

          {/* <View style={styles.table}> */}
          <Text style={styles.textBold}>
            Address: <Text style={styles.text}>{user?.location}</Text>
          </Text>
          <Text style={styles.textBold}>
            Email: <Text style={styles.text}>{user?.email}</Text>
          </Text>
          <Text style={styles.textBold}>
            Phone Number:{' '}
            <Text style={styles.text}>{user?.phoneNumber ?? '-'}</Text>
          </Text>
          {/* </View> */}

          {/* License  */}
          <View style={styles.table}>
            {license?.metadata && (
              <Text style={styles.textBold}>NIPR License </Text>
            )}

            <Text style={styles.textBold}>
              Name:{' '}
              <Text style={styles.text}>
                {licenses?.find((l) => l._id === license?.id)?.name || ''}
              </Text>
            </Text>

            <Text style={styles.textBold}>
              State: <Text style={styles.text}>{license?.state}</Text>
            </Text>

            {license?.metadata && (
              <Text style={styles.textBold}>
                License #:{' '}
                <Text
                  style={styles.text}
                >{`${license?.metadata?.LICENSE_NUM}`}</Text>
              </Text>
            )}

            <>
              <Text style={styles.textBold}>
                Issue date:{' '}
                <Text style={styles.text}>{`${formatDate(
                  license?.issuedDate ?? ''
                )}`}</Text>
              </Text>
              <Text style={styles.textBold}>
                Expiration date:{' '}
                <Text style={styles.text}>{`${formatDate(
                  license?.expiryDate ?? ''
                )}`}</Text>
              </Text>

              {license?.metadata && (
                <Text style={styles.textBold}>
                  Class:{' '}
                  <Text
                    style={styles.text}
                  >{`${license?.metadata?.LICENSE_CLASS}`}</Text>
                </Text>
              )}

              {license?.metadata && (
                <Text style={styles.textBold}>
                  Residency:{' '}
                  <Text
                    style={styles.text}
                  >{`${license?.metadata?.RESIDENCY_STATUS}`}</Text>
                </Text>
              )}

              {license?.metadata && (
                <Text style={styles.textBold}>
                  Active:{' '}
                  <Text
                    style={styles.text}
                  >{`${license?.metadata?.ACTIVE}`}</Text>
                </Text>
              )}

              {/* {license?.metadata && (
            <Text style={styles.textBold}>
              Designated Home State:{' '}
              <Text style={styles.text}>{`${}`}</Text>
            </Text>
          )} */}

              {/* Loas */}
              <View style={styles.table}>
                <Text style={styles.textBold}>LOAs</Text>
                <>{loasData}</>
              </View>
            </>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  )
}
Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
})

export default NIPRPDF
