import {
  Box,
  Stack,
  TabPanels,
  VStack,
  Text,
  Tabs,
  Heading,
  HStack,
  Tag,
  Divider,
  Button,
  Image,
  Input,
  TabPanel,
  Link,
  Select,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  SliderMark,
  Grid,
  GridItem,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import useWindowSize from '../../../hooks/useWindowSize'
import {
  DATA_SOURCE,
  EXPERIENCE_TYPES,
  EXPERIENCE_VALUES,
  Experience,
  LicenseCertification,
  User,
  UserContextType,
  getFlagReason,
} from '../../../types/userTypes'
import { UserContext } from '../../../contexts/UserContext'
import {
  StateLicenseContextType,
  US_STATES,
  US_STATES_ABBREVIATIONS,
} from '../../../types/stateLicensesTypes'
import { StateLicesesContext } from '../../../contexts/StateLicesesContext'
import { HiCalendar, HiMapPin } from 'react-icons/hi2'
import {
  formatDate,
  getFileNameFromURL,
  storeFileFromUrl,
} from '../../../utils/functions'
import { ADJUSTANT_GREEN } from '../../../themes/themes'
import { LOASContextType } from '../../../types/loasTypes'
import { LOASContext } from '../../../contexts/LOASContext'
import { TrainingCertificationContextType } from '../../../types/trainingCertificationsTypes'
import { TrainingCertificationsContext } from '../../../contexts/TrainingCertificationsContext'
import { BiArrowBack } from 'react-icons/bi'
import { ADJUSTER_ROUTES, FIRM_ROUTES, GLOBAL_ROUTES } from '../../../App'
import { useNavigate, useParams } from 'react-router-dom'
import {
  createSignNowDownloadLink,
  createStripeSession,
  getNIPRData,
  retrieveStripeSession,
} from '../../../services/adjustant'
import {
  showErrorToast,
  showSuccessToast,
} from '../../../components/Toast/Toast'
import moment from 'moment'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  CREATE_NOTIFICATION,
  UPDATE_SIGN_NOW_SIGNING_INVITATION,
  UPDATE_USER_BY_EMAIL,
} from '../../../graphql/Mutations'
import {
  setItemToLocalStorage,
  LOCALSTORAGE_OBJECTS_NAMES,
} from '../../../utils/localStorageFunctions'
import UpdateDriverLicense from '../../../components/UpdateDriverLicense/UpdateDriverLicense'
import UpdateMiscellaneosDocument from '../../../components/UpdateMiscellaneosDocument/UpdateMiscellaneosDocument'
import {
  I9_FORM_URL,
  W4_FORM_URL,
  W9_FORM_URL,
} from '../../../constants/constants'
import UpdateTaxForm from '../../../components/UpdateTaxForm/UpdateTaxForm'
import {
  SIGN_NOW_SIGNING_STATUS,
  SignNowSigningInvitation,
  SignNowTemplate,
} from '../../../types/signNowTypes'
import {
  GET_SIGN_NOW_SIGNING_INVITATIONS,
  GET_SIGN_NOW_TEMPLATES,
  GET_USERS_BY_FILTERS,
} from '../../../graphql/Queries'
import { createColumnHelper } from '@tanstack/react-table'
import CustomTable from '../../../components/CustomTable/CustomTable'
import { FaFileDownload, FaFileSignature } from 'react-icons/fa'
import SignDocumentModal from './SignDocumentModal'

const labelStyles = {
  ml: '-6',
  mt: '-14',
  fontSize: 'md',
  fontWeight: 500,
  color: 'black',
}
const ExperienceSlider = ({
  first,
  label,
  value,
  onChange,
}: {
  first?: boolean
  label: string
  value: number
  onChange: (a: number) => void
}) => {
  return (
    <Box>
      <Grid templateColumns="repeat(4, 1fr)" gap={6}>
        <GridItem
          w="100%"
          colSpan={1}
          fontWeight={
            [
              EXPERIENCE_TYPES.propertyResidential,
              EXPERIENCE_TYPES.auto,
              EXPERIENCE_TYPES.commercial,
              EXPERIENCE_TYPES.specialtyServices,
            ].includes(label)
              ? 500
              : 300
          }
        >
          {label}
        </GridItem>
        <GridItem w="100%" colSpan={3}>
          <Slider
            //   aria-label="slider-ex-6"
            min={0}
            max={5}
            step={1}
            value={value}
            onChange={onChange}
            my={4}
          >
            {first && (
              <>
                {EXPERIENCE_VALUES.map((e, i) => (
                  <SliderMark value={i} {...labelStyles} key={i}>
                    {e}
                  </SliderMark>
                ))}
              </>
            )}
            <SliderTrack>
              <SliderFilledTrack backgroundColor={ADJUSTANT_GREEN} />
            </SliderTrack>
            <SliderThumb backgroundColor={ADJUSTANT_GREEN} />
          </Slider>
        </GridItem>
      </Grid>
    </Box>
  )
}

const AdjusterJobs = () => {
  const navigate = useNavigate()
  const { stripeSessionID, niprNpn } = useParams()

  const {
    isOpen: isSignDocumentModalOpen,
    onOpen: onSignDocumentModalOpen,
    onClose: onSignDocumentModalClose,
  } = useDisclosure()

  const windowsSize = useWindowSize()

  const [getSignNowSigningInvitations] = useLazyQuery(
    GET_SIGN_NOW_SIGNING_INVITATIONS
  )
  const [getSignNowTemplates] = useLazyQuery(GET_SIGN_NOW_TEMPLATES)
  const [getUsersByFilter] = useLazyQuery(GET_USERS_BY_FILTERS)

  const signNowSigningInvitationColumnHelper =
    createColumnHelper<SignNowSigningInvitation>()
  const [showSignNowInvitationsLoader, setShowSignNowInvitationsLoader] =
    useState<boolean>(false)
  const { user, setUser }: UserContextType = useContext(UserContext)
  const { stateLicenses }: StateLicenseContextType =
    useContext(StateLicesesContext)
  const { trainingCertifications }: TrainingCertificationContextType =
    useContext(TrainingCertificationsContext)
  const { loas }: LOASContextType = useContext(LOASContext)
  const [selectedTab, setSelectedTab] = useState<number>(0)
  const [selectedLicenseOrCertification, setSelectedLicenseOrCertification] =
    useState<LicenseCertification | undefined>()
  const [selectedHRDocumentName, setSelectedHRDocumentName] = useState<
    | 'driverLicense'
    | 'w9TaxForm'
    | 'w4TaxForm'
    | 'i9TaxForm'
    | 'secondaryFormOfID'
    | 'miscelaneosDocument'
    | undefined
  >()
  const [showSidebarLoader, setSidebarLoader] = useState<boolean>(false)
  const [niprLoader, setNiprLoader] = useState<boolean>(false)

  const [licenses, setLicenses] = useState<LicenseCertification[]>(
    user?.licenses ?? []
  )
  const [certifications, setCertifications] = useState<LicenseCertification[]>(
    user?.certifications ?? []
  )

  const [signNowDataRetrieved, setSignNowDataRetrieved] =
    useState<boolean>(false)
  const [signNowSigningInvitations, setSignNowSigningInvitations] = useState<
    SignNowSigningInvitation[]
  >([])
  const [signNowTemplates, setSignNowTemplates] = useState<SignNowTemplate[]>(
    []
  )
  const [firms, setFirms] = useState<User[]>([])
  const [
    selectedSignNowSigningInvitation,
    setSelectedSignNowSigningInvitation,
  ] = useState<SignNowSigningInvitation | undefined>()

  const [experienceNeedsToBeSaved, setExperienceNeedsToBeSaved] =
    useState(false)
  const [experience, setExperience] = useState<Experience | undefined>(
    user?.experience
  )

  const [getStartedNIPR, setGetStartedNIPR] = useState<boolean>(false)
  const [npn, setNpn] = useState<number | undefined>(user?.npnNumber)

  const [updateUserMutation] = useMutation(UPDATE_USER_BY_EMAIL(user?.email))
  const [updateSignNowInvitationsMutation] = useMutation(
    UPDATE_SIGN_NOW_SIGNING_INVITATION
  )
  const [createNotificationMutation] = useMutation(CREATE_NOTIFICATION)

  const elementsToShow = useMemo(() => {
    return selectedTab === 0 ? [...licenses, ...certifications] : []
  }, [selectedTab, licenses, certifications])

  const screenWidthIsAtListMedium = useMemo(() => {
    return windowsSize.width >= 768
  }, [windowsSize.width])

  const sidebarDisplay = useMemo(() => {
    return !screenWidthIsAtListMedium && selectedLicenseOrCertification
      ? 'none'
      : 'block'
  }, [screenWidthIsAtListMedium, selectedLicenseOrCertification])

  const sidebarWidth = useMemo(() => {
    return screenWidthIsAtListMedium
      ? '25%'
      : !selectedLicenseOrCertification
      ? '100%'
      : '0'
  }, [screenWidthIsAtListMedium, selectedLicenseOrCertification])

  const selectedElementSectionWidth = useMemo(() => {
    if ([2, 3].includes(selectedTab)) return '100%'
    return screenWidthIsAtListMedium
      ? '45%'
      : selectedLicenseOrCertification
      ? '100%'
      : '0'
  }, [screenWidthIsAtListMedium, selectedLicenseOrCertification, selectedTab])

  const niprSectionWidth = useMemo(() => {
    // return screenWidthIsAtListMedium ? '30%' : !selectedElement ? '100%' : '0'
    if ([2, 3].includes(selectedTab)) return '0'
    return screenWidthIsAtListMedium ? '30%' : '0'
  }, [screenWidthIsAtListMedium, selectedTab])

  const retrieveNIPR = useCallback(
    async (npn: number) => {
      console.log(npn)
      if (npn) {
        setNiprLoader(true)
        const result = await getNIPRData({ npn: +npn })
        if (result.success) {
          interface NIPRResult {
            ACTIVE: string
            ADHS: {}
            DATE_EXPIRE_LICENSE: string
            DATE_ISSUE_LICENSE_ORIG: string
            DATE_UPDATED: string
            DETAILS: { DETAIL: {} | {}[] }
            LICENSE_CLASS: string
            LICENSE_CLASS_CODE: string
            LICENSE_NUM: string
            RESIDENCY_STATUS: string
          }
          const data = result?.data
          console.log(data)
          const niprLicenses: {
            state: US_STATES_ABBREVIATIONS
            licenses: NIPRResult[] | NIPRResult
          }[] = data?.licenses
          const niprMetadata = data?.metadata

          let dataFormatted: LicenseCertification[] = []

          niprLicenses.forEach((stateAndLicenses) => {
            const state = stateAndLicenses.state
            const licenses = Array.isArray(stateAndLicenses.licenses)
              ? stateAndLicenses.licenses
              : [stateAndLicenses.licenses]

            const formattedLicenses = licenses
              .map((license) => {
                const sl = stateLicenses?.find(
                  (sl) => sl.code === +license.LICENSE_CLASS_CODE
                )

                const details = Array.isArray(license.DETAILS.DETAIL)
                  ? license.DETAILS.DETAIL
                  : [license.DETAILS.DETAIL]
                const detailsCodes = details
                  .map(
                    (detail) =>
                      loas?.find((l) => l.code === +detail.LOA_CODE)?._id ?? ''
                  )
                  .filter((l) => l)

                return {
                  id: sl?._id ?? '',
                  state,
                  isListed: true,
                  isPrimary: false,
                  name: '',
                  url: '',
                  flag: '',
                  approved: true,
                  source: DATA_SOURCE.NIPR,
                  expiryDate: moment(license.DATE_EXPIRE_LICENSE).format(
                    'YYYY-MM-DD'
                  ),
                  issuedDate: moment(license.DATE_ISSUE_LICENSE_ORIG).format(
                    'YYYY-MM-DD'
                  ),
                  loas: detailsCodes,
                  metadata: { ...license, state },
                }
              })
              .filter((license) => {
                const date1 = new Date(`${license.expiryDate} 00:00:00`)
                const date2 = new Date()
                date2.setHours(0)
                date2.setMinutes(0)
                date2.setSeconds(0)
                //if expiration date is < than current date
                //if the license is expired
                // console.log({ license })
                // console.log(date1, date1.getTime())
                // console.log(date2, date2.getTime())
                // console.log(date1.getTime() < date2.getTime())
                return date1.getTime() > date2.getTime()
              })

            dataFormatted = [...dataFormatted, ...formattedLicenses]
          })

          console.log({ dataFormatted })
          //Deletes old nipr licenses and add new ones
          const partialUser: Partial<User> = {
            niprMetadata,
            licenses: [
              ...(user?.licenses?.filter(
                (l) => l.source !== DATA_SOURCE.NIPR
              ) ?? []),
              ...dataFormatted,
            ],
            npnNumber: npn,
          }

          const mutationResult = await updateUserMutation({
            variables: { user: partialUser },
          })

          if (mutationResult?.data && user) {
            setUser({ ...user, ...partialUser })
            setItemToLocalStorage(LOCALSTORAGE_OBJECTS_NAMES.USER, {
              ...user,
              ...partialUser,
            })
            if (partialUser.licenses) setLicenses(partialUser.licenses)
            //   if (user._id)
            // 	notifyAdmins(
            // 	  `The Adjuster ${user.firstName} ${user.lastName} has updated his documents`,
            // 	  user._id
            // 	)
            showSuccessToast('Licences & Certifications Updated!')
          }

          showSuccessToast('Data retrieved succesfully!')
        } else {
          showErrorToast('There was an error retrieving your data')
        }
        setGetStartedNIPR(false)
        setNpn(undefined)
        setNiprLoader(false)
      }
    },
    [loas, setUser, stateLicenses, updateUserMutation, user]
  )

  //Creates stripe session
  const createSession = useCallback(async () => {
    console.log(npn)
    if (npn) {
      setNiprLoader(true)
      const result = await createStripeSession({
        npn,
        url: `${process.env.REACT_APP_APP_URL}${ADJUSTER_ROUTES.DOCUMENT_CENTER}`,
      })
      if (result.success) {
        window.location.href = result.data
      }

      setNiprLoader(false)
    }
  }, [npn])

  //Retrieves stripe session and if it was paid retrieves nipr
  const retrieveSession = useCallback(async () => {
    if (stripeSessionID && niprNpn) {
      setNiprLoader(true)
      setGetStartedNIPR(true)
      setNpn(+niprNpn)
      navigate(ADJUSTER_ROUTES.DOCUMENT_CENTER)
      //   console.log(stripeSessionID, niprNpn)

      const result = await retrieveStripeSession({ sessionId: stripeSessionID })
      //   console.log(result.data, result.success && result.data === 'paid')
      // If stripe was paid
      if (result.success && result.data === 'paid') {
        await retrieveNIPR(+niprNpn)
      }

      setNiprLoader(false)
    }
  }, [navigate, niprNpn, retrieveNIPR, stripeSessionID])

  // RETRIEVES STRIPE SESSION JUST AFTER THE PAYMENT WAS DONE
  useEffect(() => {
    if (
      stripeSessionID &&
      niprNpn &&
      stateLicenses &&
      stateLicenses?.length > 0
    )
      retrieveSession()
  }, [stripeSessionID, niprNpn, navigate, retrieveSession, stateLicenses])

  const updateExperience = useCallback(async () => {
    const partialUser: Partial<User> = {
      experience,
    }

    const mutationResult = await updateUserMutation({
      variables: { user: partialUser },
    })

    if (mutationResult?.data && user) {
      setExperienceNeedsToBeSaved(false)
      setUser({ ...user, ...partialUser })
      setItemToLocalStorage(LOCALSTORAGE_OBJECTS_NAMES.USER, {
        ...user,
        ...partialUser,
      })

      showSuccessToast('Data retrieved succesfully!')
    } else {
      showErrorToast('There was an error retrieving your data')
    }
  }, [experience, setUser, updateUserMutation, user])

  const retrieveSignNowData = useCallback(async () => {
    setShowSignNowInvitationsLoader(true)
    let result = await getSignNowSigningInvitations({
      variables: {
        adjusterId: user?._id,
      },
      fetchPolicy: 'network-only',
    })

    if (result?.data?.SignNowSigningInvitationMany) {
      const signNowInvitations: SignNowSigningInvitation[] =
        result?.data?.SignNowSigningInvitationMany

      setSignNowSigningInvitations([...signNowInvitations])

      const templateIds = signNowInvitations.map((inv) => inv.templateId)
      result = await getSignNowTemplates({
        variables: { templateId: { $in: templateIds } },
        fetchPolicy: 'network-only',
      })

      if (result?.data?.SignNowTemplateMany)
        setSignNowTemplates(result?.data?.SignNowTemplateMany)

      const firmIds = signNowInvitations.map((s) => s.firmId)
      result = await getUsersByFilter({
        variables: { ids: firmIds },
        fetchPolicy: 'network-only',
      })
      if (result?.data?.UsersByFilter) {
        const users = result?.data?.UsersByFilter
        setFirms([...users])
      }
    }

    setShowSignNowInvitationsLoader(false)
  }, [
    getSignNowSigningInvitations,
    getSignNowTemplates,
    getUsersByFilter,
    user?._id,
  ])

  const storeSignedDocument = useCallback(async () => {
    console.log(user, selectedSignNowSigningInvitation)
    if (user && selectedSignNowSigningInvitation) {
      const result = await createSignNowDownloadLink({
        documentId: selectedSignNowSigningInvitation.documentId,
      })
      const downloadLink = result.data
      //   console.log({ downloadLink })

      const template = signNowTemplates.find(
        (s) => s.templateId === selectedSignNowSigningInvitation.templateId
      )
      const fileName = template?.templateName?.split('.')?.[0] ?? ''
      //   console.log({ fileName })

      let r
      if (fileName) {
        r = await storeFileFromUrl(
          downloadLink,
          `Signed-${fileName}-for-${
            firms.find(
              (firm) => firm._id === selectedSignNowSigningInvitation.firmId
            )?.firmName
          }-${formatDate(new Date().toString())}.pdf`,
          user
        )
        console.log({ r })

        if (r?.success) {
          const partialUser: Partial<User> = {
            signedDocuments: [
              ...(user.signedDocuments ?? []),
              {
                url: r.data,
                documentId: selectedSignNowSigningInvitation.documentId,
              },
            ],
          }
          const mutationResult = await updateUserMutation({
            variables: { user: partialUser },
          })

          setUser({ ...user, ...partialUser })
          setItemToLocalStorage(LOCALSTORAGE_OBJECTS_NAMES.USER, {
            ...user,
            ...partialUser,
          })
        }
      }
    }
  }, [
    firms,
    selectedSignNowSigningInvitation,
    setUser,
    signNowTemplates,
    updateUserMutation,
    user,
  ])

  const updateInvitation = useCallback(async () => {
    const invitationId = selectedSignNowSigningInvitation?._id ?? ''

    const mutationResult = await updateSignNowInvitationsMutation({
      variables: {
        invitation: { status: SIGN_NOW_SIGNING_STATUS.SIGNED },
        id: invitationId,
      },
    })
    if (mutationResult?.data) {
      const index = signNowSigningInvitations.findIndex(
        (s) => s._id === invitationId
      )
      if (index >= 0)
        signNowSigningInvitations[index] = {
          ...signNowSigningInvitations[index],
          status: SIGN_NOW_SIGNING_STATUS.SIGNED,
        }
      setSignNowSigningInvitations([...signNowSigningInvitations])

      const invitation = signNowSigningInvitations[index]
      const template = signNowTemplates.find(
        (s) => s.templateId === invitation.templateId
      )

      await createNotificationMutation({
        variables: {
          notification: {
            userId: invitation.adjusterId,
            message: `${user?.firstName} ${user?.lastName} has signed the document "${template?.templateName}".`,
            link: `${FIRM_ROUTES.DOCUMENT_CENTER}`,
            read: false,
          },
        },
      })

      showSuccessToast('Document signed succesfully!')
    } else {
      showErrorToast('There was an error signing the document')
    }
  }, [
    createNotificationMutation,
    selectedSignNowSigningInvitation?._id,
    signNowSigningInvitations,
    signNowTemplates,
    updateSignNowInvitationsMutation,
    user?.firstName,
    user?.lastName,
  ])

  useEffect(() => {
    if (!signNowDataRetrieved) {
      setSignNowDataRetrieved(true)
      retrieveSignNowData()
    }
  }, [retrieveSignNowData, signNowDataRetrieved])

  const invitationsColumns = [
    signNowSigningInvitationColumnHelper.accessor('inviteId', {
      cell: (info) => {
        const invitation = info.row.original
        const template = signNowTemplates.find(
          (s) => s.templateId === invitation.templateId
        )
        return template?.templateName
      },
      header: 'Name',
    }),
    signNowSigningInvitationColumnHelper.accessor('firmId', {
      cell: (info) =>
        firms.find((firm) => firm._id === info.getValue())?.firmName,
      header: 'Firm',
    }),
    signNowSigningInvitationColumnHelper.accessor('status', {
      cell: (info) => info.getValue()?.split('.')?.[0] ?? '',
      header: 'Status',
    }),
    signNowSigningInvitationColumnHelper.accessor('_id', {
      cell: (info) => {
        const invitation = info.row.original
        return (
          <>
            {invitation.status === SIGN_NOW_SIGNING_STATUS.PENDING ? (
              <FaFileSignature
                title="Sign Document"
                fontSize={20}
                cursor="pointer"
                color={ADJUSTANT_GREEN}
                onClick={() => {
                  // setSelectedSignNowSigningInvitation(invitation)
                  // console.log('A')
                  // storeSignedDocument() // it will work when the second click occurs

                  if (invitation.status === SIGN_NOW_SIGNING_STATUS.PENDING) {
                    setSelectedSignNowSigningInvitation(invitation)
                    onSignDocumentModalOpen()
                  }
                }}
              />
            ) : (
              <FaFileDownload
                title="Download Signed Document"
                fontSize={20}
                cursor="pointer"
                color={ADJUSTANT_GREEN}
                onClick={() => {
                  window
                    .open(
                      user?.signedDocuments?.find(
                        (s) => s.documentId === invitation.documentId
                      )?.url,
                      '_blank'
                    )
                    ?.focus()
                }}
              />
            )}
          </>
        )
      },
      header: '',
    }),
  ]

  return (
    <Box h="100%" width="100%" display="flex">
      <SignDocumentModal
        isOpen={isSignDocumentModalOpen}
        onClose={async (success) => {
          if (success) {
            await storeSignedDocument()
            await updateInvitation()
          }
          onSignDocumentModalClose()
          setSelectedSignNowSigningInvitation(undefined)
        }}
        signNowInvitation={selectedSignNowSigningInvitation}
      />

      {/* Documents lists (sidebar) */}
      <Box
        h="100%"
        display={sidebarDisplay}
        width={sidebarWidth}
        borderRight="1px solid #e3e2e2"
      >
        <Tabs
          variant="adjustant"
          h="100%"
          width="100%"
          index={selectedTab}
          onChange={(index) => setSelectedTab(index)}
        >
          {/* <TabList background="white" width="100%" height={66}>
            <Tab fontWeight={500} width="50%" py={2} mx={0}>
              Licenses and Certifications
            </Tab>
            <Tab fontWeight={500} width="50%" py={2} mx={0}>
              HR Documents
            </Tab>
          </TabList> */}

          <Select
            height={66}
            background="white"
            fontWeight={500}
            color={ADJUSTANT_GREEN}
            _focusVisible={{ boxShadow: '', borderColor: '' }}
            onChange={(e) => {
              //   console.log(e.target.value)
              if (e) setSelectedTab(+e.target.value)
            }}
          >
            <option value={0}>Licenses and Certifications</option>
            <option value={1}>HR Documents</option>
            <option value={2}>Experience</option>
            <option value={3}>Sign Now</option>
          </Select>

          <TabPanels p={2} maxH="calc(100% - 118px)" overflowY="auto">
            {/* Licenses and Certifications */}
            <TabPanel p={0}>
              {!showSidebarLoader && elementsToShow.length > 0 && (
                <>
                  {elementsToShow?.map((element, i) => (
                    <Box
                      key={i}
                      mb={1}
                      borderRadius={8}
                      backgroundColor="white"
                      cursor="pointer"
                      border="1px solid var(--light-mode-base-tertiary, #ECEAE9)"
                      p={4}
                      // h="180px"
                      h="110px"
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      onClick={() => {
                        setSelectedLicenseOrCertification(element)
                      }}
                    >
                      {/* NAME */}
                      <Heading
                        as="h4"
                        fontSize="md"
                        mb={2}
                        height="inherit"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        noOfLines={1}
                        color={
                          element?.approved
                            ? ADJUSTANT_GREEN
                            : element?.flag
                            ? 'red'
                            : 'gray.500'
                        }
                      >
                        {
                          US_STATES.find(
                            (s) => s.abbreviation === element.state
                          )?.name
                        }{' '}
                        -{' '}
                        {element?.name ||
                          stateLicenses?.find((st) => st._id === element.id)
                            ?.name ||
                          trainingCertifications?.find(
                            (st) => st._id === element.id
                          )?.name}
                        {/* Associate Adjuster Associate Adjuster Associate Adjuster */}
                      </Heading>

                      {/* STATE */}
                      {/* <Box
                        color="gray.500"
                        display="flex"
                        mb={1}
                        alignItems="center"
                      >
                        <Box mr={2}>
                          <HiMapPin fontSize={16} />
                        </Box>
                        <Text
                          fontSize="sm"
                          height="inherit"
                        >
                          {
                            US_STATES.find(
                              (s) => s.abbreviation === element.state
                            )?.name
                          }
                        </Text>
                      </Box> */}

                      {/* DATES */}
                      <Box
                        color="gray.500"
                        display="flex"
                        mb={2}
                        alignItems="center"
                      >
                        <Box mr={2}>
                          <HiCalendar fontSize={16} />
                        </Box>
                        <Text fontSize="sm" mr={2}>
                          Iss.{' '}
                          {formatDate(element.issuedDate ?? '', {
                            year: 'numeric',
                            month: 'numeric',
                          })}
                        </Text>
                        <Text fontSize="sm">
                          Exp.{' '}
                          {formatDate(element.expiryDate ?? '', {
                            year: 'numeric',
                            month: 'numeric',
                          })}
                        </Text>
                      </Box>

                      {/* FOOTER */}
                      <HStack justifyContent="space-between">
                        {/* LOAS */}
                        {/* <Box gap={2} display="flex">
                          {element?.loas?.map((loaId) => (
                            <Tag
                              key={loaId}
                              color={ADJUSTANT_GREEN}
                              backgroundColor="gray.100"
                              size="sm"
                            >
                              {loas?.find((l) => l._id === loaId)?.name}
                            </Tag>
                          ))}
                        </Box> */}

                        {/* Document type */}
                        <Box gap={2} display="flex">
                          <Tag
                            color={ADJUSTANT_GREEN}
                            backgroundColor="gray.100"
                            size="sm"
                          >
                            {licenses.find((l) => l.id === element.id)
                              ? 'License'
                              : 'Certification'}
                          </Tag>

                          {element.source === DATA_SOURCE.NIPR && (
                            <Tag
                              color={ADJUSTANT_GREEN}
                              backgroundColor="gray.100"
                              size="sm"
                            >
                              NIPR
                            </Tag>
                          )}
                        </Box>
                      </HStack>
                    </Box>
                  ))}
                </>
              )}
            </TabPanel>

            {/* HR DOCUMENTS */}
            <TabPanel p={0}>
              {/* Driver's License */}
              {user?.driverLicense && (
                <Box
                  mb={1}
                  borderRadius={8}
                  backgroundColor="white"
                  cursor="pointer"
                  border="1px solid var(--light-mode-base-tertiary, #ECEAE9)"
                  p={4}
                  h="55px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  onClick={() => {
                    setSelectedHRDocumentName('driverLicense')
                  }}
                >
                  {/* NAME */}
                  <Heading
                    as="h4"
                    fontSize="md"
                    //   mb={2}
                    height="inherit"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    noOfLines={1}
                    color={
                      user?.driverLicense?.approved
                        ? ADJUSTANT_GREEN
                        : user?.driverLicense?.flag
                        ? 'red'
                        : 'gray.500'
                    }
                  >
                    Driver's License
                  </Heading>
                </Box>
              )}

              {/* W9 Tax Form */}
              {user?.w9TaxForm && (
                <Box
                  mb={1}
                  borderRadius={8}
                  backgroundColor="white"
                  cursor="pointer"
                  border="1px solid var(--light-mode-base-tertiary, #ECEAE9)"
                  p={4}
                  h="55px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  onClick={() => {
                    setSelectedHRDocumentName('w9TaxForm')
                  }}
                >
                  {/* NAME */}
                  <Heading
                    as="h4"
                    fontSize="md"
                    //   mb={2}
                    height="inherit"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    noOfLines={1}
                    color={
                      user?.w9TaxForm?.approved
                        ? ADJUSTANT_GREEN
                        : user?.w9TaxForm?.flag
                        ? 'red'
                        : 'gray.500'
                    }
                  >
                    W9 Tax Form
                  </Heading>
                </Box>
              )}

              {/* W4 Tax Form */}
              {user?.w9TaxForm && (
                <Box
                  mb={1}
                  borderRadius={8}
                  backgroundColor="white"
                  cursor="pointer"
                  border="1px solid var(--light-mode-base-tertiary, #ECEAE9)"
                  p={4}
                  h="55px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  onClick={() => {
                    setSelectedHRDocumentName('w4TaxForm')
                  }}
                >
                  {/* NAME */}
                  <Heading
                    as="h4"
                    fontSize="md"
                    //   mb={2}
                    height="inherit"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    noOfLines={1}
                    color={
                      user?.w4TaxForm?.approved
                        ? ADJUSTANT_GREEN
                        : user?.w4TaxForm?.flag
                        ? 'red'
                        : 'gray.500'
                    }
                  >
                    W4 Tax Form
                  </Heading>
                </Box>
              )}

              {/* I9 Tax Form */}
              {user?.i9TaxForm && (
                <Box
                  mb={1}
                  borderRadius={8}
                  backgroundColor="white"
                  cursor="pointer"
                  border="1px solid var(--light-mode-base-tertiary, #ECEAE9)"
                  p={4}
                  h="55px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  onClick={() => {
                    setSelectedHRDocumentName('i9TaxForm')
                  }}
                >
                  {/* NAME */}
                  <Heading
                    as="h4"
                    fontSize="md"
                    //   mb={2}
                    height="inherit"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    noOfLines={1}
                    color={
                      user?.i9TaxForm?.approved
                        ? ADJUSTANT_GREEN
                        : user?.i9TaxForm?.flag
                        ? 'red'
                        : 'gray.500'
                    }
                  >
                    I9 Tax Form
                  </Heading>
                </Box>
              )}

              {/*  Secondary Form of ID */}
              {user?.secondaryFormOfID && (
                <Box
                  mb={1}
                  borderRadius={8}
                  backgroundColor="white"
                  cursor="pointer"
                  border="1px solid var(--light-mode-base-tertiary, #ECEAE9)"
                  p={4}
                  h="55px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  onClick={() => {
                    setSelectedHRDocumentName('secondaryFormOfID')
                  }}
                >
                  {/* NAME */}
                  <Heading
                    as="h4"
                    fontSize="md"
                    //   mb={2}
                    height="inherit"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    noOfLines={1}
                    color={
                      user?.secondaryFormOfID?.approved
                        ? ADJUSTANT_GREEN
                        : user?.secondaryFormOfID?.flag
                        ? 'red'
                        : 'gray.500'
                    }
                  >
                    Secondary Form of ID
                  </Heading>
                </Box>
              )}

              {/*  Miscelaneous document */}
              {user?.miscelaneosDocument && (
                <Box
                  mb={1}
                  borderRadius={8}
                  backgroundColor="white"
                  cursor="pointer"
                  border="1px solid var(--light-mode-base-tertiary, #ECEAE9)"
                  p={4}
                  h="55px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  onClick={() => {
                    setSelectedHRDocumentName('miscelaneosDocument')
                  }}
                >
                  {/* NAME */}
                  <Heading
                    as="h4"
                    fontSize="md"
                    //   mb={2}
                    height="inherit"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    noOfLines={1}
                    color={
                      user?.miscelaneosDocument?.approved
                        ? ADJUSTANT_GREEN
                        : user?.miscelaneosDocument?.flag
                        ? 'red'
                        : 'gray.500'
                    }
                  >
                    Miscellaneous Document
                  </Heading>
                </Box>
              )}
            </TabPanel>

            {/* EXPERIENCE */}
            <TabPanel p={0}></TabPanel>
          </TabPanels>

          {!showSidebarLoader && ![2, 3].includes(selectedTab) && (
            <Box px={2}>
              <Button
                w="100%"
                variant="adjustant"
                onClick={() =>
                  navigate(
                    selectedTab === 0
                      ? ADJUSTER_ROUTES.UPDATE_LICENCES_AND_CERTIFICATIONS
                      : GLOBAL_ROUTES.UPDATE_PROFILE
                  )
                }
              >
                Update
              </Button>
            </Box>
          )}

          {/* <VStack px={2} gap={0}>
            {showSidebarLoader && (
              <Stack direction="row" my={4}>
                <Spinner size="xl" />
              </Stack>
            )}

            {!showSidebarLoader && elementsToShow.length === 0 && (
              <Text my={8}>No elements to show</Text>
            )}
          </VStack> */}
        </Tabs>
      </Box>

      {/* Document details */}
      <Box
        h="100%"
        overflowY="auto"
        width={selectedElementSectionWidth}
        backgroundColor="white"
      >
        <Box p={[4, 8]} width="100%">
          {selectedLicenseOrCertification && selectedTab === 0 && (
            <>
              {!screenWidthIsAtListMedium && (
                <HStack justifyContent="space-between" mb={4}>
                  <Box
                    fontSize={30}
                    cursor="pointer"
                    onClick={() => {
                      setSelectedLicenseOrCertification(undefined)
                    }}
                  >
                    <BiArrowBack />
                  </Box>
                </HStack>
              )}
              <Heading
                mb={6}
                gap={2}
                display={['block', 'block', 'flex']}
                justifyContent="space-between"
                alignItems="center"
              >
                {selectedLicenseOrCertification?.name ||
                  stateLicenses?.find(
                    (st) => st._id === selectedLicenseOrCertification.id
                  )?.name ||
                  trainingCertifications?.find(
                    (st) => st._id === selectedLicenseOrCertification.id
                  )?.name}
              </Heading>
              {/* STATE AND DATES */}
              <HStack alignItems="center" mb={2}>
                {/* STATE */}
                <Box color="gray.500" display="flex" alignItems="center">
                  <Box mr={2}>
                    <HiMapPin fontSize={16} />
                  </Box>
                  <Text
                    height="inherit"
                    //   textOverflow="ellipsis"
                    //   overflow="hidden"
                    //   noOfLines={2}
                  >
                    {
                      US_STATES.find(
                        (s) =>
                          s.abbreviation ===
                          selectedLicenseOrCertification.state
                      )?.name
                    }
                  </Text>
                </Box>

                {/* DATES */}
                <Box color="gray.500" display="flex" alignItems="center">
                  <Box mr={2}>
                    <HiCalendar fontSize={16} />
                  </Box>
                  <Text mr={2}>
                    Iss.{' '}
                    {formatDate(
                      selectedLicenseOrCertification.issuedDate ?? '',
                      {
                        year: 'numeric',
                        month: 'numeric',
                      }
                    )}
                  </Text>
                  <Text>
                    Exp.{' '}
                    {formatDate(
                      selectedLicenseOrCertification.expiryDate ?? '',
                      {
                        year: 'numeric',
                        month: 'numeric',
                      }
                    )}
                  </Text>
                </Box>
              </HStack>
              {/* STATUS */}
              <Box
                color="gray.500"
                display="flex"
                alignItems="center"
                gap={2}
                mb={2}
              >
                <Text as="b">Status:</Text>
                <Text>
                  {selectedLicenseOrCertification?.approved
                    ? 'Approved'
                    : selectedLicenseOrCertification?.flag
                    ? 'Flagged'
                    : 'Pending Approval'}
                </Text>
              </Box>
              <Box color="red" display="flex" alignItems="center" gap={2}>
                <Text as="b">
                  {getFlagReason(selectedLicenseOrCertification?.flag)?.label ??
                    selectedLicenseOrCertification?.flag}
                </Text>
              </Box>
              {/* Divider */}
              <Box my={4}>
                <Divider />
              </Box>
              {/* Details */}
              <Stack>
                <Text as="b" size="xl">
                  License Details
                </Text>
                <Stack>
                  {selectedLicenseOrCertification?.loas?.map((loaId) => (
                    <Tag
                      key={loaId}
                      color={ADJUSTANT_GREEN}
                      backgroundColor="gray.100"
                      size="sm"
                      w="max-content"
                    >
                      {loas?.find((l) => l._id === loaId)?.name}
                    </Tag>
                  ))}
                </Stack>
              </Stack>
            </>
          )}

          {selectedHRDocumentName && selectedTab === 1 && (
            <>
              {!screenWidthIsAtListMedium && (
                <HStack justifyContent="space-between" mb={4}>
                  <Box
                    fontSize={30}
                    cursor="pointer"
                    onClick={() => {
                      setSelectedLicenseOrCertification(undefined)
                    }}
                  >
                    <BiArrowBack />
                  </Box>
                </HStack>
              )}

              {selectedHRDocumentName === 'driverLicense' && (
                <>
                  <Heading
                    mb={6}
                    gap={2}
                    display={['block', 'block', 'flex']}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    Driver's License
                  </Heading>
                  <UpdateDriverLicense
                    editable={false}
                    id={''}
                    address={user?.driverLicense?.address ?? ''}
                    city={user?.driverLicense?.city ?? ''}
                    state={user?.driverLicense?.state ?? ''}
                    zipcode={user?.driverLicense?.zipcode ?? ''}
                    birthDate={user?.driverLicense?.birthDate ?? ''}
                    url={user?.driverLicense?.url ?? ''}
                    fileName={
                      getFileNameFromURL(user?.driverLicense?.url) ?? ''
                    }
                  />
                </>
              )}

              {selectedHRDocumentName === 'w9TaxForm' && (
                <>
                  <Heading
                    mb={6}
                    gap={2}
                    display={['block', 'block', 'flex']}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    W9 Tax Form
                  </Heading>
                  <UpdateTaxForm
                    editable={false}
                    url={user?.w9TaxForm?.url ?? ''}
                    fileName={getFileNameFromURL(user?.w9TaxForm?.url) ?? ''}
                    align="start"
                    hideFile={!!user?.w9TaxForm?.approved}
                    officialDocumentURL={W9_FORM_URL}
                    documentName="W-9 Form"
                  />
                </>
              )}

              {selectedHRDocumentName === 'w4TaxForm' && (
                <>
                  <Heading
                    mb={6}
                    gap={2}
                    display={['block', 'block', 'flex']}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    W4 Tax Form
                  </Heading>
                  <UpdateTaxForm
                    editable={false}
                    url={user?.w4TaxForm?.url ?? ''}
                    fileName={getFileNameFromURL(user?.w4TaxForm?.url) ?? ''}
                    align="start"
                    hideFile={!!user?.w4TaxForm?.approved}
                    officialDocumentURL={W4_FORM_URL}
                    documentName="W-4 Form"
                  />
                </>
              )}

              {selectedHRDocumentName === 'i9TaxForm' && (
                <>
                  <Heading
                    mb={6}
                    gap={2}
                    display={['block', 'block', 'flex']}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    I9 Tax Form
                  </Heading>
                  <UpdateTaxForm
                    editable={false}
                    url={user?.i9TaxForm?.url ?? ''}
                    fileName={getFileNameFromURL(user?.i9TaxForm?.url) ?? ''}
                    hideFile={!!user?.i9TaxForm?.approved}
                    officialDocumentURL={I9_FORM_URL}
                    documentName="I-9 Form"
                  />
                </>
              )}

              {selectedHRDocumentName === 'secondaryFormOfID' && (
                <>
                  <Heading
                    mb={6}
                    gap={2}
                    display={['block', 'block', 'flex']}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    Secondary Form of ID
                  </Heading>

                  <UpdateTaxForm
                    editable={false}
                    align="start"
                    url={user?.secondaryFormOfID?.url ?? ''}
                    fileName={
                      getFileNameFromURL(user?.secondaryFormOfID?.url) ?? ''
                    }
                    hideFile={!!user?.secondaryFormOfID?.approved}
                    documentName="Secondary form of ID"
                  />
                </>
              )}

              {selectedHRDocumentName === 'miscelaneosDocument' && (
                <>
                  <Heading
                    mb={6}
                    gap={2}
                    display={['block', 'block', 'flex']}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    Miscellaneous Document
                  </Heading>

                  <UpdateMiscellaneosDocument
                    editable={false}
                    align="start"
                    url={user?.miscelaneosDocument?.url ?? ''}
                    fileName={
                      getFileNameFromURL(user?.miscelaneosDocument?.url) ?? ''
                    }
                    description={user?.miscelaneosDocument?.description ?? ''}
                  />
                </>
              )}
            </>
          )}

          {selectedTab === 2 && (
            <>
              <Heading
                mb={6}
                gap={2}
                display={['block', 'block', 'flex']}
                justifyContent="space-between"
                alignItems="center"
              >
                Adjusting experience listed in months
                {experienceNeedsToBeSaved && (
                  <Button
                    mt={4}
                    variant="adjustant"
                    onClick={() => updateExperience()}
                  >
                    Update
                  </Button>
                )}
              </Heading>

              <Stack mt={16} gap={4}>
                {Object.keys(EXPERIENCE_TYPES).map((key, index) => (
                  <ExperienceSlider
                    key={index}
                    first
                    label={EXPERIENCE_TYPES[key as keyof Experience] ?? ''}
                    value={experience?.[key as keyof Experience] ?? 0}
                    onChange={(val) => {
                      setExperience((prev) => ({
                        ...prev,
                        [key as keyof Experience]: val,
                      }))
                      setExperienceNeedsToBeSaved(true)
                    }}
                  />
                ))}
              </Stack>
            </>
          )}

          {selectedTab === 3 && (
            <>
              <Heading
                mb={6}
                gap={2}
                display={['block', 'block', 'flex']}
                justifyContent="space-between"
                alignItems="center"
              >
                Documents to Sign
              </Heading>

              <Stack mt={16} gap={4}>
                {showSignNowInvitationsLoader ? (
                  <HStack
                    direction="row"
                    my={4}
                    height="-webkit-fill-available"
                    w="100%"
                    // h="100%"
                    justifyContent="center"
                  >
                    <Spinner size="xl" />
                  </HStack>
                ) : (
                  <>
                    <HStack width="100%" justifyContent="end"></HStack>
                    <CustomTable
                      data={signNowSigningInvitations}
                      columns={invitationsColumns}
                    />
                  </>
                )}
              </Stack>
            </>
          )}
        </Box>

        {/* {showSelectedElementDetailsLoader && (
          <HStack direction="row" h="100%" justifyContent="center">
            <Spinner size="xl" />
          </HStack>
        )} */}
      </Box>

      {/* NIPR Section */}
      <Box
        h="100%"
        overflowY="auto"
        width={niprSectionWidth}
        backgroundColor="white"
        ml={1}
      >
        <VStack m={[4, 8]}>
          <Image src="/images/nipr_logo.png" alt="NIPR Logo" />
          <Divider />
          <Text>
            Adjustant offers automatic license upload and verification through
            NIPR's Producer Database.
          </Text>

          <Text>
            Using your National Producer Number (NPN) we retrieve the current
            information on all your State Licenses. This option will add or
            update your existing licenses to the portal automatically.
          </Text>

          <Text>
            Licenses are approved immediately avoiding the time it takes for
            your manual license uploads to be verified and approved, making your
            deployable more quickly.
          </Text>

          {!getStartedNIPR && (
            <Button
              mt={4}
              variant="outline"
              onClick={() => setGetStartedNIPR(true)}
            >
              Get Started
            </Button>
          )}

          {getStartedNIPR && (
            <>
              <Input
                mt={4}
                type="number"
                value={npn}
                placeholder="npn"
                borderRadius={3}
                onChange={(e) => {
                  setNpn(+e.target.value)
                }}
              />
              <Box justifyContent="start" width="100%">
                <Link
                  color={ADJUSTANT_GREEN}
                  fontWeight={600}
                  onClick={() =>
                    window
                      .open(`https://nipr.com/help/look-up-your-npn`, '_blank')
                      ?.focus()
                  }
                >
                  NPN Lookup
                </Link>
              </Box>

              <Text fontSize="sm" as="i" fontWeight="600" mb={4}>
                Be sure to verify your NPN. You will be charged $9.00 for each
                NIPR Sync
              </Text>
              <Button
                variant="outline"
                onClick={() => createSession()}
                isDisabled={!npn}
                isLoading={niprLoader}
              >
                Get NIPR Licenses
              </Button>
            </>
          )}
        </VStack>
      </Box>
    </Box>
  )
}

export default AdjusterJobs
