import { useRef } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import Label from '../Label/Label'

import { isNumeric } from '../../utils/functions'
import ReactSelect from 'react-select'

export interface SelectableItem {
  label: string
  value: any
  disabled?: boolean
}

interface DropdownProps {
  value?: number | string
  type?: 'string' | 'number'
  options?: SelectableItem[]
  fontSize?: string
  width?: string | string[]
  placeholder?: string
  leftElement?: JSX.Element
  leftElementTooltipLabel?: string
  label?: string
  isRequired?: boolean
  isSearchable?: boolean
  isDisabled?: boolean
  handleChange: (value: any) => void
  hasError?: boolean
  errorMessage?: string
  onBlur?: () => void
  textAlign?: 'end' | 'start'
}

const defaultOptions: SelectableItem[] = [
  { label: '0', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
]

const CustomDropdown = ({
  value,
  type = 'string',
  options = defaultOptions,
  fontSize = 'inherit',
  width = '100%',
  placeholder = '',
  leftElement,
  leftElementTooltipLabel = '',
  label,
  handleChange,
  onBlur,
  isRequired = false,
  isSearchable = false,
  isDisabled = false,
  hasError = false,
  errorMessage,
  textAlign,
}: DropdownProps) => {
  const ref = useRef(null)
  const opt = [{ value: '', label: placeholder || 'Select Value' }, ...options]
  //   const [selectContainer, setSelectContainer] = useState<Element>()

  //   useEffect(() => {
  //     const box = ref.current
  //     if (box !== null) {
  //       const select = (box as Element).children[1]
  //       setSelectContainer(select)
  //     }
  //   }, [ref])

  //   const renderOptions = useCallback(() => {
  //     const optionsArr: any[] = []
  //     options.forEach((option) => {
  //       if (option.value === '') return
  //       optionsArr.push(
  //         <option
  //           key={option.value + option.label}
  //           value={option.value}
  //           disabled={option.disabled}
  //         >
  //           {option.label}
  //         </option>
  //       )
  //     })
  //     return optionsArr
  //   }, [options])

  return (
    <Box w={width} ref={ref} mb={2}>
      {label && (
        <Flex justify="start" mb={2} gap={1}>
          <Label fontSize="sm" color="gray.700">
            {label}
          </Label>
          <Label fontSize="md" color="red.400">
            {isRequired && '*'}
          </Label>
        </Flex>
      )}
      {/* <Select
        width="100%"
        onChange={(e) =>
          handleChange(
            type === 'number' && isNumeric(e.target.value)
              ? parseFloat(e.target.value)
              : e.target.value
          )
        }
        borderColor="gray.300"
        placeholder={placeholder}
        size="md"
        style={{ textIndent: '23px' }}
        defaultValue={undefined}
        value={value}
        textAlign="right"
        isRequired={isRequired}
        onBlur={onBlur}
        isInvalid={hasError}
      >
        {renderOptions()}
      </Select> */}
      <ReactSelect
        styles={{
          container: (baseStyles, state) => ({
            ...baseStyles,
            width: '-webkit-fill-available',
            borderColor: 'red',
            fontSize,
          }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: 'var(--chakra-colors-gray-300)',
            background: 'transparent',
            textAlign,
          }),
          placeholder: (baseStyles, state) => ({
            ...baseStyles,
            color: 'black',
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            ':hover': {
              cursor: 'pointer',
            },
          }),
          //   dropdownIndicator: (baseStyles, state) => ({
          //     ...baseStyles,
          //     // width: '22px',
          //     height: 22,
          //   }),
          //   menu: (baseStyles, state) => ({
          //     ...baseStyles,
          //     zIndex: 9999,
          //   }),
        }}
        // menuIsOpen
        options={opt}
        placeholder={placeholder}
        isSearchable={isSearchable}
        isClearable={false}
        value={opt.find((o) => o.value === value)}
        isDisabled={isDisabled}
        onChange={(e) => {
          const newValue = e?.value
          console.log(e)
          //   if (newValue) {
          handleChange(
            type === 'number' &&
              isNumeric(newValue) &&
              typeof newValue === 'string'
              ? parseFloat(newValue)
              : newValue
          )
          //   }
        }}
      />
      {/* {selectContainer
        ? ReactDOM.createPortal(
            <Tooltip label={leftElementTooltipLabel} placement="top">
              <Box position="absolute" top="12px" left="10px" display="flex">
                {leftElement}
              </Box>
            </Tooltip>,
            selectContainer
          )
        : ''}
      {hasError && (
        <Text color="red" fontSize="xs">
          {errorMessage || 'Please fix'}
        </Text>
      )} */}
    </Box>
  )
}
export default CustomDropdown
