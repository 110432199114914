import { HStack, Stack, Avatar, Tag, Text, Box, Link } from '@chakra-ui/react'
import { IoMdOpen } from 'react-icons/io'
import { GLOBAL_ROUTES } from '../../App'
import { ADJUSTANT_GREEN } from '../../themes/themes'
import { Application, jobTypeOptions } from '../../types/jobTypes'
import { User, UserContextType } from '../../types/userTypes'
import { useContext } from 'react'
import { UserContext } from '../../contexts/UserContext'
import { getPathRoute } from '../../utils/functions'

const AdjusterCard = ({
  adjuster,
  customHeaderContent,
  customFooterContent,
  application,
  replaceAdjusterName,
  color = ADJUSTANT_GREEN,
}: {
  adjuster?: User
  customHeaderContent?: any
  customFooterContent?: any
  application?: Application
  replaceAdjusterName?: boolean
  color?: string
}) => {
  const { user }: UserContextType = useContext(UserContext)
  return (
    <Box py={4} w="100%">
      <HStack
        justifyContent="space-between"
        alignItems={['start', 'start', 'center']}
        spacing={2}
        flexDirection={['column', 'column', 'row']}
      >
        <HStack>
          <Stack width="40px" height="40px">
            <Avatar
              size="full"
              src={adjuster?.profilePicture || '/images/no_profile_picture.png'}
            />
          </Stack>
          <Stack gap={0} ml={2}>
            {replaceAdjusterName ? (
              <Link
                fontSize="sm"
                fontWeight="bold"
                color={ADJUSTANT_GREEN}
                onClick={() =>
                  window
                    .open(
                      `${getPathRoute(user)}${GLOBAL_ROUTES.PROFILE}/${
                        adjuster?._id
                      }`,
                      '_blank'
                    )
                    ?.focus()
                }
              >
                {/* name redacted - employee number {adjuster?._id} */}
                {adjuster?.firstName ?? ''} {adjuster?.lastName?.[0] ?? ''}
              </Link>
            ) : (
              <Text fontSize="sm" fontWeight="bold" color={color}>
                {adjuster?.firstName ?? ''} {adjuster?.lastName ?? ''}
              </Text>
            )}
            {!replaceAdjusterName && (
              <Text color="gray.500" fontSize="sm">
                {adjuster?.location ?? ''}
              </Text>
            )}
          </Stack>
        </HStack>
        {customHeaderContent}
      </HStack>
      <Box px={2} my={2} height={50}>
        <Text
          height="inherit"
          textOverflow="ellipsis"
          overflow="hidden"
          noOfLines={2}
        >
          {application ? (
            <>
              <b>Cover letter -</b> {application.message}
            </>
          ) : (
            adjuster?.bio || 'No bio added'
          )}
        </Text>
      </Box>
      <HStack
        justifyContent="space-between"
        alignItems={['start', 'start', 'center']}
        spacing={2}
        flexDirection={['column', 'column', 'row']}
      >
        <HStack>
          {adjuster?.jobTypes?.map((j, ji) => (
            <Tag
              key={ji}
              color={ADJUSTANT_GREEN}
              backgroundColor="gray.100"
              size="sm"
            >
              {jobTypeOptions.find((o) => o.value === j)?.label}
            </Tag>
          ))}
          {(!adjuster?.jobTypes || adjuster?.jobTypes?.length === 0) && (
            <Text>No job types added</Text>
          )}
        </HStack>
        <HStack alignItems="center">
          {customFooterContent}
          <IoMdOpen
            color={ADJUSTANT_GREEN}
            cursor="pointer"
            fontSize="large"
            onClick={() =>
              window
                .open(
                  `${getPathRoute(user)}${GLOBAL_ROUTES.PROFILE}/${
                    adjuster?._id
                  }`,
                  '_blank'
                )
                ?.focus()
            }
          />
        </HStack>
      </HStack>
    </Box>
  )
}

export default AdjusterCard
