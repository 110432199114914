import React, { useCallback, useContext, useMemo } from 'react'
import {
  JobCertificationRequirement,
  JobLicensingRequirement,
  RequiredTypes,
  requirementOptions,
} from '../../types/jobTypes'
import {
  CertificationRequirement,
  LicensingRequirement,
} from '../../types/userTypes'
import CustomRadio from '../CustomRadio/CustomRadio'
import { Box, Button, HStack, Input, Stack } from '@chakra-ui/react'
import { AiOutlinePlus } from 'react-icons/ai'
import CustomDropdown, {
  SelectableItem,
} from '../CustomDropdown/CustomDropdown'
import { StateLicesesContext } from '../../contexts/StateLicesesContext'
import { TrainingCertificationsContext } from '../../contexts/TrainingCertificationsContext'
import {
  ListedStateLicense,
  StateLicenseContextType,
  US_STATES,
  US_STATES_ABBREVIATIONS,
} from '../../types/stateLicensesTypes'
import { TrainingCertificationContextType } from '../../types/trainingCertificationsTypes'
import { BsFillTrashFill } from 'react-icons/bs'
import { ADJUSTANT_GREEN } from '../../themes/themes'
import CustomDropdownMultiple from '../CustomDropdownMultiple/CustomDropdownMultiple'
import { LOASContext } from '../../contexts/LOASContext'
import { LOASContextType } from '../../types/loasTypes'
import Label from '../Label/Label'

const getEmptyLicense = (): JobLicensingRequirement => ({
  id: '',
  type: RequiredTypes.RECOMMENDED,
  isListed: true,
  state: '',
  loas: [],
})

const getEmptyCertification = (
  isListed: boolean
): JobCertificationRequirement => ({
  id: '',
  name: '',
  type: RequiredTypes.RECOMMENDED,
  isListed,
})

const SelectJobLicensesAndCertifications = ({
  certificationOptions,
  licensesOptions,
  certifications,
  licenses,
  setCertifications,
  setLicenses,
  hideRadios,
  hideLicensesDetails,
  unshiftCustomOptions,
}: {
  certificationOptions: CertificationRequirement[]
  licensesOptions: LicensingRequirement[]
  certifications: JobCertificationRequirement[]
  licenses: JobLicensingRequirement[]
  setCertifications: React.Dispatch<
    React.SetStateAction<JobCertificationRequirement[]>
  >
  setLicenses: React.Dispatch<React.SetStateAction<JobLicensingRequirement[]>>
  hideRadios?: boolean
  hideLicensesDetails?: boolean
  unshiftCustomOptions?: boolean
}) => {
  const { stateLicenses }: StateLicenseContextType =
    useContext(StateLicesesContext)
  const { trainingCertifications }: TrainingCertificationContextType =
    useContext(TrainingCertificationsContext)
  const { loas }: LOASContextType = useContext(LOASContext)

  const statesOptions: SelectableItem[] = US_STATES.map((s) => {
    return { value: s.abbreviation, label: s.name }
  })

  const licensesOptionsMapped = useMemo<SelectableItem[]>(() => {
    return (
      licensesOptions?.map<SelectableItem>((s) => ({
        label: stateLicenses?.find((st) => st._id === s.id)?.name || '',
        value: s.id,
        disabled: !!licenses.find((l) => l.id === s.id),
      })) ?? []
    )
  }, [licenses, licensesOptions, stateLicenses])

  const certificationOptionsMapped = useMemo<SelectableItem[]>(() => {
    return (
      certificationOptions
        ?.map((c) => ({
          label: c?.name,
          value: c.id,
          disabled: !!certifications.find((l) => l.id === c.id),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) ?? []
    )
  }, [certificationOptions, certifications])

  const addEmptyLicense = useCallback(() => {
    setLicenses((prevValue) => [...prevValue, getEmptyLicense()])
  }, [setLicenses])

  const deleteLicense = useCallback(
    (index: number) => {
      licenses.splice(index, 1)
      setLicenses([...licenses])
    },
    [licenses, setLicenses]
  )

  const addEmptyCertification = useCallback(
    (isListed: boolean) => {
      setCertifications([...certifications, getEmptyCertification(isListed)])
    },
    [setCertifications, certifications]
  )

  const deleteCertification = useCallback(
    (index: number) => {
      certifications.splice(index, 1)
      setCertifications([...certifications])
    },
    [certifications, setCertifications]
  )

  const onLicenseChange = useCallback(
    ({
      index,
      data,
    }: {
      index: number
      data: Partial<JobLicensingRequirement>
    }) => {
      const lClone = [...licenses]
      lClone[index] = { ...lClone[index], ...data }
      setLicenses([...lClone])
    },
    [licenses, setLicenses]
  )

  const onCertificationChange = useCallback(
    ({
      index,
      certificationType,
      certificationId,
      name,
    }: {
      index: number
      certificationType?: RequiredTypes
      certificationId?: string
      name?: string
    }) => {
      const cClone = [...certifications]

      if (certificationType)
        cClone[index] = { ...cClone[index], type: certificationType }

      if (certificationId)
        cClone[index] = { ...cClone[index], id: certificationId ?? '' }

      if (typeof name === 'string')
        cClone[index] = { ...cClone[index], name: name ?? '' }

      cClone[index].isListed =
        certificationOptions.find((co) => co.id === cClone[index].id)
          ?.isListed ?? false

      setCertifications([...cClone])
    },
    [certificationOptions, certifications, setCertifications]
  )

  const isAddLicenseButtonDisabled = useMemo(() => {
    return !!licenses.find((l) => !l.id)
  }, [licenses])

  const isAddCertificationButtonDisabled = useMemo(() => {
    return !!certifications.find((l) => !l.id && !l.name)
  }, [certifications])

  const filterStateOptions = useCallback(
    (licenseId: string) => {
      const licenseStates = stateLicenses?.find(
        (l) => l._id === licenseId
      )?.states
      if (!licenseStates) return []
      const keys = Object.keys(licenseStates)
      return statesOptions.filter((s) => keys.includes(s.value))
    },
    [stateLicenses, statesOptions]
  )

  const getFilteredStateLicensesMapped = useCallback(
    (filteredStateLicenses?: ListedStateLicense[]): SelectableItem[] => {
      return (
        filteredStateLicenses
          ?.map((m) => ({
            value: m._id,
            label: m.name,
            disabled: !!licenses.find((l) => l.id === m._id),
          }))
          ?.sort((a, b) => a.label.localeCompare(b.label)) ?? []
      )
    },
    [licenses]
  )

  const getFilteredLicensesOptions = useCallback(
    (state: string): SelectableItem[] => {
      const op = getFilteredStateLicensesMapped(
        stateLicenses?.filter((sl) =>
          (Object.keys(sl.states ?? {}) ?? []).includes(state)
        )
      )
      if (unshiftCustomOptions)
        op.unshift({
          value: '0',
          label: 'Not Applicable - State License not required',
          disabled: !!licenses.find((l) => l.id === '0'),
        })
      return op
    },
    [
      getFilteredStateLicensesMapped,
      licenses,
      stateLicenses,
      unshiftCustomOptions,
    ]
  )

  const filterLoas = useCallback(
    (
      licenseId: string,
      state: US_STATES_ABBREVIATIONS | string
    ): SelectableItem[] => {
      if (!licenseId || !state) return []
      const licenseStates = stateLicenses?.find(
        (l) => l._id === licenseId
      )?.states
      if (!licenseStates) return []
      const stateLoas = licenseStates[state as US_STATES_ABBREVIATIONS]
      const filteredLoas = loas
        ?.filter((loa) => stateLoas?.includes(loa.code))
        ?.map((loa) => ({
          label: loa.name,
          //   value: loa.code,
          value: loa._id,
          //   id: loa._id,
        }))
      return filteredLoas ?? []
    },
    [loas, stateLicenses]
  )

  return (
    <Box>
      <Box backgroundColor="gray.50" mb={4} p={2}>
        {licenses.map((license, index) => (
          <Box key={`${license.id}-${index}`} mb={2}>
            {/* State */}
            <HStack
              justifyContent="space-between"
              alignItems="start"
              position="relative"
            >
              <CustomDropdown
                label="State"
                placeholder="Select a state"
                options={statesOptions}
                value={license.state}
                handleChange={(state) => {
                  onLicenseChange({
                    index,
                    data: { state, id: '', loas: [] },
                  })
                }}
              />
              <Box position="absolute" right={0}>
                <BsFillTrashFill onClick={() => deleteLicense(index)} />
              </Box>
            </HStack>

            {/* License */}
            <CustomDropdown
              isSearchable
              label="License Name"
              placeholder={
                // getFilteredLicensesOptions(license.state).length > 0
                //   ? ''
                //   : 'Select a License'
                'Select a License'
              }
              options={getFilteredLicensesOptions(license.state)}
              value={license.id}
              handleChange={(licenseId) => {
                onLicenseChange({ index, data: { id: licenseId, loas: [] } })
              }}
            />

            {/* Loas */}
            {!hideLicensesDetails && license.id !== '0' && (
              <CustomDropdownMultiple
                label="Select LOAs"
                value={
                  license.loas?.map((loaId) => ({
                    value: loaId,
                    label: loas?.find((loa) => loa._id === loaId)?.name ?? '',
                  })) ?? []
                }
                options={filterLoas(license.id, license.state)}
                isClearable
                onChange={(loas) => {
                  onLicenseChange({ index, data: { loas } })
                }}
              />
            )}

            {/* Needed Or Recommended */}
            {!hideRadios && license.id !== '0' && (
              <CustomRadio
                label=""
                subLabel=""
                value={license.type ?? ''}
                options={requirementOptions}
                onChange={(licenseType) => {
                  onLicenseChange({ index, data: { type: licenseType } })
                }}
              />
            )}
          </Box>
        ))}
        <Button
          color={ADJUSTANT_GREEN}
          variant="link"
          leftIcon={<AiOutlinePlus />}
          mt={2}
          isDisabled={isAddLicenseButtonDisabled}
          onClick={addEmptyLicense}
        >
          Add License
        </Button>
      </Box>

      <Box backgroundColor="gray.50" p={2}>
        {certifications.map((certification, index) => (
          <Box key={`${certification.id}-${index}`} mb={2}>
            <HStack
              justifyContent="space-between"
              alignItems="start"
              position="relative"
            >
              {certification.isListed ? (
                <CustomDropdown
                  isSearchable
                  label="Certification Name"
                  placeholder="Select a Certification"
                  options={certificationOptionsMapped}
                  value={certification.id}
                  handleChange={(certificationId) => {
                    onCertificationChange({ index, certificationId })
                  }}
                />
              ) : (
                <Stack w="100%">
                  <Label fontSize="sm" color="gray.700">
                    Certification Name
                  </Label>
                  <Input
                    type="text"
                    placeholder="What is the name of the certification?"
                    value={certification.name}
                    onChange={(e) => {
                      onCertificationChange({ index, name: e.target.value })
                    }}
                  />
                </Stack>
              )}

              <Box cursor="pointer" position="absolute" right={0}>
                <BsFillTrashFill onClick={() => deleteCertification(index)} />
              </Box>
            </HStack>
            {!hideRadios && (
              <CustomRadio
                label=""
                subLabel=""
                value={certification.type ?? ''}
                options={requirementOptions}
                onChange={(certificationType) => {
                  onCertificationChange({ index, certificationType })
                }}
              />
            )}
          </Box>
        ))}
        <Stack alignItems="start">
          <Button
            color={ADJUSTANT_GREEN}
            variant="link"
            leftIcon={<AiOutlinePlus />}
            mt={2}
            isDisabled={isAddCertificationButtonDisabled}
            onClick={() => addEmptyCertification(true)}
          >
            Add Certification
          </Button>
          <Button
            color={ADJUSTANT_GREEN}
            variant="link"
            leftIcon={<AiOutlinePlus />}
            // mt={2}
            w="max-content"
            isDisabled={isAddCertificationButtonDisabled}
            onClick={() => addEmptyCertification(false)}
          >
            Can’t find a certification? Click here to create it
          </Button>
        </Stack>
      </Box>
    </Box>
  )
}

export default SelectJobLicensesAndCertifications
