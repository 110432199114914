import {
  Accordion,
  AccordionItem,
  HStack,
  Stack,
  InputGroup,
  Input,
  InputRightAddon,
  AccordionButton,
  AccordionPanel,
  Flex,
  Box,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react'

import { FaFilter } from 'react-icons/fa'
import { HiMagnifyingGlass } from 'react-icons/hi2'
import { ADJUSTANT_GREEN } from '../../themes/themes'
import {
  jobTypeOptions,
  DamageType,
  JobCertificationRequirement,
  JobLicensingRequirement,
} from '../../types/jobTypes'
import AddressInput from '../AddressInput/AddressInput'
import Label from '../Label/Label'
import SelectJobLicensesAndCertifications from '../SelectJobLicensesAndCertifications/SelectJobLicensesAndCertifications'
import {
  CertificationRequirement,
  LicensingRequirement,
  USER_STATUS,
  documentStatusOptions,
  userStatusOptions,
} from '../../types/userTypes'
import CustomDropdownMultiple from '../CustomDropdownMultiple/CustomDropdownMultiple'
import CustomDropdown, {
  SelectableItem,
} from '../CustomDropdown/CustomDropdown'

const UserSearcherBox = ({
  showFilters = true,
  showAdjustersFilters = true,
  value,
  setValue,
  placeholder = 'Search',
  location,
  setLocation,
  jobTypes,
  setJobTypes,
  documentsStatus,
  setDocumentsStatus,
  statuses,
  setStatuses,
  certifications,
  setCertifications,
  licenses,
  setLicenses,
  licensesOptions,
  certificationOptions,
  onSearch,
  showAdminAdjustersFilters,
  jobsList,
  jobId,
  setJobId,
  notListedCertificationName,
  setNotListedCertificationName,
  yearsOfLicensedExperience,
  setYearsOfLicensedExperience,
  showAdminFirmsFilters,
}: {
  showFilters?: boolean
  showAdjustersFilters?: boolean
  value: string
  setValue: React.Dispatch<React.SetStateAction<string>>
  placeholder?: string
  location?: string
  setLocation?: React.Dispatch<React.SetStateAction<string>>
  jobTypes?: DamageType[]
  setJobTypes?: React.Dispatch<React.SetStateAction<DamageType[]>>
  statuses?: USER_STATUS[]
  setStatuses?: React.Dispatch<React.SetStateAction<USER_STATUS[]>>
  certifications?: JobCertificationRequirement[]
  setCertifications?: React.Dispatch<
    React.SetStateAction<JobCertificationRequirement[]>
  >
  licenses?: JobLicensingRequirement[]
  setLicenses?: React.Dispatch<React.SetStateAction<JobLicensingRequirement[]>>

  licensesOptions?: LicensingRequirement[]
  certificationOptions?: CertificationRequirement[]
  onSearch: () => void

  showAdminAdjustersFilters?: boolean
  documentsStatus?: string
  setDocumentsStatus?: React.Dispatch<React.SetStateAction<string>>
  jobsList?: SelectableItem[]
  jobId?: string
  setJobId?: React.Dispatch<React.SetStateAction<string>>
  notListedCertificationName?: string
  setNotListedCertificationName?: React.Dispatch<React.SetStateAction<string>>
  yearsOfLicensedExperience?: number
  setYearsOfLicensedExperience?: React.Dispatch<React.SetStateAction<number>>

  showAdminFirmsFilters?: boolean
}) => {
  //   const formatDates = useCallback(
  //     (selectedDates: DateObject[]) => {
  //       const dates = selectedDates.map((d) => new Date(d?.toString()))
  //       if (setAvailability) setAvailability(dates)
  //     },
  //     [setAvailability]
  //   )

  return (
    <Accordion w="100%" allowToggle>
      <AccordionItem border="none">
        <HStack w="100%" justifyContent="center">
          <Stack w="-webkit-fill-available">
            <InputGroup>
              <Input
                type="text"
                placeholder={placeholder}
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
              <InputRightAddon
                color="white"
                background={ADJUSTANT_GREEN}
                cursor="pointer"
                onClick={onSearch}
              >
                <HiMagnifyingGlass color="gray.300" />
              </InputRightAddon>
            </InputGroup>
          </Stack>
          {showFilters && (
            <AccordionButton
              w="auto"
              border="1px solid"
              borderRadius="8px"
              borderColor={ADJUSTANT_GREEN}
              color={ADJUSTANT_GREEN}
              fontWeight="500"
            >
              <Box mr={2}>
                <FaFilter />
              </Box>
              Filters
            </AccordionButton>
          )}
        </HStack>
        {/* chakra-collapse: there is a class within index.css that alters this element */}

        <AccordionPanel overflow="inherit">
          {showAdjustersFilters && (
            <>
              <Stack mb={4}>
                <AddressInput
                  isRequired
                  label="Location"
                  placeholder="e.g. 718 S. Main St."
                  address={location ?? ''}
                  onAddressChange={(e) => {
                    if (setLocation) setLocation(e)
                  }}
                  onSelectOption={(e) => {
                    if (setLocation) setLocation(e)
                  }}
                  handleAddressClear={() => {
                    if (setLocation) setLocation('')
                  }}
                />
              </Stack>

              <Stack mb={4}>
                <CustomDropdownMultiple
                  label="Job Types"
                  value={
                    jobTypes?.map((jt) => {
                      const label =
                        jobTypeOptions.find((jtp) => jtp.value === jt)?.label ??
                        ''
                      return {
                        label,
                        value: jt,
                      }
                    }) ?? []
                  }
                  options={jobTypeOptions}
                  isClearable
                  onChange={(a) => {
                    if (setJobTypes) setJobTypes(a as DamageType[])
                  }}
                />
              </Stack>

              <Stack spacing={2} mb={4}>
                {/* <Flex mb={1}>
                  <Label fontSize="sm" color="gray.700">
                    Availability
                  </Label>
                </Flex> */}
                {/* <CustomDropdown
                  label=""
                  //   placeholder="Select a state"
                  options={[
                    { label: 'All', value: 'all' },
                    ...userStatusOptions,
                  ]}
                  value={availabilityStatus}
                  textAlign="start"
                  handleChange={(a) => {
                    if (setAvailabilityStatus) setAvailabilityStatus(a)
                  }}
                /> */}

                <CustomDropdownMultiple
                  label="Availability"
                  value={
                    statuses?.map((a) => {
                      const label =
                        userStatusOptions.find((uso) => uso.value === a)
                          ?.label ?? ''
                      return {
                        label,
                        value: a,
                      }
                    }) ?? []
                  }
                  options={userStatusOptions}
                  isClearable
                  onChange={(a) => {
                    if (setStatuses) setStatuses(a as USER_STATUS[])
                  }}
                />
              </Stack>

              <Stack mb={4}>
                <Flex mb={1}>
                  <Label fontSize="sm" color="gray.700">
                    Years of Licensed Experience
                  </Label>
                </Flex>
                <NumberInput
                  mb={4}
                  value={yearsOfLicensedExperience}
                  onChange={(e) => {
                    if (setYearsOfLicensedExperience)
                      setYearsOfLicensedExperience(+e)
                  }}
                  min={0}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </Stack>

              <Stack spacing={2} mb={4}>
                <Flex mb={1}>
                  <Label fontSize="sm" color="gray.700">
                    Licensing & Certification Requirements
                  </Label>
                </Flex>
                <SelectJobLicensesAndCertifications
                  licensesOptions={licensesOptions ?? []}
                  certificationOptions={certificationOptions ?? []}
                  certifications={certifications ?? []}
                  licenses={licenses ?? []}
                  setCertifications={(e) => {
                    if (setCertifications) setCertifications(e)
                  }}
                  setLicenses={(e) => {
                    if (setLicenses) setLicenses(e)
                  }}
                  hideRadios
                  hideLicensesDetails
                />
              </Stack>
            </>
          )}

          {showAdminAdjustersFilters && (
            <>
              <Stack spacing={2} mb={4}>
                <Flex mb={1}>
                  <Label fontSize="sm" color="gray.700">
                    Documents Status
                  </Label>
                </Flex>
                <CustomDropdown
                  label=""
                  //   placeholder="Select a state"
                  options={[
                    { label: 'All', value: 'all' },
                    ...documentStatusOptions,
                  ]}
                  value={documentsStatus}
                  textAlign="start"
                  handleChange={(a) => {
                    if (setDocumentsStatus) setDocumentsStatus(a)
                  }}
                />
              </Stack>

              <Stack spacing={2} mb={4}>
                <Flex mb={1}>
                  <Label fontSize="sm" color="gray.700">
                    Job
                  </Label>
                </Flex>
                <CustomDropdown
                  label=""
                  placeholder="Select a Job"
                  options={jobsList}
                  value={jobId}
                  handleChange={(jobId) => {
                    if (setJobId) setJobId(jobId)
                  }}
                />
              </Stack>

              <Stack spacing={2} mb={4}>
                <Flex mb={1}>
                  <Label fontSize="sm" color="gray.700">
                    Not listed certification name
                  </Label>
                </Flex>
                <Input
                  type="text"
                  placeholder="Certification Name"
                  value={notListedCertificationName}
                  onChange={(e) => {
                    if (setNotListedCertificationName)
                      setNotListedCertificationName(e.target.value)
                  }}
                />
              </Stack>
            </>
          )}

          {showAdminFirmsFilters && (
            <Stack spacing={2} mb={4}>
              <Flex mb={1}>
                <Label fontSize="sm" color="gray.700">
                  Job
                </Label>
              </Flex>
              <CustomDropdown
                label=""
                placeholder="Select a Job"
                options={jobsList}
                value={jobId}
                handleChange={(jobId) => {
                  if (setJobId) setJobId(jobId)
                }}
              />
            </Stack>
          )}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

export default UserSearcherBox
