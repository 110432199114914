import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Radio,
  RadioGroup,
  Checkbox,
  Input,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import {
  SIGN_NOW_SIGNING_STATUS,
  SignNowSigningInvitation,
  SignNowTemplate,
} from '../../../types/signNowTypes'
import { User } from '../../../types/userTypes'
import { filterAndSortAdjusters } from './DocumentCenter'

const SelectAdjustersModal = ({
  isOpen,
  onClose,
  adjusters,
  signNowSigningInvitations,
}: {
  isOpen: boolean
  onClose: (adjustersToInvite?: User[]) => void
  adjusters: User[]
  signNowSigningInvitations: SignNowSigningInvitation[]
}) => {
  const [adjustersFilter, setAdjustersFilter] = useState('')
  const [filteredHiredAdjusters, setFilteredHiredAdjusters] = useState<User[]>(
    []
  )
  const [selectedHiredAdjusters, setSelectedHiredAdjusters] = useState<User[]>(
    []
  )

  //   console.log(signNowSigningInvitations, adjusters)

  useEffect(() => {
    setFilteredHiredAdjusters(adjusters)
  }, [adjusters])

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setSelectedHiredAdjusters([])
        setAdjustersFilter('')
        setFilteredHiredAdjusters(adjusters)
        onClose()
      }}
      size="xl"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select Adjusters For Signature</ModalHeader>
        <ModalCloseButton />
        <ModalBody display="grid" gap={2}>
          <Input
            type="text"
            placeholder="Search an adjuster"
            value={adjustersFilter}
            onChange={(e) => {
              setAdjustersFilter(e.target.value)
              const filteredAndSorted = filterAndSortAdjusters(
                adjusters,
                e.target.value
              )
              setFilteredHiredAdjusters(filteredAndSorted)
            }}
          />
          {filteredHiredAdjusters.map((adjuster) => (
            <Checkbox
              variant="adjustant"
              key={adjuster._id}
              isChecked={
                !!selectedHiredAdjusters.find((a) => a._id === adjuster._id)
              }
              isDisabled={signNowSigningInvitations.some(
                (i) => i.adjusterId === adjuster._id
              )}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedHiredAdjusters((prev) => [...prev, adjuster])
                } else {
                  setSelectedHiredAdjusters((prev) =>
                    prev.filter((a) => a._id !== adjuster._id)
                  )
                }
              }}
            >
              {adjuster.firstName} {adjuster.lastName}
            </Checkbox>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button
            variant="adjustant"
            mr={3}
            onClick={() => {
              onClose(selectedHiredAdjusters)
              setAdjustersFilter('')
              setFilteredHiredAdjusters(adjusters)
              setSelectedHiredAdjusters([])
            }}
            isDisabled={!selectedHiredAdjusters.length}
          >
            Send Signature Request
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SelectAdjustersModal
