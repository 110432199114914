import { useCallback, useState } from 'react'
import { MdOutlineNavigateNext } from 'react-icons/md'
import { getFileName, getFileNameFromURL } from '../../utils/functions'
import CropImage from '../CropImage/CropImage'
import { UserType } from '../../types/userTypes'
import FileInput from '../FileInput/FileInput'
import {
  HStack,
  Button,
  Box,
  Flex,
  Textarea,
  Input,
  Avatar,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react'
import CompleteProfileHeader from './CompleteProfileHeader'
import Label from '../Label/Label'
import AddressInput from '../AddressInput/AddressInput'
import { AiOutlineCamera } from 'react-icons/ai'
import { ADJUSTANT_GREEN } from '../../themes/themes'

function CompleteProfileSetProfileDetails({
  next,
  showLoader,
  yearsOfLicensedExperience: oldYearsOfLicensedExperience,
  bio: oldBio,
  profilePicture,
  firmName: oldFirmName,
  userType,
  location: oldLocation,
  languagePreference: oldLanguagePreference,
  emergencyContact: oldEmergencyContact,
  xactimateId: oldXactimateId,
  companyAlias: oldCompanyAlias,
  phoneNumber: oldPhoneNumber,
}: {
  next: (
    {
      yearsOfLicensedExperience,
      bio,
      firmName,
      location,
      languagePreference,
      emergencyContact,
      xactimateId,
      companyAlias,
      phoneNumber,
    }: {
      yearsOfLicensedExperience: number
      bio: string
      firmName: string
      location: string
      languagePreference: string
      emergencyContact: string
      xactimateId: string
      companyAlias: string
      phoneNumber: string
    },
    { fileName, file }: { fileName: string; file: any }
  ) => void
  showLoader: boolean
  bio?: string
  yearsOfLicensedExperience?: number
  profilePicture?: string
  firmName?: string
  userType?: UserType
  location?: string
  languagePreference?: string
  emergencyContact?: string
  xactimateId?: string
  companyAlias?: string
  phoneNumber?: string
}) {
  const [yearsOfLicensedExperience, setYearsOfLicensedExperience] = useState(
    oldYearsOfLicensedExperience ?? 0
  )
  const [bio, setBio] = useState<string>(oldBio ?? '')
  const [firmName, setFirmName] = useState<string>(oldFirmName ?? '')
  const [location, setLocation] = useState<string>(oldLocation ?? '')

  const [languagePreference, setLanguagePreference] = useState(
    oldLanguagePreference ?? ''
  )
  const [emergencyContact, setEmergencyContact] = useState(
    oldEmergencyContact ?? ''
  )
  const [xactimateId, setXactimateId] = useState(oldXactimateId ?? '')
  const [companyAlias, setCompanyAlias] = useState(oldCompanyAlias ?? '')
  const [phoneNumber, setPhoneNumber] = useState(oldPhoneNumber ?? '')

  const [isModalOpen, setIsModelOpen] = useState(false)

  const [file, setFile] = useState<any>()
  const [imageFileName, setimageFileName] = useState(
    getFileNameFromURL(profilePicture) ?? ''
  )
  const [profilePictureUrl, setProfilePictureUrl] = useState(
    profilePicture ?? ''
  )

  const onFileInputChange = useCallback(
    async (e: any) => {
      const fileFromFiles = e.target.files[0]
      if (fileFromFiles) {
        setFile(fileFromFiles)
        setimageFileName(getFileName(e))
        setProfilePictureUrl(URL.createObjectURL(fileFromFiles))
        setIsModelOpen(true)
      }
    },
    [setFile, setimageFileName, setProfilePictureUrl, setIsModelOpen]
  )

  return (
    <Box width={['auto', '400px', '500px']}>
      <CompleteProfileHeader text="Profile Details" />
      <Box mb={4}>
        <Flex mb={1}>
          <Label fontSize="sm" color="gray.700">
            Upload a Profile Picture (Optional)
          </Label>
        </Flex>
        <Box
          position="relative"
          // display="flex" justifyContent="center"
        >
          <Box height={24} width={24}>
            <Avatar
              size="full"
              src={profilePictureUrl || '/images/no_profile_picture.png'}
            />
          </Box>

          <Box position="absolute" bottom={2} left={16}>
            <FileInput
              element={
                <Box
                  backgroundColor={ADJUSTANT_GREEN}
                  color="white"
                  borderRadius="50%"
                  p={1}
                >
                  <AiOutlineCamera />
                </Box>
              }
              accept=".png,.jpeg,.jpg"
              onFileInputChange={onFileInputChange}
            />
          </Box>
        </Box>
        <CropImage
          isModalOpen={isModalOpen}
          setIsModelOpen={setIsModelOpen}
          imageURL={profilePictureUrl}
          onCrop={(croppedFile) => {
            if (croppedFile) {
              setFile(croppedFile)
              const url = URL.createObjectURL(croppedFile)
              console.log(croppedFile, url)
              setProfilePictureUrl(url)
            }
          }}
        />
      </Box>
      {userType === UserType.FIRM && (
        <Box mb={4}>
          <Flex mb={1}>
            <Label fontSize="sm" color="gray.700">
              Firm&apos;s Name
            </Label>
          </Flex>
          <Input
            type="text"
            placeholder=""
            value={firmName}
            onChange={(e) => {
              if (setFirmName) setFirmName(e.target.value)
            }}
          />
        </Box>
      )}

      {/* Years of Licensed Experience */}
      {userType === UserType.ADJUSTER && (
        <Box mb={4}>
          <Flex mb={1}>
            <Label fontSize="sm" color="gray.700">
              Years of Licensed Experience
            </Label>
          </Flex>
          <NumberInput
            mb={4}
            value={yearsOfLicensedExperience}
            onChange={(e) => {
              setYearsOfLicensedExperience(+e)
            }}
            min={0}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Box>
      )}

      {userType === UserType.ADJUSTER && (
        <Box mb={4}>
          <Flex mb={1}>
            <Label fontSize="sm" color="gray.700">
              Describe your Adjusting Experience
            </Label>
          </Flex>
          <Textarea
            //   placeholder="Tell us about yourself. This will be displayed to firms with the rest of your profile to help them understand who they’re hiring."
            resize="vertical"
            size="xl"
            p={2}
            value={bio}
            borderRadius={3}
            onChange={(e) => {
              setBio(e.target.value)
            }}
          />
        </Box>
      )}

      <Box mb={4} zIndex={2}>
        <AddressInput
          isRequired
          label="Location (Optional)"
          placeholder="e.g. 718 S. Main St."
          address={location}
          onAddressChange={setLocation}
          onSelectOption={setLocation}
          handleAddressClear={() => {
            setLocation('')
          }}
        />
      </Box>

      {userType === UserType.ADJUSTER && (
        <>
          {/* Language preference */}
          <Box mb={4}>
            <Flex mb={1}>
              <Label fontSize="sm" color="gray.700">
                Language Preference (optional)
              </Label>
            </Flex>
            <Input
              value={languagePreference}
              borderRadius={3}
              onChange={(e) => {
                setLanguagePreference(e.target.value)
              }}
            />
          </Box>

          {/* Emergency Contact */}
          <Box mb={4}>
            <Flex mb={1}>
              <Label fontSize="sm" color="gray.700">
                Emergency Contact (optional)
              </Label>
            </Flex>
            <Input
              value={emergencyContact}
              borderRadius={3}
              onChange={(e) => {
                setEmergencyContact(e.target.value)
              }}
            />
          </Box>

          {/* Xactimate ID */}
          <Box mb={4}>
            <Flex mb={1}>
              <Label fontSize="sm" color="gray.700">
                Xactimate ID (optional)
              </Label>
            </Flex>
            <Input
              value={xactimateId}
              borderRadius={3}
              onChange={(e) => {
                setXactimateId(e.target.value)
              }}
            />
          </Box>

          {/* Industry/company Alias */}
          <Box mb={4}>
            <Flex mb={1}>
              <Label fontSize="sm" color="gray.700">
                Industry/company Alias (optional)
              </Label>
            </Flex>
            <Input
              value={companyAlias}
              borderRadius={3}
              onChange={(e) => {
                setCompanyAlias(e.target.value)
              }}
            />
          </Box>

          {/* Phone number */}
          <Box mb={4}>
            <Flex mb={1}>
              <Label fontSize="sm" color="gray.700">
                Phone Number (optional)
              </Label>
            </Flex>
            <Input
              value={phoneNumber}
              borderRadius={3}
              onChange={(e) => {
                setPhoneNumber(e.target.value)
              }}
            />
          </Box>
        </>
      )}

      <HStack justify="end" mt={8}>
        <Button
          variant="adjustant"
          rightIcon={<MdOutlineNavigateNext size="20px" />}
          isLoading={showLoader}
          onClick={() =>
            next(
              {
                yearsOfLicensedExperience,
                bio,
                firmName,
                location,
                languagePreference,
                emergencyContact,
                xactimateId,
                companyAlias,
                phoneNumber,
              },
              { fileName: imageFileName, file }
            )
          }
        >
          Finish
        </Button>
      </HStack>
    </Box>
  )
}

export default CompleteProfileSetProfileDetails
