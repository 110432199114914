import React from 'react'
import { Button, Heading, VStack } from '@chakra-ui/react'
import { GLOBAL_ROUTES } from '../../App'
import { useNavigate } from 'react-router-dom'
import { ADJUSTANT_GREEN } from '../../themes/themes'

const NotFound = () => {
  const navigate = useNavigate()
  return (
    <VStack h="100%" justifyContent="center">
      <Heading as="h1" size="4xl" color="black">
        404
      </Heading>

      <Heading as="h4" size="xl" color="gray.500">
        Page Not Found
      </Heading>

      <Button
        mt={4}
        variant="adjustant"
        _hover={{
          backgroundColor: ADJUSTANT_GREEN,
          color: 'white',
        }}
        onClick={() => {
          navigate(GLOBAL_ROUTES.ROOT)
        }}
      >
        Go Home
      </Button>
    </VStack>
  )
}

export default NotFound
