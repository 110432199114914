import {
  Box,
  Button,
  HStack,
  Link,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react'
import UserSearcherBox from '../../../components/UserSearcherBox/UserSearcherBox'
import { useCallback, useEffect, useState } from 'react'
import {
  GET_ADJUSTERS_BY_FILTERS,
  GET_ALL_JOBS_WITHOUT_FILTERS,
  GET_FIRMS_BY_FILTERS,
  GET_ROSTERS_BY_FILTER,
} from '../../../graphql/Queries'
import { useLazyQuery } from '@apollo/client'
import { formatDate } from '../../../utils/functions'
import { ADJUSTANT_GREEN } from '../../../themes/themes'
import { ADMIN_ROUTES, GLOBAL_ROUTES } from '../../../App'
import { useNavigate } from 'react-router-dom'
import CustomTable from '../../../components/CustomTable/CustomTable'
import { createColumnHelper } from '@tanstack/react-table'
import { CiEdit } from 'react-icons/ci'
import { SelectableItem } from '../../../components/CustomDropdown/CustomDropdown'
import { Job, JobStatus, Roster } from '../../../types/jobTypes'
import JobsListModal from '../../../components/JobsListModal/JobsListModal'
import { AdminAdjuster, AdminFirm } from '../../../types/userTypes'

function AdminFirms() {
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const navigate = useNavigate()
  const {
    isOpen: jobsListModalIsOpen,
    onOpen: onOpenJobsListModal,
    onClose: onCloseJobsListModal,
  } = useDisclosure()
  const [jobsTab, setJobsTab] = useState(0)

  //Filters
  const [searcherValue, setSearcherValue] = useState<string>('')
  const [jobId, setJobId] = useState<string>('')

  const columnHelper = createColumnHelper<AdminFirm>()
  const columns = [
    columnHelper.accessor('firmName', {
      cell: (info) => (
        <Link
          onClick={() => {
            window
              .open(
                `${GLOBAL_ROUTES.ADMIN}${GLOBAL_ROUTES.PROFILE}/${info?.row
                  ?.getAllCells()?.[5]
                  ?.getValue()}`,
                '_blank'
              )
              ?.focus()
          }}
        >
          {info.getValue()}
        </Link>
      ),
      header: 'Firm Name',
    }),
    // columnHelper.accessor('areasOfOperation', {
    //   cell: (info) => info.getValue().join(', '),
    //   header: 'Active States',
    // }),
    columnHelper.accessor('openJobs', {
      cell: (info) => (
        <Link
          onClick={() => {
            setSelectedFirmJobs(
              jobs.filter((job) => job.firmId === info?.row?.original?._id) ??
                []
            )
            setJobsTab(0)
            onOpenJobsListModal()
          }}
        >
          {info.getValue()}
        </Link>
      ),
      header: 'Open Jobs',
    }),
    columnHelper.accessor('pastJobs', {
      cell: (info) => (
        <Link
          onClick={() => {
            setSelectedFirmJobs(
              jobs.filter((job) => job.firmId === info?.row?.original?._id) ??
                []
            )
            setJobsTab(1)
            onOpenJobsListModal()
          }}
        >
          {info.getValue()}
        </Link>
      ),
      header: 'Past Jobs',
    }),
    columnHelper.accessor('lastActive', {
      cell: (info) => (info.getValue() ? formatDate(info.getValue()) : '-'),
      header: 'Last Active',
    }),
    columnHelper.accessor('_id', {
      cell: (info) => {
        return (
          <CiEdit
            fontSize={20}
            cursor="pointer"
            color={ADJUSTANT_GREEN}
            onClick={() =>
              //   navigate(
              //     `${GLOBAL_ROUTES.ADMIN}${
              //       GLOBAL_ROUTES.PROFILE
              //     }/${info.getValue()}`
              //   )
              window
                .open(
                  `${GLOBAL_ROUTES.ADMIN}${
                    GLOBAL_ROUTES.PROFILE
                  }/${info.getValue()}`,
                  '_blank'
                )
                ?.focus()
            }
          />
        )
      },
      header: '',
    }),
  ]

  const [jobsListRetrieved, setJobsListRetrieved] = useState(false)
  const [jobsList, setJobsList] = useState<SelectableItem[]>([])
  const [jobs, setJobs] = useState<Job[]>([])
  const [selectedFirmJobs, setSelectedFirmJobs] = useState<Job[]>([])
  const [rosters, setRosters] = useState<Roster[]>([])
  const [adjusters, setAdjusters] = useState<AdminAdjuster[]>([])

  const [firms, setFirms] = useState<AdminFirm[]>([])

  const [getAllJobs] = useLazyQuery(GET_ALL_JOBS_WITHOUT_FILTERS, {
    onCompleted: (data: { JobMany: Job[] }) => {
      setJobsListRetrieved(true)
      setShowLoader(false)
      if (data?.JobMany) {
        setJobs(data.JobMany)
        const jobsMapped = data?.JobMany.map((job) => ({
          value: job._id,
          label: `${job.title} - ${job.status}`,
        }))
        setJobsList(jobsMapped)
      }
    },
    onError: (err) => {
      setJobsListRetrieved(true)
      setShowLoader(false)
      console.log(err)
    },
  })

  const [getAlllRosters] = useLazyQuery(GET_ROSTERS_BY_FILTER, {
    onCompleted: (data: { RostersByFilter: Roster[] }) => {
      //   console.log(data)
      if (data?.RostersByFilter) {
        const r = data?.RostersByFilter
        setRosters([...r])
      }
    },
    onError: (err) => {},
  })

  const [getAllAdjusters] = useLazyQuery(GET_ADJUSTERS_BY_FILTERS, {
    onCompleted: (data: { AdjustersByFilter: AdminAdjuster[] }) => {
      setShowLoader(false)
      if (data?.AdjustersByFilter) {
        const newUsers = [...data?.AdjustersByFilter]
        setAdjusters(newUsers)
      }
    },
    onError: (err) => {
      setShowLoader(false)
      console.log(err)
    },
  })

  useEffect(() => {
    if (!jobsListRetrieved) {
      setShowLoader(false)
      getAllJobs()
      getAlllRosters()
      getAllAdjusters()
    }
  }, [getAllAdjusters, getAllJobs, getAlllRosters, jobsListRetrieved])

  const [geFirmsByFilter] = useLazyQuery(GET_FIRMS_BY_FILTERS, {
    onCompleted: (data: { FirmsByFilter: AdminFirm[] }) => {
      setShowLoader(false)
      if (data?.FirmsByFilter) {
        const newUsers = [...data?.FirmsByFilter]
        const newUsersMapped = newUsers.map((u) => ({
          ...u,
          pastJobs:
            jobs.filter(
              (j) => j.firmId === u._id && j.status === JobStatus.FINISHED
            )?.length ?? 0,
          openJobs:
            jobs.filter(
              (j) => j.firmId === u._id && j.status === JobStatus.ONGOING
            )?.length ?? 0,
        }))
        setFirms(newUsersMapped)
      }
    },
    onError: (err) => {
      setShowLoader(false)
      console.log(err)
    },
  })

  const onSearch = useCallback(() => {
    setShowLoader(true)
    geFirmsByFilter({
      variables: {
        searcherValue,
        jobId,
      },
      fetchPolicy: 'network-only',
    })
  }, [geFirmsByFilter, jobId, searcherValue])

  return (
    <Box
      h="100%"
      width="100%"
      backgroundColor="white"
      pt={8}
      px={[4, 8]}
      overflowY="auto"
    >
      <JobsListModal
        jobs={selectedFirmJobs}
        rosters={rosters}
        adjusters={adjusters}
        isOpen={jobsListModalIsOpen}
        onClose={onCloseJobsListModal}
        tab={jobsTab}
      />
      <HStack alignItems="start">
        <Box width="-webkit-fill-available">
          <UserSearcherBox
            placeholder="Search Firms"
            showFilters
            showAdjustersFilters={false}
            showAdminAdjustersFilters={false}
            showAdminFirmsFilters={true}
            value={searcherValue}
            setValue={setSearcherValue}
            onSearch={onSearch}
            jobId={jobId}
            setJobId={setJobId}
            jobsList={jobsList}
          />
        </Box>
        <Button
          variant="adjustant"
          _hover={{ backgroundColor: ADJUSTANT_GREEN }}
          onClick={() =>
            navigate(`${GLOBAL_ROUTES.ADMIN}${ADMIN_ROUTES.INVITE_FIRM}`)
          }
        >
          Onboard Firm
        </Button>
      </HStack>

      <Box overflowY="auto" width="100%" height="calc(100% - 42px)">
        {showLoader && (
          <HStack
            direction="row"
            height="inherit"
            w="100%"
            justifyContent="center"
          >
            <Spinner size="xl" />
          </HStack>
        )}

        {firms.length > 0 && <CustomTable data={firms} columns={columns} />}
      </Box>
    </Box>
  )
}

export default AdminFirms
