import React, { useCallback, useContext, useMemo } from 'react'
import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Input,
  Link,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import CustomDropdown, {
  SelectableItem,
} from '../CustomDropdown/CustomDropdown'
import { AiOutlineClose, AiOutlineFile } from 'react-icons/ai'
import FileInput from '../FileInput/FileInput'
import CustomRadio from '../CustomRadio/CustomRadio'
import { DocumentData } from '../UploadAdjusterLicensesOrCertifications/UploadAdjusterLicensesAndCertifications'
import {
  StateLicenseContextType,
  US_STATES,
} from '../../types/stateLicensesTypes'
import { StateLicesesContext } from '../../contexts/StateLicesesContext'
import {
  FEMA_CATEGORY,
  FemaCertification,
  femaCategoriesOptions,
} from '../../types/userTypes'
import DateRangePicker from '../DateRangePicker/DateRangePicker'
import { TrainingCertificationContextType } from '../../types/trainingCertificationsTypes'
import { TrainingCertificationsContext } from '../../contexts/TrainingCertificationsContext'
import { ADJUSTANT_GREEN } from '../../themes/themes'
import { DOCUMENT_TYPE, FEMAOptions } from '../../types/jobTypes'
import UploadAdjusterLicensesOrCertifications from '../UploadAdjusterLicensesOrCertifications/UploadAdjusterLicensesAndCertifications'
import Label from '../Label/Label'
import { ADJUSTER_ROUTES } from '../../App'
import CustomDropdownMultiple from '../CustomDropdownMultiple/CustomDropdownMultiple'
import { encodeS3URI } from '../../utils/functions'

const UpdateAdjusterLicensesAndCertificationsForm = ({
  // Fema Certification
  femaCertification,
  setFemaCertification,
  setFemaCertificationFile,
  femaCertificationFileName,
  setFemaCertificationFileName,

  //Licenses
  licenses,
  setLicenses,

  //Certifications
  certifications,
  setCertifications,
}: {
  //FEMA Certification
  femaCertification: FemaCertification
  setFemaCertification: React.Dispatch<React.SetStateAction<FemaCertification>>
  setFemaCertificationFile: React.Dispatch<React.SetStateAction<any>>
  femaCertificationFileName: string
  setFemaCertificationFileName?: React.Dispatch<React.SetStateAction<string>>

  //Licenses
  licenses: DocumentData[]
  setLicenses?: React.Dispatch<React.SetStateAction<DocumentData[]>>

  //Certifications
  certifications: DocumentData[]
  setCertifications: React.Dispatch<React.SetStateAction<DocumentData[]>>
}) => {
  const { stateLicenses }: StateLicenseContextType =
    useContext(StateLicesesContext)
  const { trainingCertifications }: TrainingCertificationContextType =
    useContext(TrainingCertificationsContext)

  const stateLicensesMapped: SelectableItem[] = useMemo(() => {
    return (
      stateLicenses
        ?.map((m) => ({
          value: m._id,
          label: m.name,
          disabled: !!licenses.find((l) => l.id === m._id),
        }))
        ?.sort((a, b) => a.label.localeCompare(b.label)) ?? []
    )
  }, [licenses, stateLicenses])

  const trainingCertificationsMapped: SelectableItem[] = useMemo(() => {
    return (
      trainingCertifications
        ?.map((m) => ({
          value: m._id,
          label: m.name,
          disabled: !!certifications.find((l) => l.id === m._id),
        }))
        ?.sort((a, b) => a.label.localeCompare(b.label)) ?? []
    )
  }, [certifications, trainingCertifications])

  const statesOptions: SelectableItem[] = US_STATES.map((s) => ({
    value: s.abbreviation,
    label: s.name,
  })).filter((s) => {
    return !!stateLicenses?.find((sl) =>
      (Object.keys(sl.states ?? {}) ?? []).includes(s.value)
    )
  })

  //FEMA Certification
  const onFemaCertificationFileInputChange = useCallback(
    async (e: any) => {
      const fileFromFiles = e.target.files[0]
      if (fileFromFiles) {
        if (setFemaCertificationFile) setFemaCertificationFile(fileFromFiles)
        if (setFemaCertificationFileName)
          setFemaCertificationFileName(fileFromFiles.name)
        const url = URL.createObjectURL(fileFromFiles)
        setFemaCertification((prevValue) => ({
          ...prevValue,
          url,
          approved: false,
        }))
      }
    },
    [
      setFemaCertificationFile,
      setFemaCertificationFileName,
      setFemaCertification,
    ]
  )
  const deleteFemaCertificationFile = useCallback(() => {
    if (setFemaCertificationFile) setFemaCertificationFile(null)
    if (setFemaCertificationFileName) setFemaCertificationFileName('')
    setFemaCertification((prevValue) => ({
      ...prevValue,
      url: '',
      approved: false,
    }))
  }, [
    setFemaCertificationFile,
    setFemaCertificationFileName,
    setFemaCertification,
  ])

  return (
    <Box width="100%">
      <Tabs width="100%">
        <TabList>
          <Tab fontWeight={500} fontSize={[14, 14, 16]}>
            FEMA
          </Tab>
          <Tab fontWeight={500} fontSize={[14, 14, 16]}>
            Licenses
          </Tab>
          <Tab fontWeight={500} fontSize={[14, 14, 16]}>
            Certifications
          </Tab>
        </TabList>

        <TabPanels width="100%">
          {/* NFIP/FEMA*/}
          <TabPanel>
            <Box mb={4} borderRadius="8px" backgroundColor="gray.50" p={2}>
              <Heading as="h2" fontSize="xl" mb={2}>
                FEMA certification
              </Heading>

              <Box mb={2}>
                <CustomRadio
                  label=""
                  subLabel="Federal flood certified through FEMA?"
                  value={femaCertification.has?.toString()}
                  options={FEMAOptions}
                  onChange={(a) => {
                    setFemaCertification((prevValue) => ({
                      ...prevValue,
                      has: a === 'true',
                      approved: false,
                    }))
                  }}
                />
              </Box>

              {femaCertification.has && (
                <Box>
                  <Box mb={2}>
                    <Flex mb={2}>
                      <Label fontSize="sm" color="black">
                        FCN Number
                      </Label>
                    </Flex>
                    <Input
                      type="text"
                      //   placeholder="Insert FCN Number"
                      maxLength={12}
                      value={femaCertification?.fcnNumber}
                      onChange={(e) => {
                        // console.log(e.target.value, parseInt(e.target.value))
                        setFemaCertification((prevValue) => ({
                          ...prevValue,
                          fcnNumber: e.target.value,
                          approved: false,
                        }))
                      }}
                    />
                  </Box>

                  <Box mb={2}>
                    {/* <CustomDropdown
                      label="Category"
                      placeholder="Select a category"
                      options={femaCategoriesOptions}
                      value={femaCertification.category}
                      handleChange={(category) => {
                        setFemaCertification((prevValue) => ({
                          ...prevValue,
                          category,
                          approved: false,
                        }))
                      }}
                    /> */}
                    <CustomDropdownMultiple
                      label="Category"
                      value={
                        femaCertification?.categories?.map((femaCategory) => ({
                          value: femaCategory,
                          label:
                            femaCategoriesOptions?.find(
                              (o) => o.value === femaCategory
                            )?.label ?? '',
                        })) ?? []
                      }
                      options={femaCategoriesOptions}
                      isClearable
                      onChange={(newCategories) => {
                        setFemaCertification((prevValue) => ({
                          ...prevValue,
                          categories: newCategories as FEMA_CATEGORY[],
                          approved: false,
                        }))
                      }}
                    />
                  </Box>

                  <Box mb={2}>
                    <DateRangePicker
                      issuedDate={femaCertification.issuedDate}
                      setIssuedDate={(n) => {
                        setFemaCertification((prevValue) => ({
                          ...prevValue,
                          issuedDate: n,
                          approved: false,
                        }))
                      }}
                      expiryDate={femaCertification.expiryDate}
                      setExpiryDate={(n) => {
                        setFemaCertification((prevValue) => ({
                          ...prevValue,
                          expiryDate: n,
                          approved: false,
                        }))
                      }}
                    />
                  </Box>
                  {!femaCertification.url ? (
                    <FileInput
                      element={
                        <Button
                          textColor={ADJUSTANT_GREEN}
                          variant="link"
                          isDisabled={!femaCertification.has}
                          leftIcon={<AiOutlineFile />}
                          mt={2}
                        >
                          Upload FEMA Certification
                        </Button>
                      }
                      accept=".pdf"
                      isDisabled={!femaCertification.has}
                      onFileInputChange={onFemaCertificationFileInputChange}
                      clearFile={() => {
                        if (setFemaCertificationFile) {
                          setFemaCertificationFile(undefined)
                          setFemaCertification((prevValue) => ({
                            ...prevValue,
                            approved: false,
                          }))
                        }
                      }}
                    />
                  ) : (
                    <HStack>
                      <Link
                        color="teal.500"
                        href={encodeS3URI(femaCertification.url)}
                        isExternal
                      >
                        {femaCertificationFileName ?? '-'}
                      </Link>
                      <Box ml={2} cursor="pointer">
                        <AiOutlineClose onClick={deleteFemaCertificationFile} />
                      </Box>
                    </HStack>
                  )}
                </Box>
              )}
            </Box>
          </TabPanel>

          {/*Licenses */}
          <TabPanel>
            <Stack spacing={4}>
              <Link fontStyle="italic" href={ADJUSTER_ROUTES.DOCUMENT_CENTER}>
                Automatically pull all your current licenses from NIPR through
                the Document Center
              </Link>
              <UploadAdjusterLicensesOrCertifications
                canCreate={false}
                canSetPrimary
                label="Licenses"
                inputLabel="License Name"
                placeHolder="What is the name of the license?"
                uploadButtonLabel="Upload PDF"
                addButtonLabel="Add License"
                createButtonLabel="Create License"
                documents={licenses}
                setDocuments={setLicenses}
                selectableItemsMapped={stateLicensesMapped}
                statesOptions={statesOptions}
                documentType={DOCUMENT_TYPE.LICENSE}
              />
            </Stack>
          </TabPanel>

          {/*  Certifications */}
          <TabPanel width="100%">
            <Stack spacing={4}>
              <UploadAdjusterLicensesOrCertifications
                canCreate
                label="Certifications"
                inputLabel="Certification Name"
                placeHolder="What is the name of the certification?"
                uploadButtonLabel="Upload PDF"
                addButtonLabel="Add Certification"
                createButtonLabel="Can’t find a certification? Click here to create it"
                documents={certifications}
                setDocuments={setCertifications}
                selectableItemsMapped={trainingCertificationsMapped}
                statesOptions={statesOptions}
                documentType={DOCUMENT_TYPE.CERTIFICATION}
              />
            </Stack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default UpdateAdjusterLicensesAndCertificationsForm
