import { useState } from 'react'
import { Box, Button, HStack } from '@chakra-ui/react'
import { TaxForm } from '../../types/userTypes'
import CompleteProfileHeader from './CompleteProfileHeader'
import { MdOutlineNavigateNext } from 'react-icons/md'
import { getFileNameFromURL } from '../../utils/functions'
import { I9_FORM_URL } from '../../constants/constants'
import UpdateTaxForm from '../UpdateTaxForm/UpdateTaxForm'

function CompleteProfileUpdateUpdateI9TaxForm({
  next,
  i9TaxForm,
  showLoader,
}: {
  next: (a: TaxForm, b: { fileName: string; file: any }) => void
  i9TaxForm?: TaxForm
  showLoader: boolean
}) {
  const [url, setURL] = useState(i9TaxForm?.url ?? '')
  const [file, setFile] = useState<any>()
  const [fileName, setFileName] = useState<string>(
    getFileNameFromURL(i9TaxForm?.url)
  )

  return (
    <Box maxW="400px">
      <CompleteProfileHeader
        text="I-9 Employment Eligibility Form"
        subText="You’ll need to upload basic employment documents to apply for jobs, you
        can choose to set this up later in your profile"
        // align="start"
      />
      <UpdateTaxForm
        editable={!i9TaxForm?.approved}
        url={url}
        setURL={setURL}
        setFile={setFile}
        fileName={fileName}
        setFileName={setFileName}
        hideFile={!!i9TaxForm?.approved}
        officialDocumentURL={I9_FORM_URL}
        documentName="I-9 Form"
        align="center"
      />
      <HStack justify="end" mt={8}>
        <Button
          variant="adjustant"
          rightIcon={<MdOutlineNavigateNext size="20px" />}
          isLoading={showLoader}
          onClick={() => {
            next(
              {
                url: '',
              },
              { file, fileName }
            )
          }}
        >
          Next
        </Button>
      </HStack>
    </Box>
  )
}
export default CompleteProfileUpdateUpdateI9TaxForm
