import { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Divider,
  Heading,
  Link,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useLazyQuery } from '@apollo/client'
import {
  GET_ADJUSTERS_BY_FILTERS,
  GET_ADJUSTERS_SIGNUP_HISTORICAL,
  GET_ALL_JOBS,
  GET_FIRMS_BY_FILTERS,
  GET_JOBS_HISTORICAL,
  GET_ROSTERS_BY_FILTER,
} from '../../../graphql/Queries'
import JobsListModal from '../../../components/JobsListModal/JobsListModal'
import { Job, JobStatus, Roster } from '../../../types/jobTypes'
import AdjustersListModal from '../../../components/AdjustersListModal/AdjustersListModal'
import {
  AdminAdjuster,
  AdminFirm,
  USER_STATUS,
  User,
} from '../../../types/userTypes'
import { useNavigate } from 'react-router-dom'
import { ADMIN_ROUTES, GLOBAL_ROUTES } from '../../../App'
import FirmsListModal from '../../../components/FirmsListModal/FirmsListModal'
import CustomDropdown, {
  SelectableItem,
} from '../../../components/CustomDropdown/CustomDropdown'

// interface STAT {
//   closedJobs: number
//   logIns: number
//   openJobs: number
//   signUps: number
// }
// interface ADMIN_STATS {
//   PAST_30_DAYS: STAT
//   PAST_90_DAYS: STAT
//   PAST_6_MONTHS: STAT
//   PAST_12_MONTHS: STAT
//   YTD: STAT
// }

enum PERIODS {
  PAST_30_DAYS = 'PAST_30_DAYS',
  PAST_90_DAYS = 'PAST_90_DAYS',
  PAST_6_MONTHS = 'PAST_6_MONTHS',
  PAST_12_MONTHS = 'PAST_12_MONTHS',
  YTD = 'YTD',
}

type SignUp = {
  [key in PERIODS]: User[]
}

type JobByPeriod = {
  [key in PERIODS]: Job[]
}

export const periodsOptions: SelectableItem[] = [
  {
    value: PERIODS.PAST_30_DAYS,
    label: 'Past 30 days',
  },
  {
    value: PERIODS.PAST_90_DAYS,
    label: 'Past 90 days',
  },
  {
    value: PERIODS.PAST_6_MONTHS,
    label: 'Past 6 months',
  },
  {
    value: PERIODS.PAST_12_MONTHS,
    label: 'Past 12 months',
  },
  {
    value: PERIODS.YTD,
    label: 'YTD',
  },
]

const DataBox = ({
  header,
  text,
  customContent,
  onClick,
}: {
  header?: number
  text?: string
  customContent?: any
  onClick?: () => void
}) => {
  return (
    <Box
      h={220}
      //   w={['100%', 220]}
      w={220}
      p={4}
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius={8}
      border="1px solid var(--light-mode-base-tertiary, #ECEAE9)"
      background="white"
    >
      <Stack textAlign="center" alignItems="center" w="100%">
        {typeof header !== 'undefined' && (
          <Link
            as="h1"
            fontSize={32}
            fontWeight={500}
            onClick={() => {
              if (onClick) onClick()
            }}
          >
            {header ?? 0}
          </Link>
        )}
        {/* <Heading>{header ?? 0}</Heading> */}
        {typeof header !== 'undefined' && <Divider w="100px" />}
        {typeof text !== 'undefined' && <Text>{text}</Text>}
        {customContent}
      </Stack>
    </Box>
  )
}

function AdminDashboard() {
  const navigate = useNavigate()
  const [showLoader, setShowLoader] = useState(false)
  const [jobsTab, setJobsTab] = useState(0)
  const [adjustersTab, setAdjustersTab] = useState(0)
  const [selectedSignUpPeriod, setSelectedSignUpPeriod] = useState<
    PERIODS | undefined
  >()
  const [selectedJobsPeriod, setSelectedJobsPeriod] = useState<
    PERIODS | undefined
  >()

  const [jobs, setJobs] = useState<Job[]>([])
  const [rosters, setRosters] = useState<Roster[]>([])
  const [adjusters, setAdjusters] = useState<AdminAdjuster[]>([])
  const [firms, setFirms] = useState<AdminFirm[]>([])

  const [adjustersSignUps, setAdjustersSignUps] = useState<SignUp | undefined>()
  const [jobsByPeriod, setJobsByPeriod] = useState<JobByPeriod | undefined>()

  const {
    isOpen: jobsListModalIsOpen,
    onOpen: onOpenJobsListModal,
    onClose: onCloseJobsListModal,
  } = useDisclosure()
  const {
    isOpen: jobsByPeriodListModalIsOpen,
    onOpen: onOpenJobsByPeriodListModal,
    onClose: onCloseJobsByPeriodListModal,
  } = useDisclosure()
  const {
    isOpen: adjustersListModalIsOpen,
    onOpen: onOpenAdjustersListModal,
    onClose: onCloseAdjustersListModal,
  } = useDisclosure()
  const {
    isOpen: adjustersSignUpsListModalIsOpen,
    onOpen: onOpenAdjustersSignUpsListModal,
    onClose: onCloseAdjustersSignUpsListModal,
  } = useDisclosure()
  const {
    isOpen: firmsListModalIsOpen,
    onOpen: onOpenFirmsListModal,
    onClose: onCloseFirmsListModal,
  } = useDisclosure()

  const [getAdjustersSignUpHistorical] = useLazyQuery(
    GET_ADJUSTERS_SIGNUP_HISTORICAL,
    {
      onCompleted: (data: { getAdjustersSignUpHistorical: SignUp }) => {
        setShowLoader(false)
        console.log(data?.getAdjustersSignUpHistorical)
        if (data?.getAdjustersSignUpHistorical) {
          const signUpHistorical = data?.getAdjustersSignUpHistorical
          setAdjustersSignUps({ ...signUpHistorical })
        }
      },
      onError: () => {
        setShowLoader(false)
      },
    }
  )

  const [getJobsHistorical] = useLazyQuery(GET_JOBS_HISTORICAL, {
    onCompleted: (data: { getJobsHistorical: JobByPeriod }) => {
      setShowLoader(false)
      console.log(data?.getJobsHistorical)
      if (data?.getJobsHistorical) {
        const jobsHistorical = data?.getJobsHistorical
        setJobsByPeriod({ ...jobsHistorical })
      }
    },
    onError: () => {
      setShowLoader(false)
    },
  })

  const [getJobs] = useLazyQuery(GET_ALL_JOBS, {
    onCompleted: (data: { GetJobMany: Job[] }) => {
      setShowLoader(false)
      getFirms()
      if (data?.GetJobMany) {
        const jobs = data?.GetJobMany
        setJobs([...jobs])
      }
    },
    onError: () => {
      setShowLoader(false)
    },
  })

  const [getRosters] = useLazyQuery(GET_ROSTERS_BY_FILTER, {
    onCompleted: (data: { RostersByFilter: Roster[] }) => {
      //   console.log(data)
      if (data?.RostersByFilter) {
        const r = data?.RostersByFilter
        setRosters([...r])
      }
    },
    onError: (err) => {},
  })

  const [getAdjusters] = useLazyQuery(GET_ADJUSTERS_BY_FILTERS, {
    onCompleted: (data: { AdjustersByFilter: AdminAdjuster[] }) => {
      setShowLoader(false)
      if (data?.AdjustersByFilter) {
        const newUsers = [...data?.AdjustersByFilter]
        setAdjusters(newUsers)
      }
    },
    onError: (err) => {
      setShowLoader(false)
      console.log(err)
    },
  })

  const [getFirms] = useLazyQuery(GET_FIRMS_BY_FILTERS, {
    onCompleted: (data: { FirmsByFilter: AdminFirm[] }) => {
      setShowLoader(false)
      if (data?.FirmsByFilter) {
        const newUsers = [...data?.FirmsByFilter]
        const newUsersMapped = newUsers.map((u) => ({
          ...u,
          pastJobs:
            jobs.filter(
              (j) => j.firmId === u._id && j.status === JobStatus.FINISHED
            )?.length ?? 0,
          openJobs:
            jobs.filter(
              (j) => j.firmId === u._id && j.status === JobStatus.ONGOING
            )?.length ?? 0,
        }))
        setFirms(newUsersMapped)
      }
    },
    onError: (err) => {
      setShowLoader(false)
      console.log(err)
    },
  })

  useEffect(() => {
    getRosters()
    getJobs() // getFirms() this is call within getJobs()
    getAdjusters()
    getAdjustersSignUpHistorical()
    getJobsHistorical() //TO DO: THIS CAN BE DONE FROM THE FRONTEND, ALL THE INFO IS ALREADY THERE
  }, [
    getAdjusters,
    getFirms,
    getJobs,
    getRosters,
    getAdjustersSignUpHistorical,
    getJobsHistorical,
  ])

  const availableAdjusters = useMemo(() => {
    return adjusters.filter(
      (adjuster) => adjuster.status === USER_STATUS.AVAILABLE
    )
  }, [adjusters])

  const unavailableAdjusters = useMemo(() => {
    return adjusters.filter(
      (adjuster) => adjuster.status === USER_STATUS.UNAVAILABLE
    )
  }, [adjusters])

  const deployedAdjusters = useMemo(() => {
    return adjusters.filter(
      (adjuster) => adjuster.status === USER_STATUS.DEPLOYED
    )
  }, [adjusters])

  const signUpsToShow = useMemo((): AdminAdjuster[] => {
    let ad: User[] = []
    switch (selectedSignUpPeriod) {
      case PERIODS.YTD:
        ad = adjustersSignUps?.[PERIODS.YTD] ?? []
        break
      case PERIODS.PAST_30_DAYS:
        ad = adjustersSignUps?.[PERIODS.PAST_30_DAYS] ?? []
        break
      case PERIODS.PAST_90_DAYS:
        ad = adjustersSignUps?.[PERIODS.PAST_90_DAYS] ?? []
        break
      case PERIODS.PAST_6_MONTHS:
        ad = adjustersSignUps?.[PERIODS.PAST_6_MONTHS] ?? []
        break
      case PERIODS.PAST_12_MONTHS:
        ad = adjustersSignUps?.[PERIODS.PAST_12_MONTHS] ?? []
        break
    }

    return ad.map((a) =>
      adjusters.find((adjuster) => adjuster._id === a._id)
    ) as AdminAdjuster[]
  }, [adjusters, adjustersSignUps, selectedSignUpPeriod])

  const jobsByPeriodToShow = useMemo((): Job[] => {
    let j: Job[] = []
    switch (selectedJobsPeriod) {
      case PERIODS.YTD:
        j = jobsByPeriod?.[PERIODS.YTD] ?? []
        break
      case PERIODS.PAST_30_DAYS:
        j = jobsByPeriod?.[PERIODS.PAST_30_DAYS] ?? []
        break
      case PERIODS.PAST_90_DAYS:
        j = jobsByPeriod?.[PERIODS.PAST_90_DAYS] ?? []
        break
      case PERIODS.PAST_6_MONTHS:
        j = jobsByPeriod?.[PERIODS.PAST_6_MONTHS] ?? []
        break
      case PERIODS.PAST_12_MONTHS:
        j = jobsByPeriod?.[PERIODS.PAST_12_MONTHS] ?? []
        break
    }

    return j
  }, [jobsByPeriod, selectedJobsPeriod])

  const activeJobs = useMemo(() => {
    return jobs.filter((job) => job.status === JobStatus.ONGOING)
  }, [jobs])

  const finishedJobs = useMemo(() => {
    return jobs.filter((job) => job.status === JobStatus.FINISHED)
  }, [jobs])

  return (
    <Box px={[4, 8]} py={[8, 20]}>
      <JobsListModal
        jobs={jobs}
        rosters={rosters}
        adjusters={adjusters}
        isOpen={jobsListModalIsOpen}
        onClose={onCloseJobsListModal}
        tab={jobsTab}
      />
      <JobsListModal
        header={`${
          periodsOptions.find((p) => p.value === selectedJobsPeriod)?.label ??
          ''
        } Jobs`}
        jobs={jobsByPeriodToShow}
        rosters={rosters}
        adjusters={adjusters}
        isOpen={jobsByPeriodListModalIsOpen}
        onClose={onCloseJobsByPeriodListModal}
        tab={0}
      />
      <AdjustersListModal
        adjusters={adjusters}
        jobs={jobs}
        rosters={rosters}
        isOpen={adjustersListModalIsOpen}
        onClose={onCloseAdjustersListModal}
        tab={adjustersTab}
      />
      <FirmsListModal
        firms={firms}
        jobs={jobs}
        isOpen={firmsListModalIsOpen}
        onClose={onCloseFirmsListModal}
      />
      <AdjustersListModal
        header={`${
          periodsOptions.find((p) => p.value === selectedSignUpPeriod)?.label ??
          ''
        } Adjusters Sign Ups Summary`}
        adjusters={signUpsToShow}
        jobs={jobs}
        rosters={rosters}
        isOpen={adjustersSignUpsListModalIsOpen}
        onClose={onCloseAdjustersSignUpsListModal}
        tab={0}
      />

      <Stack
        display="flex"
        direction={['column', 'column', 'column', 'row']}
        justifyContent="space-evenly"
        alignItems={['center', 'center', 'center', 'start']}
        spacing={[8, 8, 8, 0]}
      >
        {/* Adjusters */}
        <Stack spacing={0}>
          <Heading mb={4} fontSize="2xl">
            Adjusters
          </Heading>

          <Stack
            spacing={0}
            display="flex"
            direction={['column', 'row', 'row']}
          >
            <DataBox
              header={availableAdjusters.length}
              onClick={() => {
                setAdjustersTab(0)
                onOpenAdjustersListModal()
              }}
              text="Available"
            />

            <DataBox
              header={unavailableAdjusters.length}
              onClick={() => {
                setAdjustersTab(1)
                onOpenAdjustersListModal()
              }}
              text="Unvailable"
            />
          </Stack>

          <Stack
            spacing={0}
            display="flex"
            direction={['column', 'row', 'row']}
          >
            <DataBox
              header={deployedAdjusters.length}
              onClick={() => {
                setAdjustersTab(2)
                onOpenAdjustersListModal()
              }}
              text="Deployed"
            />
            <DataBox
              customContent={
                <Link
                  as="h1"
                  fontSize={[16, 16, 20]}
                  fontWeight={500}
                  onClick={() =>
                    navigate(`${GLOBAL_ROUTES.ADMIN}${ADMIN_ROUTES.ADJUSTERS}`)
                  }
                >
                  Advanced Search
                </Link>
              }
            />
          </Stack>

          <Stack
            spacing={0}
            display="flex"
            direction={['column', 'row', 'row']}
          >
            <DataBox
              header={signUpsToShow?.length ?? 0}
              onClick={() => {
                onOpenAdjustersSignUpsListModal()
              }}
              customContent={
                <CustomDropdown
                  width="75%"
                  fontSize="12px"
                  placeholder="Select Period"
                  options={periodsOptions}
                  value={selectedSignUpPeriod}
                  textAlign="start"
                  handleChange={(a) => {
                    setSelectedSignUpPeriod(a)
                  }}
                />
              }
              text="Sign Ups"
            />
          </Stack>
        </Stack>

        {/* Firms and Jobs*/}
        <Stack spacing={0}>
          {/* Firms */}
          <>
            <Heading mb={4} fontSize="2xl">
              Firms
            </Heading>
            <Stack
              spacing={0}
              display="flex"
              direction={['column', 'row', 'row']}
            >
              <DataBox
                header={firms.length}
                onClick={() => {
                  onOpenFirmsListModal()
                }}
                text="Active"
              />
              <DataBox
                customContent={
                  <Link
                    as="h1"
                    fontSize={[16, 16, 20]}
                    fontWeight={500}
                    onClick={() =>
                      navigate(`${GLOBAL_ROUTES.ADMIN}${ADMIN_ROUTES.FIRMS}`)
                    }
                  >
                    Advanced Search
                  </Link>
                }
              />
            </Stack>
          </>

          {/* Jobs */}
          <>
            <Heading my={4} fontSize="2xl">
              Jobs
            </Heading>
            <Stack
              spacing={0}
              display="flex"
              direction={['column', 'row', 'row']}
            >
              <DataBox
                header={activeJobs.length}
                onClick={() => {
                  setJobsTab(0)
                  onOpenJobsListModal()
                }}
                text="Open Jobs"
              />

              <DataBox
                header={finishedJobs.length}
                onClick={() => {
                  setJobsTab(1)
                  onOpenJobsListModal()
                }}
                text="Past Jobs"
              />
            </Stack>
            <Stack
              spacing={0}
              display="flex"
              direction={['column', 'row', 'row']}
            >
              <DataBox
                header={jobsByPeriodToShow?.length ?? 0}
                onClick={() => {
                  onOpenJobsByPeriodListModal()
                }}
                customContent={
                  <CustomDropdown
                    width="75%"
                    fontSize="12px"
                    placeholder="Select Period"
                    options={periodsOptions}
                    value={selectedJobsPeriod}
                    textAlign="start"
                    handleChange={(a) => {
                      setSelectedJobsPeriod(a)
                    }}
                  />
                }
                text="Jobs By Period"
              />

              <DataBox
                customContent={
                  <Link
                    as="h1"
                    fontSize={[16, 16, 20]}
                    fontWeight={500}
                    onClick={() =>
                      navigate(`${GLOBAL_ROUTES.ADMIN}${ADMIN_ROUTES.FIRMS}`)
                    }
                  >
                    Advanced Search
                  </Link>
                }
              />
            </Stack>
          </>
        </Stack>
      </Stack>

      <Stack
        display="flex"
        direction={['column', 'column', 'column', 'row']}
        justifyContent="space-evenly"
        alignItems={['center', 'center', 'center', 'start']}
        spacing={[8, 8, 8, 0]}
      >
        <Stack spacing={0} alignItems={['center', 'unset']}>
          <Heading my={4} fontSize="2xl">
            Licenses and Certifications
          </Heading>
          <Stack
            spacing={0}
            display="flex"
            direction={['column', 'row', 'row']}
          >
            <DataBox
              customContent={
                <Link
                  as="h1"
                  fontSize={[16, 16, 20]}
                  fontWeight={500}
                  onClick={() =>
                    navigate(`${GLOBAL_ROUTES.ADMIN}${ADMIN_ROUTES.LOAS}`)
                  }
                >
                  LOAs
                </Link>
              }
            />
            <DataBox
              customContent={
                <Link
                  as="h1"
                  fontSize={[16, 16, 20]}
                  fontWeight={500}
                  onClick={() =>
                    navigate(`${GLOBAL_ROUTES.ADMIN}${ADMIN_ROUTES.LICENSES}`)
                  }
                >
                  Licenses
                </Link>
              }
            />
          </Stack>
          <DataBox
            customContent={
              <Link
                as="h1"
                fontSize={[16, 16, 20]}
                fontWeight={500}
                onClick={() =>
                  navigate(
                    `${GLOBAL_ROUTES.ADMIN}${ADMIN_ROUTES.CERTIFICATIONS}`
                  )
                }
              >
                Certifications
              </Link>
            }
          />
        </Stack>
        <Stack spacing={0} display={['none', 'none', 'none', 'block']}>
          <Stack
            spacing={0}
            display="flex"
            direction={['column', 'row', 'row']}
            visibility="hidden"
          >
            <DataBox customContent={<></>} />
            <DataBox customContent={<></>} />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}
export default AdminDashboard
