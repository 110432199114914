import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'
import Cropper from 'react-easy-crop'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react'
import { ADJUSTANT_GREEN } from '../../themes/themes'

interface CroppedArea {
  height: number
  width: number
  x: number
  y: number
}

const rotateSize = (
  width: any,
  height: any,
  radianAngle: number
): { width: number; height: number } => {
  return {
    width:
      Math.abs(Math.cos(radianAngle) * width) +
      Math.abs(Math.sin(radianAngle) * height),
    height:
      Math.abs(Math.sin(radianAngle) * width) +
      Math.abs(Math.cos(radianAngle) * height),
  }
}

const cropImage = async ({
  imageSrc,
  croppedAreaPixels,
  rotation,
  flip,
}: {
  imageSrc: string
  croppedAreaPixels: CroppedArea
  rotation: number
  flip: { horizontal: boolean; vertical: boolean }
}): Promise<{
  file: Blob | null
  url: string
}> => {
  const image: HTMLImageElement = await new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.src = imageSrc
  })

  const canvas = document.createElement('canvas')

  const ctx = canvas.getContext('2d')
  if (!ctx) return { file: null, url: '' }

  const radianAngle = (rotation * Math.PI) / 180

  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    radianAngle
  )

  canvas.width = bBoxWidth
  canvas.height = bBoxHeight

  ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
  ctx.rotate(radianAngle)
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
  ctx.translate(-image.width / 2, -image.height / 2)
  ctx.drawImage(image, 0, 0)

  const data = ctx.getImageData(
    croppedAreaPixels.x,
    croppedAreaPixels.y,
    croppedAreaPixels.width,
    croppedAreaPixels.height
  )

  canvas.width = croppedAreaPixels.width
  canvas.height = croppedAreaPixels.height

  ctx.putImageData(data, 0, 0)

  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      if (file) {
        resolve({ file, url: URL.createObjectURL(file) })
      }
    }, 'image/jpeg')
  })
}

const CropImage = ({
  isModalOpen,
  setIsModelOpen,
  imageURL,
  onCrop,
}: {
  isModalOpen: boolean
  setIsModelOpen: Dispatch<SetStateAction<boolean>>
  imageURL: string
  onCrop: (a: Blob | null) => void
}) => {
  const [zoom, setZoom] = useState(10)
  const [rotation, setRotation] = useState(0)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<
    CroppedArea | undefined
  >()
  const [crop, setCrop] = useState({ x: 0, y: 0 })

  // Called when the user stops moving the media, when stops zooming, and when the component loads.
  const cropComplete = useCallback(
    (_croppedArea: any, croppedAreaPixels: CroppedArea) => {
      setCroppedAreaPixels(croppedAreaPixels)
    },
    [setCroppedAreaPixels]
  )

  const onUploadImage = useCallback(async () => {
    try {
      console.log({
        imageSrc: imageURL,
        croppedAreaPixels: croppedAreaPixels,
        rotation,
        flip: { horizontal: false, vertical: false },
      })
      if (croppedAreaPixels) {
        const { file: croppedImageFile } = await cropImage({
          imageSrc: imageURL,
          croppedAreaPixels,
          rotation,
          flip: { horizontal: false, vertical: false },
        })
        onCrop(croppedImageFile)
        setIsModelOpen(false)
      }
    } catch (error) {
      console.log(error)
    }
  }, [croppedAreaPixels, imageURL, rotation, onCrop, setIsModelOpen])

  return (
    <Modal isOpen={isModalOpen} onClose={() => setIsModelOpen(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Crop & Upload</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box position="relative" height="300px">
            <Cropper
              image={imageURL}
              crop={crop}
              zoom={zoom / 10}
              aspect={1}
              onRotationChange={setRotation}
              onCropComplete={cropComplete}
              onCropChange={setCrop}
              style={{
                cropAreaStyle: {
                  borderRadius: '100%',
                  minWidth: '80%',
                  minHeight: '80%',
                },
              }}
            />
          </Box>
          <Box mt={4} mx={8}>
            <Slider
              aria-label="slider-ex-1"
              min={10}
              max={20}
              value={zoom}
              onChange={setZoom}
            >
              <SliderTrack>
                <SliderFilledTrack backgroundColor={ADJUSTANT_GREEN} />
              </SliderTrack>
              <SliderThumb backgroundColor={ADJUSTANT_GREEN} />
            </Slider>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button variant="adjustant" onClick={onUploadImage}>
            Upload Profile Photo
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CropImage
