import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  Textarea,
  Flex,
} from '@chakra-ui/react'
import { useState } from 'react'
import Label from '../Label/Label'
import CustomDropdown from '../CustomDropdown/CustomDropdown'
import {
  FLAG_REASON,
  flagReasonsOptions,
  getFlagReason,
} from '../../types/userTypes'

const FlagDocumentModal = ({
  flagReason: oldFlagReason,
  isApproved,
  isOpen,
  onClose,
}: {
  flagReason?: FLAG_REASON | string
  isApproved?: boolean
  isOpen: boolean
  onClose: (reason?: string, message?: string, approve?: boolean) => void
}) => {
  const r = getFlagReason(oldFlagReason)
  const [flagReason, setFlagReason] = useState<FLAG_REASON | undefined>(
    r?.value ? r?.value : undefined
  )
  const [flagMessage, setFlagMessage] = useState('')

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      size="xl"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Approve or Flag Document</ModalHeader>
        <ModalCloseButton onClick={() => onClose()} />
        <ModalBody>
          <CustomDropdown
            label="Reason for Flagging Document"
            placeholder="Select a Reason"
            options={flagReasonsOptions}
            value={flagReason}
            handleChange={(reason) => {
              setFlagReason(reason)
              setFlagMessage('')
            }}
          />

          {/* {flagReason === FLAG_REASON.OTHER && ( */}
          <Box>
            <Flex mb={2}>
              <Label fontSize="sm" color="gray.600">
                Message
              </Label>
            </Flex>
            <Textarea
              placeholder=""
              resize="vertical"
              size="xl"
              rows={6}
              p={2}
              value={flagMessage}
              borderRadius={3}
              onChange={(e) => {
                setFlagMessage(e.target.value)
              }}
            />
          </Box>
          {/* )} */}
        </ModalBody>
        <ModalFooter>
          {!isApproved && (
            <Button
              variant="adjustant"
              mr={3}
              onClick={() => {
                onClose('', flagMessage, true)
                setFlagMessage('')
                setFlagReason(undefined)
              }}
            >
              Approve
            </Button>
          )}
          <Button
            variant="adjustant"
            mr={3}
            onClick={() => {
              onClose(flagReason, flagMessage, false)
              setFlagMessage('')
              setFlagReason(undefined)
            }}
            isDisabled={
              !flagReason || (flagReason === FLAG_REASON.OTHER && !flagMessage)
            }
          >
            {oldFlagReason ? 'Update Flag and Send Message' : 'Flag'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default FlagDocumentModal
