import { CustomRadioOption } from '../components/CustomRadio/CustomRadio'
import { US_STATES_ABBREVIATIONS } from './stateLicensesTypes'
import { FEMA_CATEGORY, User } from './userTypes'

export interface JobLicensingRequirement {
  id: string
  isListed: boolean
  type: RequiredTypes
  state: US_STATES_ABBREVIATIONS | string
  loas: string[]
}

export interface JobCertificationRequirement {
  id: string
  name: string
  isListed: boolean
  type: RequiredTypes
}

export interface Job {
  _id?: string
  title: string
  types: DamageType[]
  typesDescription: string
  status: JobStatus
  reportDate: string
  dateOfLossType: DATE_OF_LOSS_TYPE
  dateOfLoss: string
  billingType: BillingType
  billingTypeDescription?: string
  billingTypeFileURLs: string[]
  billingForm: BILLING_FORM
  billingStatementsURLs: string[]
  description: string
  location?: string
  state: string
  city: string
  isFemaCertificationRequired: boolean
  femaCertificationCategories: FEMA_CATEGORY[]
  licensingRequirements: JobLicensingRequirement[]
  certificationRequirements: JobCertificationRequirement[]
  //
  allAdjustersCanApply?: boolean
  visibility: VISIBILITY
  postingDate: string
  finishDate: string
  firmId: string
  createdAt?: string
  adjustersNeeded?: number
  presence: PRESENCE
  adjusters?: User[]
}

export interface Application {
  _id?: string
  message: string
  status: JobApplicationStatus
  adjusterId: string
  jobId: string
}

export interface Invitation {
  _id?: string
  message: string
  status: JobApplicationStatus
  adjusterId: string
  jobId: string
}

export interface ReviewSurvey {
  q1?: JOB_REVIEW_FEEDBACK
  q2?: JOB_REVIEW_FEEDBACK
  q3?: JOB_REVIEW_FEEDBACK
  q4?: JOB_REVIEW_FEEDBACK
  q5?: JOB_REVIEW_FEEDBACK
  q6?: JOB_REVIEW_FEEDBACK
}

export interface Review {
  version: 'V1'
  testimonial: string
  date: string
  survey: ReviewSurvey
}

export interface Roster {
  _id?: string
  adjusterId: string
  jobId: string
  status: ADJUSTER_WORK_STATUS
  review?: Review
}

export interface JobSpecs {
  hired: number
  invites: number
  workforce: number
  applications: number
}

export interface BillingFile {
  file: any
  fileName: string
  url: string
}

export enum DOCUMENT_TYPE {
  LICENSE = 'LICENSE',
  CERTIFICATION = 'CERTIFICATION',
}

export enum JOB_REVIEW_FEEDBACK {
  ABOVE_AVERAGE = 'ABOVE_AVERAGE',
  SATISFACTORY = 'SATISFACTORY',
  UNSATISFACTORY = 'UNSATISFACTORY',
  NA = 'N/A',
}

export const JOB_REVIEW_FEEDBACK_SCORE: { [K in JOB_REVIEW_FEEDBACK]: number } =
  {
    ABOVE_AVERAGE: 5,
    SATISFACTORY: 4,
    UNSATISFACTORY: 2,
    'N/A': 0,
  }

export enum ADJUSTER_WORK_STATUS {
  ONGOING = 'ONGOING',
  FINISHED = 'FINISHED',
}

export enum DamageType {
  WIND_AND_HAIL = 'WIND_AND_HAIL',
  FIRE = 'FIRE',
  FLOOD = 'FLOOD',
  AUTO = 'AUTO',
  OTHER = 'OTHER',
}

export enum BillingType {
  HOURLY = 'HOURLY',
  TIME_EXPENSE = 'TIME_EXPENSE',
  PERCENTAGE = 'PERCENTAGE',
  OTHER = 'OTHER',
}

export enum RequiredTypes {
  NEEDED = 'NEEDED',
  RECOMMENDED = 'RECOMMENDED',
}

export enum JobStatus {
  DRAFT = 'DRAFT',
  ONGOING = 'ONGOING',
  FINISHED = 'FINISHED',
  DELETED = 'DELETED',
}

export enum VISIBILITY {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export enum JobApplicationStatus {
  REJECTED = 'REJECTED',
  WAITING = 'WAITING',
  ACCEPTED = 'ACCEPTED',
}

export enum PRESENCE {
  FIELD = 'FIELD',
  DESK_IN_OFFICE = 'DESK_IN_OFFICE',
  DESK_REMOTE = 'DESK_REMOTE',
}

export enum DATE_OF_LOSS_TYPE {
  DATE = 'DATE',
  MULTIPLE = 'MULTIPLE',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum BILLING_FORM {
  W2 = 'W2',
  TEN99 = '1099',
}

export const jobTypeOptions = [
  { label: 'Wind & Hail', value: DamageType.WIND_AND_HAIL },
  { label: 'Fire', value: DamageType.FIRE },
  { label: 'Flood', value: DamageType.FLOOD },
  { label: 'Auto', value: DamageType.AUTO },
  { label: 'Other', value: DamageType.OTHER },
]

export const jobStatusOptions = [
  { label: 'Draft', value: JobStatus.DRAFT },
  { label: 'Ongoing', value: JobStatus.ONGOING },
  { label: 'Finished', value: JobStatus.FINISHED },
  { label: 'Deleted', value: JobStatus.DELETED },
]

export const billingOptions = [
  { label: 'Hourly', value: BillingType.HOURLY },
  { label: 'Time/Expense', value: BillingType.TIME_EXPENSE },
  { label: 'Percentage', value: BillingType.PERCENTAGE },
  { label: 'Other', value: BillingType.OTHER },
]

export const presenceOptions = [
  { label: 'Field', value: PRESENCE.FIELD },
  { label: 'Desk In-Office', value: PRESENCE.DESK_IN_OFFICE },
  { label: 'Desk - Remote', value: PRESENCE.DESK_REMOTE },
]

export const requirementOptions = [
  { label: 'Needed', value: RequiredTypes.NEEDED },
  { label: 'Recommended', value: RequiredTypes.RECOMMENDED },
]

export const jobVisibilityOptions = [
  { label: 'Public', value: VISIBILITY.PUBLIC },
  { label: 'Private', value: VISIBILITY.PRIVATE },
]

export const dateOfLossOptions = [
  { label: 'Date of Loss', value: DATE_OF_LOSS_TYPE.DATE },
  { label: 'Multiple DOL’s', value: DATE_OF_LOSS_TYPE.MULTIPLE },
  { label: 'N/A', value: DATE_OF_LOSS_TYPE.NOT_APPLICABLE },
]

export const billingFormOptions = [
  { label: 'W-2', value: BILLING_FORM.W2 },
  { label: '1099', value: BILLING_FORM.TEN99 },
]

export const getBillingOptionTitle = (
  billingType?: BillingType
): string | undefined => {
  return billingOptions.find((bo) => bo.value === billingType)?.label
}

export const getRequirementOptionTitle = (
  requirementType: RequiredTypes
): string | undefined => {
  return requirementOptions.find((bo) => bo.value === requirementType)?.label
}

export const FEMAOptions: CustomRadioOption[] = [
  {
    value: 'false',
    label: 'No',
  },
  {
    value: 'true',
    label: 'Yes',
  },
]
