import { Box, Heading, Button, HStack, Link, Spinner } from '@chakra-ui/react'
import CustomTable from '../../../components/CustomTable/CustomTable'
import { GET_FLAGGED_DOCUMENTS } from '../../../graphql/Queries'
import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import { BsFillTrashFill } from 'react-icons/bs'
import { CiEdit } from 'react-icons/ci'
import { ADJUSTANT_GREEN } from '../../../themes/themes'
import { GLOBAL_ROUTES } from '../../../App'

interface FlaggedDocument {
  adjusterFirstName: string
  adjusterLastName: string
  adjusterID: string
  adjusterEmail: string
  documentType: string
  key: string
  flaggedReason: string
}

const AdminFlaggedDocuments = () => {
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [flaggedDocuments, setFlaggedDocuments] = useState<FlaggedDocument[]>(
    []
  )
  const columnHelper = createColumnHelper<FlaggedDocument>()
  const columns = [
    columnHelper.accessor('adjusterLastName', {
      cell: (info) => info.getValue(),
      header: 'Last Name',
    }),
    columnHelper.accessor('adjusterFirstName', {
      cell: (info) => info.getValue(),
      header: 'First Name',
    }),
    columnHelper.accessor('adjusterEmail', {
      cell: (info) => info.getValue(),
      header: 'Email',
    }),
    columnHelper.accessor('documentType', {
      cell: (info) => info.getValue(),
      header: 'Document Type',
    }),
    columnHelper.accessor('flaggedReason', {
      cell: (info) => info.getValue(),
      header: 'Flagging Reason',
    }),
    columnHelper.accessor('key', {
      cell: (info) => {
        return (
          <HStack spacing={4} justifyContent="center">
            <CiEdit
              fontSize={20}
              cursor="pointer"
              color={ADJUSTANT_GREEN}
              onClick={() => {
                const original = info?.row?.original
                window
                  .open(
                    `${GLOBAL_ROUTES.ADMIN}${GLOBAL_ROUTES.PROFILE}/${original.adjusterID}?section=${original.key}`,
                    '_blank'
                  )
                  ?.focus()
              }}
            />
          </HStack>
        )
      },
      header: '',
    }),
  ]

  const [getAllFlaggedDocuments] = useLazyQuery(GET_FLAGGED_DOCUMENTS, {
    onCompleted: (data: { AdjustersFlaggedDocuments: FlaggedDocument[] }) => {
      setShowLoader(false)
      if (data?.AdjustersFlaggedDocuments) {
        setFlaggedDocuments(data.AdjustersFlaggedDocuments)
      }
    },
    // onError: (err) => {}
  })

  useEffect(() => {
    setShowLoader(true)
    getAllFlaggedDocuments()
  }, [getAllFlaggedDocuments])

  return (
    <Box
      pt={8}
      px={[4, 8]}
      h="100%"
      width="100%"
      backgroundColor="white"
      overflowY="auto"
    >
      <Heading
        mb={4}
        fontSize="2xl"
        display="flex"
        justifyContent="space-between"
      >
        Pending Review Documents
      </Heading>
      <CustomTable data={flaggedDocuments} columns={columns} />
      {showLoader && (
        <HStack direction="row" h="80%" justifyContent="center">
          <Spinner size="xl" />
        </HStack>
      )}
    </Box>
  )
}

export default AdminFlaggedDocuments
