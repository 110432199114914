import React from 'react'
import { Box, Button, HStack } from '@chakra-ui/react'
import CompleteProfileHeader from './CompleteProfileHeader'

const CompleteProfileIntro = ({ next }: { next: () => void }) => {
  return (
    <Box maxW="500px">
      <CompleteProfileHeader text="Nice to meet you, let’s setup your profile" />
      <HStack justify="center">
        <Button variant="adjustant" onClick={next}>
          Get Started
        </Button>
      </HStack>
    </Box>
  )
}
export default CompleteProfileIntro
