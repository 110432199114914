import React from 'react'
import { Text } from '@chakra-ui/react'

interface LabelProps {
  color?: string
  children: any // JSX.Element
  fontSize?: string
}

const Label = ({
  color = 'brand.300',
  children,
  fontSize = 'sm'
}: LabelProps) => {
  return (
    <Text
      alignSelf="flex-start"
      fontSize={fontSize}
      fontWeight="bold"
      color={color}>
      {children}
    </Text>
  )
}

export default Label
