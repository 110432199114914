import React from 'react'
import { ADJUSTANT_GREEN } from '../../themes/themes'
const Logo = ({
  width = '24',
  height = '24',
}: {
  width?: string
  height?: string
}) => (
  <div className="p-3">
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2239 4.28972e-05C12.7289 0.00408312 13.1883 0.293118 13.4105 0.746637L18.1589 10.4373L21.9532 8.0354C22.5754 7.64154 23.3991 7.82663 23.7929 8.44882C24.1868 9.071 24.0017 9.89467 23.3795 10.2885L19.3393 12.8461L23.8637 22.0797C24.1877 22.741 23.9143 23.5397 23.2531 23.8637C22.5918 24.1877 21.7931 23.9143 21.4691 23.2531L17.0727 14.2809L2.04651 23.7929C1.53897 24.1142 0.879263 24.0565 0.435285 23.6519C-0.00869331 23.2473 -0.127302 22.5957 0.145604 22.0606L11.0254 0.727556C11.2549 0.27765 11.7189 -0.00399745 12.2239 4.28972e-05ZM15.8924 11.8721L12.1894 4.3148L4.73212 18.9369L15.8924 11.8721Z"
        fill={ADJUSTANT_GREEN}
      />
    </svg>
  </div>
)
export default Logo
