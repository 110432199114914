import React, { useCallback } from 'react'
import {
  Box,
  Button,
  FormControl,
  HStack,
  Input,
  InputGroup,
  Link,
  Stack,
  VStack,
} from '@chakra-ui/react'
import Label from '../Label/Label'
import FileInput from '../FileInput/FileInput'
import { AiOutlineClose, AiOutlineCloudUpload } from 'react-icons/ai'
import AddressInput from '../AddressInput/AddressInput'
import { encodeS3URI, formatDate } from '../../utils/functions'

const UpdateDriverLicense = ({
  editable,
  id,
  setId,
  address,
  setAddress,
  city,
  setCity,
  state,
  setState,
  zipcode,
  setZipcode,
  birthDate,
  setBirthdate,
  url,
  setURL,
  setFile,
  fileName,
  setFileName,
}: {
  editable: boolean
  id: string
  setId?: React.Dispatch<React.SetStateAction<string>>
  address: string
  setAddress?: React.Dispatch<React.SetStateAction<string>>
  city: string
  setCity?: React.Dispatch<React.SetStateAction<string>>
  state: string
  setState?: React.Dispatch<React.SetStateAction<string>>
  zipcode: string
  setZipcode?: React.Dispatch<React.SetStateAction<string>>
  birthDate: string
  setBirthdate?: React.Dispatch<React.SetStateAction<string>>
  url: string
  setURL?: React.Dispatch<React.SetStateAction<string>>
  setFile?: React.Dispatch<React.SetStateAction<any>>
  fileName: string
  setFileName?: React.Dispatch<React.SetStateAction<string>>
}) => {
  const onFileInputChange = useCallback(
    async (e: any) => {
      const fileFromFiles = e.target.files[0]
      if (fileFromFiles) {
        if (setFile) setFile(fileFromFiles)
        if (setFileName) setFileName(fileFromFiles.name)
        const url = URL.createObjectURL(fileFromFiles)
        if (setURL) setURL(url)
      }
    },
    [setFile, setFileName, setURL]
  )

  const deleteFile = useCallback(() => {
    if (setFile) setFile(null)
    if (setFileName) setFileName('')
    if (setURL) setURL('')
  }, [setFile, setFileName, setURL])

  return (
    <Stack spacing={6}>
      <Stack flexDirection={['column', 'initial', 'initial']} spacing={6}>
        <VStack alignItems="baseline" width={['100%', '50%', '50%']}>
          <Label fontSize="sm" color="gray.700">
            {editable ? 'Upload Your Driver’s License' : 'Document'}
          </Label>
          {url || !editable ? (
            <HStack>
              <Link color="teal.500" href={encodeS3URI(url)} isExternal>
                {fileName || '-'}
              </Link>
              {editable && (
                <AiOutlineClose cursor="pointer" onClick={deleteFile} />
              )}
            </HStack>
          ) : (
            <FileInput
              element={
                <Button
                  variant="adjustant"
                  rightIcon={<AiOutlineCloudUpload />}
                >
                  Upload
                </Button>
              }
              accept=".pdf"
              onFileInputChange={onFileInputChange}
              //   clearFile={() => {
              //     console.log('clearFile')
              //     if (setFile) setFile(undefined)
              //   }}
            />
          )}
        </VStack>
        <VStack alignItems="baseline" width={['100%', '50%', '50%']}>
          <Label fontSize="sm" color="gray.700">
            Driver’s License # / ID#
          </Label>
          {editable ? (
            <FormControl>
              <InputGroup>
                <Input
                  type="text"
                  placeholder="DL# or ID#"
                  value={id}
                  onChange={(e) => {
                    if (setId) setId(e.target.value)
                  }}
                />
              </InputGroup>
            </FormControl>
          ) : (
            <>{id || '-'}</>
          )}
        </VStack>
      </Stack>

      <Stack>
        {editable ? (
          <Box zIndex={2}>
            <AddressInput
              isRequired
              label="Address"
              placeholder="e.g. 718 S. Main St."
              address={address}
              field="full_address"
              onAddressChange={(e) => {
                if (setAddress) setAddress(e)
              }}
              onSelectOption={(e) => {
                const p = e.split(', ')
                if (setAddress && setCity && setState && setZipcode) {
                  setAddress(p?.[0] ?? '')
                  setCity(p?.[1] ?? '')
                  const p2 = p?.[2]?.split(' ')
                  setState(p2?.[0] ?? '')
                  setZipcode(p2?.[1] ?? '')
                  //`${p?.[0]}, ${p?.[1]}`
                }
              }}
              handleAddressClear={() => {
                if (setAddress && setCity && setState && setZipcode) {
                  setAddress('')
                  setCity('')
                  setState('')
                  setZipcode('')
                }
              }}
            />
          </Box>
        ) : (
          <>
            <Label fontSize="sm" color="gray.700">
              Address
            </Label>
            {address || '-'}
          </>
        )}
      </Stack>

      <Stack flexDirection={['column', 'initial', 'initial']} spacing={2}>
        <VStack alignItems="baseline" width={['100%', '33.3%', '33.3%']}>
          <Label fontSize="sm" color="gray.700">
            City
          </Label>
          {editable ? (
            <FormControl>
              <InputGroup>
                <Input
                  type="text"
                  placeholder=""
                  value={city}
                  onChange={(e) => {
                    if (setCity) setCity(e.target.value)
                  }}
                />
              </InputGroup>
            </FormControl>
          ) : (
            <>{city || '-'}</>
          )}
        </VStack>
        <VStack alignItems="baseline" width={['100%', '20%', '20%']}>
          <Label fontSize="sm" color="gray.700">
            State
          </Label>
          {editable ? (
            <FormControl>
              <InputGroup>
                <Input
                  type="text"
                  placeholder=""
                  value={state}
                  onChange={(e) => {
                    if (setState) setState(e.target.value)
                  }}
                />
              </InputGroup>
            </FormControl>
          ) : (
            <>{state || '-'}</>
          )}
        </VStack>
        <VStack alignItems="baseline" width={['100%', '13.3%', '13.3%']}>
          <Label fontSize="sm" color="gray.700">
            Zip
          </Label>
          {editable ? (
            <FormControl>
              <InputGroup>
                <Input
                  type="text"
                  placeholder=""
                  value={zipcode}
                  onChange={(e) => {
                    if (setZipcode) setZipcode(e.target.value)
                  }}
                />
              </InputGroup>
            </FormControl>
          ) : (
            <>{zipcode || '-'}</>
          )}
        </VStack>
        <VStack alignItems="baseline" width={['100%', '33.3%', '33.3%']}>
          <Label fontSize="sm" color="gray.700">
            Date of Birth
          </Label>
          {editable ? (
            <FormControl
            // isInvalid={!isListingUrlValid && listingUrlTouched}
            >
              <InputGroup>
                <Input
                  type="date"
                  value={birthDate}
                  onChange={(e) => {
                    if (setBirthdate) setBirthdate(e.target.value)
                  }}
                />
              </InputGroup>
              {/* {!isListingUrlValid && (
                <FormErrorMessage>
                  Enter a valid URL starting with http:// or https://
                </FormErrorMessage>
              )} */}
            </FormControl>
          ) : (
            <>{formatDate(birthDate) || '-'}</>
          )}
        </VStack>
      </Stack>
    </Stack>
  )
}

export default UpdateDriverLicense
