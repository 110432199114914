import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { LOASContext } from '../../../contexts/LOASContext'
import { LOA, LOASContextType } from '../../../types/loasTypes'
import { CiEdit } from 'react-icons/ci'
import { ADJUSTANT_GREEN } from '../../../themes/themes'
import { createColumnHelper } from '@tanstack/react-table'
import CustomTable from '../../../components/CustomTable/CustomTable'
import { BsFillTrashFill } from 'react-icons/bs'
import Label from '../../../components/Label/Label'
import { useMutation } from '@apollo/client'
import {
  CREATE_LOA,
  DELETE_LOA_BY_ID,
  UPDATE_LOA_BY_ID,
} from '../../../graphql/Mutations'
import { showSuccessToast } from '../../../components/Toast/Toast'
import DeleteModal from '../../../components/Modals/DeleteModal'

const UpdateLoaModal = ({
  loa,
  isOpen,
  onClose,
}: {
  loa?: LOA
  isOpen: boolean
  onClose: (newLoa: LOA, updated: boolean) => void
}) => {
  const [loaName, setLoaName] = useState(loa?.name ?? '')
  useEffect(() => {
    setLoaName(loa?.name ?? '')
  }, [loa])

  const canUpdate = useMemo(() => {
    return loa?.name !== loaName
  }, [loa?.name, loaName])

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        if (loa) onClose(loa, false)
      }}
      isCentered
      //   size="6xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update LOA</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex justify="space-between" mb={2}>
            <Label fontSize="sm" color="gray.700">
              Name
            </Label>
          </Flex>
          <Input
            value={loaName}
            //   placeholder=" (optional)"
            borderRadius={3}
            onChange={(e) => {
              setLoaName(e.target.value)
            }}
          />
        </ModalBody>

        <ModalFooter>
          <Button
            variant="adjustant"
            mr={3}
            onClick={() => {
              if (loa) onClose(loa, false)
            }}
          >
            Close
          </Button>
          <Button
            variant="adjustant"
            isDisabled={!canUpdate}
            onClick={() => {
              if (loa)
                onClose(
                  {
                    _id: loa._id,
                    code: loa.code,
                    name: loaName,
                  },
                  true
                )
            }}
          >
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const CreateModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: (loaName: string) => void
}) => {
  const [loaName, setLoaName] = useState('')

  const canCreate = useMemo(() => {
    return !!loaName
  }, [loaName])

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose('')
      }}
      isCentered
      //   size="6xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create LOA</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex justify="space-between" mb={2}>
            <Label fontSize="sm" color="gray.700">
              Name
            </Label>
          </Flex>
          <Input
            value={loaName}
            //   placeholder=" (optional)"
            borderRadius={3}
            onChange={(e) => {
              setLoaName(e.target.value)
            }}
          />
        </ModalBody>

        <ModalFooter>
          <Button
            variant="adjustant"
            mr={3}
            onClick={() => {
              onClose('')
            }}
          >
            Close
          </Button>
          <Button
            variant="adjustant"
            isDisabled={!canCreate}
            onClick={() => {
              onClose(loaName)
              setLoaName('')
            }}
          >
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const AdminLoas = () => {
  const [showLoader, setShowLoader] = useState(false)
  const { loas, setLoas }: LOASContextType = useContext(LOASContext)
  const [loaToUpdate, setLoaToUpdate] = useState<LOA | undefined>()
  const [loaToUDelete, setLoaToDelete] = useState<LOA | undefined>()

  const [updateLOAMutation] = useMutation(UPDATE_LOA_BY_ID)
  const [createLOAMutation] = useMutation(CREATE_LOA)
  const [deleteLOAMutation] = useMutation(DELETE_LOA_BY_ID)

  const columnHelper = createColumnHelper<LOA>()
  const columns = [
    // columnHelper.accessor('_id', {
    //   cell: (info) => info.getValue(),
    //   header: 'ID',
    // }),
    columnHelper.accessor('name', {
      cell: (info) => info.getValue(),
      header: 'Name',
    }),
    columnHelper.accessor('code', {
      cell: (info) => {
        return (
          <HStack spacing={4} justifyContent="center">
            {showLoader ? (
              <Spinner />
            ) : (
              <>
                <CiEdit
                  fontSize={20}
                  cursor="pointer"
                  color={ADJUSTANT_GREEN}
                  onClick={() => {
                    const l = loas?.find(
                      (o) => o.code === (info.getValue() as number)
                    )
                    if (l) {
                      setLoaToUpdate(l)
                      onOpenUpdateLOAModal()
                    }
                  }}
                />
                <BsFillTrashFill
                  fontSize={20}
                  cursor="pointer"
                  color="red"
                  onClick={() => {
                    const l = loas?.find(
                      (o) => o.code === (info.getValue() as number)
                    )
                    if (l) {
                      setLoaToDelete(l)
                      onOpenDeleteLOAModal()
                    }
                  }}
                />
              </>
            )}
          </HStack>
        )
      },
      header: '',
    }),
  ]

  const {
    isOpen: updateLOAModalIsOpen,
    onOpen: onOpenUpdateLOAModal,
    onClose: onCloseUpdateLOAModal,
  } = useDisclosure()

  const {
    isOpen: createLOAModalIsOpen,
    onOpen: onOpenCreateLOAModal,
    onClose: onCloseCreateLOAModal,
  } = useDisclosure()

  const {
    isOpen: deleteLOAModalIsOpen,
    onOpen: onOpenDeleteLOAModal,
    onClose: onCloseDeleteLOAModal,
  } = useDisclosure()

  const updateLOA = useCallback(
    async (newLoa: LOA) => {
      setShowLoader(true)
      const { _id, ...l } = newLoa
      const result = await updateLOAMutation({
        variables: {
          loa: l,
          id: _id,
        },
      })
      if (result?.data?.LOAUpdateOne?.record) {
        const index = loas?.findIndex((loa) => loa._id === _id) ?? -1
        if (index >= 0 && loas) {
          loas[index] = newLoa
          setLoas([...loas])
          showSuccessToast('LOA Updated Succesfully!')
        }
      }
      setShowLoader(false)
    },
    [loas, setLoas, updateLOAMutation]
  )

  const createLOA = useCallback(
    async (loaName: string) => {
      setShowLoader(true)
      const code = Date.now()
      const newLoa = {
        code,
        name: loaName,
      }
      const result = await createLOAMutation({
        variables: {
          loa: newLoa,
        },
      })
      if (result?.data?.LOACreateOne?.record) {
        setLoas((prev) => [...prev, result?.data?.LOACreateOne?.record])
        showSuccessToast('LOA Created Succesfully!')
      }
      setShowLoader(false)
    },
    [createLOAMutation, setLoas]
  )

  const deleteLOA = useCallback(
    async (id: string) => {
      setShowLoader(true)
      const result = await deleteLOAMutation({
        variables: {
          id,
        },
      })
      console.log(result)
      if (result?.data?.LOARemoveById?.record && loas) {
        const loaIndex = loas?.findIndex((l) => l._id === id) ?? -1
        if (loaIndex >= 0) {
          loas.splice(loaIndex, 1)
          setLoas([...loas])
          showSuccessToast('LOA Deleted Succesfully!')
        }
      }
      setShowLoader(false)
    },
    [deleteLOAMutation, loas, setLoas]
  )

  return (
    <Box background="white" px={[2, 4, 12]} py={6}>
      <UpdateLoaModal
        loa={loaToUpdate}
        isOpen={updateLOAModalIsOpen}
        onClose={(loa, updated) => {
          onCloseUpdateLOAModal()
          if (updated) updateLOA(loa)
        }}
      />
      <CreateModal
        isOpen={createLOAModalIsOpen}
        onClose={(loaName) => {
          onCloseCreateLOAModal()
          if (loaName) createLOA(loaName)
        }}
      />
      <DeleteModal
        header="Delete LOA"
        isOpen={deleteLOAModalIsOpen}
        onClose={(d) => {
          onCloseDeleteLOAModal()
          console.log(loaToUDelete)
          if (d && loaToUDelete) deleteLOA(loaToUDelete._id)
        }}
      />
      <Heading
        mb={4}
        fontSize="2xl"
        display="flex"
        justifyContent="space-between"
      >
        LOAs ({loas?.length ?? 0})
        <Button
          isLoading={showLoader}
          variant="adjustant"
          mr={3}
          onClick={() => {
            onOpenCreateLOAModal()
          }}
        >
          Create
        </Button>
      </Heading>
      <CustomTable data={loas ?? []} columns={columns} />
    </Box>
  )
}

export default AdminLoas
