import {
  Box,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { GLOBAL_ROUTES } from '../../App'
import { Job, JobStatus, Roster } from '../../types/jobTypes'
import { createColumnHelper } from '@tanstack/react-table'
import { formatDate } from '../../utils/functions'
import CustomTable from '../CustomTable/CustomTable'
import { AdminFirm } from '../../types/userTypes'
import { CiEdit } from 'react-icons/ci'
import { ADJUSTANT_GREEN } from '../../themes/themes'

function FirmsList({ firms, jobs }: { firms: AdminFirm[]; jobs: Job[] }) {
  const columnHelper = createColumnHelper<AdminFirm>()
  const columns = [
    columnHelper.accessor('firmName', {
      cell: (info) => (
        <Link
          onClick={() => {
            window
              .open(
                `${GLOBAL_ROUTES.ADMIN}${GLOBAL_ROUTES.PROFILE}/${info?.row
                  ?.getAllCells()?.[4]
                  ?.getValue()}`,
                '_blank'
              )
              ?.focus()
          }}
        >
          {info.getValue()}
        </Link>
      ),
      header: 'Firm Name',
    }),
    // columnHelper.accessor('areasOfOperation', {
    //   cell: (info) => info.getValue().join(', '),
    //   header: 'Active States',
    // }),
    columnHelper.accessor('openJobs', {
      cell: (info) => {
        const firmJobs = jobs.filter(
          (job) =>
            job.firmId === info?.row?.original?._id &&
            job.status === JobStatus.ONGOING
        )
        return (
          <Popover>
            <PopoverTrigger>
              {firmJobs.length > 0 ? (
                <Link>{firmJobs.length}</Link>
              ) : (
                <>Empty</>
              )}
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              {/* <PopoverCloseButton /> */}
              <PopoverHeader as="b">Jobs</PopoverHeader>
              <PopoverBody>
                <TableContainer>
                  <Table variant="simple" size="sm">
                    <Thead>
                      <Tr>
                        <Th>ID</Th>
                        <Th>Title</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {firmJobs.map((job) => (
                        <Tr key={job._id}>
                          <Td>
                            <Link
                              onClick={() =>
                                window
                                  .open(
                                    `${GLOBAL_ROUTES.ADMIN}${GLOBAL_ROUTES.JOB}/${job._id}`,
                                    '_blank'
                                  )
                                  ?.focus()
                              }
                            >
                              {job._id}
                            </Link>
                          </Td>
                          <Td>{job?.title}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )
      },
      header: 'Open Jobs',
    }),
    columnHelper.accessor('pastJobs', {
      cell: (info) => {
        const firmJobs = jobs.filter(
          (job) =>
            job.firmId === info?.row?.original?._id &&
            job.status === JobStatus.FINISHED
        )
        return (
          <Popover>
            <PopoverTrigger>
              {firmJobs.length > 0 ? (
                <Link>{firmJobs.length}</Link>
              ) : (
                <>Empty</>
              )}
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              {/* <PopoverCloseButton /> */}
              <PopoverHeader as="b">Jobs</PopoverHeader>
              <PopoverBody>
                <TableContainer>
                  <Table variant="simple" size="sm">
                    <Thead>
                      <Tr>
                        <Th>ID</Th>
                        <Th>Title</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {firmJobs.map((job) => (
                        <Tr key={job._id}>
                          <Td>
                            <Link
                              onClick={() =>
                                window
                                  .open(
                                    `${GLOBAL_ROUTES.ADMIN}${GLOBAL_ROUTES.JOB}/${job._id}`,
                                    '_blank'
                                  )
                                  ?.focus()
                              }
                            >
                              {job._id}
                            </Link>
                          </Td>
                          <Td>{job?.title}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )
      },
      header: 'Past Jobs',
    }),
    columnHelper.accessor('lastActive', {
      cell: (info) => (info.getValue() ? formatDate(info.getValue()) : '-'),
      header: 'Last Active',
    }),
    columnHelper.accessor('_id', {
      cell: (info) => {
        return (
          <CiEdit
            fontSize={20}
            cursor="pointer"
            color={ADJUSTANT_GREEN}
            onClick={() =>
              window
                .open(
                  `${GLOBAL_ROUTES.ADMIN}${
                    GLOBAL_ROUTES.PROFILE
                  }/${info.getValue()}`,
                  '_blank'
                )
                ?.focus()
            }
          />
        )
      },
      header: '',
    }),
  ]

  return (
    <Box>
      {firms.length > 0 ? (
        <CustomTable data={firms} columns={columns} />
      ) : (
        <Text my={8}>No adjusters to show</Text>
      )}
    </Box>
  )
}

export default FirmsList
