import React, { useCallback, useMemo, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { GET_USER_BY_EMAIL } from '../../graphql/Queries'
import { GLOBAL_ROUTES } from '../../App'
import {
  VStack,
  Heading,
  Box,
  Stack,
  Input,
  Text,
  Button,
  Link,
  Checkbox,
  HStack,
} from '@chakra-ui/react'
import Label from '../Label/Label'
import { ADJUSTANT_GREEN } from '../../themes/themes'
import { validateEmail, validatePhoneNumber } from '../../utils/functions'

const UserCredentialsForm = ({
  setStep,
  firstName,
  lastName,
  email,
  phoneNumber,
  setFirstName,
  setLastName,
  setEmail,
  setPhoneNumber,
}: {
  setStep: React.Dispatch<React.SetStateAction<number>>
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  setFirstName: React.Dispatch<React.SetStateAction<string>>
  setLastName: React.Dispatch<React.SetStateAction<string>>
  setEmail: React.Dispatch<React.SetStateAction<string>>
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>
}) => {
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false)

  const [emailIsInvalid, setEmailIsInvalid] = useState<boolean>(false)
  const [phoneIsInvalid, setPhoneIsInvalid] = useState<boolean>(false)

  const [errorMessage, setErrorMessage] = useState('')
  const [emailError, setEmailError] = useState<string>('')
  const [phoneError, setPhoneError] = useState<string>('')

  const [getUser] = useLazyQuery(GET_USER_BY_EMAIL)

  const onclick = useCallback(async () => {
    console.log('onclick')
    setErrorMessage('')
    setShowLoader(true)
    const result = await getUser({ variables: { email } })
    setShowLoader(false)
    if (result?.data?.UserOne)
      setErrorMessage('The email address is taken already')
    else setStep(2)
  }, [getUser, email, setStep])

  const validateEmailOnChange = (email: string) => {
    if (validateEmail(email)) {
      setEmailError('')
      setEmailIsInvalid(false)
    } else {
      setEmailError('Please enter a valid email')
      setEmailIsInvalid(true)
    }
  }

  const validatePhoneOnChange = (phoneNumber: string) => {
    if (validatePhoneNumber(phoneNumber)) {
      setPhoneError('')
      setPhoneIsInvalid(false)
    } else {
      setPhoneError('Please enter a valid phone number')
      setPhoneIsInvalid(true)
    }
  }

  const formIsInvalid = useMemo((): boolean => {
    return !firstName || !lastName || !email || !phoneNumber
  }, [firstName, lastName, email, phoneNumber])

  return (
    <Box
      minHeight="100%"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        background="white"
        border="1px solid #ECEAE9"
        borderRadius="8px"
        width="600px"
        mx={3}
      >
        <VStack py={[6, 6, 12]} px={[6, 6, 12]}>
          <Heading
            as="h3"
            fontSize="3xl"
            color="gray.500"
            mt={4}
            mb={8}
            textAlign="center"
          >
            Sign up
          </Heading>

          <Stack
            display={['block', 'block', 'flex']}
            direction={['column', 'column', 'row']}
            // spacing={2}
            width="100%"
          >
            <Stack width={['100%', '100%', '50%']} mb={2}>
              <Label color="gray.500">First Name</Label>
              <Input
                type="text"
                placeholder="Johnny"
                _focusVisible={{ borderColor: 'gray.300' }}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Stack>
            <Stack width={['100%', '100%', '50%']}>
              <Label color="gray.500">Last Name</Label>
              <Input
                type="text"
                placeholder="Appleseed"
                _focusVisible={{ borderColor: 'gray.300' }}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Stack>
          </Stack>

          <Stack mb={2} width="100%">
            <Label color="gray.500">Email</Label>
            <Input
              type="text"
              placeholder="johnny.appleseed@email.com"
              _focusVisible={{ borderColor: 'gray.300' }}
              value={email}
              onChange={(e) => {
                const l = e.target.value?.toLocaleLowerCase()
                setEmail(l)
                validateEmailOnChange(l)
              }}
              isInvalid={emailIsInvalid}
            />
          </Stack>

          <Stack mb={2} width="100%">
            <Label color="gray.500">Phone Number</Label>
            <Input
              type="text"
              placeholder="Phone number"
              _focusVisible={{ borderColor: 'gray.300' }}
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value)
                validatePhoneOnChange(e.target.value)
              }}
              maxLength={10}
              isInvalid={phoneIsInvalid}
            />
          </Stack>

          <HStack alignItems="center">
            <Checkbox
              variant="adjustant"
              mb={4}
              isChecked={acceptedTerms}
              onChange={(e) => {
                setAcceptedTerms(e.target.checked)
              }}
            ></Checkbox>
            <Text fontSize={14} fontWeight={500}>
              Yes, I understand and agree to the{' '}
              <Link
                color={ADJUSTANT_GREEN}
                href="https://adjustant-files-bucket.s3.amazonaws.com/AdjustTech+User+Agreement.pdf"
                isExternal
              >
                Adjustant Terms of Service
              </Link>
              , including the{' '}
              <Link
                color={ADJUSTANT_GREEN}
                href="https://adjustant-files-bucket.s3.amazonaws.com/AdjustTech+User+Agreement.pdf"
                isExternal
              >
                User Agreement
              </Link>{' '}
              and{' '}
              <Link
                color={ADJUSTANT_GREEN}
                href="https://adjustant-files-bucket.s3.amazonaws.com/AdjustTech+Privacy+Policy.pdf"
              >
                Privacy Policy
              </Link>{' '}
              .
            </Text>
          </HStack>

          <VStack mb={2} width="100%">
            <Text as="h5" color="red.500" fontSize="sm">
              {errorMessage || emailError || phoneError}
            </Text>
            <Button
              variant="adjustant"
              isLoading={showLoader}
              _hover={{ backgroundColor: ADJUSTANT_GREEN }}
              onClick={onclick}
              isDisabled={
                formIsInvalid ||
                emailIsInvalid ||
                phoneIsInvalid ||
                !acceptedTerms
              }
              disabled={
                formIsInvalid ||
                emailIsInvalid ||
                phoneIsInvalid ||
                !acceptedTerms
              }
            >
              Continue
            </Button>

            <Stack>
              <Label color="gray.500">
                Already have an account?{' '}
                <Link color="teal.500" href={GLOBAL_ROUTES.ROOT}>
                  Log In
                </Link>
              </Label>
            </Stack>
          </VStack>
        </VStack>
      </Box>
    </Box>
  )
}

export default UserCredentialsForm
