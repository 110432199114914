import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Link,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spinner,
  Stack,
} from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { GET_ADMIN_JOBS } from '../../../graphql/Queries'
import { useLazyQuery, useMutation } from '@apollo/client'
import { formatDate } from '../../../utils/functions'
import { FaFilter } from 'react-icons/fa'
import { HiMagnifyingGlass } from 'react-icons/hi2'
import { ADJUSTANT_GREEN } from '../../../themes/themes'
import { JobStatus, jobStatusOptions } from '../../../types/jobTypes'
import Label from '../../../components/Label/Label'
import CustomTable from '../../../components/CustomTable/CustomTable'
import { createColumnHelper } from '@tanstack/react-table'
import CustomDropdownMultiple from '../../../components/CustomDropdownMultiple/CustomDropdownMultiple'
import { GLOBAL_ROUTES } from '../../../App'
import { UPDATE_JOB_BY_ID } from '../../../graphql/Mutations'

interface AdminJob {
  _id: string
  jobTitle: string
  firmName: string
  req: number
  acc: number
  pend: number
  finishDate: string
  postingDate: string
  reportDate: string
  status: string
}

function AdminJobs() {
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const columnHelper = createColumnHelper<AdminJob>()

  const columns = [
    columnHelper.accessor('_id', {
      cell: (info) => (
        <Link
          onClick={() =>
            window
              .open(
                `${GLOBAL_ROUTES.ADMIN}${GLOBAL_ROUTES.JOB}/${info.getValue()}`,
                '_blank'
              )
              ?.focus()
          }
        >
          {info.getValue()}
        </Link>
      ),
      header: 'Job ID',
    }),
    columnHelper.accessor('jobTitle', {
      cell: (info) => info.getValue(),
      header: 'Job Title',
    }),
    columnHelper.accessor('firmName', {
      cell: (info) => info.getValue(),
      header: 'Firm',
    }),
    columnHelper.accessor('req', {
      cell: (info) => info.getValue(),
      header: '# Req.',
    }),
    columnHelper.accessor('acc', {
      cell: (info) => info.getValue(),
      header: '# Acc.',
    }),
    columnHelper.accessor('pend', {
      cell: (info) => info.getValue(),
      header: '# Pend.',
    }),
    columnHelper.accessor('postingDate', {
      cell: (info) => (info.getValue() ? formatDate(info.getValue()) : '-'),
      header: 'Post Date',
    }),
    columnHelper.accessor('reportDate', {
      cell: (info) => (info.getValue() ? formatDate(info.getValue()) : '-'),
      header: 'Report Date',
    }),
    columnHelper.accessor('finishDate', {
      cell: (info) => (info.getValue() ? formatDate(info.getValue()) : '-'),
      header: 'Finish Date',
    }),
    columnHelper.accessor('status', {
      cell: (info) => info.getValue(),
      header: 'Status',
    }),
  ]

  const [jobs, setJobs] = useState<AdminJob[]>([])

  const [searcherValue, setSearcherValue] = useState<string>('')
  const [id, setId] = useState<string>('')
  const [firmName, setFirmName] = useState<string>('')
  const [statuses, setStatuses] = useState<JobStatus[]>([])
  const [req, setReq] = useState<number>(0)
  //   const [acc, setAcc] = useState<number>(0)
  //   const [pend, setPend] = useState<number>(0)
  const [postingDateRangeStart, setPostingDateRangeStart] = useState<string>('')
  const [postingDateRangeEnd, setPostingDateRangeEnd] = useState<string>('')
  const [reportDateRangeStart, setReportDateRangeStart] = useState<string>('')
  const [reportDateRangeEnd, setReportDateRangeEnd] = useState<string>('')

  const [getAdminJobs] = useLazyQuery(GET_ADMIN_JOBS, {
    onCompleted: (data: { AdminJobs: AdminJob[] }) => {
      setShowLoader(false)
      if (data?.AdminJobs) {
        const newUsers = [...data?.AdminJobs]
        setJobs(newUsers)
      }
    },
    onError: (err) => {
      setShowLoader(false)
      console.log(err)
    },
  })

  const onSearch = useCallback(() => {
    setShowLoader(true)
    getAdminJobs({
      variables: {
        searcherValue,
        statuses,
        id,
        firmName,
        req,
        postingDateRangeStart,
        postingDateRangeEnd,
        reportDateRangeStart,
        reportDateRangeEnd,
      },
      fetchPolicy: 'network-only',
    })
  }, [
    firmName,
    getAdminJobs,
    id,
    postingDateRangeEnd,
    postingDateRangeStart,
    reportDateRangeEnd,
    reportDateRangeStart,
    req,
    searcherValue,
    statuses,
  ])

  //   const [updateJobMutation] = useMutation(UPDATE_JOB_BY_ID)
  //   const updateAllJobs = useCallback(() => {
  //     console.log(jobs)
  //     const filJobs = jobs.filter((a: any) => a.femaCertificationCategory)
  //     console.log(filJobs)
  //     filJobs.forEach(async (job: any) => {
  //       await updateJobMutation({
  //         variables: {
  //           job: {
  //             femaCertificationCategories: [job?.femaCertificationCategory],
  //           },
  //           id: job._id,
  //         },
  //       })
  //     })
  //   }, [jobs, updateJobMutation])

  return (
    <Box
      h="100%"
      width="100%"
      backgroundColor="white"
      pt={8}
      px={[4, 8]}
      overflowY="auto"
    >
      <Accordion w="100%" allowToggle>
        <AccordionItem border="none">
          <HStack w="100%" justifyContent="center">
            <Stack w="-webkit-fill-available">
              <InputGroup>
                <Input
                  type="text"
                  placeholder="Search Jobs"
                  value={searcherValue}
                  onChange={(e) => setSearcherValue(e.target.value)}
                />
                <InputRightAddon
                  color="white"
                  background={ADJUSTANT_GREEN}
                  cursor="pointer"
                  onClick={onSearch}
                >
                  <HiMagnifyingGlass color="gray.300" />
                </InputRightAddon>
              </InputGroup>
            </Stack>
            <AccordionButton
              w="auto"
              border="1px solid"
              borderRadius="8px"
              borderColor={ADJUSTANT_GREEN}
              color={ADJUSTANT_GREEN}
              fontWeight="500"
            >
              <Box mr={2}>
                <FaFilter />
              </Box>
              Filters
            </AccordionButton>
          </HStack>
          <AccordionPanel>
            <Stack
              mb={4}
              spacing={4}
              display="flex"
              direction={['column', 'column', 'row']}
            >
              <Stack width={['100%', '100%', '50%']}>
                <Flex mb={1}>
                  <Label fontSize="sm" color="gray.700">
                    Job ID
                  </Label>
                </Flex>
                <Input
                  value={id}
                  onChange={(e) => setId(e.target.value)}
                  type="text"
                />
              </Stack>
              <Stack width={['100%', '100%', '50%']}>
                <Flex mb={1}>
                  <Label fontSize="sm" color="gray.700">
                    Firm Name
                  </Label>
                </Flex>
                <Input
                  value={firmName}
                  onChange={(e) => setFirmName(e.target.value)}
                  type="text"
                />
              </Stack>
            </Stack>

            <Stack mb={4}>
              <CustomDropdownMultiple
                label="Status"
                value={
                  statuses?.map((jt) => {
                    const label =
                      jobStatusOptions.find((jtp) => jtp.value === jt)?.label ??
                      ''
                    return {
                      label,
                      value: jt,
                    }
                  }) ?? []
                }
                options={jobStatusOptions}
                isClearable
                onChange={(a) => setStatuses(a as JobStatus[])}
              />
            </Stack>
            <Stack mb={4}>
              <Flex mb={1}>
                <Label fontSize="sm" color="gray.700">
                  Required Adjusters
                </Label>
              </Flex>
              <NumberInput value={req} onChange={(e) => setReq(+e)}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Stack>
            {/* <Stack mb={4}>
              <Flex mb={1}>
                <Label fontSize="sm" color="gray.700">
                  Hired Adjusters
                </Label>
              </Flex>
              <NumberInput value={acc} onChange={(e) => setAcc(+e)}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Stack>
            <Stack mb={4}>
              <Flex mb={1}>
                <Label fontSize="sm" color="gray.700">
                  Pending Invitations
                </Label>
              </Flex>
              <NumberInput value={pend} onChange={(e) => setPend(+e)}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Stack> */}
            <Stack mb={4}>
              <Flex mb={1}>
                <Label fontSize="sm" color="gray.700">
                  Posting Date
                </Label>
              </Flex>
              <HStack>
                <Input
                  //  placeholder="Select Date and Time"
                  size="md"
                  type="date"
                  value={postingDateRangeStart}
                  onChange={(e) => setPostingDateRangeStart(e.target.value)}
                />
                <Input
                  //  placeholder="Select Date and Time"
                  size="md"
                  type="date"
                  value={postingDateRangeEnd}
                  onChange={(e) => setPostingDateRangeEnd(e.target.value)}
                />
              </HStack>
            </Stack>

            <Stack mb={4}>
              <Flex mb={1}>
                <Label fontSize="sm" color="gray.700">
                  Report Date
                </Label>
              </Flex>
              <HStack>
                <Input
                  //  placeholder="Select Date and Time"
                  size="md"
                  type="date"
                  value={reportDateRangeStart}
                  onChange={(e) => setReportDateRangeStart(e.target.value)}
                />
                <Input
                  //  placeholder="Select Date and Time"
                  size="md"
                  type="date"
                  value={reportDateRangeEnd}
                  onChange={(e) => setReportDateRangeEnd(e.target.value)}
                />
              </HStack>
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <Box overflowY="auto" width="100%" height="calc(100% - 42px)">
        {showLoader && (
          <HStack
            direction="row"
            height="inherit"
            w="100%"
            justifyContent="center"
          >
            <Spinner size="xl" />
          </HStack>
        )}

        {/* <Button
          w="100%"
          variant="adjustant"
          onClick={() => {
            updateAllJobs()
          }}
        >
          UPDATE ALL JOBS
        </Button> */}
        {jobs.length > 0 && <CustomTable data={jobs} columns={columns} />}
      </Box>
    </Box>
  )
}
export default AdminJobs
