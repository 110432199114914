import ReactSelect, { ActionMeta, MultiValue } from 'react-select'
import { SelectableItem } from '../CustomDropdown/CustomDropdown'
import { Flex, Box } from '@chakra-ui/react'
import Label from '../Label/Label'
import { useRef } from 'react'

const CustomDropdownMultiple = ({
  value,
  label,
  options,
  onChange,
  isClearable,
  isDisabled,
}: {
  value: SelectableItem[]
  label: string
  options: SelectableItem[]
  isClearable?: boolean
  isDisabled?: boolean
  onChange: (a: string[]) => void
}) => {
  const valueRef = useRef(value)
  valueRef.current = value

  const selectAllOption = {
    value: '<SELECT_ALL>',
    label: 'All Items',
  }

  const isSelectAllSelected = () => valueRef.current.length === options.length

  const isOptionSelected = (option: SelectableItem) =>
    valueRef.current.some(({ value }) => value === option.value) ||
    isSelectAllSelected()

  const getOptions = () => [selectAllOption, ...options]

  const getValue = () => (isSelectAllSelected() ? [selectAllOption] : value)

  const onDropdownChange = (
    newValue: MultiValue<SelectableItem>,
    actionMeta: ActionMeta<SelectableItem>
  ) => {
    const { action, option, removedValue } = actionMeta

    // If select all is selected
    if (action === 'select-option' && option?.value === selectAllOption.value) {
      //   onChange(options, actionMeta)
      onChange(options.map((loa) => loa.value))
    } else if (
      // If select all is deselected
      (action === 'deselect-option' &&
        option?.value === selectAllOption.value) ||
      (action === 'remove-value' &&
        removedValue.value === selectAllOption.value)
    ) {
      onChange([])
    } else if (
      // If a different option to "all selected" is deselected
      actionMeta.action === 'deselect-option' &&
      isSelectAllSelected()
    ) {
      onChange(
        options
          .filter(({ value }) => value !== option?.value)
          .map((a) => a.value)
      )
    } else {
      //If a new option is selected
      onChange(newValue.map((a) => a.value))
    }
  }

  return (
    <Box mb={2}>
      {label && (
        <Flex justify="space-between" mb={2}>
          <Label fontSize="sm" color="gray.700">
            {label}
          </Label>
        </Flex>
      )}
      <ReactSelect
        styles={{
          container: (baseStyles, state) => ({
            ...baseStyles,
            width: '-webkit-fill-available',
            borderColor: 'red',
          }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: 'var(--chakra-colors-gray-300)',
            background: 'transparent',
          }),
          placeholder: (baseStyles, state) => ({
            ...baseStyles,
            color: 'black',
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            ':hover': {
              cursor: 'pointer',
            },
          }),
        }}
        isDisabled={isDisabled}
        // isMulti
        isClearable={isClearable}
        // value={value}
        // options={options}
        // onChange={(selectedOptions) => {
        //   onChange(selectedOptions.map((loa) => loa.value))
        // }}

        isOptionSelected={isOptionSelected}
        options={getOptions()}
        value={getValue()}
        onChange={onDropdownChange}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        isMulti
      />
    </Box>
  )
}

export default CustomDropdownMultiple
