import { Box, Input } from '@chakra-ui/react'
import React, { useRef, useCallback } from 'react'

const FileInput = ({
  element,
  accept,
  onFileInputChange,
  clearFile,
  isDisabled,
}: {
  element: any
  accept: string
  onFileInputChange: any
  clearFile?: any
  isDisabled?: boolean
}) => {
  const fileInputRef = useRef(null)
  const onUploadImageClick = useCallback(() => {
    if (fileInputRef.current !== null) {
      if (clearFile) clearFile()
      ;(fileInputRef?.current as HTMLInputElement)?.click()
    }
  }, [clearFile])

  return (
    <Box onClick={onUploadImageClick} cursor="pointer">
      {element}
      <Input
        hidden={true}
        type="file"
        ref={fileInputRef}
        accept={accept}
        onChange={onFileInputChange}
        isDisabled={isDisabled}
      />
    </Box>
  )
}

export default FileInput
