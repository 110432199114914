import {
  Box,
  Button,
  Divider,
  HStack,
  Heading,
  Link,
  Spinner,
  Tag,
  Text,
} from '@chakra-ui/react'
import {
  GET_ADMINS,
  GET_APPLICATIONS,
  GET_INVITATIONS,
  GET_JOBS_BY_IDS,
  GET_ROSTERS,
} from '../../../graphql/Queries'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { UserContext } from '../../../contexts/UserContext'
import { USER_STATUS, User, UserContextType } from '../../../types/userTypes'
import {
  ADJUSTER_WORK_STATUS,
  Application,
  Invitation,
  Job,
  JobApplicationStatus,
  jobTypeOptions,
} from '../../../types/jobTypes'
import { HiMapPin, HiCalendar } from 'react-icons/hi2'
import { ADJUSTANT_GREEN } from '../../../themes/themes'
import { formatDate } from '../../../utils/functions'
import { IoMdOpen } from 'react-icons/io'
import { GLOBAL_ROUTES } from '../../../App'
import { showSuccessToast } from '../../../components/Toast/Toast'
import {
  CREATE_NOTIFICATION,
  CREATE_ROSTER,
  UPDATE_APPLICATION_BY_ID,
  UPDATE_INVITATION_BY_ID,
  UPDATE_USER_BY_ID,
} from '../../../graphql/Mutations'
import {
  LOCALSTORAGE_OBJECTS_NAMES,
  setItemToLocalStorage,
} from '../../../utils/localStorageFunctions'

const Invitations = () => {
  const { user, setUser }: UserContextType = useContext(UserContext)
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [admins, setAdmins] = useState<User[]>([])
  const [invitationsRetrieved, setInvitationsRetrieved] =
    useState<boolean>(false)
  const [invitations, setInvitations] = useState<Invitation[]>([])
  const [jobs, setJobs] = useState<Job[]>([])
  const [updateInvitationMutation] = useMutation(UPDATE_INVITATION_BY_ID)
  const [updateApplicationMutation] = useMutation(UPDATE_APPLICATION_BY_ID)
  const [createRosterMutation] = useMutation(CREATE_ROSTER)
  const [createNotificationMutation] = useMutation(CREATE_NOTIFICATION)
  const [updateUser] = useMutation(UPDATE_USER_BY_ID)

  const [getRosters] = useLazyQuery(GET_ROSTERS)
  const [getJobs] = useLazyQuery(GET_JOBS_BY_IDS, {
    onCompleted: (data: { JobsByIds: Job[] }) => {
      setShowLoader(false)
      if (data?.JobsByIds) {
        const jobs = data?.JobsByIds
        // console.log(jobs)
        setJobs([...jobs])
      }
    },
    onError: (err) => {
      setShowLoader(false)
    },
  })

  const [getJobApplications] = useLazyQuery(GET_APPLICATIONS)
  const [getJobInvitations] = useLazyQuery(GET_APPLICATIONS)
  const [getInvitations] = useLazyQuery(GET_INVITATIONS, {
    onCompleted: (data: { InvitationMany: Invitation[] }) => {
      //   console.log('BRINCA')
      if (data?.InvitationMany) {
        const invitations = data?.InvitationMany
        setInvitations([...invitations])
        const jobsIds = invitations.map((i) => i.jobId)
        getJobs({ variables: { jobsIds }, fetchPolicy: 'network-only' })
      } else setShowLoader(false)
    },
    onError: (err) => {
      setShowLoader(false)
    },
  })

  const [getAdmins] = useLazyQuery(GET_ADMINS, {
    onCompleted: (data: { UserMany: User[] }) => {
      if (data?.UserMany) {
        const newUsers = [...data?.UserMany]
        setAdmins(newUsers)
      }
    },
    onError: (err) => {
      console.log(err)
    },
  })

  useEffect(() => {
    if (!invitationsRetrieved && user) {
      setShowLoader(true)
      getInvitations({
        variables: {
          adjusterId: user?._id,
          status: JobApplicationStatus.WAITING,
        },
        fetchPolicy: 'network-only',
      })
      getAdmins()
      setInvitationsRetrieved(true)
    }
  }, [setShowLoader, invitationsRetrieved, user, getInvitations, getAdmins])

  const jobsAndInvitations = useMemo(() => {
    return invitations
      .map((invitation) => ({
        job: jobs.find((job) => invitation.jobId === job._id),
        invitation,
      }))
      .filter((f) => !!f.job)
  }, [invitations, jobs])

  const notifyAdmins = useCallback(
    async (message: string, jobId: string) => {
      const a = await admins.map(async (admin) => {
        await createNotificationMutation({
          variables: {
            notification: {
              userId: admin?._id,
              message,
              link: `${GLOBAL_ROUTES.ADMIN}${GLOBAL_ROUTES.JOB}/${jobId}`,
              read: false,
            },
          },
        })

        return null
      })

      await Promise.all(a)
    },
    [admins, createNotificationMutation]
  )

  const rejectAllPendingApplicationsAndInvites = useCallback(
    async (jobId?: string) => {
      if (jobId) {
        //REJECT ALL PENDING INVITATIONS
        const pendingInvitations: Invitation[] =
          (
            await getJobInvitations({
              variables: { jobId, status: JobApplicationStatus.WAITING },
              fetchPolicy: 'network-only',
            })
          ).data?.InvitationMany ?? []
        // console.log(pendingInvitations)

        pendingInvitations.forEach((pendingInvitation) => {
          updateInvitationMutation({
            variables: {
              invitation: { status: JobApplicationStatus.REJECTED },
              id: pendingInvitation?._id,
            },
          })
        })

        //REJECT ALL PENDING APPLICATIONS
        const pendingApplications: Application[] =
          (
            await getJobApplications({
              variables: { jobId, status: JobApplicationStatus.WAITING },
              fetchPolicy: 'network-only',
            })
          ).data?.ApplicationMany ?? []
        // console.log(pendingApplications)

        pendingApplications.forEach((pendingApplication) => {
          updateApplicationMutation({
            variables: {
              application: { status: JobApplicationStatus.REJECTED },
              id: pendingApplication?._id,
            },
          })
        })
      }
    },
    [
      getJobApplications,
      getJobInvitations,
      updateApplicationMutation,
      updateInvitationMutation,
    ]
  )

  const acceptOrRejectInvitation = useCallback(
    async (accept: boolean, invitation: Invitation, job?: Job) => {
      if (invitation) {
        setShowLoader(true)
        const invitationToUpdate = {
          status: accept
            ? JobApplicationStatus.ACCEPTED
            : JobApplicationStatus.REJECTED,
        }
        const invitationIndex = invitations.findIndex(
          (i) => i._id === invitation._id
        )

        // Update invitation
        await updateInvitationMutation({
          variables: {
            invitation: invitationToUpdate,
            id: invitation?._id,
          },
        })
        invitations.splice(invitationIndex, 1)
        setInvitations([...invitations])

        if (accept) {
          //CREATE ROSTER
          const roster = {
            jobId: job?._id,
            adjusterId: user?._id,
            status: ADJUSTER_WORK_STATUS.ONGOING,
          }
          await createRosterMutation({ variables: { roster } })

          //CHANGE ADJUSTER STATUS
          await updateUser({
            variables: {
              user: { status: USER_STATUS.DEPLOYED },
              id: user?._id,
            },
          })
          if (user) {
            setUser({ ...user, status: USER_STATUS.DEPLOYED })
            setItemToLocalStorage(LOCALSTORAGE_OBJECTS_NAMES.USER, {
              ...user,
              status: USER_STATUS.DEPLOYED,
            })
          }

          const result = await getRosters({
            variables: { jobId: job?._id },
            fetchPolicy: 'network-only',
          })
          //IF ROSTER IS FULL
          if (result?.data?.RosterMany?.length === job?.adjustersNeeded) {
            //NOTIFY ADMINS
            notifyAdmins(
              `The roster of the job "${job?.title}" has been filled`,
              job?._id ?? ''
            )

            //REJECT ALL PENDING APPLICATIONS AND INVITATIONS
            await rejectAllPendingApplicationsAndInvites(job?._id)
          }

          //SEND NOTIFICATION TO THE ADJUSTER
          createNotificationMutation({
            variables: {
              notification: {
                userId: user?._id,
                message: `You’ve been accepted for job "${job?.title}". The employer will reach out to you shortly.`,
                link: `${GLOBAL_ROUTES.JOBS}/${job?._id}/1`,
                read: false,
              },
            },
          })
        }

        //SEND NOTIFICATION TO THE FIRM
        createNotificationMutation({
          variables: {
            notification: {
              userId: job?.firmId,
              message: `${user?.firstName} ${user?.lastName} has ${
                accept ? 'accepted' : 'rejected'
              } your invitation for the job "${job?.title}".`,
              link: `${GLOBAL_ROUTES.JOBS}/${job?._id}/1`,
              read: false,
            },
          },
        })
        showSuccessToast(
          `Invitation ${accept ? 'accepted' : 'rejected'} Succesfully!`
        )
        setShowLoader(false)
      }
    },
    [
      createNotificationMutation,
      createRosterMutation,
      getRosters,
      invitations,
      notifyAdmins,
      rejectAllPendingApplicationsAndInvites,
      setUser,
      updateInvitationMutation,
      updateUser,
      user,
    ]
  )

  return (
    <Box h="100%" width="100%" overflowY="auto" backgroundColor="white">
      <Heading as="h4" size="md" color="gray.500" m={[4, 8]}>
        Invitations
      </Heading>

      {jobsAndInvitations.length > 0 && !showLoader && (
        <Box p={4} mx={4}>
          <Divider />
          {jobsAndInvitations.map((ji) => (
            <Box key={ji.invitation._id}>
              <Box
                key={ji?.invitation?._id}
                backgroundColor="white"
                cursor="pointer"
                py={4}
              >
                <Heading as="h1" fontSize="xl" mb={2}>
                  {ji?.invitation?.message}
                </Heading>
                {/* <Heading as="h4" fontSize="md" mb={2}>
                  {ji?.job?.title}
                </Heading> */}
                <Link
                  as="b"
                  fontSize="md"
                  mb={2}
                  onClick={() =>
                    window
                      .open(`${GLOBAL_ROUTES.JOB}/${ji?.job?._id}`, '_blank')
                      ?.focus()
                  }
                >
                  {ji?.job?.title}
                </Link>
                <Box color="gray.500" display="flex" mb={2} alignItems="start">
                  <Box mr={2} mt={1}>
                    <HiMapPin fontSize={16} />
                  </Box>
                  <Text fontSize="sm"> {ji?.job?.location}</Text>
                </Box>
                <Box>
                  <Text
                    fontSize="sm"
                    height="inherit"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    noOfLines={2}
                  >
                    {ji?.job?.description}
                  </Text>
                </Box>
                <Box color="gray.500" display="flex" mb={2} alignItems="center">
                  <Box mr={2}>
                    <HiCalendar fontSize={16} />
                  </Box>
                  <Text fontSize="sm">
                    {formatDate(ji?.job?.postingDate ?? '')}
                  </Text>
                </Box>
                <HStack justifyContent="space-between">
                  <HStack>
                    {ji?.job?.types.map((t) => (
                      <Tag
                        key={t}
                        color={ADJUSTANT_GREEN}
                        backgroundColor="gray.100"
                        size="sm"
                      >
                        {jobTypeOptions.find((j) => j.value === t)?.label}
                      </Tag>
                    ))}
                  </HStack>

                  <HStack>
                    <HStack mr={2}>
                      <Button
                        variant="reject"
                        size="sm"
                        onClick={() =>
                          acceptOrRejectInvitation(
                            false,
                            ji?.invitation,
                            ji?.job
                          )
                        }
                      >
                        Reject
                      </Button>
                      <Button
                        variant="adjustant"
                        size="sm"
                        onClick={() =>
                          acceptOrRejectInvitation(
                            true,
                            ji?.invitation,
                            ji?.job
                          )
                        }
                      >
                        Accept
                      </Button>
                    </HStack>
                    <IoMdOpen
                      color={ADJUSTANT_GREEN}
                      cursor="pointer"
                      onClick={() =>
                        window
                          .open(
                            `${GLOBAL_ROUTES.JOB}/${ji?.job?._id}`,
                            '_blank'
                          )
                          ?.focus()
                      }
                    />
                  </HStack>
                </HStack>
              </Box>
              <Divider />
            </Box>
          ))}
        </Box>
      )}

      {showLoader && (
        <HStack justifyContent="center" h="calc(100% - 90px)">
          <Spinner size="xl" />
        </HStack>
      )}

      {jobsAndInvitations.length === 0 && !showLoader && (
        <HStack direction="row" h="calc(100% - 90px)" justifyContent="center">
          <Heading>No Invitations at this time</Heading>
        </HStack>
      )}
    </Box>
  )
}

export default Invitations
