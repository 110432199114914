import React, { useCallback } from 'react'
import { Box, Button, Flex, HStack, Input } from '@chakra-ui/react'
import { AiOutlinePlus } from 'react-icons/ai'
import CustomDropdown, {
  SelectableItem,
} from '../CustomDropdown/CustomDropdown'
import Label from '../Label/Label'
import {
  LicensingRequirement,
  CertificationRequirement,
} from '../../types/userTypes'
import { BsFillTrashFill } from 'react-icons/bs'
import { ADJUSTANT_GREEN } from '../../themes/themes'

const getEmptyLicenseCertification = (isListed = true) => {
  return {
    id: '',
    name: '',
    isListed,
  }
}

const UpdateFirmsLicensingAndCertificationRequirementsForm = ({
  //   licenses,
  //   setLicenses,
  //   disableAddLicenseButton,
  //   stateLicensesMapped,

  certifications,
  setCertifications,
  disableAddCertificationButton,
  trainingCertificationsMapped,
}: {
  //   licenses: LicensingRequirement[]
  //   setLicenses: React.Dispatch<React.SetStateAction<LicensingRequirement[]>>
  //   disableAddLicenseButton: boolean
  //   stateLicensesMapped: SelectableItem[]

  certifications: CertificationRequirement[]
  setCertifications: React.Dispatch<
    React.SetStateAction<CertificationRequirement[]>
  >
  disableAddCertificationButton: boolean
  trainingCertificationsMapped: SelectableItem[]
}) => {
  //   const deleteLicense = useCallback(
  //     (index: number) => {
  //       licenses.splice(index, 1)
  //       setLicenses([...licenses])
  //     },
  //     [licenses, setLicenses]
  //   )

  const deleteCertification = useCallback(
    (index: number) => {
      certifications.splice(index, 1)
      setCertifications([...certifications])
    },
    [certifications, setCertifications]
  )

  return (
    <Box>
      {/* <Box mb={4}>
        <Flex justify="space-between" mb={2}>
          <Label fontSize="xl" color="gray.700">
            Licenses
          </Label>
        </Flex>
        {licenses.map((li, i) => (
          <HStack justifyContent="space-between" alignItems="start" key={i}>
            <CustomDropdown
				isSearchable
              label="License Name"
              placeholder="What is the name of the license needed?"
              options={stateLicensesMapped}
              value={li.id}
              handleChange={(selectedValue) => {
                licenses[i] = {
                  ...licenses[i],
                  id: selectedValue,
                }
                setLicenses([...licenses])
              }}
            />
            <Box ml={2} cursor="pointer">
              <BsFillTrashFill onClick={() => deleteLicense(i)} />
            </Box>
          </HStack>
        ))}
        <Button
          color={ADJUSTANT_GREEN}
          variant="link"
          leftIcon={<AiOutlinePlus />}
          mt={2}
          isDisabled={disableAddLicenseButton}
          onClick={() =>
            setLicenses([...licenses, getEmptyLicenseCertification()])
          }
        >
          Add License
        </Button>
      </Box> */}
      <Box mb={4}>
        <Flex justify="space-between" mb={2}>
          <Label fontSize="xl" color="gray.700">
            Certifications
          </Label>
        </Flex>
        {certifications.map((ce, i) =>
          ce.isListed ? (
            <HStack justifyContent="space-between" alignItems="start" key={i}>
              <CustomDropdown
                isSearchable
                label="Certification Name"
                placeholder="What is the name of the certification needed?"
                options={trainingCertificationsMapped}
                value={ce.id}
                handleChange={(selectedValue) => {
                  certifications[i] = {
                    ...certifications[i],
                    id: selectedValue,
                  }
                  setCertifications([...certifications])
                }}
              />
              <Box ml={2} cursor="pointer">
                <BsFillTrashFill onClick={() => deleteCertification(i)} />
              </Box>
            </HStack>
          ) : (
            <HStack justifyContent="space-between" alignItems="start" key={i}>
              <Box mb={2} width="-webkit-fill-available">
                <Flex justify="space-between" mb={2}>
                  <Label fontSize="sm" color="gray.700">
                    Certification Name
                  </Label>
                </Flex>
                <Input
                  width="100%"
                  value={ce.name}
                  borderRadius={3}
                  onChange={(e) => {
                    certifications[i] = {
                      ...certifications[i],
                      name: e.target.value,
                      id: `${Date.now()}`,
                    }
                    setCertifications([...certifications])
                  }}
                />
              </Box>
              <Box ml={2} cursor="pointer">
                <BsFillTrashFill onClick={() => deleteCertification(i)} />
              </Box>
            </HStack>
          )
        )}
        <HStack justifyContent="space-between">
          {/* <Button
            color={ADJUSTANT_GREEN}
            variant="link"
            leftIcon={<AiOutlinePlus />}
            mt={2}
            isDisabled={disableAddCertificationButton}
            onClick={() =>
              setCertifications([
                ...certifications,
                getEmptyLicenseCertification(),
              ])
            }
          >
            Add Certification
          </Button> */}
          <Button
            color={ADJUSTANT_GREEN}
            variant="link"
            leftIcon={<AiOutlinePlus />}
            mt={2}
            isDisabled={disableAddCertificationButton}
            onClick={() =>
              setCertifications([
                ...certifications,
                getEmptyLicenseCertification(false),
              ])
            }
          >
            Add New Certification
          </Button>
        </HStack>
      </Box>
    </Box>
  )
}

export default UpdateFirmsLicensingAndCertificationRequirementsForm
