import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  Textarea,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Checkbox,
} from '@chakra-ui/react'
import { useMemo, useState } from 'react'
import { User } from '../../types/userTypes'

const SendBatchMessageModal = ({
  header = 'Send a Message',
  isOpen,
  adjusters,
  firms,
  onClose,
}: {
  header?: string
  isOpen: boolean
  adjusters: User[]
  firms: User[]
  onClose: (message?: string, users?: User[]) => void
}) => {
  const [message, setMessage] = useState('')
  const [selectedFirms, setSelectedFirms] = useState<User[]>([])
  const [selectedAdjusters, setSelectedAdjusters] = useState<User[]>([])

  const selectedUsers = useMemo(
    () => [...selectedFirms, ...selectedAdjusters],
    [selectedFirms, selectedAdjusters]
  )

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      size="xl"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton
          onClick={() => {
            onClose()
            setMessage('')
          }}
        />
        <ModalBody>
          <Tabs width="100%" variant="adjustant">
            <TabList>
              <Tab fontWeight={500} fontSize={[14, 14, 16]}>
                Firms
              </Tab>
              <Tab fontWeight={500} fontSize={[14, 14, 16]}>
                Adjusters
              </Tab>
              <Tab fontWeight={500} fontSize={[14, 14, 16]}>
                Message
              </Tab>
            </TabList>

            <TabPanels width="100%">
              <TabPanel width="100%" display="grid">
                {firms.map((firm) => (
                  <Checkbox
                    variant="adjustant"
                    key={firm._id}
                    isChecked={!!selectedFirms.find((a) => a._id === firm._id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedFirms((prev) => [...prev, firm])
                      } else {
                        setSelectedFirms((prev) =>
                          prev.filter((a) => a._id !== firm._id)
                        )
                      }
                    }}
                  >
                    {firm.firmName}
                  </Checkbox>
                ))}

                <Checkbox
                  variant="adjustant"
                  isChecked={
                    firms.length > 0 && selectedFirms.length === firms.length
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedFirms(firms)
                    } else {
                      setSelectedFirms((prev) =>
                        prev.filter(
                          (user) => !firms.some((fm) => fm._id === user._id)
                        )
                      )
                    }
                  }}
                >
                  Select All
                </Checkbox>
              </TabPanel>
              <TabPanel width="100%" display="grid">
                {adjusters.map((adjuster) => (
                  <Checkbox
                    variant="adjustant"
                    key={adjuster._id}
                    isChecked={
                      !!selectedAdjusters.find((a) => a._id === adjuster._id)
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedAdjusters((prev) => [...prev, adjuster])
                      } else {
                        setSelectedAdjusters((prev) =>
                          prev.filter((a) => a._id !== adjuster._id)
                        )
                      }
                    }}
                  >
                    {adjuster.firstName} {adjuster.lastName}
                  </Checkbox>
                ))}
                <Checkbox
                  variant="adjustant"
                  isChecked={
                    adjusters.length > 0 &&
                    selectedAdjusters.length === adjusters.length
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedAdjusters(adjusters)
                    } else {
                      setSelectedAdjusters((prev) =>
                        prev.filter(
                          (user) =>
                            !adjusters.some((adj) => adj._id === user._id)
                        )
                      )
                    }
                  }}
                >
                  Select All
                </Checkbox>
              </TabPanel>

              <TabPanel width="100%">
                <Textarea
                  placeholder=""
                  resize="vertical"
                  size="xl"
                  rows={6}
                  p={2}
                  value={message}
                  borderRadius={3}
                  onChange={(e) => {
                    setMessage(e.target.value)
                  }}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <Button
            isDisabled={!message || !selectedUsers.length}
            variant="adjustant"
            mr={3}
            onClick={() => {
              onClose(message, selectedUsers)
              setMessage('')
            }}
          >
            Send
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SendBatchMessageModal
