import { useNavigate } from 'react-router'
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Link,
  Stack,
  Tag,
  Text,
  Tooltip,
  useClipboard,
  useDisclosure,
} from '@chakra-ui/react'
import { HiCalendar, HiMapPin } from 'react-icons/hi2'
import { FIRM_ROUTES, GLOBAL_ROUTES } from '../../App'
import { ADJUSTANT_GREEN } from '../../themes/themes'
import {
  jobTypeOptions,
  getRequirementOptionTitle,
  getBillingOptionTitle,
  Job,
  JobSpecs,
  JobStatus,
  presenceOptions,
  DATE_OF_LOSS_TYPE,
  dateOfLossOptions,
  billingFormOptions,
  JobCertificationRequirement,
} from '../../types/jobTypes'
import {
  encodeS3URI,
  formatDate,
  getFileNameFromURL,
  getPathRoute,
} from '../../utils/functions'
import {
  User,
  UserContextType,
  UserType,
  femaCategoriesOptions,
} from '../../types/userTypes'
import { useContext, useCallback, useState } from 'react'
import { StateLicesesContext } from '../../contexts/StateLicesesContext'
import { TrainingCertificationsContext } from '../../contexts/TrainingCertificationsContext'
import {
  StateLicenseContextType,
  US_STATES,
} from '../../types/stateLicensesTypes'
import { TrainingCertificationContextType } from '../../types/trainingCertificationsTypes'
import DeleteDialog from '../DeleteDialog/DeleteDialog'
import { BsFillInfoCircleFill } from 'react-icons/bs'
import { LOASContext } from '../../contexts/LOASContext'
import { LOASContextType } from '../../types/loasTypes'
import { UserContext } from '../../contexts/UserContext'
import { AiOutlineClose, AiOutlineCloudUpload } from 'react-icons/ai'
import FileInput from '../FileInput/FileInput'

const JobDetails = ({
  canUpdate,
  canSetAsFinished,
  canDelete,
  job,
  specs,
  firm,
  onDeleteJob,
  onFinishJob,
  onUploadBillingStatement,
  headerElement,
}: {
  canUpdate: boolean
  canSetAsFinished?: boolean
  canDelete?: boolean
  job?: Job
  specs?: JobSpecs
  firm?: User
  onDeleteJob?: () => void
  onFinishJob?: () => void
  onUploadBillingStatement?: (e: any) => void
  headerElement?: any
}) => {
  const navigate = useNavigate()
  const {
    onOpen: onDeleteModalOpen,
    isOpen: isDeleteModalOpen,
    onClose: onCloseDeleteModal,
  } = useDisclosure()
  const {
    onOpen: onFinishModalOpen,
    isOpen: isFinishModalOpen,
    onClose: onCloseFinishModal,
  } = useDisclosure()
  const { hasCopied, onCopy } = useClipboard(
    `${process.env.REACT_APP_APP_URL}/job/${job?._id}`
  )

  const { user }: UserContextType = useContext(UserContext)
  const { stateLicenses }: StateLicenseContextType =
    useContext(StateLicesesContext)
  const { trainingCertifications }: TrainingCertificationContextType =
    useContext(TrainingCertificationsContext)
  const { loas }: LOASContextType = useContext(LOASContext)
  const [isUploadingFile, setIsUploadingFile] = useState(false)

  const getJobLicenseByID = useCallback(
    (licenseID: string): string => {
      return stateLicenses?.find((s) => s._id === licenseID)?.name ?? ''
    },
    [stateLicenses]
  )

  const getJobCertificationByID = useCallback(
    (certification: JobCertificationRequirement): string => {
      if (certification.isListed)
        return (
          trainingCertifications?.find((t) => t._id === certification.id)
            ?.name ??
          firm?.additionalCertificationRequirements?.find(
            (t) => t.id === certification.id
          )?.name ??
          ''
        )
      else return certification.name
    },
    [trainingCertifications, firm?.additionalCertificationRequirements]
  )

  const onFileInputChange = useCallback(
    async (e: any) => {
      const file = e.target.files[0]
      if (file && onUploadBillingStatement) {
        // const newFile = {
        //   file: fileFromFiles,
        //   url: URL.createObjectURL(fileFromFiles),
        // }
        setIsUploadingFile(true)
        await onUploadBillingStatement(file)
        setIsUploadingFile(false)
      }
    },
    [onUploadBillingStatement]
  )

  return (
    <Box>
      <DeleteDialog
        header="Delete Job?"
        body="Are you sure you want to delete this job?"
        onDelete={() => {
          if (onDeleteJob) onDeleteJob()
        }}
        isOpen={isDeleteModalOpen}
        onClose={onCloseDeleteModal}
      />
      <DeleteDialog
        header="Mark as Finished?"
        body="Are you sure you want to mark your job as finished?"
        onDelete={() => {
          if (onFinishJob) onFinishJob()
        }}
        isOpen={isFinishModalOpen}
        onClose={onCloseFinishModal}
      />

      <Box mb={4}>
        <Heading
          mb={3}
          gap={2}
          display={['block', 'block', 'flex']}
          justifyContent="space-between"
          alignItems="center"
        >
          {job?.title}
          {headerElement}
        </Heading>

        {/* Status */}
        <Tag
          color={job?.status === JobStatus.ONGOING ? ADJUSTANT_GREEN : 'red'}
          backgroundColor="gray.100"
          size="sm"
          mb={2}
        >
          {job?.status}
        </Tag>
      </Box>

      {/* Firm info */}
      <Box mb={6}>
        <HStack>
          <Stack width="40px" height="40px">
            {/* <Img
				src={
				  firm?.profilePicture || '/images/no_profile_picture.png'
				}
				alt=""
			  /> */}
            <Avatar
              size="full"
              src={firm?.profilePicture || '/images/no_profile_picture.png'}
            />
          </Stack>
          <Stack gap={0} ml={2}>
            {user?.userType === UserType.ADMIN ? (
              <Link
                fontSize="sm"
                fontWeight="bold"
                color={ADJUSTANT_GREEN}
                onClick={() =>
                  window
                    .open(
                      `${GLOBAL_ROUTES.ADMIN}${GLOBAL_ROUTES.PROFILE}/${firm?._id}`,
                      '_blank'
                    )
                    ?.focus()
                }
              >
                {firm?.firmName ?? ''}
              </Link>
            ) : (
              <Text fontSize="sm" fontWeight="bold" color={ADJUSTANT_GREEN}>
                {firm?.firmName ?? ''}
              </Text>
            )}

            <Text color="gray.500" fontSize="sm">
              {firm?.location ?? ''}
            </Text>
          </Stack>
        </HStack>
      </Box>

      <Box mb={4}>
        {/* Location */}
        <Box color="gray.500" display="flex" mb={1} alignItems="start">
          <Box mr={2} mt={1}>
            <HiMapPin fontSize={16} />
          </Box>
          {/* <Text fontSize="sm"> {job?.location}</Text>*/}

          <Text fontSize="sm">
            {job?.city ? `${job.city}, ${job.state}` : job?.state}
          </Text>
        </Box>

        {/* Posting date */}
        <Box color="gray.500" display="flex" mb={1} alignItems="center">
          <Box mr={2}>
            <HiCalendar fontSize={16} />
          </Box>
          <Text fontSize="sm">
            Posting date: {formatDate(job?.postingDate ?? '')}
          </Text>
        </Box>

        {/* Finish date */}
        {job?.status === JobStatus.FINISHED && (
          <Box color="gray.500" display="flex" mb={1} alignItems="center">
            <Box mr={2}>
              <HiCalendar fontSize={16} />
            </Box>
            <Text fontSize="sm">
              Finish date: {formatDate(job?.finishDate ?? '')}
            </Text>
          </Box>
        )}

        {/* Report date */}
        <Box color="gray.500" display="flex" mb={1} alignItems="center">
          <Box mr={2}>
            <HiCalendar fontSize={16} />
          </Box>
          <Text fontSize="sm">
            Report date: {formatDate(job?.reportDate ?? '')}
          </Text>
        </Box>

        {/* Date of loss */}
        <Box color="gray.500" display="flex" mb={1} alignItems="center">
          <Box mr={2}>
            <HiCalendar fontSize={16} />
          </Box>
          <Text fontSize="sm">
            DOL:{' '}
            {job?.dateOfLossType === DATE_OF_LOSS_TYPE.DATE
              ? formatDate(job.dateOfLoss ?? '')
              : dateOfLossOptions.find((d) => d.value === job?.dateOfLossType)
                  ?.label}
          </Text>
        </Box>

        {/* Presence */}
        <Box mb={2}>
          {job?.presence && (
            <Tag color={ADJUSTANT_GREEN} backgroundColor="gray.100" size="sm">
              {presenceOptions.find((j) => j.value === job.presence)?.label}
            </Tag>
          )}
        </Box>

        {/* Job types and description */}
        <Stack spacing={0} mb={2}>
          <HStack mb={0}>
            {job?.types.map((t) => (
              <Tag
                key={t}
                color={ADJUSTANT_GREEN}
                backgroundColor="gray.100"
                size="sm"
                width="fit-content"
              >
                {jobTypeOptions.find((j) => j.value === t)?.label}
              </Tag>
            ))}
          </HStack>
          <Text color="gray.500" fontSize="sm">
            {job?.typesDescription}
          </Text>
        </Stack>

        {/* Specs */}
        {specs && (
          <>
            <Box color="gray.500" display="flex" mb={2} alignItems="start">
              <Text fontSize="sm" as="b">
                Needs {specs?.workforce}{' '}
                {(specs?.workforce ?? 0) > 1 ? 'adjusters' : 'adjuster'}
              </Text>
            </Box>
            <Box color="gray.500" display="flex" mb={2} alignItems="start">
              <Text fontSize="sm" as="b">
                {specs?.hired} hired
              </Text>
            </Box>
            {canUpdate && (
              <>
                <Box color="gray.500" display="flex" mb={2} alignItems="start">
                  <Text fontSize="sm" as="b">
                    {specs?.invites} invited
                  </Text>
                </Box>
                <Box color="gray.500" display="flex" mb={2} alignItems="start">
                  <Text fontSize="sm" as="b">
                    {specs?.applications} applications
                  </Text>
                </Box>
              </>
            )}
          </>
        )}

        {/* ACTIONS */}
        <HStack
          pt={4}
          //   display={['flex', 'flex', 'block']}
          //   flexDirection={['column', 'initial', 'initial']}
          justifyContent={['space-between', 'start']}
          spacing={4}
          color={ADJUSTANT_GREEN}
        >
          {/* <Text
            fontSize="md"
            as="b"
            cursor="pointer"
            // textDecoration="underline"
            onClick={onCopy}
          >
            {hasCopied ? 'Copied!' : 'Copy Link'}
          </Text> */}
          <Button variant="adjustant" fontSize="sm" onClick={onCopy}>
            {hasCopied ? 'Copied!' : 'Copy Link'}
          </Button>
          {canUpdate && canSetAsFinished && (
            // <Text
            //   fontSize="md"
            //   as="b"
            //   cursor="pointer"
            //   //   textDecoration="underline"
            //   onClick={onFinishModalOpen}
            // >
            //   Mark as Finished
            // </Text>
            <Button
              variant="adjustant"
              fontSize="sm"
              isDisabled={job?.billingStatementsURLs?.length === 0}
              onClick={onFinishModalOpen}
            >
              Mark as Finished
            </Button>
          )}
          {canUpdate && (
            // <Text
            //   fontSize="md"
            //   as="b"
            //   cursor="pointer"
            //   //   textDecoration="underline"
            //   onClick={() => navigate(`${FIRM_ROUTES.UPDATE_JOB}/${job?._id}`)}
            // >
            //   Update
            // </Text>
            <Button
              variant="adjustant"
              fontSize="sm"
              onClick={() => {
                const path = `${getPathRoute(user)}${FIRM_ROUTES.UPDATE_JOB}/${
                  job?._id
                }`
                // console.log(path)
                navigate(path)
              }}
            >
              Update
            </Button>
          )}
          {canUpdate && canDelete && (
            // <Text
            //   fontSize="md"
            //   as="b"
            //   cursor="pointer"
            //   //   textDecoration="underline"
            //   onClick={onDeleteModalOpen}
            // >
            //   Delete
            // </Text>
            <Button
              variant="adjustant"
              onClick={onDeleteModalOpen}
              fontSize="sm"
            >
              Delete
            </Button>
          )}
        </HStack>
      </Box>

      <Divider />

      {/* DESCRIPTION */}
      <Box py={8}>
        <Text>{job?.description}</Text>{' '}
      </Box>

      <Divider />

      {/* REQUIREMENTS AND BILLING DETAILS */}
      <Box py={8}>
        <Stack
          spacing={4}
          display="flex"
          direction={['column', 'column', 'row']}
        >
          <Stack width={['100%', '100%', '50%']} spacing={2}>
            {/* FEMA Requirement */}
            <Box>
              <Text as="b" size="xl">
                FEMA Certification
              </Text>
              <Text>{job?.isFemaCertificationRequired ? 'Yes' : 'No'}</Text>
              {job?.isFemaCertificationRequired && (
                <Box>
                  {job.femaCertificationCategories.map((f) => (
                    <Tag
                      key={f}
                      color={ADJUSTANT_GREEN}
                      backgroundColor="gray.100"
                      size="sm"
                    >
                      {femaCategoriesOptions.find((o) => o.value === f)?.label}
                    </Tag>
                  ))}
                </Box>
              )}
            </Box>
            {/* Licenses */}
            <Box>
              <Text as="b" size="xl">
                Licenses
              </Text>
              {job?.licensingRequirements?.map((license, i) => (
                <Box key={`license-${i}`} mb={1}>
                  {license.id === '0' ? (
                    <Text>Not Applicable - State License not required</Text>
                  ) : (
                    <Text>
                      {getJobLicenseByID(license.id)} (
                      {getRequirementOptionTitle(license.type)})
                    </Text>
                  )}
                  <Text color="gray.400" fontSize="sm">
                    {US_STATES.find((u) => u.abbreviation === license.state)
                      ?.name ?? ''}
                  </Text>
                  {license?.loas && license.loas.length > 0 && (
                    <Box gap={2} display="flex" my={1}>
                      {license?.loas
                        ?.filter(
                          (loa) =>
                            loas
                              ?.find((l) => l._id === loa)
                              ?.name.toLowerCase() !== 'none'
                        )
                        ?.map((loa, i) => (
                          <Tag
                            color={ADJUSTANT_GREEN}
                            backgroundColor="gray.100"
                            size="sm"
                            key={i}
                          >
                            {loas?.find((l) => l._id === loa)?.name}
                          </Tag>
                        )) ?? ''}
                    </Box>
                  )}
                </Box>
              ))}
              {job?.licensingRequirements?.length === 0 && (
                <Text>No licenses required</Text>
              )}
              <Text as="i" fontSize="md">
                In addition to listed license requirements, applicable primary
                state license required to apply.
              </Text>
            </Box>
            {/* Certifications */}
            <Box>
              <Text as="b" size="xl">
                Certifications
              </Text>
              {job?.certificationRequirements?.map((certification, i) => (
                <Text key={`certification-${i}`} mb={1}>
                  {getJobCertificationByID(certification)} (
                  {getRequirementOptionTitle(certification.type)})
                </Text>
              ))}

              {job?.certificationRequirements?.length === 0 && (
                <Text>No certifications required</Text>
              )}
            </Box>
          </Stack>

          {/* Billing Details */}
          <Stack width={['100%', '100%', '50%']} spacing={0}>
            <Text as="b" size="xl">
              Billing Details
            </Text>
            <HStack>
              <Text>{getBillingOptionTitle(job?.billingType)}</Text>
              <Text>-</Text>
              <Text>
                {
                  billingFormOptions.find((d) => d.value === job?.billingForm)
                    ?.label
                }
              </Text>
              {job?.billingTypeDescription && (
                <Tooltip
                  label={job?.billingTypeDescription}
                  fontSize="md"
                  hasArrow
                  // placement="top"
                >
                  <Box fontSize="sm">
                    <BsFillInfoCircleFill />
                  </Box>
                </Tooltip>
              )}
            </HStack>
            <Stack spacing={0} mt={1}>
              {job?.billingTypeFileURLs.map((billingTypeFileURL, i) => (
                <Link
                  color="teal.500"
                  href={encodeS3URI(billingTypeFileURL)}
                  isExternal
                  key={i}
                >
                  {getFileNameFromURL(billingTypeFileURL)}
                </Link>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Box>

      {/* Billing statements */}
      {(user?.userType === UserType.ADMIN || user?._id === firm?._id) && (
        <>
          <Divider />
          <Box py={8}>
            <Text as="b" size="xl">
              Billing statements
              {/* All upploaded file mustt have a different name, if not, files with the same names will be replaced eachother */}
            </Text>

            {job?.billingStatementsURLs.map((url, index) => (
              <HStack key={index} alignItems="center" gap={2} mb={2}>
                <Link color="teal.500" href={url} isExternal>
                  {getFileNameFromURL(url)}
                </Link>
                {/* <Box cursor="pointer">
                <AiOutlineClose onClick={() => deleteBillingStatement(index)} />
              </Box> */}
              </HStack>
            ))}

            {!job?.billingStatementsURLs?.length && (
              <Text mt={2}>
                No billing statements uploaded. This is needed to mark this job
                as finished.
              </Text>
            )}

            {canUpdate && (
              <FileInput
                element={
                  <Button
                    variant="adjustant"
                    rightIcon={<AiOutlineCloudUpload />}
                    isLoading={isUploadingFile}
                    mt={2}
                  >
                    Upload
                  </Button>
                }
                accept=".pdf,.png,.jpeg,.jpg"
                onFileInputChange={onFileInputChange}
              />
            )}
          </Box>
        </>
      )}
    </Box>
  )
}

export default JobDetails
