import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Text,
  Spinner,
} from '@chakra-ui/react'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { LOASContext } from '../../../contexts/LOASContext'
import { LOA, LOASContextType } from '../../../types/loasTypes'
import { CiEdit } from 'react-icons/ci'
import { ADJUSTANT_GREEN } from '../../../themes/themes'
import { createColumnHelper } from '@tanstack/react-table'
import CustomTable from '../../../components/CustomTable/CustomTable'
import { BsFillTrashFill } from 'react-icons/bs'
import Label from '../../../components/Label/Label'
import { useMutation } from '@apollo/client'
import {
  CREATE_LICENSE,
  DELETE_LICENSE_BY_ID,
  UPDATE_LICENSE_BY_ID,
} from '../../../graphql/Mutations'
import { showSuccessToast } from '../../../components/Toast/Toast'
import { StateLicesesContext } from '../../../contexts/StateLicesesContext'
import {
  ListedStateLicense,
  ListedStateLicenseState,
  StateLicenseContextType,
  US_STATES,
  US_STATES_ABBREVIATIONS,
} from '../../../types/stateLicensesTypes'
import CustomDropdownMultiple from '../../../components/CustomDropdownMultiple/CustomDropdownMultiple'
import CustomDropdown from '../../../components/CustomDropdown/CustomDropdown'
import DeleteModal from '../../../components/Modals/DeleteModal'

const UpdateModal = ({
  license,
  loas,
  isOpen,
  onClose,
}: {
  license?: ListedStateLicense
  loas: LOA[]
  isOpen: boolean
  onClose: (newLicense: ListedStateLicense, updated: boolean) => void
}) => {
  const [licenseName, setLicenseName] = useState(license?.name ?? '')
  const [licenseStates, setLicenseStates] = useState<
    ListedStateLicenseState | undefined
  >(license?.states)

  useEffect(() => {
    setLicenseName(license?.name ?? '')
    if (license?.states) setLicenseStates({ ...license?.states })
    //isOpen on the dependencies array, so every time the modal gets open the previous assignatios are done
  }, [license, isOpen])

  const canUpdate = useMemo(() => {
    if (licenseStates) {
      const abbreviations = US_STATES.map((u) => u.abbreviation)
      const invalidFound = !!Object.keys(licenseStates).find(
        (a) =>
          !abbreviations.includes(a as US_STATES_ABBREVIATIONS) ||
          licenseStates?.[a as US_STATES_ABBREVIATIONS]?.length === 0
      )
      return invalidFound ? false : true
    } else return false
  }, [licenseStates])

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        if (license) onClose(license, false)
      }}
      isCentered
      size="4xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update License</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex justify="space-between" mb={2}>
            <Label fontSize="sm" color="gray.700">
              Name
            </Label>
          </Flex>
          <Input
            value={licenseName}
            //   placeholder=" (optional)"
            borderRadius={3}
            onChange={(e) => {
              setLicenseName(e.target.value)
            }}
          />

          <Heading
            fontSize="2xl"
            my={4}
            display="flex"
            justifyContent="space-between"
          >
            States and LOAs
            <Button
              variant="adjustant"
              mr={3}
              onClick={() => {
                if (licenseStates) {
                  licenseStates[`${Date.now()}` as US_STATES_ABBREVIATIONS] = []
                  setLicenseStates({ ...licenseStates })
                }
              }}
            >
              Add
            </Button>
          </Heading>

          {licenseStates &&
            Object.keys(licenseStates).map((stateAbbreviation, index) => {
              return (
                <Box backgroundColor="gray.50" mb={4} key={index}>
                  <Flex justify="space-between" mb={2}>
                    <Label fontSize="sm" color="gray.700">
                      State
                    </Label>
                    <BsFillTrashFill
                      fontSize={20}
                      cursor="pointer"
                      color="red"
                      onClick={() => {
                        delete licenseStates[
                          stateAbbreviation as US_STATES_ABBREVIATIONS
                        ]
                        setLicenseStates({ ...licenseStates })
                      }}
                    />
                  </Flex>
                  <CustomDropdown
                    // label="State"
                    placeholder="Select a state"
                    options={
                      US_STATES.map((s) => ({
                        value: s.abbreviation,
                        label: s.name,
                      }))
                      // .filter(
                      //   (a) => !Object.keys(licenseStates).includes(a.value)
                      // )
                    }
                    value={stateAbbreviation}
                    handleChange={(abr) => {
                      const a = stateAbbreviation as US_STATES_ABBREVIATIONS
                      // THIS REPLACES A KEY WITHIN THE OBJECT
                      delete Object.assign(licenseStates, {
                        [abr]: licenseStates[a],
                      })[a]
                      setLicenseStates({ ...licenseStates })
                    }}
                  />

                  <CustomDropdownMultiple
                    label="Select LOAs"
                    value={
                      licenseStates[
                        stateAbbreviation as US_STATES_ABBREVIATIONS
                      ]?.map((loacode) => ({
                        value: loacode,
                        label:
                          loas?.find((loa) => loa.code === loacode)?.name ?? '',
                      })) ?? []
                    }
                    options={loas.map((loa) => ({
                      value: loa.code,
                      label: loa.name,
                    }))}
                    isClearable
                    onChange={(loas) => {
                      const l = loas.map((o) => parseInt(o))
                      licenseStates[
                        stateAbbreviation as US_STATES_ABBREVIATIONS
                      ] = l
                      setLicenseStates({ ...licenseStates })
                    }}
                  />
                </Box>
              )
            })}
        </ModalBody>

        <ModalFooter>
          <Button
            variant="adjustant"
            mr={3}
            onClick={() => {
              if (license) onClose(license, false)
            }}
          >
            Close
          </Button>
          <Button
            variant="adjustant"
            isDisabled={!canUpdate}
            onClick={() => {
              if (license)
                onClose(
                  {
                    _id: license._id,
                    code: license.code,
                    name: licenseName,
                    states: licenseStates ?? license.states,
                  },
                  true
                )
            }}
          >
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const CreateModal = ({
  loas,
  isOpen,
  onClose,
}: {
  loas: LOA[]
  isOpen: boolean
  onClose: (newLicense?: ListedStateLicense) => void
}) => {
  const [licenseName, setLicenseName] = useState('')
  const [licenseStates, setLicenseStates] = useState<
    Partial<ListedStateLicenseState> | undefined
  >()

  const canUpdate = useMemo(() => {
    if (licenseStates) {
      const abbreviations = US_STATES.map((u) => u.abbreviation)
      const invalidFound = !!Object.keys(licenseStates).find(
        (a) =>
          !abbreviations.includes(a as US_STATES_ABBREVIATIONS) ||
          licenseStates?.[a as US_STATES_ABBREVIATIONS]?.length === 0
      )
      return invalidFound ? false : true
    } else return false
  }, [licenseStates])

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      isCentered
      size="4xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create License</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex justify="space-between" mb={2}>
            <Label fontSize="sm" color="gray.700">
              Name
            </Label>
          </Flex>
          <Input
            value={licenseName}
            //   placeholder=" (optional)"
            borderRadius={3}
            onChange={(e) => {
              setLicenseName(e.target.value)
            }}
          />

          <Heading
            fontSize="2xl"
            my={4}
            display="flex"
            justifyContent="space-between"
          >
            States and LOAs
            <Button
              variant="adjustant"
              mr={3}
              onClick={() => {
                const licenseStatesCopy: Partial<ListedStateLicenseState> = {
                  ...(licenseStates ? licenseStates : {}),
                  // AL: [] as number[],
                  [`${Date.now()}` as US_STATES_ABBREVIATIONS]: [] as number[],
                }
                if (licenseStatesCopy)
                  setLicenseStates({ ...licenseStatesCopy })
              }}
            >
              Add
            </Button>
          </Heading>

          {licenseStates &&
            Object.keys(licenseStates).map((stateAbbreviation, index) => {
              return (
                <Box backgroundColor="gray.50" mb={4} key={index}>
                  <Flex justify="space-between" mb={2}>
                    <Label fontSize="sm" color="gray.700">
                      State
                    </Label>
                    <BsFillTrashFill
                      fontSize={20}
                      cursor="pointer"
                      color="red"
                      onClick={() => {
                        delete licenseStates[
                          stateAbbreviation as US_STATES_ABBREVIATIONS
                        ]
                        setLicenseStates({ ...licenseStates })
                      }}
                    />
                  </Flex>
                  <CustomDropdown
                    // label="State"
                    placeholder="Select a state"
                    options={
                      US_STATES.map((s) => ({
                        value: s.abbreviation,
                        label: s.name,
                      }))
                      // .filter(
                      //   (a) => !Object.keys(licenseStates).includes(a.value)
                      // )
                    }
                    value={stateAbbreviation}
                    handleChange={(abr) => {
                      const a = stateAbbreviation as US_STATES_ABBREVIATIONS
                      // THIS REPLACES A KEY WITHIN THE OBJECT
                      delete Object.assign(licenseStates, {
                        [abr]: licenseStates[a],
                      })[a]
                      setLicenseStates({ ...licenseStates })
                    }}
                  />

                  <CustomDropdownMultiple
                    label="Select LOAs"
                    value={
                      licenseStates[
                        stateAbbreviation as US_STATES_ABBREVIATIONS
                      ]?.map((loacode) => ({
                        value: loacode,
                        label:
                          loas?.find((loa) => loa.code === loacode)?.name ?? '',
                      })) ?? []
                    }
                    options={loas.map((loa) => ({
                      value: loa.code,
                      label: loa.name,
                    }))}
                    isClearable
                    onChange={(loas) => {
                      const l = loas.map((o) => parseInt(o))
                      licenseStates[
                        stateAbbreviation as US_STATES_ABBREVIATIONS
                      ] = l
                      setLicenseStates({ ...licenseStates })
                    }}
                  />
                </Box>
              )
            })}
        </ModalBody>

        <ModalFooter>
          <Button
            variant="adjustant"
            mr={3}
            onClick={() => {
              onClose()
            }}
          >
            Close
          </Button>
          <Button
            variant="adjustant"
            isDisabled={!canUpdate}
            onClick={() => {
              if (licenseStates)
                onClose({
                  _id: '',
                  name: licenseName,
                  code: Date.now(),
                  states: licenseStates as ListedStateLicenseState,
                })
            }}
          >
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const AdminLicenses = () => {
  const [showLoader, setShowLoader] = useState(false)
  const { loas }: LOASContextType = useContext(LOASContext)
  const { stateLicenses, setStateLicenses }: StateLicenseContextType =
    useContext(StateLicesesContext)
  const [licenseToUpdate, setLicenseToUpdate] = useState<
    ListedStateLicense | undefined
  >()
  const [licenseToUDelete, setLicenseToDelete] = useState<
    ListedStateLicense | undefined
  >()

  const [updateLicenseMutation] = useMutation(UPDATE_LICENSE_BY_ID)
  const [createLicenseMutation] = useMutation(CREATE_LICENSE)
  const [deleteLicenseMutation] = useMutation(DELETE_LICENSE_BY_ID)

  const columnHelper = createColumnHelper<ListedStateLicense>()
  const columns = [
    // columnHelper.accessor('_id', {
    //   cell: (info) => info.getValue(),
    //   header: 'ID',
    // }),
    columnHelper.accessor('name', {
      cell: (info) => info.getValue(),
      header: 'Name',
    }),
    columnHelper.accessor('states', {
      cell: (info) => {
        const states = info.getValue()
        // console.log(states)
        if (!states) return null
        return Object.keys(states).map((state) => {
          const loasCodes = states[state as US_STATES_ABBREVIATIONS]
          return (
            <Box key={state}>
              <Text as="b">
                {US_STATES.find((s) => s.abbreviation === state)?.name}
              </Text>
              :
              {loasCodes.map((loacode) => {
                const loaName =
                  loas?.find((loa) => loa.code === loacode)?.name ?? ''
                return <Text key={loacode}>{loaName}</Text>
              })}
            </Box>
          )
        })
      },
      header: 'States and LOAs',
    }),
    columnHelper.accessor('code', {
      cell: (info) => {
        return (
          <HStack spacing={4}>
            {showLoader ? (
              <Spinner />
            ) : (
              <>
                {' '}
                <CiEdit
                  fontSize={20}
                  cursor="pointer"
                  color={ADJUSTANT_GREEN}
                  onClick={() => {
                    const l = stateLicenses?.find(
                      (o) => o.code === (info.getValue() as number)
                    )
                    if (l) {
                      setLicenseToUpdate(l)
                      onOpenUpdateLicenseModal()
                    }
                  }}
                />
                <BsFillTrashFill
                  fontSize={20}
                  cursor="pointer"
                  color="red"
                  onClick={() => {
                    const l = stateLicenses?.find(
                      (o) => o.code === (info.getValue() as number)
                    )
                    if (l) {
                      setLicenseToDelete(l)
                      onOpenDeleteLicenseModal()
                    }
                  }}
                />
              </>
            )}
          </HStack>
        )
      },
      header: '',
    }),
  ]

  const {
    isOpen: updateLicenseModalIsOpen,
    onOpen: onOpenUpdateLicenseModal,
    onClose: onCloseUpdateLicenseModal,
  } = useDisclosure()

  const {
    isOpen: createLicenseModalIsOpen,
    onOpen: onOpenCreateLicenseModal,
    onClose: onCloseCreateLicenseModal,
  } = useDisclosure()

  const {
    isOpen: deleteLicenseModalIsOpen,
    onOpen: onOpenDeleteLicenseModal,
    onClose: onCloseDeleteLicenseModal,
  } = useDisclosure()

  const updateLcense = useCallback(
    async (newLicense: ListedStateLicense) => {
      setShowLoader(true)
      const { _id, ...l } = newLicense
      console.log(l)
      const result = await updateLicenseMutation({
        variables: {
          license: l,
          id: _id,
        },
      })
      if (result?.data?.StateLicenseUpdateOne?.record) {
        const index =
          stateLicenses?.findIndex((license) => license._id === _id) ?? -1
        if (index >= 0 && stateLicenses) {
          stateLicenses[index] = newLicense
          setStateLicenses([...stateLicenses])
          showSuccessToast('License Updated Succesfully!')
        }
      }

      setShowLoader(false)
    },
    [setStateLicenses, stateLicenses, updateLicenseMutation]
  )

  const createLicense = useCallback(
    async (nl: ListedStateLicense) => {
      setShowLoader(true)
      const { _id, ...newLicense } = nl
      const result = await createLicenseMutation({
        variables: {
          license: newLicense,
        },
      })
      if (result?.data?.StateLicenseCreateOne?.record) {
        setStateLicenses((prev) => [
          ...prev,
          result?.data?.StateLicenseCreateOne?.record,
        ])
        showSuccessToast('License Created Succesfully!')
      }
      setShowLoader(false)
    },
    [createLicenseMutation, setStateLicenses]
  )

  const deleteLicense = useCallback(
    async (id: string) => {
      const result = await deleteLicenseMutation({
        variables: {
          id,
        },
      })
      if (result?.data?.StateLicenseRemoveById?.record && loas) {
        const licenseIndex = stateLicenses?.findIndex((l) => l._id === id) ?? -1
        if (licenseIndex >= 0 && stateLicenses) {
          stateLicenses.splice(licenseIndex, 1)
          setStateLicenses([...stateLicenses])
          showSuccessToast('License Deleted Succesfully!')
        }
      }
    },
    [deleteLicenseMutation, loas, setStateLicenses, stateLicenses]
  )

  return (
    <Box background="white" px={[2, 4, 12]} py={6}>
      <UpdateModal
        license={licenseToUpdate}
        loas={loas ?? []}
        isOpen={updateLicenseModalIsOpen}
        onClose={(loa, updated) => {
          onCloseUpdateLicenseModal()
          if (updated) updateLcense(loa)
        }}
      />
      <CreateModal
        loas={loas ?? []}
        isOpen={createLicenseModalIsOpen}
        onClose={(newLicense) => {
          onCloseCreateLicenseModal()
          if (newLicense) createLicense(newLicense)
        }}
      />
      <DeleteModal
        header="Delete License"
        isOpen={deleteLicenseModalIsOpen}
        onClose={(d) => {
          onCloseDeleteLicenseModal()
          if (d && licenseToUDelete) deleteLicense(licenseToUDelete._id)
        }}
      />
      <Heading
        mb={4}
        fontSize="2xl"
        display="flex"
        justifyContent="space-between"
      >
        Licenses ({stateLicenses?.length ?? 0})
        <Button
          isLoading={showLoader}
          variant="adjustant"
          mr={3}
          onClick={() => {
            onOpenCreateLicenseModal()
          }}
        >
          Create
        </Button>
      </Heading>
      <CustomTable data={stateLicenses ?? []} columns={columns} />
    </Box>
  )
}

export default AdminLicenses
