import { User, UserType } from '../types/userTypes'
import Axios from 'axios'

interface CustomResponse {
  success: boolean
  message: string
  data?: any
}

const axios = Axios.create({
  headers: {
    'Content-Type': 'application/json',
    'X-Api-Key': process.env.REACT_APP_X_API_KEY ?? '',
  },
})

const getResponse = (response: any): CustomResponse => {
  const formatted = response?.body ? JSON.parse(response?.body) : response
  return formatted
}

export const graphqlCall = async (
  uri: string,
  options: RequestInit
): Promise<CustomResponse> => {
  try {
    const { data: responseData } = await axios.post(uri, options)
    return getResponse(responseData)
  } catch (error: any) {
    return {
      success: false,
      message: error.message,
    }
  }
}

export const registerUser = async (body: {
  email: string
  userType: string
  pwd: string
}): Promise<CustomResponse> => {
  try {
    const { data: responseData } = await axios.post(
      `${process.env.REACT_APP_API_URL}/user-register`,
      body
    )
    return getResponse(responseData)
  } catch (error: any) {
    return {
      success: false,
      message: error.message,
    }
  }
}

export const confirmRegisterUser = async (body: {
  email: string
  userType: string
  verificationCode: string
  password: string
}): Promise<CustomResponse> => {
  try {
    const { data: responseData } = await axios.post(
      `${process.env.REACT_APP_API_URL}/user-confirm-register`,
      body
    )
    return getResponse(responseData)
  } catch (error: any) {
    return {
      success: false,
      message: error.message,
    }
  }
}

export const resendSignUpConfirmationCode = async (body: {
  email: string
  userType: string
}): Promise<CustomResponse> => {
  try {
    const { data: responseData } = await axios.post(
      `${process.env.REACT_APP_API_URL}/resend-sign-up-confirmation-code`,
      body
    )
    return getResponse(responseData)
  } catch (error: any) {
    return {
      success: false,
      message: error.message,
    }
  }
}

export const forgotPassword = async (body: {
  email: string
  userType: string
}): Promise<CustomResponse> => {
  try {
    const { data: responseData } = await axios.post(
      `${process.env.REACT_APP_API_URL}/forgot-password`,
      body
    )
    return getResponse(responseData)
  } catch (error: any) {
    return {
      success: false,
      message: error.message,
    }
  }
}

export const confirmForgotPassword = async (body: {
  email: string
  userType: UserType
  newPassword: string
  confirmationCode: string
}): Promise<CustomResponse> => {
  try {
    const { data: responseData } = await axios.post(
      `${process.env.REACT_APP_API_URL}/confirm-forgot-password`,
      body
    )
    return getResponse(responseData)
  } catch (error: any) {
    return {
      success: false,
      message: error.message,
    }
  }
}

export const changePassword = async (body: {
  email: string
  userType: UserType
  newPassword: string
  oldPassword: string
  accessToken: string
}): Promise<CustomResponse> => {
  try {
    const { data: responseData } = await axios.post(
      `${process.env.REACT_APP_API_URL}/change-password`,
      body
    )
    return getResponse(responseData)
  } catch (error: any) {
    return {
      success: false,
      message: error.message,
    }
  }
}
export const loginUser = async (body: {
  email: string
  password: string
  userType: UserType
}): Promise<CustomResponse> => {
  try {
    const { data: responseData } = await axios.post(
      `${process.env.REACT_APP_API_URL}/user-login`,
      body
    )
    return getResponse(responseData)
  } catch (error: any) {
    return {
      success: false,
      message: error.message,
    }
  }
}

export const completeLoginUser = async (body: {
  encryptedCode: string
  enteredCode: string
}): Promise<CustomResponse> => {
  try {
    const { data: responseData } = await axios.post(
      `${process.env.REACT_APP_API_URL}/user-complete-login`,
      body
    )
    return getResponse(responseData)
  } catch (error: any) {
    return {
      success: false,
      message: error.message,
    }
  }
}

export const sendDataToS3 = async (body: {
  file?: string
  url?: string
  key: string
}): Promise<CustomResponse> => {
  try {
    const { data: responseData } = await axios.post(
      `${process.env.REACT_APP_API_URL}/send-data-to-s3`,
      body
    )
    return getResponse(responseData)
  } catch (error: any) {
    return {
      success: false,
      message: error.message,
    }
  }
}

export const adminInviteUser = async (body: {
  email: string
  userType: UserType
}): Promise<CustomResponse> => {
  try {
    const { data: responseData } = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin-invite-user`,
      body
    )
    return getResponse(responseData)
  } catch (error: any) {
    return {
      success: false,
      message: error.message,
    }
  }
}

export const getNIPRData = async (body: {
  npn: number
}): Promise<CustomResponse> => {
  try {
    const { data: responseData } = await axios.post(
      `${process.env.REACT_APP_API_URL}/get-nipr-data`,
      body
    )
    return getResponse(responseData)
  } catch (error: any) {
    return {
      success: false,
      message: error.message,
    }
  }
}

export const createStripeSession = async (body: {
  npn: number
  url: string
}): Promise<CustomResponse> => {
  try {
    const { data: responseData } = await axios.post(
      `${process.env.REACT_APP_API_URL}/create-stripe-session`,
      body
    )
    return getResponse(responseData)
  } catch (error: any) {
    return {
      success: false,
      message: error.message,
    }
  }
}

export const retrieveStripeSession = async (body: {
  sessionId: string
}): Promise<CustomResponse> => {
  try {
    const { data: responseData } = await axios.post(
      `${process.env.REACT_APP_API_URL}/retrieve-stripe-session`,
      body
    )
    return getResponse(responseData)
  } catch (error: any) {
    return {
      success: false,
      message: error.message,
    }
  }
}

export const sendUserEmailNotification = async (body: {
  notificationHtml: string
  notificationSubject: string
  destinationEmails: string[]
}): Promise<CustomResponse> => {
  try {
    const { data: responseData } = await axios.post(
      `${process.env.REACT_APP_API_URL}/user-email-notification`,
      body
    )
    return getResponse(responseData)
  } catch (error: any) {
    return {
      success: false,
      message: error.message,
    }
  }
}

export const uploadSignNowDocument = async (body: {
  document: any
  documentName: string
  documentContentType: string
  createSignNowFolders: boolean
  signNowfoldersName: string
}): Promise<CustomResponse> => {
  try {
    const { data: responseData } = await axios.post(
      `${process.env.REACT_APP_API_URL}/upload-signnow-document`,
      body
    )
    return getResponse(responseData)
  } catch (error: any) {
    return {
      success: false,
      message: error.message,
    }
  }
}

export const moveSignNowDocumentAndCreateTemplate = async (body: {
  documentId: string
  documentName: string
  documentsFolderId: string
  templatesFolderId: string
}): Promise<CustomResponse> => {
  try {
    const { data: responseData } = await axios.post(
      `${process.env.REACT_APP_API_URL}/move-sign-now-document-and-create-template`,
      body
    )
    return getResponse(responseData)
  } catch (error: any) {
    return {
      success: false,
      message: error.message,
    }
  }
}

export const createSigningInvite = async (body: {
  templateId: string
  documentName: string
  documentsFolderId: string
  adjusters: User[]
}): Promise<CustomResponse> => {
  try {
    const { data: responseData } = await axios.post(
      `${process.env.REACT_APP_API_URL}/create-signing-invite`,
      body
    )
    return getResponse(responseData)
  } catch (error: any) {
    return {
      success: false,
      message: error.message,
    }
  }
}

export const createSigningInviteLink = async (body: {
  inviteId: string
  documentId: string
}): Promise<CustomResponse> => {
  try {
    const { data: responseData } = await axios.post(
      `${process.env.REACT_APP_API_URL}/create-signing-invite-link`,
      body
    )
    return getResponse(responseData)
  } catch (error: any) {
    return {
      success: false,
      message: error.message,
    }
  }
}

export const createSignNowDownloadLink = async (body: {
  documentId: string
}): Promise<CustomResponse> => {
  try {
    const { data: responseData } = await axios.post(
      `${process.env.REACT_APP_API_URL}/create-signnow-download-link`,
      body
    )
    return getResponse(responseData)
  } catch (error: any) {
    return {
      success: false,
      message: error.message,
    }
  }
}

export const getCheckrAccountInfo = async (body: {
  code: string
  firmId: string
  isNew: boolean
}): Promise<CustomResponse> => {
  try {
    const { data: responseData } = await axios.post(
      `${process.env.REACT_APP_API_URL}/get-checkr-account-info`,
      body
    )
    return getResponse(responseData)
  } catch (error: any) {
    return {
      success: false,
      message: error.message,
    }
  }
}

export const getTesting = async (): Promise<CustomResponse> => {
  try {
    const { data: responseData } = await axios.post(
      `https://cky4eldw1a.execute-api.us-east-1.amazonaws.com/staging/get-checkr-session-token`
    )
    return getResponse(responseData)
  } catch (error: any) {
    return {
      success: false,
      message: error.message,
    }
  }
}
