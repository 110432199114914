import React, { useState } from 'react'
import { Box, Button, HStack, Text } from '@chakra-ui/react'
import UpdateDriverLicense from '../UpdateDriverLicense/UpdateDriverLicense'
import { DriverLicense } from '../../types/userTypes'
import CompleteProfileHeader from './CompleteProfileHeader'
import { MdOutlineNavigateNext } from 'react-icons/md'
import { getFileNameFromURL } from '../../utils/functions'

function CompleteProfileUpdateDriverLicense({
  next,
  driverLicense,
  showLoader,
}: {
  next: (a: DriverLicense, b: { fileName: string; file: any }) => void
  driverLicense?: DriverLicense
  showLoader: boolean
}) {
  const [id, setId] = useState(driverLicense?.id ?? '')
  const [address, setAddress] = useState(driverLicense?.address ?? '')
  const [city, setCity] = useState(driverLicense?.city ?? '')
  const [state, setState] = useState(driverLicense?.state ?? '')
  const [zipcode, setZipcode] = useState(driverLicense?.zipcode ?? '')
  const [birthDate, setBirthdate] = useState(driverLicense?.birthDate ?? '')
  const [url, setURL] = useState(driverLicense?.url ?? '')

  const [file, setFile] = useState<any>()
  const [fileName, setFileName] = useState<string>(
    getFileNameFromURL(driverLicense?.url)
  )

  return (
    <Box maxW="500px">
      <CompleteProfileHeader
        text="First, let’s verify your identity"
        subText="You’ll need to upload basic employment documents to apply for jobs, you
        can choose to set this up later in your profile"
      />

      <UpdateDriverLicense
        editable={true}
        id={id}
        setId={setId}
        address={address}
        setAddress={setAddress}
        city={city}
        setCity={setCity}
        state={state}
        setState={setState}
        zipcode={zipcode}
        setZipcode={setZipcode}
        birthDate={birthDate}
        setBirthdate={setBirthdate}
        url={url}
        setURL={setURL}
        setFile={setFile}
        fileName={fileName}
        setFileName={setFileName}
      />
      <HStack justify="end" mt={8}>
        <Button
          variant="adjustant"
          rightIcon={<MdOutlineNavigateNext size="20px" />}
          isLoading={showLoader}
          onClick={() => {
            next(
              {
                id,
                address,
                city,
                state,
                zipcode,
                birthDate,
                url: '',
              },
              { file, fileName }
            )
          }}
        >
          Next
        </Button>
      </HStack>
    </Box>
  )
}
export default CompleteProfileUpdateDriverLicense
