import {
  Avatar,
  AvatarBadge,
  Badge,
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from '@chakra-ui/react'
import { CHAT_STATUS, Chat, ChatMessage } from '../../types/chatTypes'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { User, UserContextType, UserType } from '../../types/userTypes'
import { formatDate } from '../../utils/functions'
import moment from 'moment'
import { IoIosArrowDown } from 'react-icons/io'
import { Job } from '../../types/jobTypes'
import { UserContext } from '../../contexts/UserContext'

export const ChatElement = ({
  chat,
  receiver,
  job,
  isSelected,
  onPinUnpinChat,
  onDeleteChat,
}: {
  chat: Chat
  receiver?: User
  job?: Job
  isSelected: boolean
  onPinUnpinChat: (chat: Chat, pin: boolean) => void
  onDeleteChat: (chat: Chat) => void
}) => {
  const { user: sender }: UserContextType = useContext(UserContext)

  const ref = useRef<HTMLDivElement | null>(null)
  const [listenerCreated, setListenerCreated] = useState(false)
  const [hovered, setHovered] = useState(false)

  const lastMessage = useMemo((): ChatMessage | null => {
    const messages = chat.messages
    if (messages.length > 0) {
      return messages[messages.length - 1]
    }
    return null
  }, [chat.messages])

  const receiverIsAdmin = useMemo(() => {
    return (
      chat.users.includes(sender?._id ?? '') &&
      chat.users.includes(UserType.ADMIN)
    )
  }, [chat.users, sender?._id])

  const senderIsAdmin = useMemo(() => {
    return sender?.userType === UserType.ADMIN
  }, [sender?.userType])

  const senderId = useMemo(() => {
    return sender?.userType === UserType.ADMIN
      ? UserType.ADMIN
      : sender?._id ?? ''
  }, [sender?._id, sender?.userType])

  const userFullName = useMemo(() => {
    return receiver?.userType === UserType.ADJUSTER
      ? `${receiver.firstName} ${receiver.lastName}`
      : receiverIsAdmin
      ? 'Adjustant Administrator'
      : receiver?.firmName
  }, [receiver, receiverIsAdmin])

  const dateFormat = useMemo((): Intl.DateTimeFormatOptions => {
    const lastMessageDateString = lastMessage?.date
    const lastMessageDate = new Date(lastMessageDateString ?? '')

    //today
    if (moment(lastMessageDate).isSame(new Date(), 'day')) {
      //   console.log('TODAY')
      return {
        hour: '2-digit',
        minute: '2-digit',
      }
    }

    //last week
    const daysDiff = moment(lastMessageDate).diff(new Date(), 'days')

    if (Math.abs(daysDiff) < 7) {
      //   console.log('LAST WEEK')
      return {
        weekday: 'long',
      }
    }

    return {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    }
  }, [lastMessage?.date])

  const unreadChatsQuantity = useMemo((): number => {
    // console.log('unreadChatsQuantity')
    return (
      chat.messages.filter(
        (message) => !message.read && message.sender !== senderId
      )?.length ?? 0
    )
  }, [chat.messages, senderId])

  useEffect(() => {
    if (ref?.current && !listenerCreated) {
      //   console.log(ref.current)
      setListenerCreated(true)
      ref.current.addEventListener('mouseenter', (event) => {
        setHovered(true)
        // console.log('mouseenter')
      })
      ref.current.addEventListener('mouseleave', (event) => {
        setHovered(false)
        // console.log('mouseleave')
      })
    }
  }, [listenerCreated, ref])

  return (
    <HStack
      ref={ref}
      align="flex-start"
      gap="3"
      px="4"
      py="3"
      height="93px"
      alignItems="center"
      _hover={{ bg: 'gray.100' }}
      bg={isSelected ? 'gray.100' : 'white'}
      color={chat.status === CHAT_STATUS.DISABLED ? 'gray.500' : ''}
      rounded="md"
    >
      <Box pt="1">
        <Avatar
          size="sm"
          src={receiver?.profilePicture}
          name={userFullName}
          {...(chat.status === CHAT_STATUS.DISABLED ? { bg: 'gray.500' } : {})}
          {...(chat.status === CHAT_STATUS.DISABLED ? { opacity: 0.4 } : {})}
        >
          {/* <AvatarBadge boxSize='1.25em' bg='green.500' /> */}
        </Avatar>
      </Box>

      <Stack spacing="0" fontSize="sm" flex="1" isTruncated>
        {/* Firm logo, firm name and last message date */}
        <HStack spacing="1">
          <Text fontWeight="medium" fontSize="md" flex="1" isTruncated>
            {userFullName}
          </Text>
          <Text color="fg.subtle" fontSize="xs">
            {formatDate(lastMessage?.date ?? '', dateFormat)}
          </Text>
        </HStack>

        {/* job title */}
        <HStack spacing="1">
          <Box color="fg.subtle" fontSize="md" isTruncated>
            {job?.title}
          </Box>
        </HStack>

        {/* last message and menu button */}
        <HStack justifyContent="space-between">
          <Box color="gray.500" isTruncated>
            {lastMessage?.message}
          </Box>
          <HStack>
            {unreadChatsQuantity && (
              <Badge
                px={2}
                py={0.5}
                bg="green.500"
                color="white"
                borderRadius="50%"
                opacity={chat.status === CHAT_STATUS.DISABLED ? 0.4 : 1}
              >
                {unreadChatsQuantity}
              </Badge>
            )}
            {hovered && (
              <Menu>
                <MenuButton
                  as="span"
                  //   style={{ visibility: hovered ? 'initial' : 'hidden' }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <IoIosArrowDown size="20px" />
                </MenuButton>
                <MenuList>
                  {/* {chat.status === CHAT_STATUS.DISABLED && (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation()
                        onDeleteChat(chat._id)
                      }}
                    >
                      Delete Chat
                    </MenuItem>
                  )} */}
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation()
                      const isPinned = chat.pinnedBy.includes(senderId)

                      onPinUnpinChat(chat, !isPinned)
                    }}
                  >
                    {chat.pinnedBy.includes(senderId) ? 'Unpin' : 'Pin'} Chat
                  </MenuItem>

                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation()

                      onDeleteChat(chat)
                    }}
                  >
                    Delete Chat
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </HStack>
        </HStack>
      </Stack>
    </HStack>
  )
}
