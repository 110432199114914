import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react'
import { useCallback, useContext, useEffect } from 'react'
import NIPRPDF from './NIPRPDF'
import ReactDOM from 'react-dom'
import { LicenseCertification, User } from '../../types/userTypes'
import { LOASContext } from '../../contexts/LOASContext'
import { LOASContextType } from '../../types/loasTypes'
import { StateLicesesContext } from '../../contexts/StateLicesesContext'
import { StateLicenseContextType } from '../../types/stateLicensesTypes'

const NIPRPDFModal = ({
  isOpen,
  onClose,
  license,
  user,
}: {
  isOpen: boolean
  onClose: () => void
  license?: LicenseCertification
  user?: User
}) => {
  const { loas }: LOASContextType = useContext(LOASContext)
  const { stateLicenses }: StateLicenseContextType =
    useContext(StateLicesesContext)

  const renderPDF = useCallback(() => {
    // console.log('renderPDF')
    // ReactPDF.renderToStream(<NIPRPDF />)
    // ReactPDF.render(<NIPRPDF />, `${__dirname}/example.pdf`)
    ReactDOM.render(
      <NIPRPDF
        license={license}
        user={user}
        loas={loas}
        licenses={stateLicenses}
      />,
      document.getElementById('KEKEKEK')
    )

    // const root = ReactDOM.createRoot(
    //   document.getElementById('KEKEKEK') as HTMLElement
    // )
    // root.render(<NIPRPDF />)
  }, [license, stateLicenses, loas, user])

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        renderPDF()
      }, 500)
    }
  }, [isOpen, renderPDF])

  return (
    <Modal
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
      size="6xl"
    >
      <ModalOverlay />
      <ModalContent>
        {/* <ModalHeader>Modal Title</ModalHeader> */}
        <ModalCloseButton />
        <ModalBody id="KEKEKEK">{/* <div ></div> */}</ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default NIPRPDFModal
