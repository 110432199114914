import { UPDATE_USER_BY_EMAIL } from '../../graphql/Mutations'
import { UserType } from '../../types/userTypes'
import { confirmForgotPassword } from '../../services/adjustant'
import { useMutation } from '@apollo/client'
import PasswordStrength, {
  PasswordStrengthType,
} from '../PasswordStrength/PasswordStrength'
import React, { useCallback, useMemo, useState } from 'react'
import {
  VStack,
  Heading,
  Box,
  Input,
  Stack,
  Text,
  Button,
} from '@chakra-ui/react'
import Label from '../Label/Label'
import { ADJUSTANT_GREEN } from '../../themes/themes'

const CreateNewPassword = ({
  confirmationCode,
  email,
  userType,
  onSendNewPassword,
}: {
  confirmationCode: string
  email: string
  userType?: UserType
  onSendNewPassword: () => void
}) => {
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [passwordStrength, setPasswordStrength] = useState<
    PasswordStrengthType | undefined
  >()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [update] = useMutation(UPDATE_USER_BY_EMAIL(email))

  const isPasswordInvalid = useMemo(() => {
    return passwordStrength !== 'strong' || password !== confirmPassword
  }, [passwordStrength, password, confirmPassword])

  const handleOnSendNewPassword = useCallback(
    async (e: any) => {
      e.preventDefault()
      if (!errorMessage && userType) {
        setShowLoader(true)
        const response = await confirmForgotPassword({
          email,
          userType,
          newPassword: password,
          confirmationCode,
        })
        setErrorMessage(response.message)
        if (response.success) {
          await update({ variables: { user: { password } } })
          setTimeout(() => {
            onSendNewPassword()
          }, 3000)
        }
      }
    },
    [
      errorMessage,
      userType,
      email,
      password,
      confirmationCode,
      update,
      onSendNewPassword,
    ]
  )

  return (
    <Box
      minHeight="100%"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        background="white"
        border="1px solid #ECEAE9"
        borderRadius="8px"
        width="600px"
        mx={3}
      >
        <VStack py={[6, 6, 12]} px={[6, 6, 12]}>
          <Heading
            as="h3"
            fontSize="3xl"
            color="gray.500"
            mt={4}
            mb={8}
            textAlign="center"
          >
            Create a Password
          </Heading>

          <Stack mb={2} width="100%">
            <Label color="gray.500">Password</Label>
            <Input
              type="password"
              placeholder="Password"
              _focusVisible={{ borderColor: 'gray.300' }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <PasswordStrength
              password={password}
              passwordStrength={passwordStrength}
              setPasswordStrength={setPasswordStrength}
            />
          </Stack>

          <Stack mb={2} width="100%">
            <Label color="gray.500">Confirm Password</Label>
            <Input
              type="password"
              placeholder="Confirm Password"
              _focusVisible={{ borderColor: 'gray.300' }}
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value)
                if (e.target.value !== password) {
                  setErrorMessage('Passwords must be the same !')
                } else {
                  setErrorMessage('')
                }
              }}
            />
          </Stack>

          <Text as="h5" color="red.500" fontSize="sm">
            {errorMessage}
          </Text>
          <Text as="h5" color="gray.500" fontSize="sm">
            Password must be at least 8 characters long and include an uppercase
            letter, a number, and a special character.
          </Text>

          <Button
            mt={6}
            variant="adjustant"
            isLoading={showLoader}
            isDisabled={isPasswordInvalid}
            _hover={{ backgroundColor: ADJUSTANT_GREEN }}
            onClick={handleOnSendNewPassword}
          >
            Continue
          </Button>
        </VStack>
      </Box>
    </Box>
  )
}

export default CreateNewPassword
