import { useCallback, useState } from 'react'
import {
  VStack,
  Heading,
  Stack,
  Input,
  Button,
  Box,
  Text,
} from '@chakra-ui/react'
import Label from '../../../components/Label/Label'
import { ADJUSTANT_GREEN } from '../../../themes/themes'
import {
  DOCUMENTS_STATUS,
  USER_STATUS,
  User,
  UserType,
} from '../../../types/userTypes'
import { adminInviteUser } from '../../../services/adjustant'
import { ADD_USER } from '../../../graphql/Mutations'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { ADMIN_ROUTES, GLOBAL_ROUTES } from '../../../App'
import { TbSend } from 'react-icons/tb'
import { GET_USER_BY_EMAIL } from '../../../graphql/Queries'

function AdminInviteAdjuster() {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [showLoader, setShowLoader] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [invitationSent, setInvitationSent] = useState(false)

  const [getUser] = useLazyQuery(GET_USER_BY_EMAIL)
  const [addUser] = useMutation(ADD_USER)

  const inviteFirm = useCallback(async () => {
    setShowLoader(true)
    setErrorMessage('')

    const result = await getUser({ variables: { email } })
    setShowLoader(false)
    if (result?.data?.UserOne)
      setErrorMessage('The email address is taken already')
    else {
      const responseData = await adminInviteUser({
        email,
        userType: UserType.ADJUSTER,
      })

      if (responseData.success) {
        const user: User = {
          email,
          phoneNumber: '',
          firstName,
          lastName,
          userType: UserType.ADJUSTER,
          password: responseData.data,
          firmName: '',
          jobTypes: [],
          profileSet: false,
          location: '',
          bio: '',
          profilePicture: '',
          status: USER_STATUS.AVAILABLE,
          licenses: [],
          certifications: [],
        }
        await addUser({
          variables: {
            user,
          },
        })
          .then(async (result) => {
            setInvitationSent(true)
            setShowLoader(false)
          })
          .catch(async (result) => {
            console.log(result)
          })
      } else {
        setErrorMessage(responseData.message)
        setShowLoader(false)
      }
    }
  }, [addUser, email, firstName, getUser, lastName])

  return (
    <Box
      minHeight="100%"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        background="white"
        border="1px solid #ECEAE9"
        borderRadius="8px"
        width="600px"
        mx={3}
      >
        <VStack py={[6, 6, 12]} px={[6, 6, 24]}>
          <Heading
            as="h3"
            fontSize="3xl"
            color="gray.500"
            mt={4}
            mb={8}
            textAlign="center"
          >
            Onboard Adjuster
          </Heading>
          {invitationSent ? (
            <>
              <Stack color={ADJUSTANT_GREEN} alignItems="center" mb={8}>
                <TbSend fontSize={80} />
                <Text as="b">Invitation Sent.</Text>
              </Stack>
              <Button
                variant="adjustant"
                isLoading={showLoader}
                _hover={{ backgroundColor: ADJUSTANT_GREEN }}
                onClick={() =>
                  navigate(`${GLOBAL_ROUTES.ADMIN}${ADMIN_ROUTES.ADJUSTERS}`)
                }
              >
                Back to Adjusters
              </Button>
            </>
          ) : (
            <>
              <Stack
                display={['block', 'block', 'flex']}
                direction={['column', 'column', 'row']}
                // spacing={2}
                width="100%"
              >
                <Stack width={['100%', '100%', '50%']} mb={2}>
                  <Label color="gray.500">First Name</Label>
                  <Input
                    type="text"
                    placeholder="Johnny"
                    _focusVisible={{ borderColor: 'gray.300' }}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Stack>
                <Stack width={['100%', '100%', '50%']}>
                  <Label color="gray.500">Last Name</Label>
                  <Input
                    type="text"
                    placeholder="Appleseed"
                    _focusVisible={{ borderColor: 'gray.300' }}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Stack>
              </Stack>
              <Stack mb={2} width="100%">
                <Label color="gray.500">Email</Label>
                <Input
                  type="email"
                  placeholder="What is the primary contact email for the carrier?"
                  _focusVisible={{ borderColor: 'gray.300' }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Stack>
              <Text as="h5" color="red.500" fontSize="sm">
                {errorMessage}
              </Text>
              <Button
                variant="adjustant"
                isLoading={showLoader}
                isDisabled={!email || !firstName || !lastName}
                _hover={{ backgroundColor: ADJUSTANT_GREEN }}
                onClick={inviteFirm}
              >
                Invite Adjuster
              </Button>
            </>
          )}
        </VStack>
      </Box>
    </Box>
  )
}

export default AdminInviteAdjuster
