import React from 'react'
import { Flex, Input, Stack } from '@chakra-ui/react'
import Label from '../Label/Label'
import moment from 'moment'

const DateRangePicker = ({
  issuedDate,
  setIssuedDate,
  expiryDate,
  setExpiryDate,
  expiryDateIsOptional,
  isDisabled,
}: {
  issuedDate: string
  setIssuedDate: (s: string) => void
  expiryDate: string
  setExpiryDate: (s: string) => void
  expiryDateIsOptional?: boolean
  isDisabled?: boolean
}) => {
  return (
    <Stack
      spacing={2}
      mb={2}
      display="flex"
      direction={['column', 'column', 'row']}
    >
      <Stack width={['100%', '100%', '50%']} spacing={0}>
        <Flex justify="space-between" mb={1}>
          <Label fontSize="sm" color="gray.700">
            Issued Date
          </Label>
        </Flex>
        <Input
          //   min="2023-12-13"
          max={moment(new Date()).format('YYYY-MM-DD')}
          type="date"
          placeholder=""
          value={issuedDate}
          onChange={(e) => {
            setIssuedDate(e.target.value)
          }}
          isDisabled={isDisabled}
        />
      </Stack>
      <Stack width={['100%', '100%', '50%']} spacing={0}>
        <Flex justify="space-between" mb={1}>
          <Label fontSize="sm" color="gray.700">
            Expiry Date {`${expiryDateIsOptional ? '(Optional)' : ''}`}
          </Label>
        </Flex>
        <Input
          min={moment(issuedDate).add(1, 'days').format('YYYY-MM-DD')}
          type="date"
          placeholder=""
          value={expiryDate}
          onChange={(e) => {
            setExpiryDate(e.target.value)
          }}
          isDisabled={isDisabled}
        />
      </Stack>
    </Stack>
  )
}

export default DateRangePicker
