import { Box, HStack, Spinner, Stack } from '@chakra-ui/react'
import {
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useNavigate, useParams } from 'react-router'
import { UserContext } from '../../contexts/UserContext'
import { User, UserContextType, UserType } from '../../types/userTypes'
import {
  Application,
  JobApplicationStatus,
  JobSpecs,
  JobStatus,
  Job as JobType,
  Roster,
} from '../../types/jobTypes'
import { useLazyQuery } from '@apollo/client'
import {
  GET_ADMINS,
  GET_APPLICATIONS,
  GET_JOB_BY_ID,
  GET_JOB_SPECS_BY_ID,
  GET_ROSTERS,
  GET_ROSTER_BY_ID,
  GET_USER_BY_ID,
} from '../../graphql/Queries'
import { GLOBAL_ROUTES } from '../../App'
import FirmJobTabs from '../../components/FirmJobTabs/FirmJobTabs'
import AdjusterJobTabs from '../../components/AdjusterJobTabs/AdjusterJobTabs'

const JobContent = ({
  setShowLoader,
  user,
  job,
  setJob,
  specs,
  firm,
  applications,
  setApplications,
  adjusterRoster,
  setAdjusterRoster,
  admins,
  userIsOwner,
  onDelete,
}: {
  setShowLoader: React.Dispatch<SetStateAction<boolean>>
  user: User
  job: JobType
  setJob: React.Dispatch<React.SetStateAction<JobType | undefined>>

  specs?: JobSpecs
  setSpecs: React.Dispatch<React.SetStateAction<JobSpecs | undefined>>

  firm?: User
  setFirm: React.Dispatch<React.SetStateAction<User | undefined>>

  applications: Application[]
  setApplications: React.Dispatch<React.SetStateAction<Application[]>>

  rosters: Roster[]
  setRosters: React.Dispatch<React.SetStateAction<Roster[]>>

  adjusterRoster?: Roster
  setAdjusterRoster: React.Dispatch<React.SetStateAction<Roster | undefined>>

  admins: User[]
  canUpdateJob: boolean
  userIsOwner: boolean

  onDelete: () => void
}) => {
  const userIsHired = useMemo(() => {
    return user?._id === adjusterRoster?.adjusterId
  }, [adjusterRoster?.adjusterId, user?._id])

  return (
    <HStack paddingY="10px" paddingX={['10px', '50px']} justifyContent="center">
      <Box
        width="4xl"
        // py={10}
        // px={8}
        background="white"
        borderRadius={8}
        border="1px solid #ECEAE9"
        // mb={2}
      >
        {userIsOwner ? (
          <FirmJobTabs
            firm={firm}
            job={job}
            setJob={setJob}
            setShowParentLoader={setShowLoader}
            onDelete={(jobId) => {
              onDelete()
            }}
          />
        ) : (
          <AdjusterJobTabs
            job={job}
            user={user}
            userIsHired={userIsHired}
            applications={applications}
            setApplications={setApplications}
            admins={admins}
            backButton={<></>}
          />
        )}
      </Box>
    </HStack>
  )
}

const Job = () => {
  const navigate = useNavigate()
  const { jobId } = useParams()
  // const [canEditProfile, setCanEditProfile] = useState<boolean>(false)

  const { user }: UserContextType = useContext(UserContext)
  const [jobRetrieved, setJobRetrieved] = useState<boolean>(false)
  const [showLoader, setShowLoader] = useState<boolean>(true)

  const [admins, setAdmins] = useState<User[]>([])

  const [job, setJob] = useState<JobType | undefined>()
  const [specs, setSpecs] = useState<JobSpecs | undefined>()
  const [firm, setFirm] = useState<User>()
  const [applications, setApplications] = useState<Application[]>([])
  const [rosters, setRosters] = useState<Roster[]>([])
  const [adjusterRoster, setAdjusterRoster] = useState<Roster>()

  const userIsOwner = useMemo(() => {
    return user?._id === job?.firmId || user?.userType === UserType.ADMIN
  }, [job?.firmId, user?._id, user?.userType])

  const canUpdateJob = useMemo(() => {
    return userIsOwner && job?.status !== JobStatus.FINISHED
  }, [job?.status, userIsOwner])

  const [getJob] = useLazyQuery(GET_JOB_BY_ID)
  const [getFirm] = useLazyQuery(GET_USER_BY_ID)
  const [getJobSpecs] = useLazyQuery(GET_JOB_SPECS_BY_ID)
  const [getApplications] = useLazyQuery(GET_APPLICATIONS)
  const [getRoster] = useLazyQuery(GET_ROSTER_BY_ID)
  const [getRosters] = useLazyQuery(GET_ROSTERS)
  const [getAdmins] = useLazyQuery(GET_ADMINS)

  const retrieveData = useCallback(async () => {
    setShowLoader(true)
    let result
    result = await getJob({
      variables: {
        id: jobId,
        statuses: [JobStatus.ONGOING, JobStatus.FINISHED],
      },
      fetchPolicy: 'network-only',
    })
    const job = result?.data?.GetJobById
    if (job) {
      setJob(job)
      setJobRetrieved(true)
      if (job?.firmId === user?._id) {
        setFirm(user)
        result = await getRosters({
          variables: { jobId: job._id },
        })
        const rosters = result?.data?.RosterMany
        if (rosters?.length > 0) setRosters(rosters)
      } else {
        result = await getFirm({ variables: { id: job?.firmId } })
        const firm = result?.data?.UserOne
        if (firm) setFirm(firm)
        result = await getRoster({
          variables: { adjusterId: user?._id, jobId: job?._id },
        })
        const roster = result?.data?.RosterOne
        if (roster) setAdjusterRoster(roster)
      }

      result = await getJobSpecs({ variables: { jobId: job?._id } })
      const specs = result?.data?.GetJobSpecsById
      if (specs) setSpecs(specs)

      result = await getApplications({
        variables: { jobId: job._id, status: JobApplicationStatus.WAITING },
      })
      const applications = result?.data?.ApplicationMany
      if (applications > 0) setApplications(applications)

      result = await getAdmins()
      setAdmins(result?.data?.UserMany ?? [])
    } else {
      navigate(GLOBAL_ROUTES.JOBS)
    }

    setShowLoader(false)
  }, [
    getAdmins,
    getApplications,
    getFirm,
    getJob,
    getJobSpecs,
    getRoster,
    getRosters,
    jobId,
    navigate,
    user,
  ])

  useEffect(() => {
    if (jobId && !jobRetrieved) retrieveData()
  }, [getJob, jobId, jobRetrieved, retrieveData])

  if (showLoader)
    return (
      <Box
        minHeight="100%"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Stack direction="row" spacing={4}>
          <Spinner size="xl" />
        </Stack>
      </Box>
    )
  return (
    <>
      {job && user && (
        <JobContent
          setShowLoader={setShowLoader}
          user={user}
          job={job}
          setJob={setJob}
          specs={specs}
          setSpecs={setSpecs}
          firm={firm}
          setFirm={setFirm}
          applications={applications}
          setApplications={setApplications}
          rosters={rosters}
          setRosters={setRosters}
          adjusterRoster={adjusterRoster}
          setAdjusterRoster={setAdjusterRoster}
          admins={admins}
          canUpdateJob={canUpdateJob}
          userIsOwner={userIsOwner}
          onDelete={() => {
            navigate(GLOBAL_ROUTES.JOBS)
          }}
        />
      )}
    </>
  )
}

export default Job
