import React, { useCallback, useContext, useState } from 'react'
import { GET_USER_BY_EMAIL } from '../../../graphql/Queries'
import { UserContext } from '../../../contexts/UserContext'
import { User, UserContextType, UserType } from '../../../types/userTypes'
import { completeLoginUser, loginUser } from '../../../services/adjustant'
import { useLazyQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import {
  setItemToLocalStorage,
  LOCALSTORAGE_OBJECTS_NAMES,
} from '../../../utils/localStorageFunctions'
import { ADMIN_ROUTES, GLOBAL_ROUTES } from '../../../App'
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Stack,
  VStack,
  Text,
  HStack,
  PinInput,
  PinInputField,
} from '@chakra-ui/react'
import Label from '../../../components/Label/Label'
import { ADJUSTANT_GREEN } from '../../../themes/themes'

const CODE_LENGHT = 6

function AdminLogin() {
  const navigate = useNavigate()
  const { setUser }: UserContextType = useContext(UserContext)
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [step, setStep] = useState<number>(1)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  //Step two
  const [code, setCode] = useState<string>('')
  const [encryptedCode, setEncryptedCode] = useState<string>('')
  const [accessToken, setAccessToken] = useState<string>('')

  const [getUser] = useLazyQuery(GET_USER_BY_EMAIL, {
    onCompleted: (data: { UserOne: User }) => {
      setItemToLocalStorage(LOCALSTORAGE_OBJECTS_NAMES.USER, data.UserOne)
      setUser(data.UserOne) // this is what triggers th usefect that sets the user inside app.tsx
      navigate(`${GLOBAL_ROUTES.ADMIN}${ADMIN_ROUTES.DASHBOARD}`)
    },
    onError: (err) => {
      setShowLoader(false)
    },
  })

  const logIn = useCallback(async () => {
    setShowLoader(true)
    setErrorMessage('')
    const responseData = await loginUser({
      email,
      password,
      userType: UserType.ADMIN,
    })
    if (!responseData.success) {
      setErrorMessage(responseData.message)
      setShowLoader(false)
    } else {
      if (responseData?.data?.EncryptedCode) {
        setErrorMessage(responseData.message)
        setShowLoader(false)
        setAccessToken(responseData?.data?.AccessToken)
        setEncryptedCode(responseData?.data?.EncryptedCode)
        setTimeout(() => {
          setErrorMessage('')
          setStep(2)
        }, 2000)
      }
    }
  }, [email, password, setShowLoader])

  const completeLogIn = useCallback(async () => {
    setShowLoader(true)
    setErrorMessage('')
    const responseData = await completeLoginUser({
      encryptedCode,
      enteredCode: code,
    })
    if (!responseData.success) {
      setErrorMessage(responseData.message)
      setShowLoader(false)
    } else {
      getUser({ variables: { email } })
    }
  }, [encryptedCode, code, getUser, email])

  return (
    <Box
      minHeight="100%"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        background="white"
        border="1px solid #ECEAE9"
        borderRadius="8px"
        width="600px"
        mx={3}
      >
        <VStack py={[6, 6, 12]} px={[6, 6, 24]}>
          {step === 1 ? (
            <>
              <Heading
                as="h3"
                fontSize="3xl"
                color="gray.500"
                mt={4}
                mb={8}
                textAlign="center"
              >
                Welcome Back
              </Heading>
              <Stack mb={2} width="100%">
                <Label color="gray.500">Email</Label>
                <Input
                  type="email"
                  placeholder="johnny.appleseed@email.com"
                  _focusVisible={{ borderColor: 'gray.300' }}
                  value={email}
                  onChange={(e) =>
                    setEmail(e.target.value?.toLocaleLowerCase())
                  }
                />
              </Stack>
              <Stack width="100%" mb={4}>
                <Flex justifyContent="space-between">
                  <Label color="gray.500">Password</Label>
                </Flex>
                <Input
                  type="password"
                  placeholder="Password"
                  _focusVisible={{ borderColor: 'gray.300' }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Stack>
              <Text as="h5" color="red.500" fontSize="sm">
                {errorMessage}
              </Text>
              <Button
                variant="adjustant"
                isLoading={showLoader}
                isDisabled={!email || !password}
                _hover={{ backgroundColor: ADJUSTANT_GREEN }}
                onClick={logIn}
              >
                Log In
              </Button>
            </>
          ) : step === 2 ? (
            <>
              <Heading
                as="h3"
                fontSize="3xl"
                color="gray.500"
                mt={4}
                mb={8}
                textAlign="center"
              >
                Welcome Back
              </Heading>

              <HStack>
                <PinInput onChange={setCode}>
                  {[...Array(CODE_LENGHT)].map((_key, index) => (
                    <PinInputField key={index} />
                  ))}
                </PinInput>
              </HStack>

              <Text as="h5" color="red.500" fontSize="sm">
                {errorMessage}
              </Text>
              <Button
                variant="adjustant"
                isLoading={showLoader}
                isDisabled={code.length !== CODE_LENGHT}
                _hover={{ backgroundColor: ADJUSTANT_GREEN }}
                onClick={completeLogIn}
              >
                Log In
              </Button>
            </>
          ) : (
            <></>
          )}
        </VStack>
      </Box>
    </Box>
  )
}

export default AdminLogin
