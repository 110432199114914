import {
  createMultiStyleConfigHelpers,
  defineStyle,
  defineStyleConfig,
  extendTheme,
} from '@chakra-ui/react'
import { radioAnatomy, tabsAnatomy, checkboxAnatomy } from '@chakra-ui/anatomy'
import { MultiSelectTheme } from '../themes/dropDownMultipleTheme'

export const ADJUSTANT_GREEN = '#488488'

const {
  definePartsStyle: defineRadioPartsStyle,
  defineMultiStyleConfig: defineRadioMultiStyleConfig,
} = createMultiStyleConfigHelpers(radioAnatomy.keys)
const adjustantRadio = defineRadioPartsStyle({
  control: {
    border: '1px solid',
    borderColor: ADJUSTANT_GREEN,
    // backgroundColor: 'red'

    _checked: {
      background: 'white',
      borderColor: ADJUSTANT_GREEN,
      color: ADJUSTANT_GREEN,

      _hover: {
        background: 'white',
      },
    },
  },
  label: {
    fontWeight: 'bold',
  },
})
const adjustantRadioTheme = defineRadioMultiStyleConfig({
  variants: { adjustant: adjustantRadio },
})

///////////////////////

const {
  definePartsStyle: defineTabsPartsStyle,
  defineMultiStyleConfig: defineTabsMultiStyleConfig,
} = createMultiStyleConfigHelpers(tabsAnatomy.keys)
const adjustantTab = defineTabsPartsStyle({
  tab: {
    _selected: {
      color: ADJUSTANT_GREEN,
      borderColor: ADJUSTANT_GREEN,
      borderBottom: `2px solid ${ADJUSTANT_GREEN}`,
    },
    borderBottom: '2px solid transparent',
    padding: '0',
    marginRight: '10px',
    color: 'gray.600',
    // fontWeight: 'bold'
  },
})
const adjustantTabTheme = defineTabsMultiStyleConfig({
  variants: { adjustant: adjustantTab },
})

///////////////////////
const adjustantButton = defineStyle({
  color: 'white',
  backgroundColor: ADJUSTANT_GREEN,
})
const adjustantOutlinedButton = defineStyle({
  color: ADJUSTANT_GREEN,
  backgroundColor: 'white',
  borderColor: ADJUSTANT_GREEN,
})
const rejectButton = defineStyle({
  color: 'white',
  backgroundColor: 'red.500',
})
const adjustantButtonTheme = defineStyleConfig({
  variants: {
    adjustant: adjustantButton,
    reject: rejectButton,
    outline: adjustantOutlinedButton,
  },
})

/////////////////////
const {
  definePartsStyle: defineCheckboxPartsStyle,
  defineMultiStyleConfig: defineCheckboxMultiStyleConfig,
} = createMultiStyleConfigHelpers(checkboxAnatomy.keys)

const adjustantCheckbox = defineCheckboxPartsStyle({
  control: {
    _checked: {
      bg: ADJUSTANT_GREEN,
      borderColor: ADJUSTANT_GREEN,
      color: 'white',
      _hover: {
        bg: ADJUSTANT_GREEN,
        borderColor: ADJUSTANT_GREEN,
      },
    },
  },
  label: {
    color: ADJUSTANT_GREEN,
    fontWeight: 600,
  },
})

const adjustantCheckboxTheme = defineCheckboxMultiStyleConfig({
  variants: { adjustant: adjustantCheckbox },
})

/////////////////////
const adjustantLink = defineStyle({
  borderBottom: `2px solid transparent`,
  _hover: {
    // borderBottom: `2px solid ${ADJUSTANT_GREEN}`,
    textDecoration: 'none',
  },
})
const adjustantSelectedLink = defineStyle({
  borderBottom: `2px solid ${ADJUSTANT_GREEN}`,
  _hover: {
    textDecoration: 'none',
  },
})
const adjustantSelectedLinkTheme = defineStyleConfig({
  variants: {
    adjustant: adjustantLink,
    adjustantSelected: adjustantSelectedLink,
  },
})

const theme = extendTheme({
  components: {
    Radio: adjustantRadioTheme,
    Tabs: adjustantTabTheme,
    MultiSelect: MultiSelectTheme,
    Button: adjustantButtonTheme,
    Checkbox: adjustantCheckboxTheme,
    Link: adjustantSelectedLinkTheme,
  },
  colors: {
    adjustantGreen: ADJUSTANT_GREEN,
  },
})

export default theme
