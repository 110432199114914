import React, { useState } from 'react'
import { Box, Button, Flex, HStack } from '@chakra-ui/react'
import CompleteProfileHeader from './CompleteProfileHeader'
import Map from '../Map/Map'
import Label from '../Label/Label'

const CompleteProfileSetAreasOfOperation = ({
  next,
  areasOfOperation,
  showLoader,
}: {
  next: (a: string[]) => void
  showLoader: boolean
  areasOfOperation?: string[]
}) => {
  const [states, setStates] = useState<string[]>(areasOfOperation ?? [])

  return (
    <Box width={['auto', '400px', '500px']}>
      <CompleteProfileHeader text="Areas of Operation" />
      <Box>
        <Flex mb={4} justifyContent="center">
          <Label fontSize="sm" color="gray.700">
            Please select the states your firm operates within
          </Label>
        </Flex>
        <Map states={states} setStates={setStates} editable />
      </Box>
      <HStack justify="end">
        <Button
          variant="adjustant"
          isLoading={showLoader}
          onClick={() => next(states)}
        >
          Next
        </Button>
      </HStack>
    </Box>
  )
}
export default CompleteProfileSetAreasOfOperation
