import { ADD_USER, CREATE_NOTIFICATION } from '../../graphql/Mutations'
import { UserContext } from '../../contexts/UserContext'
import {
  USER_STATUS,
  User,
  UserContextType,
  UserType,
} from '../../types/userTypes'
import {
  confirmRegisterUser,
  resendSignUpConfirmationCode,
} from '../../services/adjustant'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router'
import React, { useCallback, useContext, useState } from 'react'
import {
  setItemToLocalStorage,
  LOCALSTORAGE_OBJECTS_NAMES,
} from '../../utils/localStorageFunctions'
import { GLOBAL_ROUTES } from '../../App'
import {
  Box,
  Button,
  Heading,
  Stack,
  VStack,
  Text,
  HStack,
  PinInput,
  PinInputField,
} from '@chakra-ui/react'
import { ADJUSTANT_GREEN } from '../../themes/themes'

const CODE_LENGHT = 6

function ConfirmSignUp({
  firstName,
  lastName,
  email,
  phoneNumber,
  password,
  userType,
  admins,
}: {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  password: string
  userType: UserType
  admins: User[]
}) {
  const navigate = useNavigate()
  const [addUser] = useMutation(ADD_USER)

  const { setUser }: UserContextType = useContext(UserContext)
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [code, setCode] = useState('')

  const [createNotificationMutation] = useMutation(CREATE_NOTIFICATION)

  const notifyAdmins = useCallback(
    async (message: string, userId: string) => {
      admins.map((admin) => {
        createNotificationMutation({
          variables: {
            notification: {
              userId: admin?._id,
              message,
              link: `${GLOBAL_ROUTES.ADMIN}${GLOBAL_ROUTES.PROFILE}/${userId}`,
              read: false,
            },
          },
        })

        return null
      })
    },
    [admins, createNotificationMutation]
  )

  const handleConfirmation = useCallback(async () => {
    if (!userType) return

    setShowLoader(true)
    const responseData = await confirmRegisterUser({
      email,
      verificationCode: code,
      userType,
      password,
    })

    if (!responseData.success) {
      setShowLoader(false)
      setShowErrorMessage(true)
    } else {
      const user: User = {
        email,
        phoneNumber,
        firstName,
        lastName,
        userType,
        password,
        firmName: '',
        jobTypes: [],
        profileSet: false,
        location: '',
        bio: '',
        profilePicture: '',
        status: USER_STATUS.AVAILABLE,
        licenses: [],
        certifications: [],
      }
      await addUser({
        variables: {
          user,
        },
      }).then(async (result) => {
        const { __typename, ...user } = result.data.UserCreateOne.record
        setItemToLocalStorage(LOCALSTORAGE_OBJECTS_NAMES.USER, user)
        setItemToLocalStorage(
          LOCALSTORAGE_OBJECTS_NAMES.ACCESS_TOKEN,
          responseData?.data?.AccessToken
        )
        setUser(user)
        notifyAdmins(
          `The Adjuster ${user.firstName} ${user.lastName} has signed up`,
          user._id
        )
        navigate(GLOBAL_ROUTES.COMPLETE_PROFILE)
      })
    }
  }, [
    userType,
    email,
    code,
    password,
    phoneNumber,
    firstName,
    lastName,
    addUser,
    setUser,
    notifyAdmins,
    navigate,
  ])

  const resendCode = useCallback(
    async (e: any) => {
      e.preventDefault()
      console.log('RESENDING CODE')
      if (userType) {
        const responseData = await resendSignUpConfirmationCode({
          email,
          userType,
        })
        console.log(responseData)
      }
    },
    [userType, email]
  )

  return (
    <Box
      minHeight="100%"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        background="white"
        border="1px solid #ECEAE9"
        borderRadius="8px"
        width="600px"
        mx={3}
      >
        <VStack py={[6, 6, 12]} px={[6, 6, 12]}>
          <Heading
            as="h3"
            fontSize="3xl"
            color="gray.500"
            mt={4}
            mb={8}
            textAlign="center"
          >
            Verify Your Email
          </Heading>

          <HStack>
            <PinInput onChange={setCode}>
              {[...Array(CODE_LENGHT)].map((_key, index) => (
                <PinInputField key={index} />
              ))}
            </PinInput>
          </HStack>

          <VStack textAlign="center" mt={6}>
            {showErrorMessage && (
              <Text as="h5" color="red.500" fontSize="sm">
                Wrong verification code please try again
              </Text>
            )}
            <Text as="h5" color="gray.500" fontSize="sm">
              Please check your email for the verification code we just sent. If
              you don’t see it, please wait a few minutes or check your spam
              folder.
            </Text>

            <Button
              variant="adjustant"
              isLoading={showLoader}
              isDisabled={code?.toString()?.length !== CODE_LENGHT}
              _hover={{ backgroundColor: ADJUSTANT_GREEN }}
              onClick={handleConfirmation}
            >
              Continue
            </Button>

            <Stack>
              <Text fontSize="sm">Didn&apos;t receive a code?</Text>
              <Button
                variant="link"
                color={ADJUSTANT_GREEN}
                textDecoration="underline"
                onClick={resendCode}
              >
                Resend
              </Button>
            </Stack>
          </VStack>
        </VStack>
      </Box>
    </Box>
  )
}

export default ConfirmSignUp
