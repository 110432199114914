import {
  Box,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Tab,
  TabList,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { GLOBAL_ROUTES } from '../../App'
import { useMemo, useState } from 'react'
import { ADJUSTER_WORK_STATUS, Job, Roster } from '../../types/jobTypes'
import { createColumnHelper } from '@tanstack/react-table'
import { formatDate } from '../../utils/functions'
import CustomTable from '../CustomTable/CustomTable'
import { AdminAdjuster, USER_STATUS } from '../../types/userTypes'
import { CiEdit } from 'react-icons/ci'
import { ADJUSTANT_GREEN } from '../../themes/themes'

interface TableAdjuster extends AdminAdjuster {
  ongoingJobs: Roster[]
  finishedJobs: Roster[]
}

function AdjustersList({
  adjusters,
  jobs,
  rosters,
  tab = 0,
}: {
  adjusters: AdminAdjuster[]
  jobs: Job[]
  rosters: Roster[]
  tab?: number
}) {
  const columnHelper = createColumnHelper<TableAdjuster>()
  const [selectedTab, setSelectedTab] = useState<number>(tab)

  const adjustersToShow = useMemo((): TableAdjuster[] => {
    const status =
      selectedTab === 0
        ? USER_STATUS.AVAILABLE
        : selectedTab === 1
        ? USER_STATUS.UNAVAILABLE
        : USER_STATUS.DEPLOYED

    return adjusters
      .filter((adjuster) => adjuster.status === status)
      .map((adjuster) => ({
        _id: adjuster._id ?? '',
        firstName: adjuster.firstName,
        lastName: adjuster.lastName,
        email: adjuster.email,
        lastActive: adjuster.lastActive,
        status: adjuster.status,
        documentsStatus: adjuster.documentsStatus,
        ongoingJobs: rosters.filter(
          (r) =>
            r.adjusterId === adjuster._id &&
            r.status === ADJUSTER_WORK_STATUS.ONGOING
        ),
        finishedJobs: rosters.filter(
          (r) =>
            r.adjusterId === adjuster._id &&
            r.status === ADJUSTER_WORK_STATUS.FINISHED
        ),
      }))
  }, [adjusters, rosters, selectedTab])

  const columns = [
    columnHelper.accessor('_id', {
      cell: (info) => {
        return (
          <CiEdit
            fontSize={20}
            cursor="pointer"
            color={ADJUSTANT_GREEN}
            onClick={() =>
              window
                .open(
                  `${GLOBAL_ROUTES.ADMIN}${
                    GLOBAL_ROUTES.PROFILE
                  }/${info.getValue()}`,
                  '_blank'
                )
                ?.focus()
            }
          />
        )
      },
      header: '',
    }),
    columnHelper.accessor('lastName', {
      cell: (info) => info.getValue(),
      header: 'Last Name',
    }),
    columnHelper.accessor('firstName', {
      cell: (info) => info.getValue(),
      header: 'First Name',
    }),
    columnHelper.accessor('email', {
      cell: (info) => info.getValue(),
      header: 'Email',
    }),
    columnHelper.accessor('status', {
      cell: (info) => info.getValue().toString(),
      header: 'Status',
    }),
    columnHelper.accessor('documentsStatus', {
      cell: (info) => info.getValue()?.toString(),
      header: 'Documents Status',
    }),
    columnHelper.accessor('ongoingJobs', {
      cell: (info) => {
        const rosters = info.getValue()
        console.log(rosters)
        return (
          <Popover>
            <PopoverTrigger>
              {rosters.length > 0 ? <Link>{rosters.length}</Link> : <>Empty</>}
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              {/* <PopoverCloseButton /> */}
              <PopoverHeader as="b">Jobs</PopoverHeader>
              <PopoverBody>
                <TableContainer>
                  <Table variant="simple" size="sm">
                    <Thead>
                      <Tr>
                        <Th>ID</Th>
                        <Th>Title</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {rosters.map((roster) => (
                        <Tr key={roster._id}>
                          <Td>
                            <Link
                              onClick={() =>
                                window
                                  .open(
                                    `${GLOBAL_ROUTES.ADMIN}${GLOBAL_ROUTES.JOB}/${roster.jobId}`,
                                    '_blank'
                                  )
                                  ?.focus()
                              }
                            >
                              {roster.jobId}
                            </Link>
                          </Td>
                          <Td>
                            {
                              jobs.find((job) => job._id === roster.jobId)
                                ?.title
                            }
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )
      },
      header: 'Ongoing Jobs',
    }),
    columnHelper.accessor('finishedJobs', {
      cell: (info) => {
        const rosters = info.getValue()
        console.log(rosters)
        return (
          <Popover>
            <PopoverTrigger>
              {rosters.length > 0 ? <Link>{rosters.length}</Link> : <>Empty</>}
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              {/* <PopoverCloseButton /> */}
              <PopoverHeader as="b">Jobs</PopoverHeader>
              <PopoverBody>
                <TableContainer>
                  <Table variant="simple" size="sm">
                    <Thead>
                      <Tr>
                        <Th>ID</Th>
                        <Th>Title</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {rosters.map((roster) => (
                        <Tr key={roster._id}>
                          <Td>
                            <Link
                              onClick={() =>
                                window
                                  .open(
                                    `${GLOBAL_ROUTES.ADMIN}${GLOBAL_ROUTES.JOB}/${roster.jobId}`,
                                    '_blank'
                                  )
                                  ?.focus()
                              }
                            >
                              {roster.jobId}
                            </Link>
                          </Td>
                          <Td>
                            {
                              jobs.find((job) => job._id === roster.jobId)
                                ?.title
                            }
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )
      },
      header: 'Finished Jobs',
    }),
    columnHelper.accessor('lastActive', {
      cell: (info) => (info.getValue() ? formatDate(info.getValue()) : '-'),
      header: 'Last Active',
    }),
  ]

  return (
    <Box>
      <Tabs
        variant="adjustant"
        h="100%"
        width="100%"
        index={selectedTab}
        onChange={(index) => {
          setSelectedTab(index)
        }}
      >
        <TabList background="white">
          <Tab fontWeight={500}>Available</Tab>
          <Tab fontWeight={500}>Unavailable</Tab>
          <Tab fontWeight={500}>Deployed</Tab>
        </TabList>

        {adjustersToShow.length > 0 ? (
          <TabPanels p={2} overflowY="auto">
            <CustomTable data={adjustersToShow} columns={columns} />
          </TabPanels>
        ) : (
          <Text my={8}>No adjusters to show</Text>
        )}
      </Tabs>
    </Box>
  )
}

export default AdjustersList
