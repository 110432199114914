import React from 'react'
import { ADJUSTANT_GREEN } from '../../themes/themes'
const CheckrLogo = ({
  id,
  width = '24',
  height = '24',
  title = 'Checkr Logo',
  color = ADJUSTANT_GREEN,
}: {
  id: string
  width?: string
  height?: string
  title?: string
  color?: string
}) => (
  <div className="p-3">
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 554.09 130"
      width={width}
      height={height}
    >
      <title>{title}</title>
      <desc>Checkr Logo (White)</desc>
      <defs>
        <style>{`.cls-${id}{fill:${color};}`}</style>
      </defs>
      <path
        className={`cls-${id}`}
        d="M545.77,31.22c-12.02,0-20.48,5.71-27.15,13.99l-4.5-11.47h-16.23V127.35h25.31v-48.63c0-15.3,5.63-23.82,19.89-23.82h10.98V31.22h-8.31Z"
      />
      <polygon
        className={`cls-${id}`}
        points="489.19 33.74 460.03 33.74 431.94 75.34 431.94 0 406.63 0 406.63 127.35 431.94 127.35 431.94 82.57 461.89 127.35 492.56 127.35 457.52 78.32 489.19 33.74"
      />
      <path
        className={`cls-${id}`}
        d="M353.04,106.2c-13.55,0-24.05-11.3-24.05-25.8s10.32-25.52,24.05-25.52c10.06,0,18.48,5.38,22.59,14.55l22.9-7.93c-6.92-18.53-24.03-30.41-45.35-30.41-28.44,0-49.85,21.12-49.85,49.17s21.54,49.74,49.7,49.74c21.55,0,38.97-12.36,45.85-31.35l-23.3-8.07c-4.28,9.96-12.35,15.62-22.55,15.62Z"
      />
      <path
        className={`cls-${id}`}
        d="M49.7,106.2c-13.55,0-24.05-11.3-24.05-25.8s10.32-25.52,24.05-25.52c10.06,0,18.48,5.38,22.59,14.55l22.9-7.93c-6.92-18.53-24.03-30.41-45.35-30.41C21.4,31.09,0,52.21,0,80.26s21.54,49.74,49.7,49.74c21.55,0,38.97-12.36,45.85-31.35l-23.3-8.07c-4.28,9.96-12.35,15.62-22.55,15.62Z"
      />
      <path
        className={`cls-${id}`}
        d="M157.01,31.09c-11.18,0-21.16,4.57-28.27,11.34V0h-25.31V127.35h25.31v-49.2c0-15.21,8.38-24.41,21-24.41,11.38,0,16.33,6.96,16.33,20.97v52.64h25.31v-56.87c0-23.84-13.18-39.39-34.37-39.39Z"
      />
      <path
        className={`cls-${id}`}
        d="M248.07,31.09c-27.78,0-48.56,21.26-48.56,49.46s20.91,49.45,48.56,49.45c19.24,0,35.48-10.2,43.68-26.7l-22.99-7.97c-5.1,8.99-12.63,11.91-20.69,12.12-12.27,.32-20.68-7.03-22.88-19.81h70.85c.32-2.47,.49-4.95,.49-7.42h0c0-26.96-19.72-49.12-48.46-49.12Zm-22.21,39.26c2.94-11.08,10.94-17.65,22.21-17.65s19.58,6.21,23.16,17.65h-45.36Z"
      />
    </svg>
  </div>
)
export default CheckrLogo
