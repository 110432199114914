import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  Textarea,
  Flex,
} from '@chakra-ui/react'
import { useState } from 'react'
import Label from '../Label/Label'

const RequestWorkModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: (message: string, send: boolean) => void
}) => {
  const [message, setMessage] = useState(`Hello!
  
I'd like to participate in this job.`)

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose(message, false)
      }}
      size="xl"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Request Work</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Flex mb={2}>
              <Label fontSize="sm" color="gray.600">
                Message
              </Label>
            </Flex>
            <Textarea
              placeholder=""
              resize="vertical"
              size="xl"
              rows={6}
              p={2}
              value={message}
              borderRadius={3}
              onChange={(e) => {
                setMessage(e.target.value)
              }}
            />
          </Box>
        </ModalBody>
        <ModalFooter>
          {/* <Button variant="ghost">Close</Button> */}
          <Button
            variant="adjustant"
            mr={3}
            onClick={() => {
              onClose(message, true)
            }}
          >
            Send Application
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default RequestWorkModal
