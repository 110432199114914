import React, { useCallback, useContext, useMemo } from 'react'
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Input,
  Link,
  Stack,
  VStack,
  Text,
  Checkbox,
} from '@chakra-ui/react'
import Label from '../Label/Label'
import { AiOutlineFile, AiOutlinePlus, AiOutlineClose } from 'react-icons/ai'
import FileInput from '../FileInput/FileInput'
import { BsFillTrashFill } from 'react-icons/bs'
import { DATA_SOURCE, LicenseCertification } from '../../types/userTypes'
import CustomDropdown, {
  SelectableItem,
} from '../CustomDropdown/CustomDropdown'
import DateRangePicker from '../DateRangePicker/DateRangePicker'
import { checkIfDocumentsAreComplete, encodeS3URI } from '../../utils/functions'
import { ADJUSTANT_GREEN } from '../../themes/themes'
import { DOCUMENT_TYPE } from '../../types/jobTypes'
import { StateLicesesContext } from '../../contexts/StateLicesesContext'
import {
  ListedStateLicense,
  StateLicenseContextType,
  US_STATES_ABBREVIATIONS,
} from '../../types/stateLicensesTypes'
import { LOASContextType } from '../../types/loasTypes'
import { LOASContext } from '../../contexts/LOASContext'
import CustomDropdownMultiple from '../CustomDropdownMultiple/CustomDropdownMultiple'
import { TrainingCertificationsContext } from '../../contexts/TrainingCertificationsContext'
import { TrainingCertificationContextType } from '../../types/trainingCertificationsTypes'

export interface FileData {
  file: any
  fileName: string
}

export interface DocumentData extends FileData, LicenseCertification {
  oldUrl?: string
}

const UploadAdjusterLicensesOrCertifications = ({
  canCreate,
  canSetPrimary,
  label,
  inputLabel,
  placeHolder,
  uploadButtonLabel,
  addButtonLabel,
  createButtonLabel,
  documents,
  setDocuments,
  selectableItemsMapped,
  statesOptions,
  documentType,
}: {
  canCreate: boolean
  canSetPrimary?: boolean
  label: string
  inputLabel: string
  placeHolder: string
  uploadButtonLabel: string
  addButtonLabel: string
  createButtonLabel: string
  documents: DocumentData[]
  setDocuments?: React.Dispatch<React.SetStateAction<DocumentData[]>>
  selectableItemsMapped: SelectableItem[]
  statesOptions: SelectableItem[]
  documentType: DOCUMENT_TYPE
}) => {
  const { stateLicenses }: StateLicenseContextType =
    useContext(StateLicesesContext)
  const { trainingCertifications }: TrainingCertificationContextType =
    useContext(TrainingCertificationsContext)
  const { loas }: LOASContextType = useContext(LOASContext)

  const appendDocument = useCallback(
    (isListed: boolean) => {
      const emptyDocument: DocumentData = {
        name: '',
        url: '',
        file: undefined,
        fileName: '',
        id: '',
        state: '',
        loas: [],
        isListed,
        isPrimary: false,
        expiryDate: '',
        issuedDate: '',
      }
      if (setDocuments) setDocuments([...documents, emptyDocument])
    },
    [setDocuments, documents]
  )

  const deleteDocument = useCallback(
    (dIndex: number) => {
      documents.splice(dIndex, 1)
      if (setDocuments) setDocuments([...documents])
    },
    [setDocuments, documents]
  )

  const updateDocument = useCallback(
    (dIndex: number, doc: Partial<DocumentData>) => {
      documents[dIndex] = {
        ...documents[dIndex],
        ...doc,
      }
      if (setDocuments) setDocuments([...documents])
    },
    [setDocuments, documents]
  )

  const deselectPrimaryDocument = useCallback(() => {
    const index = documents.findIndex((d) => d.isPrimary)
    if (index >= 0) {
      documents[index] = {
        ...documents[index],
        isPrimary: false,
      }
    }

    if (setDocuments) setDocuments([...documents])
  }, [setDocuments, documents])

  const onFileInputChange = useCallback(
    async (fileFromFiles: any, dIndex: number) => {
      //   console.log('onFileInputChange', fileFromFiles, dIndex)
      if (fileFromFiles) {
        const url = URL.createObjectURL(fileFromFiles)
        updateDocument(dIndex, {
          file: fileFromFiles,
          url,
          fileName: fileFromFiles.name,
          name: documents[dIndex].name,
          approved: false,
        })
      }
    },
    [updateDocument, documents]
  )

  const deleteFile = useCallback(
    (dIndex: number) => {
      updateDocument(dIndex, {
        file: undefined,
        url: '',
        fileName: '',
        name: documents[dIndex].name,
        approved: false,
      })
    },
    [updateDocument, documents]
  )

  const getFilteredStateLicensesMapped = useCallback(
    (filteredStateLicenses?: ListedStateLicense[]): SelectableItem[] => {
      return (
        filteredStateLicenses
          ?.map((m) => ({
            value: m._id,
            label: m.name,
            disabled: !!documents.find((l) => l.id === m._id),
          }))
          ?.sort((a, b) => a.label.localeCompare(b.label)) ?? []
      )
    },
    [documents]
  )

  const trainingCertificationsMapped: SelectableItem[] = useMemo(() => {
    return (
      trainingCertifications
        ?.map((m) => ({
          value: m._id,
          label: m.name,
          disabled: !!documents.find((l) => l.id === m._id),
        }))
        ?.sort((a, b) => a.label.localeCompare(b.label)) ?? []
    )
  }, [documents, trainingCertifications])

  const getFilteredDocumentOptions = useCallback(
    (state: string): SelectableItem[] => {
      return documentType === DOCUMENT_TYPE.CERTIFICATION
        ? trainingCertificationsMapped
        : getFilteredStateLicensesMapped(
            stateLicenses?.filter((sl) =>
              (Object.keys(sl.states ?? {}) ?? []).includes(state)
            )
          )
    },
    [
      documentType,
      trainingCertificationsMapped,
      getFilteredStateLicensesMapped,
      stateLicenses,
    ]
  )

  const filterLoas = useCallback(
    (
      licenseId: string,
      state: US_STATES_ABBREVIATIONS | string
    ): SelectableItem[] => {
      if (!licenseId || !state) return []
      const licenseStates = stateLicenses?.find(
        (l) => l._id === licenseId
      )?.states
      if (!licenseStates) return []
      const stateLoas = licenseStates[state as US_STATES_ABBREVIATIONS]
      const filteredLoas = loas
        ?.filter((loa) => stateLoas?.includes(loa.code))
        ?.map((loa) => ({
          label: loa.name,
          //   value: loa.code,
          value: loa._id,
          //   id: loa._id,
        }))
      return filteredLoas ?? []
    },
    [loas, stateLicenses]
  )

  const isAddDocumentButtonDisabled = useMemo(() => {
    return checkIfDocumentsAreComplete(documents, canCreate)
  }, [canCreate, documents])

  return (
    <Box width="100%">
      <Flex mb={1}>
        <Label fontSize="sm" color="gray.700">
          {label}
        </Label>
      </Flex>

      {documents.map((d, dIndex) => (
        <HStack
          key={dIndex}
          mb={4}
          width="100%"
          alignItems="baseline"
          borderRadius="8px"
          backgroundColor="gray.50"
          px={1}
          py={2}
        >
          <Divider orientation="vertical" color="gray.500" />
          <VStack width="inherit">
            <Stack width="100%">
              <Stack>
                <Flex width="100%" justifyContent="space-between">
                  <Label fontSize="sm" color="gray.700">
                    State{' '}
                    {documentType === DOCUMENT_TYPE.CERTIFICATION
                      ? '(if applicable)'
                      : ''}
                  </Label>
                  {d?.source !== DATA_SOURCE.NIPR && (
                    <Box ml={2} cursor="pointer">
                      <BsFillTrashFill onClick={() => deleteDocument(dIndex)} />
                    </Box>
                  )}
                </Flex>
                <CustomDropdown
                  label=""
                  placeholder="Select a state"
                  options={statesOptions}
                  value={d.state}
                  handleChange={(state) => {
                    updateDocument(dIndex, {
                      ...d,
                      state,
                      id: '',
                      loas: [],
                      approved: false,
                    })
                  }}
                  isDisabled={d?.source === DATA_SOURCE.NIPR}
                />
              </Stack>

              {d.isListed ? (
                <CustomDropdown
                  isSearchable
                  label={inputLabel}
                  placeholder="Select an item"
                  options={getFilteredDocumentOptions(d.state)}
                  value={d.id}
                  handleChange={(selectedValue) => {
                    updateDocument(dIndex, {
                      ...d,
                      id: selectedValue,
                      loas: [],
                      approved: false,
                    })
                  }}
                  isDisabled={d?.source === DATA_SOURCE.NIPR}
                />
              ) : (
                <Input
                  type="text"
                  placeholder={placeHolder}
                  value={d.name}
                  onChange={(e) => {
                    updateDocument(dIndex, {
                      ...d,
                      name: e.target.value,
                      approved: false,
                    })
                  }}
                />
              )}

              {documentType === DOCUMENT_TYPE.LICENSE && (
                <CustomDropdownMultiple
                  label="Select LOAs"
                  value={
                    d.loas?.map((loaId) => ({
                      value: loaId,
                      label: loas?.find((loa) => loa._id === loaId)?.name ?? '',
                    })) ?? []
                  }
                  options={filterLoas(d.id, d.state)}
                  isClearable
                  onChange={(loas) => {
                    updateDocument(dIndex, {
                      ...d,
                      loas,
                      approved: false,
                    })
                  }}
                  isDisabled={d?.source === DATA_SOURCE.NIPR}
                />
              )}

              <DateRangePicker
                issuedDate={d.issuedDate}
                setIssuedDate={(n) => {
                  updateDocument(dIndex, {
                    ...d,
                    issuedDate: n,
                    approved: false,
                  })
                }}
                expiryDate={d.expiryDate}
                setExpiryDate={(n) => {
                  updateDocument(dIndex, {
                    ...d,
                    expiryDate: n,
                    approved: false,
                  })
                }}
                expiryDateIsOptional={canCreate}
                isDisabled={d?.source === DATA_SOURCE.NIPR}
              />

              {canSetPrimary && (
                <Checkbox
                  variant="adjustant"
                  mb={4}
                  isChecked={d.isPrimary}
                  onChange={(e) => {
                    if (e.target.checked) {
                      deselectPrimaryDocument()
                    }
                    updateDocument(dIndex, {
                      ...d,
                      isPrimary: e.target.checked,
                      //   approved: false,
                    })
                  }}
                >
                  Set as Primary
                </Checkbox>
              )}

              {d.source !== DATA_SOURCE.NIPR && (
                <>
                  {d.url ? (
                    <HStack>
                      <Link
                        color="teal.500"
                        href={encodeS3URI(d.url)}
                        isExternal
                      >
                        {d.fileName}
                      </Link>
                      <Box ml={2} cursor="pointer">
                        <AiOutlineClose onClick={() => deleteFile(dIndex)} />
                      </Box>
                    </HStack>
                  ) : (
                    <FileInput
                      element={
                        <Button
                          variant="adjustant"
                          // isDisabled={d.isListed ? !d.id : !d.name}
                          rightIcon={<AiOutlineFile />}
                          isDisabled={d?.source === DATA_SOURCE.NIPR}
                        >
                          {uploadButtonLabel}
                        </Button>
                      }
                      accept=".pdf"
                      // isDisabled={d.isListed ? !d.id : !d.name}
                      onFileInputChange={(e: any) =>
                        onFileInputChange(e.target?.files?.[0], dIndex)
                      }
                      isDisabled={d?.source === DATA_SOURCE.NIPR}
                    />
                  )}
                </>
              )}
            </Stack>
          </VStack>
        </HStack>
      ))}

      <Stack>
        <Button
          color={ADJUSTANT_GREEN}
          variant="link"
          leftIcon={<AiOutlinePlus />}
          mt={2}
          w="max-content"
          isDisabled={isAddDocumentButtonDisabled}
          onClick={() => appendDocument(true)}
        >
          {/* {windowsSize.width >= 768 ? addButtonLabel : 'Add'} */}
          <Text fontSize={[14, 14, 16]}> {addButtonLabel}</Text>
        </Button>

        {canCreate && (
          <Button
            color={ADJUSTANT_GREEN}
            variant="link"
            leftIcon={<AiOutlinePlus />}
            // mt={2}
            w="max-content"
            isDisabled={isAddDocumentButtonDisabled}
            onClick={() => appendDocument(false)}
          >
            {/* {windowsSize.width >= 768 ? createButtonLabel : 'Create'} */}
            <Text fontSize={[14, 14, 16]}>{createButtonLabel}</Text>
          </Button>
        )}
      </Stack>
    </Box>
  )
}

export default UploadAdjusterLicensesOrCertifications
