import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  Textarea,
} from '@chakra-ui/react'
import { useState } from 'react'

const SendMessageModal = ({
  header = 'Send a Message',
  isOpen,
  onClose,
}: {
  header?: string
  isOpen: boolean
  onClose: (message?: string) => void
}) => {
  const [message, setMessage] = useState('')

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      size="xl"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton
          onClick={() => {
            onClose()
            setMessage('')
          }}
        />
        <ModalBody>
          <Box>
            {/* <Flex mb={2}>
				<Label fontSize="sm" color="gray.600">
				  Message
				</Label>
			  </Flex> */}
            <Textarea
              placeholder=""
              resize="vertical"
              size="xl"
              rows={6}
              p={2}
              value={message}
              borderRadius={3}
              onChange={(e) => {
                setMessage(e.target.value)
              }}
            />
          </Box>
          {/* )} */}
        </ModalBody>
        <ModalFooter>
          <Button
            isDisabled={!message}
            variant="adjustant"
            mr={3}
            onClick={() => {
              onClose(message)

              setMessage('')
            }}
          >
            Send
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SendMessageModal
