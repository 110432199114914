import React, { useEffect, useMemo, useState } from 'react'
import { User, UserType } from '../../types/userTypes'
import UserCredentialsForm from '../../components/SignUp/UserCredentialsForm'
import CreatePassword from '../../components/SignUp/CreatePassword'
import ConfirmSignUp from '../../components/SignUp/ConfirmSignUp'
import { Box } from '@chakra-ui/react'
import { GET_ADMINS } from '../../graphql/Queries'
import { useLazyQuery } from '@apollo/client'

const SignUpWorkflow = () => {
  const [step, setStep] = useState<number>(1)
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [userType] = useState<UserType>(UserType.ADJUSTER)

  const [adminsRetrieved, setAdminsRetrieved] = useState(false)
  const [admins, setAdmins] = useState<User[]>([])
  const [getAdmins] = useLazyQuery(GET_ADMINS, {
    onCompleted: (data: { UserMany: User[] }) => {
      if (data?.UserMany) {
        const newUsers = [...data?.UserMany]
        setAdmins(newUsers)
      }
      setAdminsRetrieved(true)
    },
    onError: (err) => {
      console.log(err)
    },
  })

  useEffect(() => {
    if (!adminsRetrieved) getAdmins()
  }, [adminsRetrieved, getAdmins])

  const element = useMemo(() => {
    switch (step) {
      case 1:
        return (
          <UserCredentialsForm
            setStep={setStep}
            setFirstName={setFirstName}
            setLastName={setLastName}
            setEmail={setEmail}
            setPhoneNumber={setPhoneNumber}
            firstName={firstName}
            lastName={lastName}
            email={email}
            phoneNumber={phoneNumber}
          />
        )
      case 2:
        return (
          <CreatePassword
            email={email}
            password={password}
            setPassword={setPassword}
            userType={userType}
            setStep={setStep}
          />
        )
      case 3:
        return (
          <ConfirmSignUp
            firstName={firstName}
            lastName={lastName}
            email={email}
            phoneNumber={phoneNumber}
            password={password}
            userType={userType}
            admins={admins}
          />
        )
      default:
        break
    }
  }, [
    step,
    firstName,
    lastName,
    email,
    phoneNumber,
    password,
    userType,
    admins,
  ])

  return (
    <Box
      minHeight="100%"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {element}
    </Box>
  )
}

export default SignUpWorkflow
