import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
  UserContextType,
  DriverLicense,
  UserType,
  User,
  TaxForm,
  MiscelaneosDocument,
} from '../../types/userTypes'
import {
  CREATE_NOTIFICATION,
  UPDATE_USER_BY_EMAIL,
} from '../../graphql/Mutations'
import { UserContext } from '../../contexts/UserContext'
import { getFileNameFromURL, storeFile } from '../../utils/functions'
import { changePassword, loginUser } from '../../services/adjustant'
import { useLazyQuery, useMutation } from '@apollo/client'
import CropImage from '../../components/CropImage/CropImage'
import {
  LOCALSTORAGE_OBJECTS_NAMES,
  getItemFromLocalStorage,
  setItemToLocalStorage,
} from '../../utils/localStorageFunctions'
import AddressInput from '../../components/AddressInput/AddressInput'
import PasswordStrength, {
  PasswordStrengthType,
} from '../../components/PasswordStrength/PasswordStrength'
import FileInput from '../../components/FileInput/FileInput'
import UpdateDriverLicense from '../../components/UpdateDriverLicense/UpdateDriverLicense'

import { showSuccessToast } from '../../components/Toast/Toast'

import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Input,
  Spinner,
  Stack,
  Textarea,
  Text,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Image,
} from '@chakra-ui/react'
import Label from '../../components/Label/Label'
import { DamageType, jobTypeOptions } from '../../types/jobTypes'
import { AiOutlineCamera } from 'react-icons/ai'
import { ADJUSTANT_GREEN } from '../../themes/themes'
import { useNavigate, useParams } from 'react-router-dom'
import { ADMIN_ROUTES, GLOBAL_ROUTES } from '../../App'
import { GET_ADMINS, GET_USER_BY_ID } from '../../graphql/Queries'
import { BiArrowBack } from 'react-icons/bi'
import CustomDropdownMultiple from '../../components/CustomDropdownMultiple/CustomDropdownMultiple'
import { SelectableItem } from '../../components/CustomDropdown/CustomDropdown'
import {
  US_STATES,
  US_STATES_ABBREVIATIONS,
} from '../../types/stateLicensesTypes'
import UpdateMiscellaneosDocument from '../../components/UpdateMiscellaneosDocument/UpdateMiscellaneosDocument'
import UpdateTaxForm from '../../components/UpdateTaxForm/UpdateTaxForm'
import {
  W9_FORM_URL,
  I9_FORM_URL,
  W4_FORM_URL,
} from '../../constants/constants'

function UpdateProfileContent({
  user,
  editorIsAdmin,
}: {
  user?: User
  editorIsAdmin: boolean
}) {
  const navigate = useNavigate()
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const { setUser }: UserContextType = useContext(UserContext)
  const [updateUserMutation] = useMutation(UPDATE_USER_BY_EMAIL(user?.email))
  const [createNotificationMutation] = useMutation(CREATE_NOTIFICATION)
  const [admins, setAdmins] = useState<User[]>([])

  const [getAdmins] = useLazyQuery(GET_ADMINS, {
    onCompleted: (data: { UserMany: User[] }) => {
      setShowLoader(false)
      if (data?.UserMany) {
        const newUsers = [...data?.UserMany]
        setAdmins(newUsers)
      }
    },
    onError: (err) => {
      setShowLoader(false)
      console.log(err)
    },
  })

  const [profilePicture, setProfilePicture] = useState(
    user?.profilePicture ?? ''
  )
  const [profileImageFileHasChanged, setProfileImageFileHasChanged] =
    useState<boolean>(false)
  const [profileImageFile, setProfileImageFile] = useState<any>()
  const [profileImageFileName, setProfileImageFileName] = useState<string>(
    getFileNameFromURL(profilePicture)
  )

  const [bannerPicture, setBannerPicture] = useState(user?.bannerPicture ?? '')
  const [bannerImageFileHasChanged, setBannerImageFileHasChanged] =
    useState<boolean>(false)
  const [bannerImageFile, setBannerImageFile] = useState<any>()
  const [bannerImageFileName, setBannerImageFileName] = useState<string>(
    getFileNameFromURL(bannerPicture)
  )

  const [bio, setBio] = useState(user?.bio ?? '')
  const [yearsOfLicensedExperience, setYearsOfLicensedExperience] = useState(
    user?.yearsOfLicensedExperience ?? 0
  )
  const [location, setLocation] = useState(user?.location ?? '')
  const [jobLocations, setJobLocations] = useState(user?.jobLocations ?? [])
  const [jobTypes, setJobTypes] = useState(user?.jobTypes ?? [])
  const [firstName, setFirstName] = useState(user?.firstName ?? '')
  const [lastName, setLastName] = useState(user?.lastName ?? '')
  const [firmName, setFirmName] = useState(user?.firmName ?? '')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [languagePreference, setLanguagePreference] = useState(
    user?.languagePreference ?? ''
  )
  const [emergencyContact, setEmergencyContact] = useState(
    user?.emergencyContact ?? ''
  )
  const [xactimateId, setXactimateId] = useState(user?.xactimateId ?? '')
  const [companyAlias, setCompanyAlias] = useState(user?.companyAlias ?? '')
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber ?? '')

  const [driverLicenseId, setDriverLicenseId] = useState(
    user?.driverLicense?.id ?? ''
  )
  const [driverLicenseAddress, setDriverLicenseAddress] = useState(
    user?.driverLicense?.address ?? ''
  )
  const [driverLicenseCity, setDriverLicenseCity] = useState(
    user?.driverLicense?.city ?? ''
  )
  const [driverLicenseState, setDriverLicenseState] = useState(
    user?.driverLicense?.state ?? ''
  )
  const [driverLicenseZipcode, setDriverLicenseZipcode] = useState(
    user?.driverLicense?.zipcode ?? ''
  )
  const [driverLicenseBirthDate, setDriverLicenseBirthDate] = useState(
    user?.driverLicense?.birthDate ?? ''
  )
  const [driverLicenseURL, setDriverLicenseURL] = useState(
    user?.driverLicense?.url ?? ''
  )
  const [driverLicenseFileHasChanged, setDriverLicenseFileHasChanged] =
    useState<boolean>(false)
  const [driverLicenseFile, setDriverLicenseFile] = useState<any>()
  const [driverLicensefileName, setDriverLicensefileName] = useState<string>(
    getFileNameFromURL(user?.driverLicense?.url)
  )

  const [w9TaxFormURL, setW9TaxFormURL] = useState(user?.w9TaxForm?.url ?? '')
  const [w9TaxFormFileHasChanged, setW9TaxFormFileHasChanged] =
    useState<boolean>(false)
  const [w9TaxFormFile, setW9TaxFormFile] = useState<any>()
  const [w9TaxFormfileName, setW9TaxFormfileName] = useState<string>(
    getFileNameFromURL(user?.w9TaxForm?.url)
  )

  const [w4TaxFormURL, setW4TaxFormURL] = useState(user?.w4TaxForm?.url ?? '')
  const [w4TaxFormFileHasChanged, setW4TaxFormFileHasChanged] =
    useState<boolean>(false)
  const [w4TaxFormFile, setW4TaxFormFile] = useState<any>()
  const [w4TaxFormfileName, setW4TaxFormfileName] = useState<string>(
    getFileNameFromURL(user?.w4TaxForm?.url)
  )

  const [i9TaxFormURL, setI9TaxFormURL] = useState(user?.i9TaxForm?.url ?? '')
  const [i9TaxFormFileHasChanged, setI9TaxFormFileHasChanged] =
    useState<boolean>(false)
  const [i9TaxFormFile, setI9TaxFormFile] = useState<any>()
  const [i9TaxFormfileName, setI9TaxFormfileName] = useState<string>(
    getFileNameFromURL(user?.i9TaxForm?.url)
  )

  const [secondaryFormOfIDURL, setSecondaryFormOfIDURL] = useState(
    user?.secondaryFormOfID?.url ?? ''
  )
  const [secondaryFormOfIDFileHasChanged, setSecondaryFormOfIDFileHasChanged] =
    useState<boolean>(false)
  const [secondaryFormOfIDFile, setSecondaryFormOfIDFile] = useState<any>()
  const [secondaryFormOfIDFileName, setSecondaryFormOfIDFileName] =
    useState<string>(getFileNameFromURL(user?.secondaryFormOfID?.url))

  const [miscelaneosDocumentURL, setMiscelaneosDocumentURL] = useState(
    user?.miscelaneosDocument?.url ?? ''
  )
  const [
    miscelaneosDocumentFileHasChanged,
    setMiscelaneosDocumentFileHasChanged,
  ] = useState<boolean>(false)
  const [miscelaneosDocumentFile, setMiscelaneosDocumentFile] = useState<any>()
  const [miscelaneosDocumentFileName, setMiscelaneosDocumentFileName] =
    useState<string>(getFileNameFromURL(user?.miscelaneosDocument?.url))

  const [miscelaneosDocumentDescription, setMiscelaneosDocumentDescription] =
    useState<string>('')

  const [passwordStrength, setPasswordStrength] =
    useState<PasswordStrengthType>()
  const [passwordError, setPasswordError] = useState<boolean>(false)
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>('')

  const [isCropImageModalOpen, setIsCropImageModalOpen] = useState(false)

  const statesOptions: SelectableItem[] = US_STATES.map((s) => {
    return { value: s.abbreviation, label: s.name }
  })

  const onProfilePictureFileInputChange = useCallback(async (e: any) => {
    const fileFromFiles = e.target.files[0]
    if (fileFromFiles) {
      setProfileImageFileHasChanged(true)
      setProfileImageFile(fileFromFiles)
      setProfileImageFileName(fileFromFiles.name)
      const url = URL.createObjectURL(fileFromFiles)
      setProfilePicture(url)
      setIsCropImageModalOpen(true)
    }
  }, [])

  const onBannerPictureFileInputChange = useCallback(async (e: any) => {
    const fileFromFiles = e.target.files[0]
    if (fileFromFiles) {
      setBannerImageFileHasChanged(true)
      setBannerImageFile(fileFromFiles)
      setBannerImageFileName(fileFromFiles.name)
      const url = URL.createObjectURL(fileFromFiles)
      setBannerPicture(url)
      // setIsCropImageModalOpen(true)
    }
  }, [])

  const updateUser = useCallback(
    async (partialUser?: Partial<User>): Promise<boolean> => {
      if (user) {
        console.log('PROFILE TO UPDATE: ', partialUser)
        const result = await updateUserMutation({
          variables: { user: partialUser },
        })

        if (result?.data) {
          if (!editorIsAdmin) {
            const newUser = { ...user, ...partialUser }
            setUser(newUser)
            setItemToLocalStorage(LOCALSTORAGE_OBJECTS_NAMES.USER, newUser)
          }
          return true
        }
      }
      return false
    },
    [user, updateUserMutation, editorIsAdmin, setUser]
  )

  const canUpdateUserDetails = useMemo(() => {
    if (user?.userType === UserType.ADJUSTER) {
      return (
        firstName !== user?.firstName ||
        lastName !== user?.lastName ||
        yearsOfLicensedExperience !== user?.yearsOfLicensedExperience ||
        // optional fields
        bio !== (user?.bio ?? '') ||
        location !== (user?.location ?? '') ||
        languagePreference !== (user?.languagePreference ?? '') ||
        emergencyContact !== (user?.emergencyContact ?? '') ||
        xactimateId !== (user?.xactimateId ?? '') ||
        companyAlias !== (user?.companyAlias ?? '') ||
        phoneNumber !== (user?.phoneNumber ?? '') ||
        profileImageFileHasChanged ||
        bannerImageFileHasChanged
      )
    } else {
      //FIRMS
      return (
        firmName !== user?.firmName ||
        bio !== user?.bio ||
        location !== user?.location ||
        profileImageFileHasChanged ||
        bannerImageFileHasChanged
      )
    }
  }, [
    bannerImageFileHasChanged,
    bio,
    companyAlias,
    emergencyContact,
    firmName,
    firstName,
    languagePreference,
    lastName,
    location,
    phoneNumber,
    profileImageFileHasChanged,
    user?.bio,
    user?.companyAlias,
    user?.emergencyContact,
    user?.firmName,
    user?.firstName,
    user?.languagePreference,
    user?.lastName,
    user?.location,
    user?.phoneNumber,
    user?.userType,
    user?.xactimateId,
    user?.yearsOfLicensedExperience,
    xactimateId,
    yearsOfLicensedExperience,
  ])

  const updateUserDetails = useCallback(async () => {
    if (user) {
      setShowLoader(true)
      const profileImageResponse = await storeFile(
        profileImageFile,
        profileImageFileName,
        user
      )
      const bannerImageResponse = await storeFile(
        bannerImageFile,
        bannerImageFileName,
        user
      )
      const partialUser: Partial<User> = {
        yearsOfLicensedExperience,
        bio,
        location,
        profilePicture:
          profileImageFile && profileImageFileName
            ? profileImageResponse?.data
            : profileImageFileName
            ? profilePicture
            : '',
        bannerPicture:
          bannerImageFile && bannerImageFileName
            ? bannerImageResponse?.data
            : bannerImageFileName
            ? bannerPicture
            : '',
        firstName,
        lastName,
        firmName,
        languagePreference,
        emergencyContact,
        xactimateId,
        companyAlias,
        phoneNumber,
        profileSet: true,
      }
      const result = await updateUser(partialUser)
      if (result) {
        setProfileImageFileHasChanged(false)
        showSuccessToast('Profile Updated!')
      }
      setShowLoader(false)
    }
  }, [
    user,
    profileImageFile,
    profileImageFileName,
    bannerImageFile,
    bannerImageFileName,
    yearsOfLicensedExperience,
    bio,
    location,
    profilePicture,
    bannerPicture,
    firstName,
    lastName,
    firmName,
    languagePreference,
    emergencyContact,
    xactimateId,
    companyAlias,
    phoneNumber,
    updateUser,
  ])

  const canUpdateJobPreferences = useMemo(() => {
    return (
      JSON.stringify(jobLocations) !== JSON.stringify(user?.jobLocations) ||
      JSON.stringify(jobTypes) !== JSON.stringify(user?.jobTypes)
    )
  }, [jobLocations, jobTypes, user?.jobLocations, user?.jobTypes])

  const updateJobPreferences = useCallback(async () => {
    setShowLoader(true)
    const partialUser: Partial<User> = {
      jobLocations,
      jobTypes,
      profileSet: true,
    }
    const result = await updateUser(partialUser)
    if (result) showSuccessToast('Job Preferences Updated!')
    setShowLoader(false)
  }, [setShowLoader, updateUser, jobLocations, jobTypes])

  const notifyAdmins = useCallback(
    async (message: string, userId: string, queryParams: string) => {
      admins.map((admin) => {
        createNotificationMutation({
          variables: {
            notification: {
              userId: admin?._id,
              message,
              link: `${GLOBAL_ROUTES.ADMIN}${GLOBAL_ROUTES.PROFILE}/${userId}${queryParams}`,
              read: false,
            },
          },
        })

        return null
      })
    },
    [admins, createNotificationMutation]
  )

  const canUpdateDriversLicense = useMemo(() => {
    return (
      driverLicenseId !== user?.driverLicense?.id ||
      driverLicenseAddress !== user?.driverLicense?.address ||
      driverLicenseCity !== user?.driverLicense?.city ||
      driverLicenseState !== user?.driverLicense?.state ||
      driverLicenseZipcode !== user?.driverLicense?.zipcode ||
      driverLicenseBirthDate !== user?.driverLicense?.birthDate ||
      driverLicenseFileHasChanged
    )
  }, [
    driverLicenseAddress,
    driverLicenseBirthDate,
    driverLicenseCity,
    driverLicenseFileHasChanged,
    driverLicenseId,
    driverLicenseState,
    driverLicenseZipcode,
    user?.driverLicense?.address,
    user?.driverLicense?.birthDate,
    user?.driverLicense?.city,
    user?.driverLicense?.id,
    user?.driverLicense?.state,
    user?.driverLicense?.zipcode,
  ])

  const updateDriversLicense = useCallback(async () => {
    if (user?._id) {
      setShowLoader(true)
      const respose = await storeFile(
        driverLicenseFile,
        driverLicensefileName,
        user
      )
      const newLicenseData: DriverLicense = {
        id: driverLicenseId,
        address: driverLicenseAddress,
        city: driverLicenseCity,
        state: driverLicenseState,
        zipcode: driverLicenseZipcode,
        birthDate: driverLicenseBirthDate,
        url:
          driverLicenseFile && driverLicensefileName
            ? respose?.data
            : driverLicensefileName
            ? user?.driverLicense?.url
            : '',
        approved: false,
      }
      const result = await updateUser({ driverLicense: newLicenseData })
      if (result) {
        setDriverLicenseFileHasChanged(false)
        const param: keyof User = 'driverLicense'
        showSuccessToast('Driver Licensed Updated!')
        notifyAdmins(
          `The Adjuster ${user.firstName} ${user.lastName} has updated his Driver's License`,
          user._id,
          `?section=${param}`
        )
      }
      setShowLoader(false)
    }
  }, [
    user,
    driverLicenseFile,
    driverLicensefileName,
    driverLicenseId,
    driverLicenseAddress,
    driverLicenseCity,
    driverLicenseState,
    driverLicenseZipcode,
    driverLicenseBirthDate,
    updateUser,
    notifyAdmins,
  ])

  const canUpdateW9TaxForm = useMemo(() => {
    return w9TaxFormFileHasChanged
  }, [w9TaxFormFileHasChanged])

  const updateW9TaxForm = useCallback(async () => {
    if (user?._id) {
      setShowLoader(true)
      const respose = await storeFile(w9TaxFormFile, w9TaxFormfileName, user)
      const neWw9Form: TaxForm = {
        url:
          w9TaxFormFile && w9TaxFormfileName
            ? respose?.data
            : w9TaxFormfileName
            ? user?.w9TaxForm?.url
            : '',
        approved: false,
      }
      const result = await updateUser({ w9TaxForm: neWw9Form })
      if (result) {
        setW9TaxFormFileHasChanged(false)
        const param: keyof User = 'w9TaxForm'
        showSuccessToast('W9 Tax Form Updated!')
        notifyAdmins(
          `The Adjuster ${user.firstName} ${user.lastName} has updated his W9 Tax Form`,
          user._id,
          `?section=${param}`
        )
      }
      setShowLoader(false)
    }
  }, [user, w9TaxFormFile, w9TaxFormfileName, updateUser, notifyAdmins])

  const canUpdateW4TaxForm = useMemo(() => {
    return w4TaxFormFileHasChanged
  }, [w4TaxFormFileHasChanged])

  const updateW4TaxForm = useCallback(async () => {
    if (user?._id) {
      setShowLoader(true)
      const respose = await storeFile(w4TaxFormFile, w4TaxFormfileName, user)
      const neW4Form: TaxForm = {
        url:
          w4TaxFormFile && w4TaxFormfileName
            ? respose?.data
            : w4TaxFormfileName
            ? user?.w4TaxForm?.url
            : '',
        approved: false,
      }
      const result = await updateUser({ w4TaxForm: neW4Form })
      if (result) {
        setW4TaxFormFileHasChanged(false)
        const param: keyof User = 'w4TaxForm'
        showSuccessToast('W4 Tax Form Updated!')
        notifyAdmins(
          `The Adjuster ${user.firstName} ${user.lastName} has updated his W4 Tax Form`,
          user._id,
          `?section=${param}`
        )
      }
      setShowLoader(false)
    }
  }, [user, w4TaxFormFile, w4TaxFormfileName, updateUser, notifyAdmins])

  const canUpdateI9TaxForm = useMemo(() => {
    return i9TaxFormFileHasChanged
  }, [i9TaxFormFileHasChanged])

  const updateI9TaxForm = useCallback(async () => {
    if (user?._id) {
      setShowLoader(true)

      const respose = await storeFile(i9TaxFormFile, i9TaxFormfileName, user)
      const neWI9Form: TaxForm = {
        url:
          i9TaxFormFile && i9TaxFormfileName
            ? respose?.data
            : i9TaxFormfileName
            ? user?.i9TaxForm?.url
            : '',
        approved: false,
      }
      const result = await updateUser({ i9TaxForm: neWI9Form })
      if (result) {
        setI9TaxFormFileHasChanged(false)
        showSuccessToast('I9 Tax Form Updated!')
        const param: keyof User = 'i9TaxForm'
        notifyAdmins(
          `The Adjuster ${user.firstName} ${user.lastName} has updated his I9 Tax Form`,
          user._id,
          `?section=${param}`
        )
      }
      setShowLoader(false)
    }
  }, [user, i9TaxFormFile, i9TaxFormfileName, updateUser, notifyAdmins])

  const canUpdateSecondaryFormOfID = useMemo(() => {
    return secondaryFormOfIDFileHasChanged
  }, [secondaryFormOfIDFileHasChanged])

  const updateSecondaryFormOfID = useCallback(async () => {
    if (user?._id) {
      setShowLoader(true)
      const respose = await storeFile(
        secondaryFormOfIDFile,
        secondaryFormOfIDFileName,
        user
      )
      const newAecondaryFormOfID: TaxForm = {
        url:
          secondaryFormOfIDFile && secondaryFormOfIDFileName
            ? respose?.data
            : secondaryFormOfIDFileName
            ? user?.secondaryFormOfID?.url
            : '',
        approved: false,
      }
      const result = await updateUser({
        secondaryFormOfID: newAecondaryFormOfID,
      })
      if (result) {
        setSecondaryFormOfIDFileHasChanged(false)
        showSuccessToast('Secondary Form Of ID Tax Form Updated!')
        const param: keyof User = 'secondaryFormOfID'
        notifyAdmins(
          `The Adjuster ${user.firstName} ${user.lastName} has updated his Secondary Form Of ID`,
          user._id,
          `?section=${param}`
        )
      }
      setShowLoader(false)
    }
  }, [
    user,
    secondaryFormOfIDFile,
    secondaryFormOfIDFileName,
    updateUser,
    notifyAdmins,
  ])

  const canUpdateMiscellaneousDocument = useMemo(() => {
    return (
      miscelaneosDocumentDescription !==
        user?.miscelaneosDocument?.description ||
      miscelaneosDocumentFileHasChanged
    )
  }, [
    miscelaneosDocumentDescription,
    miscelaneosDocumentFileHasChanged,
    user?.miscelaneosDocument?.description,
  ])

  const updateMiscellaneousDocument = useCallback(async () => {
    if (user?._id) {
      setShowLoader(true)
      const respose = await storeFile(
        miscelaneosDocumentFile,
        miscelaneosDocumentFileName,
        user
      )
      const newMiscellaneousDocument: MiscelaneosDocument = {
        url:
          miscelaneosDocumentFile && miscelaneosDocumentFileName
            ? respose?.data
            : miscelaneosDocumentFileName
            ? user?.miscelaneosDocument?.url
            : '',
        description: miscelaneosDocumentDescription,
        approved: false,
      }
      const result = await updateUser({
        miscelaneosDocument: newMiscellaneousDocument,
      })
      if (result) {
        setMiscelaneosDocumentFileHasChanged(false)
        showSuccessToast('Miscellaneous Document Updated!')
        const param: keyof User = 'miscelaneosDocument'
        notifyAdmins(
          `The Adjuster ${user.firstName} ${user.lastName} has updated his Miscellaneous Document`,
          user._id,
          `?section=${param}`
        )
      }
      setShowLoader(false)
    }
  }, [
    user,
    miscelaneosDocumentFile,
    miscelaneosDocumentFileName,
    miscelaneosDocumentDescription,
    updateUser,
    notifyAdmins,
  ])

  const getUserAccessToken = useCallback(async (): Promise<string> => {
    if (user) {
      if (!editorIsAdmin)
        return (
          getItemFromLocalStorage(LOCALSTORAGE_OBJECTS_NAMES.ACCESS_TOKEN) ?? ''
        )
      else {
        const responseData = await loginUser({
          email: user?.email ?? '',
          userType: user?.userType,
          password: user?.password,
        })

        return responseData?.data?.AccessToken ?? ''
      }
    }
    return ''
  }, [editorIsAdmin, user])

  const updatePassword = useCallback(async () => {
    if (!passwordError && user) {
      setShowLoader(true)

      const accessToken = await getUserAccessToken()
      const response = await changePassword({
        email: user?.email ?? '',
        userType: user?.userType,
        newPassword: password,
        oldPassword: user?.password,
        accessToken,
      })
      if (response.success) {
        const result = await updateUser({ password })
        if (result) showSuccessToast('Password Updated!')
      } else {
        setPasswordErrorMessage(response.message)
      }
      setShowLoader(false)
    }
  }, [passwordError, user, getUserAccessToken, password, updateUser])

  useEffect(() => {
    if (confirmPassword !== password) {
      setPasswordErrorMessage('Passwords must be the same !')
      setPasswordError(true)
    } else {
      setPasswordErrorMessage('')
      setPasswordError(false)
    }
  }, [password, confirmPassword, setPasswordErrorMessage, setPasswordError])

  useEffect(() => {
    getAdmins()
  }, [getAdmins])

  return (
    <HStack paddingX={['10px', '50px']} paddingY="10px" justifyContent="center">
      <Box w="4xl">
        {/* Profile Details */}
        <Box
          py={10}
          px={8}
          background="white"
          borderRadius={8}
          border="1px solid #ECEAE9"
          mb={2}
        >
          <Heading
            as="h3"
            fontSize={['3xl', '4xl']}
            color="#6D6E6F"
            mb={8}
            textAlign={['start', 'center']}
          >
            <Box mb={2}>
              <BiArrowBack
                cursor="pointer"
                onClick={() =>
                  navigate(
                    `${editorIsAdmin ? GLOBAL_ROUTES.ADMIN : ''}${
                      GLOBAL_ROUTES.PROFILE
                    }${editorIsAdmin ? '/' + user?._id : ''}`
                  )
                }
              />
            </Box>
            Profile Details
          </Heading>

          {/* Prolfile Picture */}
          <Box mb={4}>
            <Flex mb={1}>
              <Label fontSize="sm" color="gray.700">
                Upload a Profile Picture
              </Label>
            </Flex>
            <Box
              position="relative"
              // display="flex" justifyContent="center"
            >
              <Box height={24} width={24}>
                <Avatar
                  size="full"
                  src={profilePicture || '/images/no_profile_picture.png'}
                />
              </Box>
              <Box position="absolute" bottom={2} left={16}>
                <FileInput
                  element={
                    <Box
                      backgroundColor={ADJUSTANT_GREEN}
                      color="white"
                      borderRadius="50%"
                      p={1}
                    >
                      <AiOutlineCamera />
                    </Box>
                  }
                  accept=".png,.jpeg,.jpg"
                  onFileInputChange={onProfilePictureFileInputChange}
                />
              </Box>
            </Box>
            <CropImage
              isModalOpen={isCropImageModalOpen}
              setIsModelOpen={setIsCropImageModalOpen}
              imageURL={profilePicture}
              onCrop={(croppedFile) => {
                if (croppedFile) {
                  setProfileImageFile(croppedFile)
                  const url = URL.createObjectURL(croppedFile)
                  setProfilePicture(url)
                }
              }}
            />
          </Box>

          {/* Banner */}
          <Box mb={4}>
            <Flex mb={1}>
              <Label fontSize="sm" color="gray.700">
                Upload a Banner Picture
              </Label>
            </Flex>
            <Box
              position="relative"
              // display="flex" justifyContent="center"
            >
              <Box
                height="180px"
                width="100%"
                background={`linear-gradient(91.49deg, #68A5A1 0%, ${ADJUSTANT_GREEN} 100%)`}
              >
                {bannerPicture && (
                  <Image w="100%" h="100%" src={bannerPicture} />
                )}
              </Box>
              <Box position="absolute" bottom={-1} right={-1}>
                <FileInput
                  element={
                    <Box
                      backgroundColor={ADJUSTANT_GREEN}
                      color="white"
                      borderRadius="50%"
                      p={1}
                    >
                      <AiOutlineCamera />
                    </Box>
                  }
                  accept=".png,.jpeg,.jpg"
                  onFileInputChange={onBannerPictureFileInputChange}
                />
              </Box>
            </Box>
            {/* <CropImage
              isModalOpen={isCropImageModalOpen}
              setIsModelOpen={setIsCropImageModalOpen}
              imageURL={bannerPicture}
              onCrop={(croppedFile) => {
                if (croppedFile) {
                  setBannerImageFile(croppedFile)
                  const url = URL.createObjectURL(croppedFile)
                  setBannerPicture(url)
                }
              }}
            /> */}
          </Box>

          {/* First and last name */}
          {user?.userType === UserType.ADJUSTER && (
            <Box mb={4}>
              <Box mb={4}>
                <Flex mb={1}>
                  <Label fontSize="sm" color="gray.700">
                    First Name
                  </Label>
                </Flex>
                <Input
                  value={firstName}
                  borderRadius={3}
                  onChange={(e) => {
                    setFirstName(e.target.value)
                  }}
                />
              </Box>
              <Box>
                <Flex mb={1}>
                  <Label fontSize="sm" color="gray.700">
                    Last Name
                  </Label>
                </Flex>
                <Input
                  value={lastName}
                  borderRadius={3}
                  onChange={(e) => {
                    setLastName(e.target.value)
                  }}
                />
              </Box>
            </Box>
          )}

          {/* Firmname-Location */}
          {user?.userType === UserType.FIRM && (
            <Box mb={4}>
              <Flex mb={1}>
                <Label fontSize="sm" color="gray.700">
                  Firm&apos;s Name
                </Label>
              </Flex>
              <Input
                type="text"
                placeholder=""
                value={firmName}
                onChange={(e) => {
                  if (setFirmName) setFirmName(e.target.value)
                }}
              />
            </Box>
          )}

          {/* Years of Licensed Experience */}
          {user?.userType === UserType.ADJUSTER && (
            <Box mb={4}>
              <Flex mb={1}>
                <Label fontSize="sm" color="gray.700">
                  Years of Licensed Experience
                </Label>
              </Flex>
              <NumberInput
                mb={4}
                value={yearsOfLicensedExperience}
                onChange={(e) => {
                  setYearsOfLicensedExperience(+e)
                }}
                min={0}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Box>
          )}

          {/* Bio */}
          <Box mb={4}>
            <Flex mb={1}>
              <Label fontSize="sm" color="gray.700">
                {user?.userType === UserType.ADJUSTER
                  ? 'Describe your Adjusting Experience'
                  : 'Bio'}{' '}
                (Optional)
              </Label>
            </Flex>
            <Textarea
              //   placeholder="Tell us about yourself. This will be displayed to firms with the rest of your profile to help them understand who they’re hiring."
              resize="vertical"
              size="xl"
              p={2}
              value={bio}
              borderRadius={3}
              onChange={(e) => {
                setBio(e.target.value)
              }}
            />
          </Box>

          {/* Location */}
          <Box mb={4} zIndex={2}>
            <AddressInput
              isRequired
              label="Location (Optional)"
              placeholder="e.g. 718 S. Main St."
              address={location}
              onAddressChange={setLocation}
              onSelectOption={setLocation}
              handleAddressClear={() => {
                setLocation('')
              }}
            />
          </Box>

          {user?.userType === UserType.ADJUSTER && (
            <>
              {/* Language preference */}
              <Box mb={4}>
                <Flex mb={1}>
                  <Label fontSize="sm" color="gray.700">
                    Language Preference (optional)
                  </Label>
                </Flex>
                <Input
                  value={languagePreference}
                  borderRadius={3}
                  onChange={(e) => {
                    setLanguagePreference(e.target.value)
                  }}
                />
              </Box>

              {/* Emergency Contact */}
              <Box mb={4}>
                <Flex mb={1}>
                  <Label fontSize="sm" color="gray.700">
                    Emergency Contact (optional)
                  </Label>
                </Flex>
                <Input
                  value={emergencyContact}
                  borderRadius={3}
                  onChange={(e) => {
                    setEmergencyContact(e.target.value)
                  }}
                />
              </Box>

              {/* Xactimate ID */}
              <Box mb={4}>
                <Flex mb={1}>
                  <Label fontSize="sm" color="gray.700">
                    Xactimate ID (optional)
                  </Label>
                </Flex>
                <Input
                  value={xactimateId}
                  borderRadius={3}
                  onChange={(e) => {
                    setXactimateId(e.target.value)
                  }}
                />
              </Box>

              {/* Industry/company Alias */}
              <Box mb={4}>
                <Flex mb={1}>
                  <Label fontSize="sm" color="gray.700">
                    Industry/company Alias (optional)
                  </Label>
                </Flex>
                <Input
                  value={companyAlias}
                  borderRadius={3}
                  onChange={(e) => {
                    setCompanyAlias(e.target.value)
                  }}
                />
              </Box>

              {/* Phone number */}
              <Box mb={4}>
                <Flex mb={1}>
                  <Label fontSize="sm" color="gray.700">
                    Phone Number (optional)
                  </Label>
                </Flex>
                <Input
                  value={phoneNumber}
                  borderRadius={3}
                  onChange={(e) => {
                    setPhoneNumber(e.target.value)
                  }}
                />
              </Box>
            </>
          )}

          <HStack justify="center" mt={8}>
            <Button
              variant="adjustant"
              isLoading={showLoader}
              isDisabled={!canUpdateUserDetails}
              onClick={updateUserDetails}
            >
              Update
            </Button>
          </HStack>
        </Box>

        {/* Job Preferences */}
        {user?.userType === UserType.ADJUSTER && (
          <Box
            py={10}
            px={8}
            background="white"
            borderRadius={8}
            border="1px solid #ECEAE9"
            mb={2}
          >
            <Heading
              as="h3"
              fontSize={['3xl', '4xl']}
              color="#6D6E6F"
              mb={8}
              textAlign={['start', 'center']}
            >
              Job Preferences
            </Heading>
            <Box mb={4} zIndex={2}>
              {/* <AddressInput
                isRequired
                label="Job Location (Optional)"
                placeholder="e.g. 718 S. Main St."
                field="region"
                address={jobLocation}
                onAddressChange={setJobLocation}
                onSelectOption={setJobLocation}
                handleAddressClear={() => {
                  setJobLocation('')
                }}
              /> */}

              {/* <CustomDropdown
                label="Job Location (Optional)"
                placeholder="Select a state"
                options={statesOptions}
                value={jobLocations}
                textAlign="start"
                handleChange={(state) => {
                  setJobLocations(state)
                }}
              /> */}

              <CustomDropdownMultiple
                label="Job Locations (Optional)"
                value={
                  jobLocations?.map((jl) => {
                    const label =
                      statesOptions.find((sp) => sp.value === jl)?.label ?? ''
                    return {
                      label,
                      value: jl,
                    }
                  }) ?? []
                }
                options={statesOptions}
                isClearable
                onChange={(a) =>
                  setJobLocations(a as US_STATES_ABBREVIATIONS[])
                }
              />
            </Box>
            <Box>
              <CustomDropdownMultiple
                label="Job Types (Optional)"
                value={
                  jobTypes?.map((jt) => {
                    const label =
                      jobTypeOptions.find((jtp) => jtp.value === jt)?.label ??
                      ''
                    return {
                      label,
                      value: jt,
                    }
                  }) ?? []
                }
                options={jobTypeOptions}
                isClearable
                onChange={(a) => setJobTypes(a as DamageType[])}
              />
            </Box>
            <HStack justify="center" mt={8}>
              <Button
                variant="adjustant"
                isLoading={showLoader}
                isDisabled={!canUpdateJobPreferences}
                onClick={updateJobPreferences}
              >
                Update
              </Button>
            </HStack>
          </Box>
        )}

        {/* Update license */}
        {user?.userType === UserType.ADJUSTER && (
          <Box
            py={10}
            px={8}
            background="white"
            borderRadius={8}
            border="1px solid #ECEAE9"
            mb={2}
          >
            <Heading
              as="h3"
              fontSize={['3xl', '4xl']}
              color="#6D6E6F"
              mb={8}
              textAlign={['start', 'center']}
            >
              Driver&apos;s License
            </Heading>

            <UpdateDriverLicense
              editable={true}
              id={driverLicenseId}
              setId={setDriverLicenseId}
              address={driverLicenseAddress}
              setAddress={setDriverLicenseAddress}
              city={driverLicenseCity}
              setCity={setDriverLicenseCity}
              state={driverLicenseState}
              setState={setDriverLicenseState}
              zipcode={driverLicenseZipcode}
              setZipcode={setDriverLicenseZipcode}
              birthDate={driverLicenseBirthDate}
              setBirthdate={setDriverLicenseBirthDate}
              url={driverLicenseURL}
              setURL={setDriverLicenseURL}
              setFile={(nf) => {
                setDriverLicenseFileHasChanged(true)
                setDriverLicenseFile(nf)
              }}
              fileName={driverLicensefileName}
              setFileName={setDriverLicensefileName}
            />
            <HStack justify="center" mt={8}>
              <Button
                variant="adjustant"
                isLoading={showLoader}
                isDisabled={!canUpdateDriversLicense}
                onClick={updateDriversLicense}
              >
                Update
              </Button>
            </HStack>
          </Box>
        )}

        {/* W9 Tax form */}
        {user?.userType === UserType.ADJUSTER && !user?.w9TaxForm?.approved && (
          <Box
            py={10}
            px={8}
            background="white"
            borderRadius={8}
            border="1px solid #ECEAE9"
            mb={2}
          >
            <Heading
              as="h3"
              fontSize={['3xl', '4xl']}
              color="#6D6E6F"
              mb={8}
              textAlign={['start', 'center']}
            >
              W9 Tax Form
            </Heading>

            <UpdateTaxForm
              editable
              url={w9TaxFormURL}
              setURL={setW9TaxFormURL}
              setFile={(nf) => {
                setW9TaxFormFileHasChanged(true)
                setW9TaxFormFile(nf)
              }}
              fileName={w9TaxFormfileName}
              setFileName={setW9TaxFormfileName}
              align="center"
              hideFile={!!user?.w9TaxForm?.approved}
              officialDocumentURL={W9_FORM_URL}
              documentName="W-9 Form"
            />
            <HStack justify="center" mt={8}>
              <Button
                variant="adjustant"
                isLoading={showLoader}
                isDisabled={!canUpdateW9TaxForm}
                onClick={updateW9TaxForm}
              >
                Update
              </Button>
            </HStack>
          </Box>
        )}

        {/* W4 Tax form */}
        {user?.userType === UserType.ADJUSTER && !user?.w4TaxForm?.approved && (
          <Box
            py={10}
            px={8}
            background="white"
            borderRadius={8}
            border="1px solid #ECEAE9"
            mb={2}
          >
            <Heading
              as="h3"
              fontSize={['3xl', '4xl']}
              color="#6D6E6F"
              mb={8}
              textAlign={['start', 'center']}
            >
              W4 Tax Form
            </Heading>

            <UpdateTaxForm
              editable
              url={w4TaxFormURL}
              setURL={setW4TaxFormURL}
              setFile={(nf) => {
                setW4TaxFormFileHasChanged(true)
                setW4TaxFormFile(nf)
              }}
              fileName={w4TaxFormfileName}
              setFileName={setW4TaxFormfileName}
              align="center"
              hideFile={!!user?.w4TaxForm?.approved}
              officialDocumentURL={W4_FORM_URL}
              documentName="W-4 Form"
            />
            <HStack justify="center" mt={8}>
              <Button
                variant="adjustant"
                isLoading={showLoader}
                isDisabled={!canUpdateW4TaxForm}
                onClick={updateW4TaxForm}
              >
                Update
              </Button>
            </HStack>
          </Box>
        )}

        {/* I9 Tax form */}
        {user?.userType === UserType.ADJUSTER && !user?.i9TaxForm?.approved && (
          <Box
            py={10}
            px={8}
            background="white"
            borderRadius={8}
            border="1px solid #ECEAE9"
            mb={2}
          >
            <Heading
              as="h3"
              fontSize={['3xl', '4xl']}
              color="#6D6E6F"
              mb={8}
              textAlign={['start', 'center']}
            >
              I-9 Employment Eligibility Form
            </Heading>

            <UpdateTaxForm
              editable
              url={i9TaxFormURL}
              setURL={setI9TaxFormURL}
              setFile={(nf) => {
                setI9TaxFormFileHasChanged(true)
                setI9TaxFormFile(nf)
              }}
              fileName={i9TaxFormfileName}
              setFileName={setI9TaxFormfileName}
              align="center"
              hideFile={!!user?.i9TaxForm?.approved}
              officialDocumentURL={I9_FORM_URL}
              documentName="I-9 Form"
            />
            <HStack justify="center" mt={8}>
              <Button
                variant="adjustant"
                isLoading={showLoader}
                isDisabled={!canUpdateI9TaxForm}
                onClick={updateI9TaxForm}
              >
                Update
              </Button>
            </HStack>
          </Box>
        )}

        {/* Secondary Form of ID (optional) */}
        {user?.userType === UserType.ADJUSTER && i9TaxFormURL && (
          <Box
            py={10}
            px={8}
            background="white"
            borderRadius={8}
            border="1px solid #ECEAE9"
            mb={2}
          >
            <Heading
              as="h3"
              fontSize={['3xl', '4xl']}
              color="#6D6E6F"
              mb={8}
              textAlign={['start', 'center']}
            >
              Secondary Form of ID (Optional)
            </Heading>

            <UpdateTaxForm
              editable
              url={secondaryFormOfIDURL}
              setURL={setSecondaryFormOfIDURL}
              setFile={(nf) => {
                setSecondaryFormOfIDFileHasChanged(true)
                setSecondaryFormOfIDFile(nf)
              }}
              fileName={secondaryFormOfIDFileName}
              setFileName={setSecondaryFormOfIDFileName}
              align="center"
              hideFile={!!user?.secondaryFormOfID?.approved && !editorIsAdmin}
              documentName="Secondary form of ID"
            />

            <HStack justify="center" mt={8}>
              <Button
                variant="adjustant"
                isLoading={showLoader}
                isDisabled={!canUpdateSecondaryFormOfID}
                onClick={updateSecondaryFormOfID}
              >
                Update
              </Button>
            </HStack>
          </Box>
        )}

        {/* Miscellaneous Document (optional) */}
        {user?.userType === UserType.ADJUSTER && (
          <Box
            py={10}
            px={8}
            background="white"
            borderRadius={8}
            border="1px solid #ECEAE9"
            mb={2}
          >
            <Heading
              as="h3"
              fontSize={['3xl', '4xl']}
              color="#6D6E6F"
              textAlign={['start', 'center']}
            >
              Miscellaneous Document (Optional)
            </Heading>
            <Box textAlign={['start', 'center']} mb={8}>
              <Text as="i">Business and other related documents</Text>
            </Box>

            <UpdateMiscellaneosDocument
              editable
              url={miscelaneosDocumentURL}
              setURL={setMiscelaneosDocumentURL}
              setFile={(nf) => {
                setMiscelaneosDocumentFileHasChanged(true)
                setMiscelaneosDocumentFile(nf)
              }}
              fileName={miscelaneosDocumentFileName}
              setFileName={setMiscelaneosDocumentFileName}
              align="start"
              description={miscelaneosDocumentDescription}
              setDescription={setMiscelaneosDocumentDescription}
            />

            <HStack justify="center" mt={8}>
              <Button
                variant="adjustant"
                isLoading={showLoader}
                isDisabled={!canUpdateMiscellaneousDocument}
                onClick={updateMiscellaneousDocument}
              >
                Update
              </Button>
            </HStack>
          </Box>
        )}

        {/* Update Password */}
        <Box
          py={10}
          px={8}
          background="white"
          borderRadius={8}
          border="1px solid #ECEAE9"
          mb={2}
        >
          <Heading
            as="h3"
            fontSize={['3xl', '4xl']}
            color="#6D6E6F"
            textAlign={['start', 'center']}
          >
            Change Password
          </Heading>
          <Box textAlign={['start', 'center']} mb={8}>
            <Text as="i">
              This update will change your existing login credentials
            </Text>
          </Box>

          <Box mb={4}>
            <Flex mb={1}>
              <Label fontSize="sm" color="gray.700">
                Password
              </Label>
            </Flex>
            <Input
              value={password}
              borderRadius={3}
              type="password"
              onChange={(e) => {
                setPassword(e.target.value)
              }}
            />
            <PasswordStrength
              password={password}
              passwordStrength={passwordStrength}
              setPasswordStrength={setPasswordStrength}
            />
          </Box>

          <Box mb={4}>
            <Flex mb={1}>
              <Label fontSize="sm" color="gray.700">
                Confirm Password
              </Label>
            </Flex>
            <Input
              borderRadius={3}
              type="password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value)
              }}
            />
          </Box>

          <Heading as="h6" size="xs" color="red">
            {passwordErrorMessage}
          </Heading>

          <HStack justify="center" mt={8}>
            <Button
              variant="adjustant"
              isLoading={showLoader}
              isDisabled={
                !password || passwordError || passwordStrength !== 'strong'
              }
              onClick={updatePassword}
            >
              Update
            </Button>
          </HStack>
        </Box>
      </Box>
    </HStack>
  )
}

function UpdateProfile() {
  const { userId } = useParams()
  const navigate = useNavigate()
  const { user }: UserContextType = useContext(UserContext)
  const [userToShow, setUserToShow] = useState<User | undefined>()
  const [loadingProfile, setLoadingProfile] = useState<boolean>(true)

  const [getUser] = useLazyQuery(GET_USER_BY_ID, {
    onCompleted: (data: { UserOne: User }) => {
      setLoadingProfile(false)
      if (data?.UserOne) setUserToShow(data?.UserOne)
    },
    // onError: (err) => {}
  })

  useEffect(() => {
    if (user) {
      console.log('userId: ', userId)
      if (!userId || userId === user?._id) {
        setUserToShow(user)
        setLoadingProfile(false)
      } else if (user.userType === UserType.ADMIN) {
        getUser({ variables: { id: userId } })
      } else {
        navigate(`${GLOBAL_ROUTES.ADMIN}${ADMIN_ROUTES.DASHBOARD}`)
      }
    }
  }, [userId, user, userToShow, setUserToShow, getUser, navigate])

  if (loadingProfile)
    return (
      <Box
        minHeight="100%"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Stack direction="row" spacing={4}>
          <Spinner size="xl" />
        </Stack>
      </Box>
    )
  return (
    <UpdateProfileContent
      user={userToShow}
      editorIsAdmin={user?.userType === UserType.ADMIN}
    />
  )
}

export default UpdateProfile
