import React, { useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import type { FeatureCollection, Feature } from 'geojson'
import { ADJUSTANT_GREEN } from '../../themes/themes'

const Map = ({
  editable,
  states,
  setStates,
}: {
  editable: boolean
  states: string[]
  setStates?: React.Dispatch<React.SetStateAction<string[]>>
}) => {
  const UNSELECTED_COLOR = '#d3d3d3'
  const SELECTED_COLOR = ADJUSTANT_GREEN
  const svgRef = useRef<SVGSVGElement | null>(null)
  const [statesData, setStatesData] = useState<Feature[]>([])
  const projection = d3.geoAlbersUsa().translate([300, 150]).scale(600)
  const path = d3.geoPath().projection(projection)
  const colorScale = d3.scaleOrdinal<string>().range([SELECTED_COLOR])
  const [drSvg, setDrSvg] =
    useState<d3.Selection<SVGSVGElement, Feature<any>, null, undefined>>()

  useEffect(() => {
    if (statesData?.length === 0) {
      d3.json<FeatureCollection>(
        'https://s3-us-west-2.amazonaws.com/s.cdpn.io/25240/us-states.json'
      ).then((json) => {
        if (json) setStatesData(json.features)
      })
    }
  }, [setStatesData, statesData?.length])

  useEffect(() => {
    if (svgRef.current && !drSvg) {
      setDrSvg(d3.select<SVGSVGElement, Feature>(svgRef.current))
    }
  }, [drSvg])

  useEffect(() => {
    if (statesData?.length > 0 && drSvg) {
      //Removes all the children(path)
      drSvg.selectChildren().remove()

      const paths = drSvg
        .selectAll<d3.BaseType, Feature>('path')
        .data(statesData, (d) => d.properties?.name)

      paths
        .enter()
        .append('path')
        .attr('d', path)
        .style('fill', (d) => {
          const stateData = states.find((s) => s === d.properties?.name)
          if (stateData) return colorScale(stateData)
          return UNSELECTED_COLOR
        })
        .on('mouseover', function () {
          if (editable) {
            d3.select(this)
              .style('fill', SELECTED_COLOR)
              .style('cursor', 'pointer')
          }
        })
        .on('mouseout', function (dd, d) {
          if (editable) {
            const isSelected = !!states.find((s) => s === d?.properties?.name)
            d3.select(this).style(
              'fill',
              isSelected ? SELECTED_COLOR : UNSELECTED_COLOR
            )
          }
        })
        .on('click', function (dd, d) {
          console.log(d?.properties?.name)
          if (editable) {
            const i = states.findIndex((s) => s === d?.properties?.name)
            if (i >= 0) states.splice(i, 1)
            else states.push(d?.properties?.name)
            if (setStates) setStates([...states])
          }
        })

      //Borders
      drSvg
        .selectAll('path')
        .style('stroke', 'white')
        .style('stroke-width', '1px')
    }
  }, [
    states,
    setStates,
    statesData,
    svgRef,
    editable,
    drSvg,
    path,
    colorScale,
    SELECTED_COLOR,
  ])

  return (
    <div
      style={{
        background: '#F9F8F8',
        width: '100%',
        height: '100%',
      }}
    >
      <svg ref={svgRef} width="100%" height="100%" viewBox="0 0 570 335" />
    </div>
  )
}

export default Map
